<script>
import { PVC, POD } from '@/shell/config/types';
import { PVC_LABELS } from '@/pkg/pai/config/labels-annotations';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { get } from '@shell/utils/object';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { VM_POWER_STATES_ENUM } from '../plugins/pai-resource-class';

export default {
  data() {
    return {
      pods:            [],
      isDeleteDisks:   false,
      pvcs:            [],
      isShowVolumes:   false,
      checkList:       [],
      isIndeterminate: false,
      checkAll:        false,
      loading:         false,
    };
  },
  async fetch() {
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.pods = await this.$store.dispatch('cluster/findAll', { type: POD });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },
    values() {
      const values = this.$store.state['pai-common'].currentItem;

      if (Array.isArray(values)) {
        return values;
      } else {
        return [values];
      }
    },
    allVolumes() {
      const result = [];

      for (const value of this.values) {
        const vmPvcs = this.pvcs.filter((pvc) => {
          const mountTo = pvc.metadata.labels ? pvc.metadata.labels['com.tdology.pvc.mounto'] : null;
          const vmsetName = pvc.metadata.labels ? pvc.metadata.labels['com.tdology.virt.vmsets'] : null;

          return mountTo && vmsetName && vmsetName === value.metadata.name && pvc.metadata.namespace === value.metadata.namespace;
        });

        result.push(...vmPvcs);
      }

      return result;
    },
    allVolumesData() {
      return this.allVolumes.map((item) => {
        return {
          disabled: item.spec.accessModes[0] === 'ReadOnlyMany',
          name:     item.metadata.name,
        };
      });
    }
  },
  methods: {
    handleClose() {
      this.isDeleteDisks = false;
      this.isShowVolumes = false;
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    async onConfirm() {
      if (this.isShowVolumes) {
        // 用户选择了需要删除的磁盘后，删除选择的磁盘，清空清除剩余pvc的annotations
        try {
          this.loading = true;

          const deleteList = this.allVolumes.filter(v => this.checkList.includes(v.metadata.name));
          const list = this.allVolumes.filter(v => !this.checkList.includes(v.metadata.name));

          for (const item of deleteList) {
            await item.remove();
          }
          for (const item of list) {
            item.setLabel(PVC_LABELS.MOUNT_POD, '');
            item.setLabel(PVC_LABELS.MOUNT_VM, '');
            await item.save();
          }

          for (const value of this.values) {
            const vm = await this.$store.getters['cluster/byId'](PAI_RESOURCES.VMSET, value.id);

            await vm.remove();
          }
          this.loading = false;
          await this.handleClose();
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success')
          });
          if (this.$route.name === 'pai-c-cluster-resource-namespace-id') {
            await this.$router.push({
              name:   `${ PAI }-c-cluster-resource`,
              params: {
                product:  PAI,
                cluster:  this.$route.params.cluster,
                resource: PAI_RESOURCES.VMSET,
              },
            });
          }
        } catch (e) {
          this.loading = false;
          await this.$message({
            type:    'warning',
            message: e.message
          });
        }
      } else if (!this.isDeleteDisks) {
        // 直接删除云主机的情况,同时情况所有被占用的pvc的annotations
        try {
          this.loading = true;
          for (const item of this.allVolumes) {
            item.setLabel(PVC_LABELS.MOUNT_POD, '');
            item.setLabel(PVC_LABELS.MOUNT_VM, '');
            await item.save();
          }
          for (const value of this.values) {
            const vm = await this.$store.getters['cluster/byId'](PAI_RESOURCES.VMSET, value.id);

            await vm.remove();
          }
          this.loading = false;
          await this.handleClose();
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success')
          });
          if (this.$route.name === 'pai-c-cluster-resource-namespace-id') {
            await this.$router.push({
              name:   `${ PAI }-c-cluster-resource`,
              params: {
                product:  PAI,
                cluster:  this.$route.params.cluster,
                resource: PAI_RESOURCES.VMSET,
              },
            });
          }
        } catch (e) {
          this.loading = false;
          await this.$message({
            type:    'warning',
            message: e.message
          });
        }
      } else {
        // 删除云主机同时删除磁盘时,先列出云主机占有的磁盘
        this.isShowVolumes = true;
      }
    },
    handleCheckAllChange(val) {
      this.checkList = val ? this.allVolumes.map(v => v.name) : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      const checkedCount = value.length;

      this.checkAll = checkedCount === this.allVolumes.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allVolumes.length;
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('dialog.deleteDialog.title')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <div
      v-if="!isShowVolumes"
      class="content"
    >
      <span><el-checkbox v-model="isDeleteDisks">{{ t('pai.vmset.tips.delete') }}</el-checkbox></span>
    </div>
    <div v-else>
      <div>
        {{ allVolumesData.length > 0 ? t('pai.vmset.tips.disks') : t('pai.vmset.tips.nodisk') }}
      </div>
      <el-checkbox
        v-if="allVolumesData.length > 0"
        v-model="checkAll"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
      >
        {{ t('pai.labels.selectAll') }}
      </el-checkbox>
      <el-checkbox-group
        v-for="(item,i) in allVolumesData"
        :key="item.name+i"
        v-model="checkList"
        @change="handleCheckedChange"
      >
        <el-checkbox
          :label="item.name"
          :disabled="item.disabled"
        />
      </el-checkbox-group>
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.content{
  display: flex;
  justify-content: space-evenly;
}
</style>
