<script>
import { PAI_ANNOTATIONS } from '../config/types';
import { omitString } from '../utils/string';

export default {
  name:    'AuthNs',
  methods: { omitString },
  data() {
    let result = '';
    const auths = this.$attrs.row.annotations[PAI_ANNOTATIONS.NAMESPACES];

    if (auths && auths.split(',').length) {
      result = auths;
    } else {
      result = this.t('pai.apps.vm.global');
    }

    return { result };
  },
};
</script>

<template>
  <div>
    <el-tooltip
      v-if="result.length > 50"
      :content="result"
      effect="light"
    >
      <div slot="content">
        {{ result }}
      </div>
      <span>
        {{ omitString(result, 50) }}
      </span>
    </el-tooltip>
    <span v-else>
      {{ result }}
    </span>
  </div>
</template>
