export function importTypes($plugin) { 
const _chart = require.context('@pkg/pai/chart', true, /\.(vue|js|yaml)$/).keys();

_chart.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('chart', name, () => import(/* webpackChunkName: "chart" */ `@pkg/pai/chart/${ name }`)); // eslint-disable-line no-undef
});
const _detail = require.context('@pkg/pai/detail', true, /\.(vue|js|yaml)$/).keys();

_detail.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('detail', name, () => import(/* webpackChunkName: "detail" */ `@pkg/pai/detail/${ name }`)); // eslint-disable-line no-undef
});
const _edit = require.context('@pkg/pai/edit', true, /\.(vue|js|yaml)$/).keys();

_edit.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('edit', name, () => import(/* webpackChunkName: "edit" */ `@pkg/pai/edit/${ name }`)); // eslint-disable-line no-undef
});
const _list = require.context('@pkg/pai/list', true, /\.(vue|js|yaml)$/).keys();

_list.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('list', name, () => import(/* webpackChunkName: "list" */ `@pkg/pai/list/${ name }`)); // eslint-disable-line no-undef
});
const _models = require.context('@pkg/pai/models', true, /\.(vue|js|yaml)$/).keys();

_models.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('models', name, () => require(`@pkg/pai/models/${ name }`)); // eslint-disable-line no-undef
});
const _l10n = require.context('@pkg/pai/l10n', true, /\.(vue|js|yaml)$/).keys();

_l10n.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('l10n', name, () => import(/* webpackChunkName: "[request]" */ `@pkg/pai/l10n/${ name }.yaml`)); // eslint-disable-line no-undef
});
const _dialog = require.context('@pkg/pai/dialog', true, /\.(vue|js|yaml)$/).keys();

_dialog.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('dialog', name, () => import(/* webpackChunkName: "dialog" */ `@pkg/pai/dialog/${ name }`)); // eslint-disable-line no-undef
});
const _formatters = require.context('@pkg/pai/formatters', true, /\.(vue|js|yaml)$/).keys();

_formatters.forEach((f) => {
  let name = f.substr(2);
  const ext = name.lastIndexOf('.');

  name = name.substr(0, ext);

  $plugin.register('formatters', name, () => import(/* webpackChunkName: "formatters" */ `@pkg/pai/formatters/${ name }`)); // eslint-disable-line no-undef
});
};
