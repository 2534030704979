<script>
import containerIcon from '../assets/vmImage/container.png';
import vmsetsIcon from '../assets/vmImage/vmsets.png';
import kataIcon from '../assets/vmImage/kata.png';

export default {
  props: {
    row: {
      type:     Object,
      required: true,
    },
  },

  computed: {
    icon() {
      if (this.row.isVmsets) {
        return vmsetsIcon;
      }
      if (this.row.isKata) {
        return kataIcon;
      }
      return containerIcon;
    },
  },

};
</script>

<template>
  <img
    :src="icon"
  >
</template>
