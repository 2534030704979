<script>
import * as echarts from 'echarts';
import day from 'dayjs';
import { numDelivery, getLastDayHour, supplementZero } from '../../../utils/units';
import metricPoller from '@shell/mixins/metric-poller';

export default {
  name:  'ClusterChart',
  props: {
    rows: {
      type:     Array,
      required: true
    },
  },
  computed: {
    initData() {
      return !!(this.rows?.length);
    },
  },
  mixins: [metricPoller],
  watch:  {
    initData: {
      handler(neu) {
        if (neu) {
          this.rows.map((item, index) => {
            if (item.id === 'local') {
              this.rows.unshift(this.rows.splice(index, 1)[0]);
            }
          });
          this.rows.forEach((item) => {
            if (item.metadata?.state?.name === 'active') {
              this.activeRows.push(item);
            }
          });
          this.loadMetrics();
        }
      },
      immediate: true
    },
  },
  data() {
    return {
      clusterValue:   [],
      useValue:       '01',
      topValue:       '01',
      isTopValue:     true,
      clusterOptions: [],
      useOptions:     [
        {
          name:  this.t(`pai.detail.vmset.cpuUtilization`),
          value: '01'
        },
        {
          name:  this.t('pai.detail.vmset.ramUtilization'),
          value: '02'
        },
        {
          name:  this.t('pai.detail.vmset.tab.overview.diskPercentage'),
          value: '03'
        },
      ],
      topDatas:      [],
      topChartData:  [],
      useChartData:  [],
      timeFormat:    'HH:mm',
      activeRows:    [],
      selectCluster: [],
      showGrowl:     false,
      dataNum:       0,
    };
  },
  methods: {
    async loadMetrics() {
      this.dataNum += 1;
      if (this.dataNum !== 1) {
        await this.getCPUUtil('one');
        await this.getMemoryUtil( 'one');
        await this.gtDiskUtil('one');
        if (this.isTopValue === true) {
          this.$nextTick(() => {
            const chartArr = [];

            for (let i = 0; i < this.topDatas[0].length; i++) {
              const cpuData = this.topDatas[0][i];
              const memoryData = this.topDatas[1][i];
              const diskData = this.topDatas[2][i];

              let cpuNum = 0;
              let memoryNum = 0;
              let diskNum = 0;

              if ( cpuData?.num && memoryData?.num && diskData?.num) {
                const filteredCpuData = cpuData.num.filter(val => val !== undefined);
                const filteredMemoryData = memoryData.num.filter(val => val !== undefined);
                const filteredDiskData = diskData.num.filter(val => val !== undefined);

                cpuNum = filteredCpuData[filteredCpuData.length - 1];
                memoryNum = filteredMemoryData[filteredMemoryData.length - 1];
                diskNum = filteredDiskData[filteredDiskData.length - 1];
              }
              if (cpuNum !== 0 && memoryNum !== 0 && diskNum !== 0) {
                chartArr.push({
                  name: this.topDatas[0][i].name,
                  cpuNum,
                  memoryNum,
                  diskNum,
                });
              }
            }
            this.topChartData = chartArr;
            this.$nextTick(() => {
              this.initTopChart();
            });
          });
        }
      }
    },

    getCLusterName(id) {
      let clusterName = '';

      this.activeRows.filter((item) => {
        if (item.id === id) {
          clusterName = item.nameDisplay;
        }
      });

      return clusterName;
    },
    // 柱状图排序
    sortNum(property) {
      return function(object1, object2) {
        const value1 = object1[property];
        const value2 = object2[property];

        if (value1 > value2 ) {
          return -1;
        } else if (value1 < value2 ) {
          return 1;
        } else {
          return 0;
        }
      };
    },

    // TOP排行切换
    topChange(val) {
      this.isTopValue = true;
      this.topValue = val;
      this.initTopChart();
    },

    //  集群select切换
    clusterChange(val) {
      const selectData = this.activeRows.filter((item) => {
        return val.includes(item.id);
      });

      this.selectCluster = selectData;
      this.isTopValue = false;
      this.showGrowl = true;
      this.dataNum = 2;
      if (this.useValue === '01') {
        this.getCPUUtil();
      } else if (this.useValue === '02') {
        this.getMemoryUtil();
      } else if (this.useValue === '03') {
        this.gtDiskUtil();
      }
    },

    //  利用率select切换
    CPUChange(val) {
      this.isTopValue = false;
      this.useValue = val;
      this.showGrowl = true;
      this.dataNum = 2;
      if (val === '01') {
        this.getCPUUtil();
      } else if (val === '02') {
        this.getMemoryUtil();
      } else {
        this.gtDiskUtil();
      }
    },

    // 资源排行
    initTopChart() {
      if (!this.$refs.topChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.topChart);

      myChart.clear();
      let option = {};
      const seriesArr = [];
      const labelOption = {
        rotate:        90,
        align:         'left',
        verticalAlign: 'middle',
        position:      'insideBottom',
        distance:      15,
      };

      let sortArr = [];

      if (this.topChartData?.length !== 0) {
        if (this.topValue === '01') {
          sortArr = (this.topChartData.sort(this.sortNum('cpuNum'))).slice(0, 5);
          seriesArr[0] = {
            name:        this.t(`pai.detail.vmset.cpuUtilization`),
            type:        'bar',
            barMaxWidth: '22px',
            label:       labelOption,
            emphasis:    { focus: 'series' },
            itemStyle:   { normal: { color: '#5087EC' } },
            data:        sortArr.map(item => item.cpuNum)
          };
        } else if (this.topValue === '02' ) {
          sortArr = (this.topChartData.sort(this.sortNum('memoryNum'))).slice(0, 5);
          seriesArr[0] = {
            name:        this.t('pai.detail.vmset.ramUtilization'),
            type:        'bar',
            barMaxWidth: '22px',
            label:       labelOption,
            emphasis:    { focus: 'series' },
            itemStyle:   { normal: { color: '#68BBC4' } },
            data:        sortArr.map(item => item.memoryNum)
          };
        } else {
          sortArr = (this.topChartData.sort(this.sortNum('diskNum'))).slice(0, 5);
          seriesArr[0] = {
            name:        this.t('pai.detail.vmset.tab.overview.diskPercentage'),
            type:        'bar',
            barMaxWidth: '22px',
            label:       labelOption,
            emphasis:    { focus: 'series' },
            itemStyle:   { normal: { color: '#58a55C' } },
            data:        sortArr.map(item => item.diskNum)
          };
        }

        option = {
          animation: false,
          grid:      {
            left:         '2%',
            right:        '2%',
            bottom:       '6%',
            containLabel: true
          },
          tooltip: {
            trigger:     'axis',
            axisPointer: { type: 'shadow' },
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `
               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: [
            {
              type: 'category',
              data: sortArr.map(item => item?.name)
            }
          ],
          yAxis: [
            {
              type:    'value',
              name:    '单位：%',
              nameGap: '20',
              max:     100,
            }
          ],
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    //  资源利用率
    initUseChart(data) {
      if (!this.$refs.useChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.useChart);

      myChart.clear();
      const seriesArr = [];
      const Xdata = getLastDayHour(new Date(), 24);
      let option = {};

      if (data?.length !== 0) {
        data.forEach((item) => {
          seriesArr.push({
            name: item?.name,
            type: 'line',
            data: supplementZero(Xdata, item.numArr)
          });
        });

        option = {
          animation: false,
          color:     ['#76C1C9', '#5087EC', '#FAC95D', '#D95040', '#58A55C', '#CECECE'],
          grid:      {
            left:         '3%',
            right:        '4%',
            bottom:       '6%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `
               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        Xdata
          },
          yAxis: {
            type:    'value',
            name:    '单位：%',
            nameGap: '20',
            max:     100,
          },
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // CPU利用率数据
    async getCPUUtil(status) {
      const startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000; // 24小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const activeRows = this.selectCluster?.length !== 0 ? this.selectCluster : this.activeRows;

      for (const item of activeRows) {
        const params = {
          showGrowl: this.dataNum === 2, clusterName: this.getCLusterName(item.id), url: `/k8s/clusters/${ item.id }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate({__name__=~"node_cpu_seconds_total",mode="idle"}[240s])))*100&start=${ startTime }&end=${ endTime }&step=3600`
        };

        const result = await this.$store.dispatch('pai-common/getUse', params).catch(err => new Error(err));
        const datas = result?.data?.data?.result[0]?.values;

        if (datas !== undefined) {
          newArr.push({
            name: item?.nameDisplay,
            num:  datas?.map((val) => {
              return numDelivery(val[1]);
            }),
            numArr: datas?.map((val) => {
              const now = new Date(val[0] * 1000);

              const time = `${ [now.getMonth() + 1, now.getDate()].join('/') } ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
              const num = numDelivery(val[1]);

              val[0] = time;
              val[1] = num;

              return val;
            })
          });
        }
      }
      if (status === 'one') {
        this.topDatas[0] = newArr;
      }
      this.initUseChart(newArr);
    },

    // 内存利用率数据
    async getMemoryUtil(status) {
      const startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000; // 24小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const activeRows = this.selectCluster?.length !== 0 ? this.selectCluster : this.activeRows;

      for (const item of activeRows) {
        const params = {
          showGrowl: status === 'one' ? false : this.showGrowl, clusterName: this.getCLusterName(item.id), url: `/k8s/clusters/${ item.id }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - sum(node_memory_MemAvailable_bytes OR windows_os_physical_memory_free_bytes) / sum(node_memory_MemTotal_bytes OR windows_cs_physical_memory_bytes))*100&start=${ startTime }&end=${ endTime }&step=3600`
        };
        const result = await this.$store.dispatch('pai-common/getUse', params).catch(err => new Error(err));

        const datas = result?.data?.data?.result[0]?.values;

        if (datas !== undefined) {
          newArr.push({
            name: item?.nameDisplay,
            num:  datas?.map((val) => {
              return numDelivery(val[1]);
            }),
            numArr: datas?.map((val) => {
              const now = new Date(val[0] * 1000);

              const time = `${ [now.getMonth() + 1, now.getDate()].join('/') } ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
              const num = numDelivery(val[1]);

              val[0] = time;
              val[1] = num;

              return val;
            })
          });
        }
      }

      if (status !== 'one') {
        this.initUseChart(newArr);
      } else {
        this.topDatas[1] = newArr;
      }
    },

    // 磁盘利用率数据
    async gtDiskUtil(status) {
      const startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000; // 24小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const activeRows = this.selectCluster?.length !== 0 ? this.selectCluster : this.activeRows;

      for (const item of activeRows) {
        const params = {
          showGrowl: status === 'one' ? false : this.showGrowl, clusterName: this.getCLusterName(item.id), url: `/k8s/clusters/${ item.id }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes) by (device, instance)))) / ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes{device=~"data/.*|longhorn/.*"}) by (device, instance) or vector(0)))) * 100&start=${ startTime }&end=${ endTime }&step=3600`
        };
        const result = await this.$store.dispatch('pai-common/getUse', params).catch(err => new Error(err));

        const datas = result?.data?.data?.result[0]?.values;

        if (datas !== undefined) {
          newArr.push({
            name: item?.nameDisplay,
            num:  datas?.map((val) => {
              return numDelivery(val[1]);
            }),
            numArr: datas?.map((val) => {
              const now = new Date(val[0] * 1000);

              const time = `${ [now.getMonth() + 1, now.getDate()].join('/') } ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
              const num = numDelivery(val[1]);

              val[0] = time;
              val[1] = num;

              return val;
            })
          });
        }
      }

      if (status !== 'one') {
        this.initUseChart(newArr);
      } else {
        this.topDatas[2] = newArr;
      }
    },

  }
};
</script>

<template>
  <div class="clusterChart">
    <div class="clusterChart-top">
      <div class="clusterChart-title">
        {{ t('pai.vmset.resource') + t('pai.labels.ranking') +'Top5' }}
        <div class="cluster-slect">
          <el-select
            v-model="topValue"
            filterable
            @change="topChange"
          >
            <el-option
              v-for="(item, index) in useOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div />
      <div
        ref="topChart"
        style="width: 100%; height: 300px"
      />
    </div>
    <div class="clusterChart-use">
      <div class="clusterChart-title">
        <div>{{ t('pai.detail.vmset.resourceUtilization') }}</div>
        <div class="cluster-slect">
          <el-select
            v-model="clusterValue"
            class="mySelect"
            :placeholder="t('pai.detail.vmset.allCluster')"
            clearable
            multiple
            @change="clusterChange"
          >
            <el-option
              v-for="(item, index) in rows"
              :key="index"
              :label="item.nameDisplay"
              :value="item.id"
              :disabled="item.metadata.state.name !== 'active'"
            />
          </el-select>
          <el-select
            v-model="useValue"
            filterable
            @change="CPUChange"
          >
            <el-option
              v-for="(item, index) in useOptions"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div
        ref="useChart"
        style="width: 100%; height: 300px"
      />
    </div>
  </div>
</template>

<style lang="scss">
.clusterChart {
  .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  .el-select .el-input .el-select__caret {
    color: #000;
  }

  .el-input__icon {
    line-height: 30px;
  }
  .mySelect {
    .el-select__tags{
      width: 100%;
      max-width: 208px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
  }
}
</style>

<style lang="scss" scoped>
.clusterChart {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  .clusterChart-top,.clusterChart-use{
    width: 50%;
    padding: 10px 16px;
    background-color: rgba(255, 255, 255, 1)
  }
  .clusterChart-title{
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  .clusterChart-top{
    margin-right: 14px;
  }
}

</style>
