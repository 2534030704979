import NetworkAttachmentDef from '@shell/models/k8s.cni.cncf.io.networkattachmentdefinition';
import { ALIAS } from '~/pkg/pai/config/labels-annotations';
import { sortableNumericSuffix } from '@shell/utils/sort';
import Vue from 'vue';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';

export default class extends PaiLocationMixin(NetworkAttachmentDef) {
  get _availableActions() {
    const out = super._availableActions;

    return [{
      action: 'goToViewYaml',
      label:  this.t( 'action.viewYaml'),
      icon:   'icon icon-file',
    }, ...out];
  }

  get alias() {
    if (this.annotations && this.annotations[ALIAS]) {
      return this.annotations[ALIAS];
    }

    return this.name;
  }

  get aliasSort() {
    return sortableNumericSuffix(this.alias)
      .toLowerCase();
  }

  applyDefaults() {
    const spec = this.spec || {
      config: JSON.stringify({
        cniVersion:  '1.0.0',
        name:        '',
        type:        'bridge',
        bridge:      '',
        promiscMode: true,
        vlan:        '',
        ipam:        {},
      }),
    };

    Vue.set(this, 'spec', spec);
  }
}
