<script>
import { PRODUCT_NAME } from '../config/pai';
import { convertResource } from '../utils';
import { NAME as EXPLORER } from '@shell/config/product/explorer';

export default {
  props: {
    value: {
      type:     null,
      required: true
    },
    objectId: { type: String },
    type:     {
      type:     String,
      required: true,
    },
    cluster: {
      type:    String,
      default: 'local',
    },
    namespace: {
      type:    String,
      default: '',
    },

    product: {
      type:    String,
      default: PRODUCT_NAME,
    }
  },

  computed: {
    url() {
      const product = this.$store.getters['currentProduct'].name;

      if (product === PRODUCT_NAME) {
        const name = `${ this.product }-c-cluster-resource${ this.namespace ? '-namespace' : '' }-id`;

        const params = {
          resource:  this.type,
          namespace: this.namespace,
          id:        this.objectId ? this.objectId : this.value,
          product:   this.product || PRODUCT_NAME,
        };

        return { name, params };
      } else {
        const name = `c-cluster-product-resource-id`;

        const params = {
          resource: convertResource(this.type),
          id:       this.objectId ? this.objectId : this.value,
          product:  this.product || EXPLORER,
        };

        return { name, params };
      }
    }
  }
};
</script>

<template>
  <span v-if="value">
    <nuxt-link :to="url">
      {{ value }}
    </nuxt-link>
  </span>
</template>
