import GlobalRole from '@shell/models/management.cattle.io.globalrole';
import { getDetailLocation } from '~/pkg/pai/utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';
import { SUBTYPE_MAPPING } from '@shell/models/management.cattle.io.roletemplate';

const GLOBAL = SUBTYPE_MAPPING.GLOBAL.key;

export default class extends GlobalRole {
  get detailLocation() {
    return getDetailLocation(this, PRODUCT_NAME);
  }

  get listLocation() {
    return {
      name: `${ PRODUCT_NAME }-c-cluster-auths-roles`,
      hash: `#${ GLOBAL }`,
    };
  }
}
