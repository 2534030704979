import { getDetailLocation } from '../utils';
import Namespace from '@shell/models/namespace';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import { POD } from '@shell/config/types';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { CONTAINER_DEFAULT_RESOURCE_LIMIT, RESOURCE_QUOTA } from '@shell/config/labels-annotations';

export default class extends Namespace {
  get containerDefaultResourceLimit() {
    return JSON.parse(this.metadata.annotations[CONTAINER_DEFAULT_RESOURCE_LIMIT] || `{"limit":{}}`);
  }
  get isHarvester() {
    return false;
  }

  get detailLocation() {
    return getDetailLocation(this);
  }

  get listLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-projectsnamespaces`,
        params: {
          product: PRODUCT_NAME,
          cluster: this.$rootGetters['clusterId'],
        },
      };
    }

    return super.listLocation;
  }

  get pods() {
    const inStore = this.$rootGetters['currentProduct'].inStore;

    const pods = this.$rootGetters[`${ inStore }/all`](POD) || [];

    return pods.filter(
      p => p.namespace === this.name
    );
  }

  async getPods() {
    const inStore = this.$rootGetters['currentProduct'].inStore;

    let pods = this.$rootGetters[`${ inStore }/all`](POD) || [];

    if (pods.length === 0) {
      pods = await this.$dispatch('findAll', { type: POD });
    }

    return pods.filter(
      p => p.namespace === this.name
    );
  }

  get vmsets() {
    const pods = this.$rootGetters[`cluster/all`](PAI_RESOURCES.VMSET) || [];

    return pods.filter(
      p => p.namespace === this.name
    );
  }

  async getVmsets() {
    let vmsets = this.$rootGetters[`cluster/all`](PAI_RESOURCES.VMSET) || [];

    if (vmsets.length === 0) {
      vmsets = await this.$dispatch('findAll', { type: PAI_RESOURCES.VMSET });
    }

    return vmsets.filter(
      p => p.namespace === this.name
    );
  }
}
