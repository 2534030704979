<script>
import * as echarts from 'echarts';
import day from 'dayjs';
import { mapGetters } from 'vuex';
export default {
  name:  'Monitor',
  props: {
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
    activeName: {
      type:    String,
      default: () => {
        return '';
      }
    },
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    const params = this.$route.params;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return {
      params,
      dataValue:     '',
      value1:        '',
      status:        'anHour',
      startTime:     new Date(String(day().subtract(1, 'hours').format(dateFormat))).getTime() / 1000,
      endTime:       new Date(String(day().format(dateFormat))).getTime() / 1000,
      end:           new Date(String(day().format(dateFormat))).getTime() / 1000,
      step:          15,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
    };
  },
  created() {
    this.$store.dispatch('pai-grafana/getCpuUsedRate', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getRamUsedRate', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getNetLine', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getDiskIOPS', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getNetMBps', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getDiskBPS', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.podName,
      step:      15,
      startTime: this.startTime,
      endTime:   this.endTime
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.getCpuUsedRate();
      this.getRamUsedRate();
      this.getNetLineRate();
      this.getDiskIOPSRate();
      this.getNetBpsData();
      this.getDiskBPSData();
    });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    diskOptionValue() {
      return this.diskTable.length > 0 ? this.diskTable[0].name : '';
    },
    storageClass() {
      return this.diskTable.length > 0 ? this.diskTable[0].storageClass : '';
    },
    size() {
      return this.diskTable.length > 0 ? this.diskTable[0].size : '';
    },
    cpuLine() {
      return this.$store.state['pai-grafana'].cpuLine;
    },
    ramLine() {
      return this.$store.state['pai-grafana'].ramLine;
    },
    netLine() {
      return this.$store.state['pai-grafana'].netLine;
    },
    diskIOPSData() {
      return this.$store.state['pai-grafana'].diskIOPSData;
    },
    netMBpsData() {
      return this.$store.state['pai-grafana'].netMBpsData;
    },
    diskBPSData() {
      return this.$store.state['pai-grafana'].diskBPSData;
    }
  },
  watch: {
    podName() {
      this.getCpuUsedRate();
      this.getRamUsedRate();
      this.getNetLineRate();
      this.getDiskIOPSRate();
      this.getNetBpsData();
      this.getDiskBPSData();
    },
    cpuLine(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getCpuUsedRate();
      }
    },
    ramLine(val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.getRamUsedRate();
      }
    },
    netLine() {
      this.getNetLineRate();
    },
    diskIOPSData() {
      this.getDiskIOPSRate();
    },
    netMBpsData() {
      this.getNetBpsData();
    },
    diskBPSData() {
      this.getDiskBPSData();
    },
    activeName(val) {
      if (val === 'monitor') {
        this.getCpuUsedRate();
        this.getRamUsedRate();
        this.getNetLineRate();
        this.getDiskIOPSRate();
        this.getNetBpsData();
        this.getDiskBPSData();
      }
    }
  },
  methods: {
    getCpuUsedRate() {
      const myChart = echarts.init(this.$refs.cpuUsedRate);
      const option = {
        title: {
          text:      this?.cpuLine?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${ params[0].marker }&nbsp;${ params[0]?.seriesName }&nbsp;` + `<span style="float: right">${ params[0].value }%</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.cpuLine[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：%',
          nameGap: '10',
          max:     100,
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this?.cpuLine[0]?.pod,
            smooth:    true,
            symbol:    this?.cpuLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.cpuLine?.length === 0 ? [] : this?.cpuLine[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(178,157,225,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(178,157,225,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(178,157,225,0.1)'
                }
              ])
            },
          },
        ],
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getRamUsedRate() {
      const myChart = echarts.init(this.$refs.ramUsedRate);
      const option = {
        title: {
          text:      this?.ramLine?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${ params[0].marker }&nbsp;${ params[0]?.seriesName }&nbsp;` + `<span style="float: right">${ params[0].value }%</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.ramLine[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：%',
          nameGap: '10',
          max:     100,
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this?.ramLine[0]?.pod,
            smooth:    true,
            symbol:    this?.ramLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.ramLine?.length === 0 ? [] : this?.ramLine[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(178,157,225,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(178,157,225,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(178,157,225,0.1)'
                }
              ])
            },
          },
        ],

      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getNetLineRate() {
      const myChart = echarts.init(this.$refs.netRate);
      const option = {
        title: {
          text:      this?.netLine?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }M</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }M</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.netLine?.length === 0 ? [] : this.netLine[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：M',
          nameGap: '10',
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.netLine[0]?.node,
            smooth:    true,
            symbol:    this.netLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.netLine?.length === 0 ? [] : this.netLine[0]?.nodeValuesData,
            itemStyle: { color: '#4097D5' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  '#4097D5'
                },
                {
                  offset: 1,
                  color:  '#DBE4EF'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.netLine[1]?.node,
            smooth:    true,
            symbol:    this.netLine[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.netLine?.length === 0 ? [] : this.netLine[1]?.nodeValuesData,
            itemStyle: { color: '#C75250' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  '#C75250'
                },
                {
                  offset: 1,
                  color:  '#F7ECEC'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getDiskIOPSRate() {
      const myChart = echarts.init(this.$refs.diskIOPS);
      const option = {
        title: {
          text:      this?.diskIOPSData?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }(次/s）</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }(次/s）</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.diskIOPSData?.length === 0 ? [] : this.diskIOPSData[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：(次/s)',
          nameGap: '10',
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.diskIOPSData[0]?.node,
            smooth:    true,
            symbol:    this.diskIOPSData[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.diskIOPSData?.length === 0 ? [] : this.diskIOPSData[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(189,183,107,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(189,183,107,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(189,183,107,0.1)'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.diskIOPSData[1]?.node,
            smooth:    true,
            symbol:    this.diskIOPSData[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.diskIOPSData?.length === 0 ? [] : this.diskIOPSData[1]?.nodeValuesData,
            itemStyle: { color: 'rgba(32,178,170,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(32,178,170,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(32,178,170,0.1)'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getNetBpsData() {
      const myChart = echarts.init(this.$refs.netBPS);
      const option = {
        title: {
          text:      this?.netMBpsData?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }个</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }个</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.netMBpsData?.length === 0 ? [] : this.netMBpsData[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：Kb/s',
          nameGap: '10',
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.netMBpsData[0]?.node,
            smooth:    true,
            symbol:    this.netMBpsData[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.netMBpsData?.length === 0 ? [] : this.netMBpsData[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(189,183,107,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(189,183,107,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(189,183,107,0.1)'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.netMBpsData[1]?.node,
            smooth:    true,
            symbol:    this.netMBpsData[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.netMBpsData?.length === 0 ? [] : this.netMBpsData[1]?.nodeValuesData,
            itemStyle: { color: 'rgba(32,178,170,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(32,178,170,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(32,178,170,0.1)'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getDiskBPSData() {
      const myChart = echarts.init(this.$refs.diskBPS);
      const option = {
        title: {
          text:      this?.diskBPSData?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }（Kb/s）</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }（Kb/s）</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this?.diskBPSData?.length === 0 ? [] : this.diskBPSData[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：Kb/s',
          nameGap: '10',
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.diskBPSData[0]?.node,
            smooth:    true,
            symbol:    this.diskBPSData[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.diskBPSData?.length === 0 ? [] : this.diskBPSData[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(189,183,107,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(189,183,107,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(189,183,107,0.1)'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.diskBPSData[1]?.node,
            smooth:    true,
            symbol:    this.diskBPSData[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this?.diskBPSData?.length === 0 ? [] : this.diskBPSData[1]?.nodeValuesData,
            itemStyle: { color: 'rgba(32,178,170,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(32,178,170,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(32,178,170,0.1)'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getStartTime(time) {
      return new Date(String(time.format(this.dateFormat))).getTime() / 1000;
    },
    getEndTime(time) {
      return new Date(String(time.format('YYYY-MM-DD'))).getTime() / 1000;
    },
    query() {
      const timeInterval = parseInt(this.endTime - this.startTime) / 3600; // 结束时间-开始时间 = N小时

      if (timeInterval <= 1) {
        this.step = 15;
      } else if (timeInterval > 1 && timeInterval <= 12) {
        this.step = 172;
      } else if (timeInterval > 12 && timeInterval <= 24) {
        this.step = 345;
      } else {
        this.step = 15 * timeInterval;
      }

      this.$store.dispatch('pai-grafana/getCpuUsedRate', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getRamUsedRate', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getNetLine', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getDiskIOPS', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getNetMBps', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getDiskBPS', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      this.step,
        startTime: this.startTime,
        endTime:   this.endTime
      });
    },
    reset() {
      this.status = 'anHour';
      this.value1 = '';
      this.startTime = new Date(String(day().subtract(1, 'hours').format(this.dateFormat))).getTime() / 1000;
      this.endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000;
      this.$store.dispatch('pai-grafana/getCpuUsedRate', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getRamUsedRate', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getNetLine', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getDiskIOPS', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getNetMBps', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
      this.$store.dispatch('pai-grafana/getDiskBPS', {
        cluster:   this.params.cluster,
        namespace: this.value.metadata.namespace,
        podName:   this.podName,
        step:      15,
        startTime: this.startTime,
        endTime:   this.endTime
      });
    },
    dataVal(val) {
      const startTime = new Date(val[0]);
      const endTime = new Date(val[1]);

      this.startTime = startTime / 1000;
      this.endTime = endTime / 1000;
    },
    selectTime(value) {
      if (value === 'anHour') {
        const start = new Date(String(day().subtract(1, 'hours').format(this.dateFormat))).getTime() / 1000;

        this.$store.dispatch('pai-grafana/getCpuUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getRamUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetLine', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskIOPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetMBps', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskBPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      15,
          startTime: start,
          endTime:   this.end
        });
      } else if (value === 'twelveHours') {
        const start = new Date(String(day().subtract(12, 'hours').format(this.dateFormat))).getTime() / 1000;

        this.$store.dispatch('pai-grafana/getCpuUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getRamUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetLine', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskIOPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetMBps', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskBPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      172,
          startTime: start,
          endTime:   this.end
        });
      } else if (value === 'twentyFourHours') {
        const start = new Date(String(day().day(day().day() - 1).format(this.dateFormat))).getTime() / 1000;

        this.$store.dispatch('pai-grafana/getCpuUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getRamUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetLine', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskIOPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetMBps', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskBPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      345,
          startTime: start,
          endTime:   this.end
        });
      } else if (value === 'lastMonth') {
        const start = new Date(String(day().subtract(1, 'months').format(this.dateFormat))).getTime() / 1000;

        this.$store.dispatch('pai-grafana/getCpuUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getRamUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetLine', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskIOPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getNetMBps', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
        this.$store.dispatch('pai-grafana/getDiskBPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.podName,
          step:      368,
          startTime: start,
          endTime:   this.end
        });
      }
    }
  }
};
</script>

<template>
  <el-card>
    <div class="filterTime">
      <el-radio-group
        v-model="status"
        size="small"
        @change="selectTime"
      >
        <el-radio-button
          label="anHour"
        >
          {{ t('pai.detail.vmset.tab.monitor.anHour') }}
        </el-radio-button>
        <el-radio-button
          label="twelveHours"
        >
          {{ t('pai.detail.vmset.tab.monitor.twelveHours') }}
        </el-radio-button>
        <el-radio-button
          label="twentyFourHours"
        >
          {{ t('pai.detail.vmset.tab.monitor.day') }}
        </el-radio-button>
        <el-radio-button
          label="lastMonth"
        >
          {{ t('pai.detail.vmset.tab.monitor.month') }}
        </el-radio-button>
      </el-radio-group>
      <div class="block">
        <el-date-picker
          v-model="value1"
          size="small"
          type="datetimerange"
          :range-separator="t('pai.detail.vmset.tab.monitor.to')"
          :start-placeholder="t('pai.detail.vmset.tab.monitor.startDate')"
          :end-placeholder="t('pai.detail.vmset.tab.monitor.endDate')"
          :picker-options="pickerOptions"
          @change="dataVal"
        />
      </div>
      <span style="marginLeft: 10px">
        <el-button
          size="small"
          @click="query"
        >{{ t('pai.detail.vmset.tab.monitor.query') }}</el-button>
        <el-button
          style="marginLeft: 10px"
          size="small"
          @click="reset"
        >{{ t('pai.detail.vmset.tab.monitor.reset') }}</el-button>
      </span>
    </div>
    <el-row
      :gutter="20"
      class="card"
    >
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.overview.cpuUsage') }}
        </div>
        <el-card shadow="never">
          <div
            ref="cpuUsedRate"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.overview.ramUsage') }}
        </div>
        <el-card shadow="never">
          <div
            ref="ramUsedRate"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.monitor.netWorkIO') }}
        </div>
        <el-card shadow="never">
          <div
            ref="netBPS"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.monitor.netRate') }}
        </div>
        <el-card shadow="never">
          <div
            ref="netRate"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.monitor.diskBPS') }}
        </div>
        <el-card shadow="never">
          <div
            ref="diskBPS"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
      <el-col :span="12">
        <div style="border-left: 3px solid #246FA5; margin: 10px 0">
&emsp;{{ t('pai.detail.vmset.tab.monitor.diskIOPS') }}
        </div>
        <el-card shadow="never">
          <div
            ref="diskIOPS"
            style="width: 100%; height: 260px"
          />
        </el-card>
      </el-col>
    </el-row>
  </el-card>
</template>
<style lang="scss" scoped>
.card{
  .el-card{
    background-color: #FAFAFA;
  }
 }
.filterTime{
  display: flex;
}
.block{
  margin-left: 10px;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner{
  border-radius: 140px;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner{
  border-radius: 140px;
}
::v-deep .el-radio-button__inner{
  border-radius: 140px;
  width: 85px;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-radius: 140px;
  width: 85px;
}
::v-deep .el-radio-button{
  margin-left: 10px;
}
</style>
