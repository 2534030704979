import { PRODUCT_NAME } from '../config/pai';
import Promise from 'lodash/_Promise';
import { deepClone } from '../utils';

const lbBaseUrl = '/api/v1/namespaces/picloud-system/services/http:ipvs-ctrl:80/proxy';

const state = function() {
  return {
    osList:              [],
    currentItem:         {},
    currentModal:        '',
    services:            [],
    useList:             [],
    lbServices:          {},
    lbStatus:            [],
    currentVmRow:        1,
    currentContainerRow: 1
  };
};

const mutations = {
  getOs(state, payload) {
    state.osList = payload;
  },
  UPDATE_STATE: (state, payloads) => {
    for (const key in payloads) {
      state[key] = deepClone(payloads[key]);
    }
  },
  getUse(state, payload) {
    state.useList = payload;
  },
};

const getters = {};

const actions = {
  getOs({ commit }, cluster) {
    const url = '/ipxe/os';

    return new Promise((resolve, reject) => {
      return this.$axios.get(url, { params: { cluster } }).then((res) => {
        if (Array.isArray(res.data)) {
          commit('getOs', res.data);
        } else {
          commit('getOs', []);
        }
        resolve(res);
      }).catch((err) => {
        commit('getOs', []);
        reject(err);
      });
    });
  },

  restoreSnapShot({ commit }, payload) {
    const urlOperation = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/volumes/${ payload.pvName }?action=attach`;
    const urlRevert = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/volumes/${ payload.pvName }?action=snapshotRevert`;
    const urlDetach = `/k8s/clusters/${ payload.cluster }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/volumes/${ payload.pvName }?action=detach`;

    return new Promise((resolve) => {
      this.$axios.post(urlOperation, { hostId: payload.hostId, disableFrontend: true }).then((resOperation) => {
        if (resOperation.status === 200) {
          setTimeout(() => {
            this.$axios.post(urlRevert, { name: payload.name }).then((resRevert) => {
              if (resRevert.status === 200) {
                setTimeout(() => {
                  this.$axios.post(urlDetach);
                  resolve();
                }, 5000);
              }
            });
          }, 5000);
        }
      });
    });
  },

  getServices({ commit }) {
    const url = `${ lbBaseUrl }/api/svcs`;

    this.$axios.get(url).then((res) => {
      if (res.data) {
        commit('UPDATE_STATE', { lbServices: res.data });
      } else {
        commit('UPDATE_STATE', { lbServices: {} });
      }
    }).catch(() => {
      commit('UPDATE_STATE', { lbServices: {} });
    });
  },

  updateServices({ commit }, payloads) {
    const url = `${ lbBaseUrl }/api/map`;

    this.$axios.post(url, { ...payloads }).then((res) => {
      if (res.result) {
        this.$message({
          type:    'success',
          message: res.result
        });
      }
    }).catch(() => {});
  },

  getStatus({ commit }) {
    const url = `${ lbBaseUrl }/api/status`;

    this.$axios.get(url).then((res) => {
      if (Array.isArray(res.data)) {
        commit('UPDATE_STATE', { lbStatus: res.data });
      } else {
        commit('UPDATE_STATE', { lbStatus: [] });
      }
    }).catch(() => {
      commit('UPDATE_STATE', { lbStatus: [] });
    });
  },

  getUse({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      return this.$axios.get(params.url).then((res) => {
        commit('getUse', res.data);
        resolve(res);
      }).catch((err) => {
        commit('getUse', []);
        if (err.response.status === 404 && params.clusterName !== undefined && params.showGrowl !== false) {
          dispatch('growl/info', {
            title:   '警告',
            message: `${ params.clusterName } 未安装监控，暂无数据！`,
            timeout: 2000
          }, { root: true });
        }
        reject(err);
        console.log(err);
      });
    });
  },

  updateState({ commit }, payloads) {
    commit('UPDATE_STATE', payloads);
  },

  clear({ commit }) {
    commit('UPDATE_STATE', { currentModal: '', currentItem: {} });
  },
};

const paiFactory = () => {
  return {
    state,
    getters:   { ...getters },
    mutations: { ...mutations },
    actions:   { ...actions }
  };
};
const config = {
  namespace:      `${ PRODUCT_NAME }-common`,
  isClusterStore: false
};

export default {
  specifics: paiFactory(),
  config
};
