<script>
import { mapGetters } from 'vuex';
import { MANAGEMENT } from '@/shell/config/types';
import { stateDisplay } from '@/shell/plugins/dashboard-store/resource-class';
import { createNamespaceFilterKey } from '@/shell/utils/namespace-filter';
import metricPoller from '@/shell/mixins/metric-poller';

export default {

  mixins: [metricPoller],

  async beforeCreate() {
    if (!this.$store.getters['currentCluster']) {
      if (!this.$route.query.cluster) {
        try {
          this.clusters = await this.$store.dispatch('management/findAll', { type: MANAGEMENT.CLUSTER });
          const id = this.clusters.find(v => v.isLocal) ? this.clusters.find(v => v.isLocal).id : 'local';

          await this.onClusterChange(id);
        } catch (e) {}
      } else {
        await this.onClusterChange(this.$route.query.cluster);
      }
    }
  },

  data() {
    return {
      clusters:      [],
      isOpen:        false,
      filter:        '',
      hidden:        0,
      total:         0,
      activeElement: null,
    };
  },
  computed: {
    ...mapGetters(['currentProduct', 'namespaceFilterMode']),

    hasFilter() {
      return this.filter.length > 0;
    },

    key() {
      return createNamespaceFilterKey(this.$store.getters['clusterId'], this.currentProduct);
    },

    clusterOptions() {
      const clusters = this.$store.getters['management/all'](MANAGEMENT.CLUSTER);

      return clusters.map((cluster) => {
        return {
          id:      cluster.metadata.name,
          label:   cluster.spec.displayName,
          enabled: stateDisplay(cluster.metadata?.state?.name) === 'Active',
        };
      });
    },

    currentCluster() {
      const cluster = this.$store.getters['currentCluster'];

      if (cluster) {
        return cluster;
      } else {
        return {};
      }
    },
  },

  methods: {
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
      this.activeElement = null;
    },
    clear() {
      this.value = [];
    },
    async onClusterChange(nue) {
      this.isOpen = false;
      await this.$router.replace({ ...this.$route, params: { ...this.$route.params, cluster: nue } });
      await this.$store.dispatch('loadCluster', { id: nue });
    },
  },
};
</script>

<template>
  <div
    class="ns-filter"
    data-testid="namespaces-filter"
    tabindex="0"
    @focus="open()"
  >
    <div
      v-if="isOpen"
      class="ns-glass"
      @click="close()"
    />

    <!-- Select Dropdown control -->
    <div
      ref="dropdown"
      class="ns-dropdown"
      data-testid="namespaces-dropdown"
      :class="{ 'ns-open': isOpen }"
      @click="toggle()"
    >
      <div
        class="ns-values"
        data-testid="namespaces-values-label"
      >
        {{ currentCluster.nameDisplay }}
      </div>
      <i
        v-if="!isOpen"
        class="icon icon-chevron-down"
      />
      <i
        v-else
        class="icon icon-chevron-up"
      />
    </div>
    <button
      v-shortkey.once="['n']"
      class="hide"
      @shortkey="open()"
    />

    <!-- Dropdown menu -->
    <div
      v-if="isOpen"
      class="ns-dropdown-menu"
      data-testid="namespaces-menu"
    >
      <div class="ns-dropdown-menu-content">
        <el-radio-group
          :value="currentCluster.id"
          @input="onClusterChange"
        >
          <div
            v-for="cluster in clusterOptions"
            :key="cluster.id"
            class="cluster"
          >
            <el-radio
              :label="cluster.id"
              :disabled="!cluster.enabled"
            >
              {{ cluster.label }}
            </el-radio>
          </div>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$ns_dropdown_size: 24px;

.ns-abs {
  position: absolute;
}

.ns-filter {
  width: 140px;
  display: inline-block;

  $glass-z-index: 2;
  $dropdown-z-index: 1000;

  .ns-glass {
    height: 100vh;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: $glass-z-index;
  }

  .ns-controls {
    align-items: center;
    display: flex;
  }

  .ns-clear {
    &:hover {
      color: var(--primary);
      cursor: pointer;
    }
  }

  .ns-singleton-info, .ns-clear {
    align-items: center;
    display: flex;

    > i {
      padding-right: 5px;
    }
  }

  .ns-input {
    flex: 1;
    padding: 5px;
    position: relative;
  }

  .ns-filter-input {
    height: 24px;
  }

  .ns-filter-clear {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
    line-height: 24px;
    text-align: center;
    width: 24px;
  }

  .ns-dropdown-menu {
    width: 140px;
    background-color: var(--header-bg);
    border: 1px solid var(--primary-border);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    color: white;
    margin-top: -1px;
    padding-bottom: 10px;
    position: absolute;
    z-index: $dropdown-z-index;

    .ns-dropdown-menu-content {
      display: flex;

      .cluster {
        align-items: center;
        display: flex;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        margin-top: 10px;
      }

      .title {
        text-align: center;
        padding: 10px 0;
        background: #EFF3F8;
      }

      .namespaces {
        border-left: 1px solid #cccccc;
      }
    }

    .ns-options {
      max-height: 50vh;
      width: 240px;
      overflow-y: auto;

      .ns-none {
        color: var(--muted);
        padding: 0 10px;
      }
    }

    .ns-divider {
      border-top: 1px solid var(--border);
      cursor: default;
      margin-top: 10px;
      padding-bottom: 10px;
    }

    .ns-option {

      &[disabled] {
        cursor: default;
      }

      &:not([disabled]) {
        &:focus {
          background-color: var(--dropdown-hover-bg);
          color: var(--dropdown-hover-text);
        }

        .ns-item {
          &:hover, &:focus {
            background-color: var(--dropdown-hover-bg);
            color: var(--dropdown-hover-text);
            cursor: pointer;

            > i {
              color: var(--dropdown-hover-text);
            }
          }
        }

        &.ns-selected {
          &:hover, &:focus {
            .ns-item {
              > * {
                background-color: var(--dropdown-hover-bg);
                color: var(--dropdown-hover-text);
              }
            }
          }
        }

        &.ns-selected:not(:hover) {
          .ns-item {
            > * {
              color: var(--dropdown-hover-bg);
            }
          }
        }
      }

      .ns-item {
        align-items: center;
        display: flex;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;

        > i {
          color: var(--muted);
          margin: 0 5px;
        }

        > div {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }

      &.ns-single-match {
        .ns-item {
          background-color: var(--dropdown-hover-bg);

          > * {
            color: var(--dropdown-hover-text);
          }
        }
      }
    }
  }

  .ns-dropdown {
    align-items: center;
    display: flex;
    border: 1px solid var(--header-border);
    border-radius: var(--border-radius);
    color: white;
    cursor: pointer;
    height: 40px;
    padding: 0 10px;
    position: relative;
    z-index: $dropdown-z-index;

    &.ns-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: var(--primary-border);
    }

    > .ns-values {
      line-height: normal;
      flex: 1;
    }

    &:hover {
      > i {
        color: var(--primary);
      }
    }

    > i {
      height: $ns_dropdown_size;
      width: $ns_dropdown_size;
      cursor: pointer;
      text-align: center;
      line-height: $ns_dropdown_size;
    }

    .ns-more {
      border: 1px solid var(--header-border);
      border-radius: 5px;
      padding: 2px 8px;
      margin-left: 4px;
    }

    .ns-values {
      display: flex;
      overflow: hidden;

      .ns-value {
        align-items: center;
        background-color: rgba(0, 0, 0, .05);
        border: 1px solid var(--header-border);
        border-radius: 5px;
        color: var(--tag-text);
        display: flex;
        line-height: 20px;
        padding: 2px 5px;
        white-space: nowrap;

        > i {
          margin-left: 5px;

          &:hover {
            color: var(--primary);
          }
        ;
        }

        // Spacing between tags
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
