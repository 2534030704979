<script>
import ClusterSingleResource from './components/ClusterSingleResource';
import ClusterSingleModule from './components/ClusterSingleModule';
import ClusterSingleChart from './components/ClusterSingleChart';
import RunResourcesTable from './components/runResourcesTable';
import StaticResourcesTable from './components/staticResourcesTable';
import { PAI_RESOURCES } from '../../../../config/types';
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';

export default {
  layout:     'pai/default',
  components: {
    ClusterSingleResource,
    ClusterSingleModule,
    ClusterSingleChart,
    RunResourcesTable,
    StaticResourcesTable,
  },
  data() {
    return {
      cloudData:     [],
      podData:       [],
      activeName:    'run',
      isExpandAll:   false,
      fullScreen:    false,
      runVlaue:      '',
      staticValue:   '',
      allNamespaces: [],
      projects:      []
    };
  },
  async fetch() {
    // const inStore = this.$store.getters['currentStore'](NAMESPACE);
    this.allNamespaces = this.$store.getters[`cluster/all`](NAMESPACE);
    this.projects = this.$store.getters['management/all'](MANAGEMENT.PROJECT);
    this.podData = await this.$store.dispatch('cluster/findAll', { type: 'pod' });
    try {
      this.cloudData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleTabs(val) {
      this.runVlaue = '';
      this.staticValue = '';
    },
    handleExpand() {
      this.isExpandAll = !this.isExpandAll;
    },
    handleScreen() {
      this.fullScreen = !this.fullScreen;
    }
  }
};

</script>

<template>
  <div class="cluster-single">
    <div class="cluster-single-title">
      {{ t('pai.overview.label') }}
    </div>
    <ClusterSingleResource
      v-show="!fullScreen"
      :cloud-data="cloudData"
      :pod-data="podData"
    />
    <ClusterSingleModule
      v-show="!fullScreen"
      :cloud-data="cloudData"
      :pod-data="podData"
    />
    <ClusterSingleChart v-show="!fullScreen" />
    <div class="clusterSingleTable bgCard">
      <div class="tableOperate flex">
        <span>
          <el-input
            v-if="activeName == 'run'"
            v-model="runVlaue"
            style="width:220px;position: relative;top: -10px;"
            :placeholder="t('pai.vmset.nameSpace')"
          />
          <el-input
            v-else
            v-model="staticValue"
            style="width:220px;position: relative;top: -10px;"
            :placeholder="t('pai.vmset.nameSpace')"
          />
        </span>

        <button
          class="btn role-primary"
          style="position: relative;top: -12px;margin: 0px 10px"
          @click="handleExpand"
        >
          <i
            class="el-icon-finished"
          />{{ isExpandAll ? t('pai.list.collapseAll') : t('pai.list.expandAll') }}
        </button>
        <span
          style="font-size: 34px;position: relative;top: -3px;cursor: pointer"
          @click="handleScreen"
        >
          <i
            :class="fullScreen ? 'el-icon-rank' : 'el-icon-full-screen'"
          />
        </span>
      </div>
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleTabs"
      >
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.overview.dynamicResourceAllocation')"
          name="run"
        >
          <RunResourcesTable
            :is-expand-all="isExpandAll"
            :input-value="runVlaue"
            :all-namespaces="allNamespaces"
            :projects="projects"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.overview.staticResourceAllocation')"
          name="static"
        >
          <StaticResourcesTable
            :is-expand-all="isExpandAll"
            :input-value="staticValue"
            :cloud-data="cloudData"
            :pod-data="podData"
            :all-namespaces="allNamespaces"
            :projects="projects"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style lang="scss">
.cluster-single{
  background-color: #EFEFEF;
  .cluster-single-title{
    font-size: 16px;
    color: #2F76A9;
  }
  .bgCard{
    padding: 10px;
    margin-top: 14px;
    background-color: #FFFFFF;
    border-radius: 5px;
  }
  .cluster-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
  }
  .cluster-flex {
    display: flex;
    justify-content: space-between;
  }
  .cluster-num{
    font-size: 18px;
    font-weight: 600;
  }
  .clusterSingleTable{
    overflow: hidden;
    .tableOperate{
      float: right;
    }
  }
  .el-tabs--card>.el-tabs__header {
    width: 480px;
  }
  .el-table__header{
    width: 100% !important;
  }
  .el-table__body{
    width: 100% !important;
  }
}
</style>
