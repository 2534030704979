<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@/shell/components/form/LabeledSelect';
import CruResource from '@shell/components/CruResource';
import LabeledRadioGroup from '../components/form/LabeledRadioGroup';
import { _CLONE, _CREATE, _EDIT } from '@shell/config/query-params';
import { MACHINE_ANNOTATIONS } from '../config/labels-annotations';

export default {
  components: {
    LabeledRadioGroup,
    CruResource,
    LabeledInput,
    LabeledSelect,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    realMode: {
      type:    String,
      default: 'edit'
    }
  },
  async fetch() {
    this.nodes = await this.$store.dispatch('management/findAll', { type: 'cluster.liveit100.com.machine' });
    this.gatewayData = await this.$store.dispatch('management/findAll', { type: 'cluster.liveit100.com.gateway' });
  },
  data() {
    return {
      tip:         '',
      nodes:       [],
      gatewayData: [],
      MACHINE_ANNOTATIONS,
    };
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _CLONE() {
      return _CLONE;
    },
    _EDIT() {
      return _EDIT;
    },
    nodeNameList() {
      const nodeNameList = this.nodes?.map(steveModel => steveModel.metadata.name);

      nodeNameList?.splice(nodeNameList?.indexOf(this.value.metadata.name), 1);

      return nodeNameList;
    },
    clusterList() {
      return this.gatewayData.map(item => item.metadata.name);
    },
    osList() {
      if (this.$store.state['pai-common'].osList.length === 0) {
        this.$set(this.value.spec, 'os', '');
      }

      return this.$store.state['pai-common'].osList?.map((v) => {
        return ({
          label: v.split('/')[v.split('/').length - 1],
          value: v
        });
      });
    },
    cluster() {
      return this.value.metadata.labels['com.tdology.gateway'];
    },
    isEditable() {
      return this.realMode === _CREATE || this.realMode === _CLONE || (this.realMode === _EDIT && this.value.metadata.annotations && this.value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION] === 'provision');
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    selectionChanged(e) {
      if (e === 'mode=install' && this.value.status.mode === 'mode=disk') {
        this.tip = this.t('pai.edit.machine.installTip');
      }
      if (e === 'provision') {
        this.tip = this.t('pai.edit.machine.provisionTip');
      }
    },
    async saveScan(buttonCb) {
      if (this.isEditable) {
        if (!this.value.metadata.name || !this.value.spec.ip || !this.value.spec.os || !this.value.spec.arch) {
          this.$message.error(this.t('pai.edit.tips.required'));
          buttonCb(false);

          return;
        } else if (!/^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/.test(this.value.spec.ip)) {
          this.$message.error(this.t('pai.edit.tips.ipError'));
          buttonCb(false);

          return;
        } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.name) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.annotations['picloud/alias']) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.install.device) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.arch)) {
          this.$message.error(this.t('pai.edit.tips.specialCharacters'));
          buttonCb(false);

          return;
        }
      }
      try {
        await this.save(buttonCb);
        if (this.tip) {
          this.$message({
            type:    'warning',
            message: this.tip
          });
        } else {
          this.$message.success(this.t('pai.edit.tips.success'));
        }
      } catch (e) {
        this.$message.error(e);
        buttonCb(false);
      }
    },
  },
  watch: {
    cluster() {
      this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
    }
  },
  created() {
    // Add fields missing when create or clone
    const spec = {
      ip: '', os: '', install: { device: '' }, cloudconfig: { k3os: {} }
    };

    if (!this.value.spec && this.realMode === _CREATE) {
      this.$set(this.value, 'spec', spec);
      this.$set(this.value.metadata.labels, 'com.tdology.gateway', '');
    }
    if (!this.value.status) {
      this.$set(this.value, 'status', '');
    }
    for (const key in spec) {
      if (!this.value.spec[key]) {
        this.value.spec[key] = spec[key];
      }
    }
    // query os list
    if (this.realMode !== _CREATE) {
      if (this.value.metadata.labels['com.tdology.gateway']) {
        this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
      }
    }
    if (this.value.metadata.namespace) {
      this.$set(this.value.metadata, 'namespace', 'picloud-system');
    }
    // default setting
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/role']) {
      this.$set(this.value.metadata.annotations, 'picloud/role', 'agent');
    }
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/seednode']) {
      this.$set(this.value.metadata.annotations, 'picloud/seednode', 'false');
    }
  }
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="saveScan"
    @cancel="done"
  >
    <div v-if="realMode===_CLONE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :label="t('pai.edit.machine.templateNode')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
            :tooltip="t('pai.edit.machine.templateNodeTooltip')"
            :append-to-body="false"
            :options="nodeNameList"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('pai.edit.machine.operatingSystem')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
            :append-to-body="false"
            :options="osList"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('pai.edit.machine.nodeRole')"
            :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('pai.edit.machine.seedNode')"
            :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('pai.edit.machine.firstNode')"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :label="t('pai.edit.machine.systemDisk')"
            :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
            :mode="mode"
          />
        </div>
      </div>
    </div>
    <div v-else-if="realMode===_CREATE">
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :append-to-body="false"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.labels['com.tdology.gateway']"
            :label="t('pai.edit.gateway.name')"
            :placeholder="t('pai.edit.placeholder') + t('pai.edit.gateway.name')"
            :append-to-body="false"
            :options="clusterList"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>ANNOTATIONS</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.metadata.annotations['picloud/tmpl']"
            :label="t('pai.edit.machine.templateNode')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
            :tooltip="t('pai.edit.machine.templateNodeTooltip')"
            :append-to-body="false"
            :options="nodeNameList"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledSelect
            v-model.trim="value.spec.os"
            :required="true"
            :label="t('pai.edit.machine.operatingSystem')+t('pai.edit.machine.byClusterShow')"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
            :append-to-body="false"
            :options="osList"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('pai.edit.machine.nodeRole')"
            :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
            :mode="mode"
          />
        </div>
        <div class="col span-6">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('pai.edit.machine.seedNode')"
            :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('pai.edit.machine.firstNode')"
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.install.device"
            type="text"
            :label="t('pai.edit.machine.systemDisk')"
            :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <h4>SPEC</h4>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.arch"
            type="text"
            :required="true"
            label="ARCH"
            :mode="mode"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            mode="view"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            mode="view"
          />
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-6">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <div class="mb-20">
        <LabeledRadioGroup
          v-model.trim="value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION]"
          :default-value="value.status.mode"
          name="picloud/action"
          :label="t('pai.edit.machine.nodeOperation')"
          :options="[{label:t('pai.edit.machine.modifyConfiguration'),value:'provision'},{label:t('pai.edit.machine.liveMode'),value:'mode=live'},
                     {label:t('pai.edit.machine.installationSystem'),value:'mode=install'},{label:t('pai.edit.machine.diskBoot'),value:'mode=disk'},
                     {label:t('pai.edit.machine.emergencyRepair'),value:'fallback_mode=live'}]"
          :row="true"
          :mode="_EDIT"
          @input="selectionChanged($event)"
        />
      </div>
      <div v-if="value.metadata.annotations[MACHINE_ANNOTATIONS.ACTION] === 'provision'">
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledInput
              v-model.trim="value.metadata.annotations['picloud/newname']"
              type="text"
              :label="t('pai.edit.machine.newName')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.newName')"
              :mode="mode"
            />
          </div>
          <div class="col span-6">
            <LabeledSelect
              v-model.trim="value.metadata.annotations['picloud/tmpl']"
              :label="t('pai.edit.machine.templateNode')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
              :tooltip="t('pai.edit.machine.templateNodeTooltip')"
              :append-to-body="false"
              :options="nodeNameList"
              :mode="mode"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/role']"
              name="picloud/role"
              :label="t('pai.edit.machine.nodeRole')"
              :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
              :row="true"
              :required="true"
              :mode="mode"
            />
          </div>
          <div class="col span-6">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/seednode']"
              name="picloud/seednode"
              :label="t('pai.edit.machine.seedNode')"
              :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
              :required="true"
              :row="true"
              :tooltip="t('pai.edit.machine.firstNode')"
              :mode="mode"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-6">
            <div class="mb-20">
              <LabeledSelect
                v-model.trim="value.spec.os"
                :required="true"
                :label="t('pai.edit.machine.operatingSystem')"
                :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
                :append-to-body="false"
                :options="osList"
                :mode="mode"
              />
            </div>
          </div>
          <div class="col span-6">
            <div class="mb-20">
              <LabeledInput
                v-model.trim="value.spec.install.device"
                type="text"
                :label="t('pai.edit.machine.systemDisk')"
                :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
                :mode="mode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </CruResource>
</template>

<style>
.v-select, .v-select *{
  width: 100% !important;
}
</style>
