import { getDetailLocation } from '../utils';
import Project from '@shell/models/management.cattle.io.project';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';

export default class extends Project {
  get detailLocation() {
    return getDetailLocation(this);
  }

  get listLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-projectsnamespaces`,
        params: {
          product: PRODUCT_NAME,
          cluster: this.$rootGetters['clusterId'],
        },
      };
    }

    return this.listLocation;
  }

  get customValidationRules() {
    const rules = [{
      path:       'spec.displayName',
      nullable:   false,
      required:   true,
      type:       'string',
      validators: ['projectDisplayName'],
    }];

    return rules;
  }
}
