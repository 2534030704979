<script>
import { NETWORK_ATTACHMENT } from '@/shell/config/types';
import { mapGetters } from 'vuex';
import LiveDate from '@/shell/components/formatter/LiveDate';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  components: { LiveDate },
  data() {
    return {
      tableData:           [],
      networks:            [],
      dialogVisible:       false,
      networkOptions:      [],
      networkOptionsValue: '',
      filterValue:         '',
      nadList:             []
    };
  },
  async fetch() {
    this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
    this.nadList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.NAD });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    newTableData() {
      const info = this.value?.status?.instances;
      const tableData = [];
      const list = [];

      for (const key in info) {
        list.push({ podName: key, ...info[key] });
      }
      list.filter(list => list.podName === this.podName).map((net) => {
        net?.net?.forEach((n, i) => {
          tableData[i] = {
            interface: n.interface,
            name:      n.name,
            ip:        n.ips
          };
        });
      });
      this.nadList.forEach((item, index) => {
        const config = item.spec.config ? JSON.parse(item.spec.config) : '';

        list[0]?.net?.forEach((n, i) => {
          if (item.metadata.name === n.name?.split('/')[1]) {
            tableData[i] = {
              ...tableData[i],
              name:            item.metadata.name,
              alias:           item.alias,
              type:            config?.type,
              addressType:     config?.ipam?.type,
              hostNetworkName: config?.uplink,
              createTime:      item.metadata?.creationTimestamp
            };
          }
        });
      });

      return tableData;
    }
  },
  methods: {
    deleteNetwork(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        const index = this.value.spec.nics.findIndex(i => i === value.name.split('/')[1]);

        this.value.spec.nics.splice(index, 1);
        await this.value.save();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteWaitVm')
        });
      }).catch(() => {
        this.$message({
          type:    'info',
          message: this.t('pai.detail.vmset.cancelDelete')
        });
      });
    }
  }
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col
        :span="24"
      >
        <el-input
          v-model="filterValue"
          :placeholder="t('pai.detail.vmset.filter')"
          style="float: right"
          prefix-icon="el-icon-search"
          size="small"
        />
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.name.includes(filterValue)):newTableData"
          class="netWorkTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
            width="50"
          />
          <el-table-column
            prop="name"
            :label="t('pai.detail.vmset.tab.networkManagement.name')"
            width="150"
          />
          <el-table-column
            prop="alias"
            :label="t('tableHeaders.alias')"
            width="150"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.networkManagement.type')"
          >
            <template slot-scope="scope">
              {{ scope.row.interface === 'eth0' ? '管理网卡' : scope.row.type }}
            </template>
          </el-table-column>
          <el-table-column
            prop="addressType"
            :label="t('pai.detail.vmset.tab.networkManagement.addressType')"
          />
          <el-table-column
            prop="ipAddress"
            :label="t('pai.detail.vmset.tab.overview.ipAddress')"
          >
            <template slot-scope="scope">
              <el-tooltip
                effect="light"
              >
                <div slot="content">
                  <div
                    v-for="(item, index) in scope.row.ip"
                    :key="index"
                    class="content"
                  >
                    <p style="font-size: 14px">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <template>
                  <div
                    v-if="scope.row.ip && scope.row.ip.length"
                    class="content"
                  >
                    {{ scope.row.ip[0] }}
                    <i class="icon el-icon-s-unfold" />
                  </div>
                </template>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="hostNetworkName"
            :label="t('pai.detail.vmset.tab.networkManagement.hostNetworkName')"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.networkManagement.survivalTime')"
          >
            <template slot-scope="scope">
              <div>
                <LiveDate :value="scope.row.createTime" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.name === 'cbr0'"
                @click="deleteNetwork(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.netWorkTable{
  padding: 0 20px;
}
.el-input {
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 215px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
