import Pod from '@shell/models/pod';
import { convertType } from '../utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import { PAI_NODE } from '~/pkg/pai/config/types';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { VIRT } from '~/pkg/pai/config/labels-annotations';

export default class extends PaiLocationMixin(Pod) {
  get openShellMenuItem() {
    return {
      action:  'openShell',
      enabled: !!this.links.view && this.isRunning,
      icon:    'icon icon-fw icon-chevron-right',
      label:   this.t('pai.detail.vmset.control'),
      total:   1,
    };
  }

  get openLogsMenuItem() {
    return {
      action:  'openLogs',
      enabled: !!this.links.view,
      icon:    'icon icon-fw icon-chevron-right',
      label:   this.t('pai.detail.vmset.viewLog'),
      total:   1,
    };
  }

  get details() {
    const product = this.$rootGetters['productId'];
    const cluster = this.$rootGetters['clusterId'];

    if (product === PRODUCT_NAME) {
      const out = [
        {
          label:   this.t('workload.detailTop.podIP'),
          content: this.status.podIP,
        },
      ];

      if (this.workloadRef) {
        out.push({
          label:         'Workload',
          formatter:     'PaiLinkName',
          formatterOpts: {
            value:     this.workloadRef.name,
            type:      convertType(this.workloadRef.type),
            namespace: this.workloadRef.namespace,
            cluster,
            product,
          },
          content: this.workloadRef.name,
        });
      }

      if (this.spec.nodeName) {
        out.push({
          label:         'Node',
          formatter:     'PaiLinkName',
          formatterOpts: {
            type:  PAI_NODE,
            value: this.spec.nodeName,
            cluster,
            product,
          },
          content: this.spec.nodeName,
        });
      }

      return out;
    }

    return super.details;
  }

  openShell(containerName = this.defaultContainerName) {
    const vncLocation = `/k8s/clusters/${ this.$rootGetters['clusterId'] }/api/v1/namespaces/vncproxy/services/http:vncproxy:80/proxy/console/${ this.$rootGetters['clusterId'] }/${ this.metadata?.namespace }/${ this.metadata?.name }?title=${ this.metadata?.name }`;

    if (this.spec.containers) {
      const containers = this.spec.containers.filter(c => c.name === containerName);

      if (containers.length > 0 && containers[0].ports) {
        const vncport = containers[0].ports.filter(p => p.containerPort === 5900 || p.containerPort === 5901);

        if (vncport.length) {
          window.open(`${ vncLocation }&vncport=${ vncport[0].containerPort }`);

          return;
        }
      }
    }

    if (this.metadata.labels?.[VIRT] === 'true') {
      window.open(vncLocation);

      return;
    }

    // no vnc, open normal shell
    this.$dispatch('wm/open', {
      id:        `${ this.id }-shell`,
      label:     this.nameDisplay,
      icon:      'terminal',
      component: 'ContainerShell',
      attrs:     {
        pod:              this,
        initialContainer: containerName,
      },
    }, { root: true });
  }

  get isVmsets() {
    return this.metadata && this.metadata.labels && this.metadata.labels[VIRT] === 'true';
  }

  get isKata() {
    return this.spec && this.spec.runtimeClassName && this.spec.runtimeClassName.includes('kata');
  }
}
