<script>
import {
  SCHEMA, NORMAN, NODE, PVC, PV, POD
} from '@/shell/config/types';
import { clone } from '@/shell/utils/object';
import { createYaml } from '@/shell/utils/create-yaml';
import { mapGetters } from 'vuex';
import { convertUnitToG } from '@/pkg/pai/utils/units';
import { PAI_RESOURCES } from '../../../config/types';

export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    const params = this.$route.params;
    const time = new Date().valueOf();

    return {
      params,
      snapshotList:                [],
      createSnapshotDialogVisible: false,
      form:                        { name: `snapshot-${ this.value.metadata.name }-${ time }` },
      allDisk:                     [],
      diskNameOptionValue:         [],
      yamlList:                    [],
      pvData:                      [],
      diskInfoValue:               {},
      filterValue:                 '',
      nodeList:                    [],
      podList:                     [],
      pvcs:                        []
    };
  },
  async fetch() {
    this.snapshotList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.SNAPSHOT });
    this.pvData = await this.$store.dispatch('cluster/findAll', { type: PV });
    this.nodeList = await this.$store.dispatch('cluster/findAll', { type: NODE });
    this.podList = await this.$store.dispatch('cluster/findAll', { type: POD });
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    diskName() {
      const tableData = [];

      this.pvcs.forEach((pvc) => {
        if ((pvc.spec.volumeName && pvc.metadata.namespace === this.value.metadata.namespace && pvc.metadata.labels && pvc.metadata.labels['com.tdology.pvc.mounto'] === this.podName)) {
          tableData.push({
            pvcName:  pvc.metadata.name,
            pvName:   pvc.spec.volumeName,
            type:     pvc.metadata.annotations['volume.beta.kubernetes.io/storage-provisioner'],
            disabled: pvc.metadata.annotations['volume.beta.kubernetes.io/storage-provisioner'] === 'driver.longhorn.io'
          });
        }
      });

      return tableData;
    },
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    },
    newTableData() {
      const snapshotTableData = this.snapshotList;
      const newData = {};

      snapshotTableData.forEach((item) => {
        const podName = item.metadata.labels ? item.metadata.labels.podName : '';

        if (podName === this.podName && item.metadata?.labels ) {
          const { displayName } = item.metadata?.labels;

          if (!newData[displayName]) {
            newData[displayName] = {
              name:            displayName,
              vmName:          item.metadata.labels['com.tdology.virt.vmset'],
              createTimeStamp: item.metadata.labels.createTime,
              nameSpace:       item.metadata.namespace,
              tempList:        [],
              status:          item.metadata.state.name,
              size:            convertUnitToG(`${ item.status?.size }k`),
              volumeList:      [],
              createBy:        item.metadata.labels.createBy,
              createTime:      new Date(Number(item.metadata.labels.createTime)).toLocaleString().replace('/', '-').replace('/', '-'),
              podName:         item.metadata?.labels.podName,
              markRemoved:     item.status?.markRemoved
            };
          }
          newData[displayName].volumeList.push(item.metadata.labels.persistentvolumeclaim);
          newData[displayName].tempList.push({ tempName: item.metadata.name, pvName: item.spec.volume });
          if (!this.diskInfoValue[displayName]) {
            this.diskInfoValue[displayName] = item.metadata.labels.persistentvolumeclaim;
          }
        }
      });

      return Object.values(newData).filter(item => item.markRemoved === false);
    }
  },
  watch: {
    'form.name': {
      handler(newVal) {
        this.form.name = newVal;
      },
      deep: true
    }
  },
  methods: {
    snapshotShow() {
      const time = new Date().valueOf();

      this.createSnapshotDialogVisible = true;
      this.diskNameOptionValue = [];
      this.form = { name: `snapshot-${ this.value.metadata.name }-${ time }` };
    },
    onFormName(value) {
      this.form.name = value;
    },
    selectDisk(value) {
      const newAllDisk = [];

      this.pvcs.length > 0 && this.pvcs.map((item, index) => {
        value.map((pv, index) => {
          if (pv === item.spec.volumeName) {
            newAllDisk.push({
              pvName:  pv,
              pvcName: item.metadata.name
            });
          }
        });
      });
      this.allDisk = newAllDisk;
    },

    async onSnapshot() {
      if (this.form.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.form.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.form.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if (this.diskNameOptionValue.length === 0) {
        this.$message.error(this.t('pai.detail.vmset.mustSelectDisk'));

        return;
      }
      for (const item in this.snapshotList) {
        if (this.snapshotList[item]?.metadata?.labels?.displayName === this.form.name) {
          this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

          return;
        }
      }

      const yamlList = [];
      const time = new Date().valueOf();
      const info = this.value?.status?.instances;
      const podList = [];

      for (const key in info) {
        podList.push({ name: key, ...info[key] });
      }
      for (let i = 0; i < this.allDisk.length - 1; i++) {
        for (let j = i + 1; j < this.allDisk.length; j++) {
          if (this.allDisk[i].pvName == this.allDisk[j].pvName) {
            this.allDisk.splice(j, 1);
            j--;
          }
        }
      }
      for (let i = 0;i < this.allDisk.length;i++) {
        const item = this.allDisk[i];
        const yaml = {
          apiVersion: 'longhorn.io/v1beta2',
          kind:       'Snapshot',
          metadata:   {
            labels: {
              longhornvolume:           '', // # 磁盘对应的pv名字
              persistentvolumeclaim:    '',
              createBy:                 '', // 当前操作用户
              createTime:               '', // 当前时间戳/毫秒级 #（不使用资源本身的创建时间是因为要创建多个快照CRD资源，很可能出现微小的差异，时间戳充当了快照id的作用，需要避免）
              'com.tdology.virt.vmset': '', // 虚机名
              displayName:              '', // 展示的快照名称
              podName:                  '',
            },
            name:      '', // # 快照的名字，需要注意的是，快照名需要添加后缀-num，比如快照名demo，其中3个longhorn磁盘做了快照，那么它们的名称就分别是demo-0/demo-1/demo-2
            namespace: 'longhorn-system', // # 固定，不可修改
          },
          spec: {
            createSnapshot: true,
            volume:         '' // # 磁盘对应的pv名字
          }
        };

        if (this.allDisk.length <= 1) {
          yaml.metadata.labels.longhornvolume = item.pvName;
          yaml.metadata.labels.persistentvolumeclaim = item.pvcName;
          yaml.metadata.labels.createBy = this.principal.loginName;
          yaml.metadata.labels.createTime = String(time);
          yaml.metadata.labels['com.tdology.virt.vmset'] = this.value.metadata.name;
          yaml.metadata.name = `${ this.form.name }-${ this.value.metadata.namespace }-0`;
          yaml.metadata.labels.displayName = this.form.name;
          yaml.metadata.labels.podName = this.podName;
          yaml.spec.volume = item.pvName;
          yamlList.push(yaml);
        } else if (this.allDisk.length > 1) {
          yaml.metadata.labels.longhornvolume = item.pvName;
          yaml.metadata.labels.persistentvolumeclaim = item.pvcName;
          yaml.metadata.labels.createBy = this.principal.loginName;
          yaml.metadata.labels.createTime = String(time);
          yaml.metadata.labels['com.tdology.virt.vmset'] = this.value.metadata.name;
          yaml.metadata.name = `${ this.form.name }-${ this.value.metadata.namespace }-${ i }`;
          yaml.metadata.labels.displayName = this.form.name;
          yaml.metadata.labels.podName = this.podName;
          yaml.spec.volume = item.pvName;
          yamlList.push(yaml);
        }
      }
      this.yamlList = yamlList;
      const inStore = this.$store.getters['currentStore'](this.value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const snapShotSchema = schemas.filter(v => v.id === PAI_RESOURCES.SNAPSHOT)[0];

      this.createSnapshotDialogVisible = false;
      const opt = {
        method:  'post',
        headers: {
          'content-type': 'application/yaml',
          accept:         'application/json',
        },
        data: {},
        url:  `v1/${ PAI_RESOURCES.SNAPSHOT }`,
      };

      try {
        for (let i = 0;i < this.yamlList.length;i++) {
          const item = this.yamlList[i];

          opt.data = this.toYaml(item);
          await snapShotSchema.$ctx.dispatch('request', opt);
        }
        this.$message({
          type:    'success',
          message: `${ this.t('pai.labels.success') }`,
        });
      } catch (e) {
        this.$message({
          type:    'warning',
          message: e.message
        });
      }
    },
    toYaml(value) {
      const inStore = this.$store.getters['currentStore'](value);
      const schemas = this.$store.getters[`${ inStore }/all`](SCHEMA);
      const clonedResource = clone(value);

      return createYaml(schemas, this.snapshotList[0].type, clonedResource);
    },

    deleteSnapshot(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(() => {
        for (let i = 0; i < value.tempList.length; i++) {
          const index = this.snapshotList.findIndex((item) => {
            const vmName = item.metadata.labels && item.metadata.labels['com.tdology.virt.vmset'] ? item.metadata.labels['com.tdology.virt.vmset'] : '';

            return vmName === value.vmName && item.metadata.labels.createTime === value.createTimeStamp && item.metadata.namespace === value.nameSpace && value.tempList[i].tempName === item.metadata.name;
          });

          this.snapshotList[index].remove();
        }
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
      }).catch(() => {
        this.$message({
          type:    'info',
          message: this.t('pai.detail.vmset.cancelDelete')
        });
      });
    },
    restoreSnapShot(value) {
      if (this.value.spec.power === 'On') {
        //  请在关机状态下恢复，请确实是否关机
        this.$confirm('请在关机状态下恢复，请确实是否关机', this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.edit.machine.yes'),
          cancelButtonText:  this.t('pai.edit.machine.no'),
          type:              'warning'
        }).then(async() => {
          try {
            this.powerState = 'Off';
            await this.value.power('Off');
            this.$message({
              type:    'success',
              message: `${ this.t('pai.labels.success') }`,
            });
          } catch (e) {
            this.$message({
              type:    'warning',
              message: '已取消关机，如需恢复，请继续关机操作'
            });
          }
        }).catch(() => {
        });
      } else {
        this.$confirm(this.t('pai.detail.vmset.confirmRestore'), this.t('pai.detail.vmset.tooltip'), {
          confirmButtonText: this.t('pai.detail.vmset.confirm'),
          cancelButtonText:  this.t('pai.detail.vmset.cancel'),
          type:              'warning'
        }).then(async() => {
          const newArr = [];

          for (let i = 0; i < value.tempList.length; i++) {
            if (this.value.spec.power === 'Off') {
              newArr.push({
                pvName:  value.tempList[i].pvName,
                cluster: this.params.cluster,
                hostId:  this.nodeList[0].metadata.name,
                name:    value.tempList[i].tempName
              });
            }
          }
          for (let i = 0;i < newArr.length;i++) {
            await this.$store.dispatch('pai-common/restoreSnapShot', newArr[i]);
          }
          this.$message({
            type:    'success',
            message: this.t('pai.detail.vmset.restoreSuccess')
          });
        }).catch(() => {
          this.$message({
            type:    'error',
            message: this.t('pai.detail.vmset.restorefailed')
          });
        });
      }
    },
  }
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <el-button
              class="btn role-primary"
              @click="snapshotShow"
            >
              <i class="el-icon-plus" /> {{ t('pai.detail.vmset.tab.snapshotManagement.newSnapshot') }}
            </el-button>
          </div>
          <el-input
            v-model="filterValue"
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
            size="small"
          />
        </div>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.name.includes(filterValue)):newTableData"
          class="snapShotTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
          />
          <el-table-column
            prop="name"
            :label="t('pai.detail.vmset.tab.snapshotManagement.name')"
            width="300"
          />
          <el-table-column
            prop="status"
            :label="t('pai.detail.vmset.status')"
          />
          <el-table-column
            prop="size"
            :label="t('pai.detail.vmset.tab.snapshotManagement.size')"
          />
          <el-table-column
            prop="diskInfo"
            :label="t('pai.detail.vmset.tab.snapshotManagement.diskInfo')"
            width="300"
          >
            <template slot-scope="scope">
              <el-tooltip effect="light">
                <div slot="content">
                  <div
                    v-for="(item, index) in scope.row.volumeList"
                    :key="index"
                    class="content"
                  >
                    <p style="font-size: 14px">
                      {{ item }}
                    </p>
                  </div>
                </div>
                <template>
                  <div class="content">
                    {{ scope.row.volumeList[0] }}
                    <i class="icon el-icon-s-unfold" />
                  </div>
                </template>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="createBy"
            :label="t('pai.detail.vmset.tab.snapshotManagement.createBy')"
          />
          <el-table-column
            prop="createTime"
            :label="t('pai.detail.vmset.tab.snapshotManagement.createTime')"
            width="200"
          />
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="restoreSnapShot(scope.row)"
              >
                <i class="el-icon-refresh-left" /> <span>{{ t('pai.detail.vmset.tab.snapshotManagement.resume') }}</span>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="deleteSnapshot(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="t('pai.detail.vmset.tab.snapshotManagement.newSnapshot')"
          :visible.sync="createSnapshotDialogVisible"
          width="500px"
        >
          <el-descriptions
            :column="1"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.snapshotManagement.name') }}
              </template>
              <el-input
                v-model="form.name"
                @change="onFormName"
              />
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.snapshotManagement.selectDisk') }}
              </template>
              <el-select
                v-model="diskNameOptionValue"
                :placeholder="t('pai.detail.vmset.placeholderSelect')"
                multiple
                @change="selectDisk"
              >
                <el-option
                  v-for="item in diskName"
                  :key="item.pvName"
                  :label="`${item.pvcName}(${item.type})`"
                  :value="item.pvName"
                  :disabled="item.disabled===false"
                />
              </el-select>
            </el-descriptions-item>
          </el-descriptions>
          <p>{{ t('pai.labels.tip')+':'+t('pai.detail.vmset.tab.snapshotManagement.longhornTip') }}</p>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="createSnapshotDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
            <el-button
              type="primary"
              @click="onSnapshot"
            >{{ t('pai.detail.vmset.confirm') }}</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>
<style lang="scss" scoped>
.snapShotTable{
  padding: 0 20px;
  width: 100%;
}
.el-input, .el-select, .el-input-number{
  width: 300px;
}
::v-deep .el-descriptions-item__label {
  width: 100px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
