import { addParam } from '@shell/utils/url';
import Socket, { EVENT_CONNECT_ERROR, EVENT_MESSAGE } from '@shell/utils/socket';

const GUEST_COMMANDS = { shutdown: ['qga', 'execute', 'guest-shutdown'] };

const CONTAINER_NOT_FOUND = 'container_not_found';

export function sendCommandToContainer(cluster, namespace, podName, containerName, commands, maxTries = 3) {
  return new Promise((resolve, reject) => {
    let url = `/k8s/clusters/${ cluster }/api/v1/namespaces/${ namespace }/pods/${ podName }/exec?container=${ containerName }&stderr=true&stdout=true`;

    url = addParam(url, 'command', commands);
    const socket = new Socket(url, true, null, null, maxTries);

    socket.addEventListener(EVENT_MESSAGE, (msg) => {
      resolve(msg);
    });
    socket.addEventListener(EVENT_CONNECT_ERROR, (err) => {
      reject(err);
    });
    socket.connect();
  });
}

export function shutdownPod(cluster, pod) {
  return sendCommandsToPodFirstContainer(cluster, pod, GUEST_COMMANDS.shutdown);
}

export function sendCommandsToPodFirstContainer(cluster, pod, commands) {
  const {
    containers = [],
    namespace,
    name,
  } = pod;

  if (containers.length > 0) {
    return sendCommandToContainer(cluster, namespace, name, containers[0].name, commands);
  }

  return new Promise((resolve, reject) => {
    reject(CONTAINER_NOT_FOUND);
  });
}
