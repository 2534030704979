<script>
import KeyValue from './KeyValue';
import { ToggleSwitch } from '@components/Form/ToggleSwitch';
import SectionTitle from './SectionTitle';

export default {
  components: {
    SectionTitle,
    ToggleSwitch,
    KeyValue
  },

  props: {
    value: {
      type:     Object,
      required: true,
    },

    mode: {
      type:     String,
      required: true,
    },

    displaySideBySide: {
      type:    Boolean,
      default: false,
    },

    defaultContainerClass: {
      type:    String,
      default: '',
    },

    defaultSectionClass: {
      type:    String,
      default: '',
    },

    labelTitleTooltip: {
      type:    String,
      default: '',
    },

    annotationTitleTooltip: {
      type:    String,
      default: '',
    }
  },

  data() {
    return { toggler: false };
  },
};
</script>
<template>
  <div>
    <SectionTitle :value="t('labels.labels.title')" />
    <KeyValue
      key="labels"
      :value="value.labels"
      :protected-keys="value.systemLabels || []"
      :toggle-filter="toggler"
      :add-label="t('labels.addLabel')"
      :mode="mode"
      :read-allowed="false"
      :value-can-be-empty="true"
      @input="value.setLabels($event)"
    />
    <div className="spacer" />
    <SectionTitle :value="t('labels.annotations.title')" />
    <KeyValue
      key="annotations"
      :value="value.annotations"
      :add-label="t('labels.addAnnotation')"
      :mode="mode"
      :title-protip="annotationTitleTooltip"
      :read-allowed="false"
      :value-can-be-empty="true"
      @input="value.setAnnotations($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.labels {
  &__header {
    display: flex;
    justify-content: space-between;
  }
}
</style>
