<script>
import debounce from 'lodash/debounce';
import Group from '@shell/components/nav/Group';
import { isMac } from '@shell/utils/platform';
import {
  BOTH, ALL, BASIC, FAVORITE, USED, NAMESPACED, CLUSTER_LEVEL, ROOT,
} from '@shell/store/type-map';
import { findBy } from '@shell/utils/array';
import { COUNT, SCHEMA } from '@shell/config/types';
import { escapeHtml, escapeRegex } from '@shell/utils/string';
import { sortBy } from '@shell/utils/sort';
import { convertType } from '../../utils';

export default {
  components: { Group },

  data() {
    return {
      isMac,
      value:  '',
      groups: null,
    };
  },

  watch: {
    value() {
      this.queueUpdate();
    },
  },

  mounted() {
    this.updateMatches();
    this.queueUpdate = debounce(this.updateMatches, 250);

    this.$refs.input.focus();
  },

  methods: {
    updateMatches() {
      const clusterId = this.$store.getters['clusterId'];
      const isAllNamespaces = this.$store.getters['isAllNamespaces'];
      const product = 'pai';

      let namespaces = null;

      if (!isAllNamespaces) {
        namespaces = Object.keys(this.$store.getters['activeNamespaceCache']);
      }

      const allTypes = this.allTypes() || {};
      const out = this.getTree(product, ALL, allTypes, clusterId, BOTH, namespaces, null, this.value);

      this.groups = out;

      // Hide top-level groups with no children (or one child that is an overview)
      this.groups.forEach((g) => {
        const isRoot = g.isRoot || g.name === 'Root';
        const hidden = isRoot || g.children?.length === 0 || (g.children?.length === 1 && g.children[0].overview);

        g.hidden = !!hidden;
      });
    },
    allTypes() {
      const mode = 'all';
      const isBasic = true;
      const product = 'pai';
      const out = {};
      const schemas = this.$store.getters[`cluster/all`](SCHEMA);
      const counts = this.$store.getters[`cluster/all`](COUNT)?.[0]?.counts || {};

      for (const schema of schemas) {
        const attrs = schema.attributes || {};
        const count = counts[schema.id];
        const label = this.$store.getters['type-map/labelFor'](schema, count);
        const weight = this.$store.getters['type-map/typeWeightFor'](convertType(schema?.id || label), isBasic);
        const typeOptions = this.$store.getters['type-map/optionsFor'](schema);

        //         // These are separate ifs so that things with no kind can still be basic
        if (!this.$store.getters['type-map/groupForBasicType'](product, convertType(schema.id))) {
          continue;
        }

        out[schema.id] = {
          label,
          mode,
          weight,
          schema,
          name:        schema.id,
          namespaced:  typeOptions.namespaced === null ? attrs.namespaced : typeOptions.namespaced,
          count:       count ? count.summary.count || 0 : null,
          byNamespace: count ? count.namespaces : {},
          revision:    count ? count.revision : null,
          route:       typeOptions.customRoute,
        };
      }

      return out;
    },
    getTree(productId, mode, allTypes, clusterId, namespaceMode, namespaces, currentType, search) {
      // getTree has four modes:
      // - `basic` matches data types that should always be shown even if there
      //    are 0 of them.
      // - `used` matches the data types where there are more than 0 of them
      //    in the current set of namespaces.
      // - `all` matches all types.
      // - `favorite` matches starred types.
      // namespaceMode: 'namespaced', 'cluster', or 'both'
      // namespaces: null means all, otherwise it will be an array of specific namespaces to include
      const isBasic = mode === BASIC;

      let searchRegex;

      if (search) {
        searchRegex = new RegExp(`^(.*)(${ escapeRegex(search) })(.*)$`, 'i');
      }

      const root = { children: [] };

      // Add types from shortest to longest so that parents
      // get added before children
      const keys = Object.keys(allTypes)
        .sort((a, b) => a.length - b.length);

      // Set these for later
      const currentLocal = this.$store.getters['i18n/current']();
      const defaultLocal = this.$store.getters['i18n/default']();

      for (const type of keys) {
        const typeObj = allTypes[type];

        if (typeObj.schema && this.$store.getters['type-map/isIgnored'](typeObj.schema)) {
          // Skip ignored groups & types
          continue;
        }

        const namespaced = typeObj.namespaced;

        if ((namespaceMode === NAMESPACED && !namespaced) || (namespaceMode === CLUSTER_LEVEL && namespaced)) {
          // Skip types that are not the right namespace mode
          continue;
        }

        const count = this._matchingCounts(typeObj, namespaces);
        const groupForBasicType = this.$store.getters['type-map/groupForBasicType'](productId, typeObj.name);

        if (typeObj.id === currentType) {
          // If this is the type currently being shown, always show it
        } else if (isBasic && !groupForBasicType) {
          // If we want the basic tree only return basic types;
          continue;
        } else if (mode === USED && count <= 0) {
          // If there's none of this type, ignore this entry when viewing only in-use types
          // Note: count is sometimes null, which is <= 0.
          continue;
        }

        const label = typeObj.labelKey ? this.$store.getters['i18n/t'](typeObj.labelKey) || typeObj.label : typeObj.label;
        const virtual = !!typeObj.virtual;
        let icon = typeObj.icon;

        if ((!virtual || typeObj.isSpoofed) && !icon) {
          if (namespaced) {
            icon = 'folder';
          } else {
            icon = 'globe';
          }
        }

        const labelDisplay = highlightLabel(this.$store, label, icon, typeObj.count, typeObj.schema);

        if (!labelDisplay) {
          // Search happens in highlight and returns null if not found
          continue;
        }

        let group;

        if (isBasic) {
          group = _ensureGroup(this.$store, root, groupForBasicType, true);
        } else if (mode === FAVORITE) {
          group = _ensureGroup(this.$store, root, 'starred');
          group.weight = 1000;
        } else if (mode === USED) {
          group = _ensureGroup(this.$store, root, `inUse::${ this.$store.getters['type-map/groupLabelFor'](typeObj.schema) }`);
        } else {
          group = _ensureGroup(this.$store, root, typeObj.schema || typeObj.group || ROOT);
        }

        let route = typeObj.route;

        // Make the default route if one isn't set
        if (!route) {
          route = {
            name:   'pai-c-cluster-resource',
            params: {
              product:  productId,
              cluster:  clusterId,
              resource: convertType(typeObj.name),
            },
          };

          typeObj.route = route;
        }

        // Cluster ID and Product should always be set
        if (route && typeof route === 'object') {
          route.params = route.params || {};
          route.params.cluster = clusterId;
          route.params.product = productId;
        }

        group.children.push({
          label,
          labelDisplay,
          mode:     typeObj.mode,
          count,
          exact:    typeObj.exact || false,
          namespaced,
          route,
          name:     typeObj.name,
          weight:   typeObj.weight || this.$store.getters['type-map/typeWeightFor'](typeObj.schema?.id || label, isBasic),
          overview: !!typeObj.overview,
        });
      }

      // Recursively sort the groups
      this._sortGroup(root, mode);

      return root.children;

      // ----------------------

      function _ensureGroup(store, tree, schemaOrName, forBasic = false) {
        let name = store.getters['type-map/groupLabelFor'](schemaOrName);
        const isRoot = (name === ROOT || name.startsWith(`${ ROOT }::`));

        if (name && name.includes('::')) {
          let parent;

          [parent, name] = name.split('::', 2);
          tree = _ensureGroup(store, tree, parent);
        }

        // Translate if an entry exists
        let label = name;
        const key = `nav.group."${ name }"`;

        if (store.getters['i18n/exists'](key)) {
          label = store.getters['i18n/t'](key);
        }

        let group = findBy(tree.children, 'name', name);

        if (!group) {
          group = {
            name,
            label,
            weight:      store.getters['type-map/groupWeightFor'](name, forBasic),
            defaultType: store.getters['type-map/groupDefaultTypeFor'](name),
          };

          tree.children.push(group);
        }

        if (isRoot) {
          group.isRoot = true;
        }

        if (!group.children) {
          group.children = [];
        }

        return group;
      }

      function highlightLabel(store, original, icon, count, schema) {
        let label = escapeHtml(original);

        if (searchRegex) {
          let match = label.match(searchRegex);

          if (!match) {
            if (currentLocal !== defaultLocal && schema) {
              const defaultLabel = store.getters['type-map/labelFor'](schema, count, defaultLocal);

              if (defaultLabel && defaultLabel !== label) {
                label += ` (${ defaultLabel })`;
                match = label.match(searchRegex);
              }
            }
          }

          if (match) {
            label = `${ escapeHtml(match[1]) }<span class="highlight">${ escapeHtml(match[2]) }</span>${ escapeHtml(match[3]) }`;
          } else {
            return null;
          }
        }

        if (icon) {
          label = `<i class="icon icon-fw icon-${ icon }"></i>${ label }`;
        }

        return label;
      }
    },
    _matchingCounts(typeObj, namespaces) {
      // That was easy
      if (!typeObj.namespaced || !typeObj.byNamespace || namespaces === null || typeObj.count === null) {
        return typeObj.count;
      }

      let out = 0;

      // Otherwise start with 0 and count up
      for (const namespace of namespaces) {
        out += typeObj.byNamespace[namespace]?.count || 0;
      }

      return out;
    },
    _sortGroup(tree, mode) {
      const by = ['weight:desc', 'namespaced', 'label'];

      tree.children = sortBy(tree.children, by);

      for (const entry of tree.children) {
        if (entry.children) {
          this._sortGroup(entry, mode);
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <input
      ref="input"
      v-model="value"
      :placeholder="t('nav.resourceSearch.placeholder')"
      class="search"
      @keyup.esc="$emit('closeSearch')"
    >
    <div class="results">
      <div
        v-for="g in groups"
        :key="g.name"
        class="package"
      >
        <Group
          v-if="!g.hidden"
          :key="g.name"
          id-prefix=""
          :group="g"
          :can-collapse="false"
          :fixed-open="true"
          @close="$emit('closeSearch')"
        >
          <template slot="accordion">
            <h6>{{ g.label }}</h6>
          </template>
        </Group>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search, .search:hover, .search:focus {
  position: relative;
  background-color: var(--dropdown-bg);
  border-radius: 0;
  box-shadow: none;
}

.results {
  margin-top: -1px;
  overflow-y: auto;
  padding: 10px;
  color: var(--dropdown-text);
  background-color: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  height: 75vh;
}
</style>
