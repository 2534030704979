import SteveModel from '@shell/plugins/steve/steve-class';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { VM_POWER_STATES, VM_POWER_STATES_ENUM } from '~/pkg/pai/plugins/pai-resource-class';
import { ALIAS } from '~/pkg/pai/config/labels-annotations';
import { formatTime } from '~/pkg/pai/utils';
import { MANAGEMENT, NAMESPACE, POD } from '@shell/config/types';
import { APPLICATION_ACTION_STATE } from '~/pkg/epinio/types';
import { colorForState } from '@shell/plugins/dashboard-store/resource-class';
import { INSTANCE_STATUS, PAI_RESOURCES } from '~/pkg/pai/config/types';
import { Message } from 'element-ui';
import jsyaml from 'js-yaml';
import { shutdownPod } from '~/pkg/pai/utils/guest-command';
import StarMixin from '~/pkg/pai/mixins/star';
import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import { MODE } from '@shell/config/query-params';

const DEFAULT_WAIT_INTERVAL = 1000;
const DEFAULT_WAIT_TIMEOUT = 15000;

export default class VMSet extends StarMixin(PaiLocationMixin(SteveModel)) {
  get details() {
    const out = super.details;

    out.push({
      label:   this.t('pai.detail.vmset.project'),
      content: this.projectDisplayName,
    });
    out.push({
      label:   this.t('nameNsDescription.name.label'),
      content: this.alias,
    });
    out.push({
      label:   this.t('pai.detail.vmset.instanceCount'),
      content: this.spec.replicas,
    });
    if (this.metadata?.annotations['kubernetes.io/arch']) {
      out.push({
        label:   this.t('pai.detail.vmset.arch'),
        content: this.metadata?.annotations['kubernetes.io/arch'],
      });
    }
    if (this.metadata?.annotations['tdology.com/monitoring']) {
      out.push({
        label:   this.t('pai.detail.vmset.monitoring'),
        content: this.metadata?.annotations['tdology.com/monitoring'],
      });
    }
    if (this.spec?.os) {
      out.push({
        label:   this.t('pai.detail.vmset.operationSystem'),
        content: this.spec.os,
      });
    }
    if (this.osVersion) {
      out.push({
        label:   this.t('pai.detail.vmset.operationSystemImage'),
        content: this.osVersion,
      });
    }
    if (this.agent) {
      out.push({
        label:   this.t('pai.vmset.system.agent'),
        content: this.agent,
      });
    }
    if (this?.spec?.template?.spec.containers && this?.spec?.template?.spec.containers.length > 0) {
      const container = this.spec.template.spec.containers[0];

      if (container.resources) {
        if (container.resources.requests) {
          if (container.resources.requests.cpu) {
            out.push({
              label:   this.t('pai.vmset.requests.cpu'),
              content: container.resources.requests.cpu,
            });
          }
          if (container.resources.requests.memory) {
            out.push({
              label:   this.t('pai.vmset.requests.memory'),
              content: container.resources.requests.memory,
            });
          }
        }
        if (container.resources.limits) {
          if (container.resources.limits.cpu) {
            out.push({
              label:   this.t('pai.vmset.limits.cpu'),
              content: container.resources.limits.cpu,
            });
          }
          if (container.resources.limits.memory) {
            out.push({
              label:   this.t('pai.vmset.limits.memory'),
              content: container.resources.limits.memory,
            });
          }
        }
      }
    }

    if (this.metadata?.creationTimestamp) {
      out.push({
        label:   this.t('pai.detail.vmset.createTime'),
        content: formatTime(this.metadata.creationTimestamp),
      });
    }

    return out;
  }

  get projectId() {
    const all = this.$rootGetters['cluster/all'](NAMESPACE);
    const { projectId } = all.find(namespace => namespace.id === this.metadata.namespace);

    return projectId;
  }

  get projectDisplayName() {
    const clusterId = this.$rootGetters['currentCluster']?.id;

    if (this.projectId) {
      const project = this.$rootGetters['management/byId'](MANAGEMENT.PROJECT, `${ clusterId }/${ this.projectId }`);

      return project?.spec?.displayName;
    }

    return null;
  }

  get stateDisplay() {
    if (this.powerState === VM_POWER_STATES_ENUM.Off) {
      return this.t(`pai.vmset.powerState.off`);
    }

    return this.t(`pai.vmset.powerState.${ this.instanceState.toLocaleLowerCase() }`);
  }

  get stateColor() {
    return VM_POWER_STATES[this.instanceState].color || super.stateColor;
  }

  get stateObj() {
    switch (this.state) {
    case APPLICATION_ACTION_STATE.SUCCESS:
      return {
        name:          'succeeded',
        error:         false,
        transitioning: false,
      };
    case APPLICATION_ACTION_STATE.RUNNING:
      return {
        name:          'pending',
        error:         false,
        transitioning: false,
      };
    case APPLICATION_ACTION_STATE.FAIL:
      return {
        name:          'fail',
        error:         true,
        transitioning: false,
        message:       this.stateMessage,
      };
    case APPLICATION_ACTION_STATE.PENDING:
    default:
      return {
        name:          'pending',
        error:         false,
        transitioning: false,
      };
    }
  }

  get state() {
    if (this.powerState === VM_POWER_STATES_ENUM.On) {
      if (this.runningInstances.length > 0 && this.runningInstances.length === this.instanceCount) {
        return APPLICATION_ACTION_STATE.RUNNING;
      }
      if (this.succeededInstances.length > 0 && this.succeededInstances.length === this.instanceCount) {
        return APPLICATION_ACTION_STATE.SUCCESS;
      }
      const errorPods = this.metadata.relationships?.filter(pod => pod.error === true || pod.state == 'scheduling' || pod.state == 'unavailable');

      if (errorPods && errorPods.length > 0) {
        return APPLICATION_ACTION_STATE.FAIL;
      }

      if (this.status && this.status.instances) {
        const { instances = {} } = this.status;
        const keys = Object.keys(instances);

        for (const key of keys) {
          if (instances[key].status !== 'Running' && instances[key].status !== 'Pending' && instances[key].status !== 'Stopped' && instances[key].status !== 'Succeeded') {
            return APPLICATION_ACTION_STATE.FAIL;
          }
        }
      }
    }

    return APPLICATION_ACTION_STATE.SUCCESS;
  }

  get stateMessage() {
    const errorPods = this.metadata.relationships?.filter(pod => pod.error === true || pod.state == 'scheduling' || pod.state == 'unavailable');

    if (errorPods && errorPods.length > 0) {
      return errorPods[0].message;
    }
    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      for (const key of keys) {
        if (instances[key].status !== 'Running' && instances[key].status !== 'Pending' && instances[key].status !== 'Stopped' && instances[key].status !== 'Succeeded') {
          return instances[key].status;
        }
      }
    }

    return null;
  }

  get availableActions() {
    const out = super._availableActions;

    const clone = out.find(action => action.action === 'goToClone');

    if (clone) {
      clone.action = 'goToCloneVM';
      clone.label = this.t('generic.copy');
      clone.enabled = (!this.power || this.power === VM_POWER_STATES_ENUM.Off) || this.runningInstances.length < 1;
    }

    return [
      {
        action:   'confirmPowerOff',
        enabled:  this.powerState === VM_POWER_STATES_ENUM.On,
        icon:     'icon icon-close',
        label:    this.t('pai.detail.vmset.powerOff'),
        bulkable: true,
      },
      {
        action:   'powerOn',
        enabled:  this.powerState === VM_POWER_STATES_ENUM.Off,
        icon:     'icon icon-play',
        label:    this.t('pai.detail.vmset.powerOn'),
        bulkable: true,
      },
      {
        action:   'confirmPowerRestart',
        enabled:  this.powerState === VM_POWER_STATES_ENUM.On,
        icon:     'icon icon-refresh',
        label:    this.t('pai.detail.vmset.powerRestart'),
        bulkable: true,
      },
      ...out,
    ];
  }

  promptRemove(resources) {
    if (!resources) {
      resources = this;
    }

    this.$dispatch('pai-common/updateState', {
      currentModal: 'deleteVmModal',
      currentItem:  resources,
    }, { root: true });
  }

  goToCloneVM(resources = this) {
    // 复制前进行状态检查
    const vmPods = [];

    if (resources.status && resources.status.instances) {
      const { instances = {} } = resources.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        vmPods.push({
          name:   key,
          status: instances[key].status,
        });
      });
    }

    let flag = true;

    if (vmPods.length < 1) {
      flag = false;
    }
    for (const pod of vmPods) {
      if ((resources.spec.power === VM_POWER_STATES_ENUM.On && pod.status !== VM_POWER_STATES_ENUM.Running) || (resources.spec.power === VM_POWER_STATES_ENUM.Off && pod.status !== VM_POWER_STATES_ENUM.Succeeded)) {
        flag = false;
      }
    }
    if (flag) {
      this.$dispatch('pai-common/updateState', {
        currentModal: 'cloneVmModal',
        currentItem:  resources,
      }, { root: true });
    } else {
      Message.warning(this.t('pai.vmset.tips.clone.forbidden'));
    }
  }

  confirmPowerOff(resource = this) {
    this.$dispatch('promptModal', {
      component:      'ConfirmDialog',
      componentProps: {
        resource,
        confirm: resource => resource.powerOff(),
      },
    });
  }

  confirmPowerRestart(resource = this) {
    this.$dispatch('promptModal', {
      component:      'ConfirmDialog',
      componentProps: {
        resource,
        confirm: resource => resource.powerRestart(),
      },
    });
  }

  needPowerRestart(resource = this, callback, titleKey, bodyKey) {
    this.$dispatch('promptModal', {
      component:      'ConfirmDialog',
      componentProps: {
        resource,
        title:   titleKey ? this.t(titleKey) : this.t('dialog.generic.needRestart.title'),
        body:    bodyKey ? this.t(bodyKey) : this.t('dialog.generic.needRestart.body'),
        confirm: async(resource) => {
          callback(resource);
          await resource.powerRestart();
        },
        handleCancel: async(resource) => {
          callback(resource);
          await resource.forceFetch();
        },
      },
    });
  }

  needPowerOff(resource = this, callback) {
    this.$dispatch('promptModal', {
      component:      'ConfirmDialog',
      componentProps: {
        resource,
        title:   this.t('dialog.generic.needPowerOff.title'),
        body:    this.t('dialog.generic.needPowerOff.body'),
        confirm: (resource) => {
          resource.powerOff()
            .then((r) => {
              if (callback) {
                callback(resource);
              }
            });
        },
      },
    });
  }

  needPowerOn(resource = this, callback) {
    this.$dispatch('promptModal', {
      component:      'ConfirmDialog',
      componentProps: {
        resource,
        title:   this.t('dialog.generic.needPowerOn.title'),
        body:    this.t('dialog.generic.needPowerOn.body'),
        confirm: (resource) => {
          resource.powerOn()
            .then((r) => {
              if (callback) {
                callback(resource);
              }
            });
        },
      },
    });
  }

  goToBackup(resources = this) {
    this.$dispatch('pai-common/updateState', {
      currentModal: 'cloneVmModal',
      currentItem:  resources,
    }, { root: true });
  }

  onOpenShell(podName) {
    const clusterId = this.$rootGetters['clusterId'];
    const resource = this;

    function openShell(podName) {
      const vncLocation = `/k8s/clusters/${ clusterId }/api/v1/namespaces/vncproxy/services/http:vncproxy:80/proxy/console/${ clusterId }/${ resource.metadata.namespace }/${ podName }?title=${ podName }`;
      const containers = resource.spec.template.spec.containers;

      if (containers.length) {
        const vncPorts = containers[0].ports.filter(p => p.containerPort === 5900 || p.containerPort === 5901);

        if (vncPorts.length) {
          window.open(`${ vncLocation }&vncport=${ vncPorts[0].containerPort }`, podName);

          return;
        }
      }
      window.open(`${ vncLocation }`, podName);
    }

    if (podName) {
      openShell(podName);
    } else {
      const vmPods = this.runningInstances;

      if (vmPods.length === 1) {
        const podName = vmPods[0];

        openShell(podName);
      } else if (vmPods.length) {
        this.$dispatch('pai-common/updateState', {
          currentItem:  { id: this.id },
          currentModal: 'consoleModal',
        }, { root: true });
      } else {
        Message.warning(this.t('pai.vmset.tips.console'));
      }
    }
  }

  async power(state) {
    switch (state) {
    case VM_POWER_STATES_ENUM.On:
      await this.powerOn();
      break;
    case VM_POWER_STATES_ENUM.Off:
      await this.powerOff();
      break;
    case VM_POWER_STATES_ENUM.Restart:
      await this.powerRestart();
    }
  }

  async powerOn() {
    const resource = await this.forceFetch();

    resource.spec.power = VM_POWER_STATES_ENUM.On;
    await resource.save();
  }

  async powerOff() {
    const resource = await this.forceFetch();

    resource.spec.power = VM_POWER_STATES_ENUM.Off;
    await resource.save();
  }

  async powerRestart() {
    const resource = await this.forceFetch();

    resource.spec.power = VM_POWER_STATES_ENUM.Off;
    const resource1 = await resource.save();

    await resource1.waitForInstancesStatuses([VM_POWER_STATES_ENUM.Succeeded, VM_POWER_STATES_ENUM.Pending]);
    const resource2 = await resource1.forceFetch();

    resource2.spec.power = VM_POWER_STATES_ENUM.On;
    try {
      await resource2.save();
    } catch (e) {
      console.log(e);
      setTimeout(async() => {
        const resource3 = await resource2.forceFetch();

        resource3.spec.power = VM_POWER_STATES_ENUM.On;
        await resource3.save();
      }, 1000);
    }
  }

  async safeOff() {
    let pods = [];
    const allPods = await this.$dispatch('findAll', { type: POD });

    if (this.metadata.relationships && this.metadata.relationships.length > 0) {
      const toIds = this.metadata.relationships.map(v => v.toId);

      pods = allPods.filter(v => toIds.includes(v.id));
    }
    for await (const pod of pods) {
      try {
        await shutdownPod(this.$rootGetters['clusterId'], pod);
      } catch (e) {
        return e;
      }
    }
  }

  get alias() {
    return (this.metadata.annotations && this.metadata.annotations[ALIAS]) ? this.metadata.annotations[ALIAS] : '';
  }

  get osVersion() {
    return this.spec.volumeClaimTemplates && this.spec.volumeClaimTemplates.length > 0 ? this.spec.volumeClaimTemplates[0]?.spec?.storageClassName : 'unknown';
  }

  get agent() {
    return this.spec.agent || '未知';
  }

  get powerState() {
    return (this.spec && this.spec.power) ? this.spec.power : VM_POWER_STATES_ENUM.Unknown;
  }

  get instanceState() {
    const powerState = this.powerState;

    if (powerState === VM_POWER_STATES_ENUM.Off || powerState === VM_POWER_STATES_ENUM.Unknown) {
      return powerState;
    }
    if (this.succeededInstances.length > 0 && this.succeededInstances.length === this.instanceCount) {
      return VM_POWER_STATES_ENUM.Succeeded;
    }
    if (this.stoppedInstances.length > 0 && this.stoppedInstances.length === this.instanceCount) {
      return VM_POWER_STATES_ENUM.Succeeded;
    }
    if (this.runningInstances.length > 0 && this.runningInstances.length === this.instanceCount) {
      return VM_POWER_STATES_ENUM.Running;
    }

    if (this.metadata.relationships && this.metadata.relationships.some(pod => pod.state === 'scheduling')) {
      return VM_POWER_STATES_ENUM.Scheduling;
    }
    if (this.metadata.relationships && this.metadata.relationships.some(pod => pod.state === 'unavailable')) {
      return VM_POWER_STATES_ENUM.Unavailable;
    }

    if (this.metadata.relationships && this.metadata.relationships.some(pod => pod.error)) {
      return VM_POWER_STATES_ENUM.Error;
    }

    return VM_POWER_STATES_ENUM.Pending;
  }

  get instanceCount() {
    return this.status && this.status.instances ? Object.keys(this.status.instances).length : 0;
  }

  get runningInstances() {
    const runningInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (instances[key].status === 'Running') {
          runningInstances.push(key);
        }
      });
    }

    return runningInstances;
  }

  get pendingInstances() {
    const pendingInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (instances[key].status === 'Pending') {
          pendingInstances.push(key);
        }
      });
    }

    return pendingInstances;
  }

  get succeededInstances() {
    const succeededInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (instances[key].status === 'Succeeded') {
          succeededInstances.push(key);
        }
      });
    }

    return succeededInstances;
  }

  getInstancesByStatus(status) {
    const statusInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (instances[key].status === status) {
          statusInstances.push(key);
        }
      });
    }

    return statusInstances;
  }

  getInstancesByStatuses(statuses) {
    const statusInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (statuses.includes(instances[key].status)) {
          statusInstances.push(key);
        }
      });
    }

    return statusInstances;
  }

  waitForInstancesStatus(status, timeout = DEFAULT_WAIT_TIMEOUT, interval = DEFAULT_WAIT_INTERVAL) {
    return this.waitForTestFn(() => {
      return this.getInstancesByStatus(status).length === this.instanceCount;
    }, `status=${ status }`, timeout, interval);
  }

  waitForInstancesStatuses(statuses, timeout = DEFAULT_WAIT_TIMEOUT, interval = DEFAULT_WAIT_INTERVAL) {
    return this.waitForTestFn(() => {
      return this.getInstancesByStatuses(statuses).length === this.instanceCount;
    }, `status=${ statuses }`, timeout, interval);
  }

  get stoppedInstances() {
    const stoppedInstances = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        if (instances[key].status === 'Stopped') {
          stoppedInstances.push(key);
        }
      });
    }

    return stoppedInstances;
  }

  get instances() {
    const instancesResult = [];

    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        instancesResult.push({ name: key, ...instances[key] });
      });
    }

    return instancesResult;
  }

  get instanceNames() {
    if (this.status && this.status.instances) {
      const { instances = {} } = this.status;

      return Object.keys(instances);
    }

    return [];
  }

  get instanceGauges() {
    const out = {};

    if (!this.instances || this.instances.length === 0) {
      return out;
    }

    this.instances.map((instance) => {
      let { status } = instance;

      if (!Object.keys(INSTANCE_STATUS)
        .includes(status)) {
        status = INSTANCE_STATUS.Error;
      }
      if (out[status]) {
        out[status].count++;
      } else {
        out[status] = {
          color: colorForState(status)
            .replace('text-', ''),
          count: 1,
        };
      }
    });

    return out;
  }

  get schedulingPods() {
    const schedulingPods = [];

    if (this.metadata.relationships) {
      this.metadata.relationships.forEach((pod) => {
        if (pod.state === 'scheduling') {
          schedulingPods.push(pod);
        }
      });
    }

    return schedulingPods;
  }

  async forceFetch() {
    return await this.$dispatch('find', {
      type: PAI_RESOURCES.VMSET,
      id:   this.id,
      opt:  { force: true },
    });
  }

  get doneOverride() {
    return this.detailLocation;
  }

  async saveYaml(yaml) {
    await this.forceFetch();
    const parsed = jsyaml.load(yaml);

    if (!parsed.spec.volumeClaimTemplates ||
      parsed.spec.volumeClaimTemplates.length === 0 ||
      !parsed.spec.volumeClaimTemplates[0].metadata.name ||
      !parsed.spec.volumeClaimTemplates[0].spec ||
      !parsed.spec.volumeClaimTemplates[0].spec.resources ||
      !parsed.spec.volumeClaimTemplates[0].spec.resources.requests ||
      !parsed.spec.volumeClaimTemplates[0].spec.resources.requests.storage ||
      !parsed.spec.volumeClaimTemplates[0].spec.storageClassName) {
      throw this.$rootGetters['i18n/t']('resourceYaml.errors.volumeClaimTemplatesRequired');
    }
    await super.saveYaml(yaml);
  }

  remove() {
    this.powerOff()
      .then(() => {
        this.waitForInstancesStatuses([VM_POWER_STATES_ENUM.Succeeded, VM_POWER_STATES_ENUM.Pending, VM_POWER_STATES_ENUM.Error])
          .then(() => {
            this._remove(...arguments);
          });
      });
  }

  goToMakeImage() {
    // 制作镜像前进行状态检查,只有关机状态下且有实例才能创建
    const instances = this.instances;

    if (instances.length < 1) {
      Message.warning(this.t('pai.vmset.tips.image.forbidden'));

      return;
    }
    for (let i = 0;i < instances.length;i++) {
      if (this.powerState !== VM_POWER_STATES_ENUM.Off || ![VM_POWER_STATES_ENUM.Succeeded, VM_POWER_STATES_ENUM.Pending, VM_POWER_STATES_ENUM.Error].includes(instances[i].status)) {
        Message.warning(this.t('pai.vmset.tips.image.power'));

        return;
      }
    }
    const clusterId = this.$rootGetters['currentCluster']?.id;

    this.currentRouter().push({
      name:   `${ PAI }-c-cluster-resource-create`,
      params: {
        product:  PAI,
        cluster:  clusterId,
        resource: PAI_RESOURCES.VM_IMAGE,
      },
      query: { [MODE]: 'makeImage', vm: this.id },
    });
  }
}
