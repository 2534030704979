import Secret from '@shell/models/secret';
import { getDetailLocation, getListLocation } from '../utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';

export default class extends PaiLocationMixin(Secret) {
  get doneRoute() {
    if ( this.$rootGetters['currentProduct'].name === PRODUCT_NAME ) {
      return getListLocation(this );
    } else {
      return super.doneRoute;
    }
  }
}
