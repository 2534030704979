<script>
import CreateEditView from '@/shell/mixins/create-edit-view';
import FormValidation from '@/shell/mixins/form-validation';
import { NETWORK_ATTACHMENT, MANAGEMENT } from '@/shell/config/types';
import WorkLoadMixin from './mixins/workload';
import Tabbed from '../../components/Tabbed/index.vue';
import Tab from '../../components/Tabbed/Tab.vue';
import { ADDRESS_ASSIGNMENT_TYPE } from '@/pkg/pai/config/settings';
import { addOffset } from '@/pkg/pai/utils/ip';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { PAI_POD } from '@/pkg/pai/config/types';

export default {
  components: {
    Tabbed,
    Tab
  },
  name:   'Workload',
  mixins: [CreateEditView, FormValidation, WorkLoadMixin], // The order here is important since WorkLoadMixin contains some FormValidation configuration
  props:  {
    value: {
      type:     Object,
      required: true,
    },

    mode: {
      type:    String,
      default: 'create',
    },
  },
  async created() {
    this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
    const networkData = [this.defaultNetwork];

    this.value?.metadata?.annotations['k8s.v1.cni.cncf.io/networks']?.split(',').forEach((v) => {
      const network = this.networks.find(network => network.metadata.namespace === this.value.metadata.namespace && network.metadata.name === v);

      if (network) {
        const config = JSON.parse(network.spec.config);

        const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
        let ip = '-';
        let srcIP = '-';

        if (this.value.metadata?.annotations && !this.value.metadata?.annotations['k8s.v1.cni.cncf.io/network-status']) {
          this.value.metadata = { annotations: { 'k8s.v1.cni.cncf.io/network-status': '[]' } };
        }
        JSON.parse(this.value.metadata?.annotations['k8s.v1.cni.cncf.io/network-status'])?.forEach((ips) => {
          if (ips.name.includes(network.metadata.name)) {
            ip = ips?.ips[0];
            srcIP = config?.ipam?.addresses[0].address;
          }
        });
        if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
          if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
            ip = config?.ipam?.ranges[0][0].subnet;
          }
        }
        networkData.push({
          name:        network.metadata.name,
          alias:       network.alias,
          networkType: config.type,
          hostNetwork: config.uplink,
          assignmentType,
          ip,
          srcIP,
        });
      }
    });

    this.networkData = networkData;
    this.updateNetworkOptions();
  },
  data() {
    return {
      selectedName:         null,
      activeContainerIndex: 0,
      networkData:          [],
      ipOffset:             this.mode !== 'create' ? this.value.metadata.name?.split('.')[this.value.metadata.name?.split('.').length - 1] : '',
      aliasArr:             [],
      networks:             [],
      networkOptions:       [],
    };
  },
  computed: {
    defaultNetwork() {
      return {
        alias:     this.t('pai.vmset.defaultNetwork'),
        isDefault: true,
      };
    },
    namespace() {
      return this.value?.metadata?.namespace || '';
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  watch: {
    namespace() {
      this.updateNetworkOptions();
    },
    ipOffset(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setRestartState();

        this.networkData = this.networkData.map((item) => {
          let ip = '';

          if (item?.assignmentType === 'static' && item?.ip) {
            ip = addOffset(item?.srcIP, newValue);
          } else {
            ip = item?.ip;
          }

          return {
            ...item,
            ip
          };
        });
      }
    },
  },
  methods: {
    changed(tab) {
      const key = this.idKey;

      this.selectedName = tab.selectedName;
      const container = this.containerOptions.find( c => c[key] === tab.selectedName);

      if ( container ) {
        this.selectContainer(container);
      }
    },

    /**
     * Find error exceptions to be mapped for each case
     */
    mapError(error) {
      switch (true) {
      case error.includes('violates PodSecurity'): {
        const match = error.match(/\"(.*?)\"/gi);
        const name = match[0];
        const policy = match[1];

        return {
          message: `Pod ${ name } Security Policy Violation ${ policy }`,
          icon:    'icon-pod_security'
        };
      }

      default:
        break;
      }
    },

    /**
     * Map all the error texts to a message and icon object
     */
    getErrorsMap(errors) {
      return !errors || !Array.isArray(errors) ? {} : errors.reduce((acc, error) => ({
        ...acc,
        [error]: this.mapError(error) || {
          message: error,
          icon:    null
        }
      }), {});
    },
    onActiveContainer(i) {
      this.activeContainerIndex = i;
    },
    onClose(tab) {
      this.removeContainer(tab);
      this.onActiveContainer(0);
    },
    getAliasArr() {
      const newArr = [];

      this.networkData?.forEach((item) => {
        newArr.push(item.alias);
      });
      this.aliasArr = [...new Set(newArr)];
    },
    addNetwork() {
      this.setRestartState();
      this.networkData.push({});
      this.getAliasArr();
    },
    removeAllNetworks() {
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(() => {
        this.setRestartState();
        this.networkData.splice(0, this.networkData.length);
        this.networkData.push(this.defaultNetwork);
        this.getAliasArr();
      }).catch(() => {});
    },
    onNetworkChange(e, index) {
      this.setRestartState();
      const config = JSON.parse(e.spec.config);
      const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
      let ip = '-';

      if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
        if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
          ip = config?.ipam?.ranges[0][0].subnet;
        }
      } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
        if (config?.ipam?.addresses?.length) {
          ip = config?.ipam?.addresses[0].address;
        }
      }
      this.$set(this.networkData, index, {
        name:        e.metadata.name,
        alias:       e.alias,
        networkType: config.type,
        hostNetwork: config.uplink,
        assignmentType,
        ip,
        srcIP:       ip,
      });
      this.networkData = this.networkData.map((item) => {
        let ip = '';

        if (item?.assignmentType === 'static' && item?.ip) {
          ip = addOffset(item?.srcIP, this.ipOffset ? this.ipOffset : 0);
        } else {
          ip = item?.ip;
        }

        return {
          ...item,
          ip
        };
      });
      this.getAliasArr();
    },
    removeNetwork(row, index) {
      if (row.alias) {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        }).then(() => {
          this.setRestartState();
          this.networkData.splice(index, 1);
          this.getAliasArr();
        });
      } else {
        this.setRestartState();
        this.networkData.splice(index, 1);
        this.getAliasArr();
      }
    },
    updateNetworkOptions() {
      this.networkOptions = this.networks.filter(v => v.metadata.namespace === this.value.metadata.namespace).map((v) => {
        return {
          label: v.alias,
          value: v,
        };
      });
    },
    setRestartState() {
      if (!this.isCreate) {
        this.needRestart = true;
      }
    },
    handleSetIpOffset(e) {
      if (e.target._value) {
        this.ipOffset = Number(e.target._value);
        this.$set(this.value.metadata, 'name', `${ this.value.metadata.name }.${ Number(e.target._value) }` );
      }
    },
    async save(buttonCb) {
      for (let i = 0; i < this.allContainers.length;i++) {
        if (!this.allContainers[i].image) {
          this.$message.error(this.t('pai.edit.tips.required'));
          buttonCb(false);

          return;
        }
        if (/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g.test(this.allContainers[i].image)) {
          this.$message.error(this.t('pai.apps.container.containerMirroring') + this.t('pai.edit.tips.prohibitChinese'));
          buttonCb(false);

          return;
        }
      }
      const networkDataAlias = this.networkData.filter((v, index) => {
        return index !== 0;
      }).map((item) => {
        return item.name;
      });

      try {
        this.$set(this.value.spec.template.metadata.annotations, 'k8s.v1.cni.cncf.io/networks', networkDataAlias.toString() );
        await this.value.save();
        buttonCb(true);
        this.$router.push({
          name:   `${ PAI }-c-cluster-resource`,
          params: {
            product:  PAI,
            resource: PAI_POD,
            cluster:  this.currentCluster,
          },
        });
        this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      } catch (e) {
        buttonCb(false);
        console.log(e);
      }
    },
    done() {
      this.$router.go(-1)
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    }
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <form
    v-else
    class="filled-height"
  >
    <CruResource
      :cancel-event="true"
      :validation-passed="fvFormIsValid"
      :selected-subtype="type"
      :resource="value"
      :mode="mode"
      :errors="fvUnreportedValidationErrors"
      :done-route="doneRoute"
      :subtypes="workloadSubTypes"
      :apply-hooks="applyHooks"
      :value="value"
      :errors-map="getErrorsMap(fvUnreportedValidationErrors)"
      @finish="save"
      @cancel="done"
      @select-type="selectType"
      @error="e=>errors = e"
    >
      <!-- <pre>{{ JSON.stringify(allContainers, null, 2) }}</pre> -->
      <NameNsDescription
        :value="value"
        :mode="mode"
        :rules="{name: fvGetAndReportPathRules('metadata.name'), namespace: fvGetAndReportPathRules('metadata.namespace'), description: []}"
        @change="name=value.metadata.name"
        @isNamespaceNew="isNamespaceNew = $event"
      />
      <div
        v-if="isCronJob || isReplicable || isStatefulSet || containerOptions.length > 1"
        class="row mb-20"
      >
        <div
          v-if="isCronJob"
          class="col span-3"
        >
          <LabeledInput
            v-model="spec.schedule"
            type="cron"
            required
            :mode="mode"
            :label="t('workload.cronSchedule')"
            :rules="fvGetAndReportPathRules('spec.schedule')"
            placeholder="0 * * * *"
          />
        </div>
        <div
          v-if="isReplicable"
          class="col span-3"
        >
          <LabeledInput
            v-model.number="spec.replicas"
            type="number"
            min="0"
            required
            :mode="mode"
            :label="t('workload.replicas')"
          />
        </div>
        <div
          v-if="isStatefulSet"
          class="col span-3"
        >
          <LabeledSelect
            v-model="spec.serviceName"
            option-label="metadata.name"
            :reduce="service=>service.metadata.name"
            :mode="mode"
            :label="t('workload.serviceName')"
            :options="headlessServices"
            required
          />
        </div>
      </div>
      <Tabbed
        ref="containersTabbed"
        class="deployment-tabs"
        :show-tabs-add-remove="true"
        :default-tab="defaultTab"
        :flat="true"
        @changed="changed"
      >
        <Tab
          key="container"
          :label="t('workload.container.titles.containers')"
          name="container"
          :weight="100"
        >
          <div class="containers">
            <el-tag
              v-for="(tab, i) in allContainers"
              :key="tab[idKey]"
              :closable="allContainers.length > 1 && !isView"
              :class="{'active':i===activeContainerIndex }"
              @click="onActiveContainer(i)"
              @close="onClose(tab)"
            >
              <img
                v-if="!tab.image"
                src="../../assets/images/common/warn.svg"
                style="width: 15px; height: 15px; position: relative; top: 4px;"
              >
              {{ tab.name }}
            </el-tag>
            <button
              v-if="!isView"
              type="button"
              class="btn-sm role-link"
              @click="addContainerBtn"
            >
              <i class="el-icon-plus" />{{ t('generic.add') }}
            </button>
          </div>

          <Tabbed
            v-if="allContainers.length > 0"
            :side-tabs="true"
            :weight="99"
          >
            <Tab
              :label="t('pai.vmset.info')"
              name="info"
              :weight="tabWeightMap['general']"
              :error="tabErrors.general"
            >
              <div>
                <div
                  :style="{'align-items':'center'}"
                  class="row mb-20"
                >
                  <div class="col span-6">
                    <LabeledInput
                      v-model="allContainers[activeContainerIndex].name"
                      :mode="mode"
                      :label="t('workload.container.containerName')"
                    />
                  </div>
                  <div class="col span-6">
                    <RadioGroup
                      :mode="mode"
                      :value="allContainers[activeContainerIndex]._init"
                      name="initContainer"
                      :options="[true, false]"
                      :labels="[t('workload.container.init'), t('workload.container.standard')]"
                      @input="updateInitContainer($event, allContainers[activeContainerIndex])"
                    />
                  </div>
                </div>
                <h3>{{ t('workload.container.titles.image') }}</h3>
                <div class="row mb-20">
                  <div class="col span-6">
                    <LabeledInput
                      v-model.trim="allContainers[activeContainerIndex].image"
                      :mode="mode"
                      :label="t('workload.container.image')"
                      :placeholder="t('generic.placeholder', {text: 'nginx:latest'}, true)"
                      :rules="fvGetAndReportPathRules('image')"
                    />
                  </div>
                  <div class="col span-6">
                    <LabeledSelect
                      v-model="allContainers[activeContainerIndex].imagePullPolicy"
                      :label="t('workload.container.imagePullPolicy')"
                      :options="pullPolicyOptions"
                      :mode="mode"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col span-6">
                    <LabeledSelect
                      v-model="imagePullSecrets"
                      :label="t('workload.container.imagePullSecrets')"
                      :multiple="true"
                      :taggable="true"
                      :options="imagePullNamespacedSecrets"
                      :mode="mode"
                      option-label="metadata.name"
                      :reduce="service=>service.metadata.name"
                      :create-option="createOption"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              :label="t('workload.container.titles.general')"
              name="general"
              :weight="tabWeightMap['general']"
              :rules="fvGetAndReportPathRules('metadata.name')"
            >
              <div>
                <h3>
                  {{ t('workload.container.ports.expose') }}
                  <i
                    v-tooltip="t('workload.container.ports.toolTip')"
                    class="icon icon-info"
                  />
                </h3>
                <p class="padded">
                  {{ t('workload.container.ports.description') }}
                </p>
                <div class="row">
                  <WorkloadPorts
                    v-model="allContainers[activeContainerIndex].ports"
                    :name="value.metadata.name"
                    :services="servicesOwned"
                    :mode="mode"
                  />
                </div>
              </div>
              <div class="spacer" />
              <div>
                <h3>{{ t('workload.container.titles.command') }}</h3>
                <Command
                  v-model="allContainers[activeContainerIndex]"
                  :secrets="namespacedSecrets"
                  :config-maps="namespacedConfigMaps"
                  :mode="mode"
                  :loading="isLoadingSecondaryResources"
                />
              </div>
              <ServiceNameSelect
                :value="podTemplateSpec.serviceAccountName"
                :mode="mode"
                :select-label="t('workload.serviceAccountName.label')"
                :select-placeholder="t('workload.serviceAccountName.label')"
                :options="namespacedServiceNames"
                option-label="metadata.name"
                :loading="isLoadingSecondaryResources"
                @input="updateServiceAccount"
              />
              <div class="spacer" />
              <div>
                <h3>{{ t('workload.container.titles.lifecycle') }}</h3>
                <LifecycleHooks
                  v-model="allContainers[activeContainerIndex].lifecycle"
                  :mode="mode"
                />
              </div>
            </Tab>
            <Tab
              :label="t('workload.container.titles.resources')"
              name="resources"
              :weight="tabWeightMap['resources']"
            >
              <!-- Resources and Limitations -->
              <ContainerResourceLimit
                v-model="flatResources"
                :mode="mode"
                :show-tip="false"
              />
            </Tab>

            <Tab
              v-if="!allContainers[activeContainerIndex]._init"
              :label="t('workload.container.titles.healthCheck')"
              name="healthCheck"
              :weight="tabWeightMap['healthCheck']"
            >
              <HealthCheck
                :value="allContainers[activeContainerIndex]"
                :mode="mode"
                @input="Object.assign(allContainers[activeContainerIndex], $event)"
              />
            </Tab>
            <Tab
              :label="t('workload.container.titles.securityContext')"
              name="securityContext"
              :weight="tabWeightMap['securityContext']"
            >
              <Security
                v-model="allContainers[activeContainerIndex].securityContext"
                :mode="mode"
              />
            </Tab>
            <Tab
              :label="t('workload.storage.title')"
              name="storage"
              :weight="tabWeightMap['storage']"
            >
              <ContainerMountPaths
                v-model="podTemplateSpec"
                :namespace="value.metadata.namespace"
                :register-before-hook="registerBeforeHook"
                :mode="mode"
                :secrets="namespacedSecrets"
                :config-maps="namespacedConfigMaps"
                :container="allContainers[activeContainerIndex]"
                :save-pvc-hook-name="savePvcHookName"
                @removePvcForm="clearPvcFormState"
              />
            </Tab>
          </Tabbed>
        </Tab>
        <Tab
          v-if="!isPod"
          :label="nameDisplayFor(type)"
          :name="nameDisplayFor(type)"
          :weight="99"
        >
          <Tabbed :side-tabs="true">
            <Tab
              name="labels"
              label-key="generic.labelsAndAnnotations"
              :weight="tabWeightMap['labels']"
            >
              <Labels
                v-model="value"
                :mode="mode"
              />
            </Tab>
            <Tab
              :label="t('workload.container.titles.upgrading')"
              name="upgrading"
              :weight="tabWeightMap['upgrading']"
            >
              <Job
                v-if="isJob || isCronJob"
                v-model="spec"
                :mode="mode"
                :type="type"
              />
              <Upgrading
                v-else
                v-model="spec"
                :mode="mode"
                :type="type"
                :no-pod-spec="true"
              />
            </Tab>
          </Tabbed>
        </Tab>
        <Tab
          :label="t('pai.vmset.advancedOptions')"
          :name="'pod'"
          :weight="97"
        >
          <Tabbed :side-tabs="true">
            <Tab
              :label="t('workload.container.titles.resources')"
              name="resources"
              :weight="tabWeightMap['resources']"
            >
              <template>
                <div>
                  <h3 class="mb-10">
                    <t k="workload.scheduling.titles.tolerations" />
                  </h3>
                  <div class="row">
                    <Tolerations
                      v-model="podTemplateSpec.tolerations"
                      :mode="mode"
                    />
                  </div>
                </div>

                <div>
                  <div class="spacer" />
                  <h3 class="mb-10">
                    <t k="workload.scheduling.titles.priority" />
                  </h3>
                  <div class="row">
                    <div class="col span-6">
                      <LabeledInput
                        v-model.number="podTemplateSpec.priority"
                        :mode="mode"
                        :label="t('workload.scheduling.priority.priority')"
                      />
                    </div>
                    <div class="col span-6">
                      <LabeledInput
                        v-model="podTemplateSpec.priorityClassName"
                        :mode="mode"
                        :label="t('workload.scheduling.priority.className')"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Tab>
            <Tab
              :label="t('workload.container.titles.podScheduling')"
              name="podScheduling"
              :weight="tabWeightMap['podScheduling']"
            >
              <PodAffinity
                :mode="mode"
                :value="podTemplateSpec"
                :nodes="allNodeObjects"
                :loading="isLoadingSecondaryResources"
              />
            </Tab>
            <Tab
              :label="t('workload.container.titles.nodeScheduling')"
              name="nodeScheduling"
              :weight="tabWeightMap['nodeScheduling']"
            >
              <NodeScheduling
                :mode="mode"
                :value="podTemplateSpec"
                :nodes="allNodes"
                :loading="isLoadingSecondaryResources"
              />
            </Tab>
            <Tab
              :label="t('workload.container.titles.upgrading')"
              name="upgrading"
              :weight="tabWeightMap['upgrading']"
            >
              <Job
                v-if="isJob || isCronJob"
                v-model="spec"
                :mode="mode"
                :type="type"
              />
              <Upgrading
                v-else
                v-model="spec"
                :mode="mode"
                :type="type"
                :no-deployment-spec="true"
              />
            </Tab>
            <Tab
              :label="t('workload.container.titles.securityContext')"
              name="securityContext"
              :weight="tabWeightMap['securityContext']"
            >
              <div>
                <h3>{{ t('workload.container.security.podFsGroup') }}</h3>
                <div class="row">
                  <div class="col span-6">
                    <LabeledInput
                      v-model.number="podFsGroup"
                      type="number"
                      :mode="mode"
                      :label="t('workload.container.security.fsGroup')"
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              v-if="isStatefulSet"
              :label="t('workload.container.titles.volumeClaimTemplates')"
              name="volumeClaimTemplates"
              :weight="tabWeightMap['volumeClaimTemplates']"
            >
              <VolumeClaimTemplate
                v-model="spec"
                :mode="mode"
              />
            </Tab>
            <Tab
              name="labels"
              label-key="generic.labelsAndAnnotations"
              :weight="tabWeightMap['labels']"
            >
              <div>
                <h3>{{ t('workload.container.titles.podLabels') }}</h3>
                <div class="row mb-20">
                  <KeyValue
                    key="labels"
                    v-model="podLabels"
                    :add-label="t('labels.addLabel')"
                    :mode="mode"
                    :read-allowed="false"
                    :protip="false"
                  />
                </div>
                <div class="spacer" />
                <h3>{{ t('workload.container.titles.podAnnotations') }}</h3>
                <div class="row">
                  <KeyValue
                    key="annotations"
                    v-model="podAnnotations"
                    :add-label="t('labels.addAnnotation')"
                    :mode="mode"
                    :read-allowed="false"
                    :protip="false"
                  />
                </div>
              </div>
            </Tab>
          </Tabbed>
        </Tab>
        <Tab
          :label="t('workload.storage.title')"
          :name="'storage'"
          :weight="98"
        >
          <Storage
            v-model="podTemplateSpec"
            :namespace="value.metadata.namespace"
            :register-before-hook="registerBeforeHook"
            :mode="mode"
            :secrets="namespacedSecrets"
            :config-maps="namespacedConfigMaps"
            :save-pvc-hook-name="savePvcHookName"
            :loading="isLoadingSecondaryResources"
            :namespaced-pvcs="pvcs"
            @removePvcForm="clearPvcFormState"
          />
        </Tab>
        <Tab
          :label="t('workload.container.titles.networking')"
          :name="'network'"
          :weight="98"
        >
          <div class="storageBox">
            <div class="row mt-10 mb-10">
              <button
                type="button"
                class="btn role-primary"
                style="margin-right: 10px"
                @click="addNetwork"
              >
                <i class="el-icon-plus" />
                {{ t('generic.add') }}
              </button>
              <button
                type="button"
                class="btn bg-error"
                @click="removeAllNetworks"
              >
                <i class="el-icon-delete" />
                {{ t('pai.labels.removeAll') }}
              </button>
            </div>
            <el-table
              :data="networkData"
              style="width: 100%"
            >
              <el-table-column
                :label="t('nav.group.Networking')"
                width="190"
              >
                <template slot-scope="scope">
                  <el-select
                    v-model="networkData[scope.$index].alias"
                    :placeholder="t('pai.edit.SelectPlaceholder')"
                    :disabled="scope.row.isDefault"
                    filterable
                    @change="onNetworkChange($event,scope.$index)"
                  >
                    <el-option
                      v-for="(item,i) in networkOptions"
                      :key="item.value+i"
                      :label="item.label"
                      :value="item.value"
                      :disabled="aliasArr.includes(item.label)"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.list.network.hostNetwork')"
                prop="hostNetwork"
                width="200"
              >
                <template slot-scope="scope">
                  <div class="tableCellBox">
                    {{ scope.row.isDefault || !scope.row.hostNetwork ? '-' : scope.row.hostNetwork }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.detail.vmset.tab.networkManagement.type')"
                prop="networkType"
                width="200"
              >
                <template slot-scope="scope">
                  <div class="tableCellBox">
                    {{ scope.row.isDefault || !scope.row.networkType ? '-' : (scope.row.networkType === 'bridge' ? '桥接网络' : scope.row.networkType) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.list.network.assignmentType')"
                prop="assignmentType"
                width="200"
              >
                <template slot-scope="scope">
                  <div
                    class="tableCellBox"
                  >
                    {{ scope.row.isDefault || !scope.row.assignmentType ? '-' : scope.row.assignmentType }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="ip"
                min-width="200"
              >
                <!-- eslint-disable vue/no-unused-vars -->
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <!--eslint-enable-->
                  <div style="display: flex">
                    <span style="margin: 14px 6px 0px 0px;">
                      <el-tooltip
                        :content="t('pai.vmset.network.ipOffsetTip')"
                        effect="light"
                      >
                        <i class="el-icon-question" />
                      </el-tooltip>
                      {{ t('pai.detail.vmset.tab.overview.ipAddress') }}
                    </span>
                    <span>
                      <LabeledInput
                        v-model.number="ipOffset"
                        :mode="mode"
                        label=""
                        class="col span-1 mb-20"
                        style="width: 110px;position: relative;top: 10px;"
                        type="number"
                        required
                        :min="0"
                        :placeholder="t('pai.vmset.network.editOffset')"
                        :disabled="mode==='edit'"
                        @blur="handleSetIpOffset"
                      />
                    </span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div
                    class="tableCellBox"
                  >
                    {{ scope.row.ip || '-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                  <el-button
                    v-show="!scope.row.isDefault"
                    type="primary"
                    size="mini"
                    class="primaryBtn"
                    @click="removeNetwork(scope.row, scope.$index)"
                  >
                    {{ t('generic.remove') }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </Tab>
      </Tabbed>
    </CruResource>
  </form>
</template>

<style lang='scss'>
.container-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.type-placeholder {
  color: white;
  font-size: 2.5em;
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-description {
  color: var(--input-label);
}

.next-dropdown {
  display: inline-block;
}

.tab-external-controls {
  display: flex;
  justify-content: right;
}

.tab-content-controls {
  display: flex;
  justify-content: right;
}

.tablist-controls {
  .role-link {
    padding: 10px 15px;
    min-height: unset;
    line-height: unset;

    &:focus {
      background: none;
      box-shadow: none;
    }

    &:hover {
      border: none;
    }
  }

}

.deployment-tabs {
  > .tabs.horizontal {
    border-bottom: 1px solid var(--border);
    margin-bottom: 10px;
  }
}
.padded {
  padding-bottom: 10px;
}
.containers{
  padding: 0 10px;
  width: 100%;
  height: 50px;
  line-height: 50px;

  .el-tag {
    margin: 0 5px;
    cursor: pointer;
    border-radius: 35px;
  }

  .active{
    border-color: var(--primary);
  }
}
.storageBox {
  background-color: #FAFAFA;
  border: 1px solid #DCDEE7;
  padding: 20px 10px;
  border-radius: 3px;
}
::v-deep .tab-header[data-v-1d6db493]{
  display: none;
}
</style>
