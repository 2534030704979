import Service from '@shell/models/service';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { POD } from '@shell/config/types';
import { matching } from '@shell/utils/selector';

export default class extends PaiLocationMixin(Service) {
  get pods() {
    return this.podRelationship ? matching( this.$rootGetters['cluster/all'](POD), this.podRelationship.selector ) : [];
  }
}
