export default {
  power: {
    Off:     '关闭',
    On:      '运行中',
    Unknown: '未知',
  },
};

export const VM_POWER_STATES_ENUM = {
  Off:         'Off',
  On:          'On',
  Error:       'Error',
  Running:     'Running',
  Scheduling:  'Scheduling',
  Pending:     'Pending',
  Restart:     'Restart',
  Unknown:     'Unknown',
  Unavailable: 'Unavailable',
  Succeeded:   'Succeeded',
};

export const VM_POWER_STATES = {
  [VM_POWER_STATES_ENUM.Off]: {
    className: 'bg-darker',
    color:     'text-error',
    labelKey:  'pai.vmset.powerState.off',
  },
  [VM_POWER_STATES_ENUM.On]: {
    className: 'bg-success',
    color:     'text-success',
    labelKey:  'pai.vmset.powerState.on',
  },
  [VM_POWER_STATES_ENUM.Running]: {
    className: 'bg-success',
    color:     'text-success',
    labelKey:  'pai.vmset.powerState.running',
  },
  [VM_POWER_STATES_ENUM.Pending]: {
    className: 'bg-warning',
    color:     'text-warning',
    labelKey:  'pai.vmset.powerState.pending',
  },
  [VM_POWER_STATES_ENUM.Unknown]: {
    className: 'bg-darker',
    color:     'text-info',
    labelKey:  'pai.vmset.powerState.unknown',
  },
  [VM_POWER_STATES_ENUM.Error]: {
    className: 'bg-error',
    color:     'text-error',
    labelKey:  'pai.vmset.powerState.error',
  },
  [VM_POWER_STATES_ENUM.Scheduling]: {
    className: 'bg-warning',
    color:     'text-warning',
    labelKey:  'pai.vmset.powerState.scheduling',
  },
  [VM_POWER_STATES_ENUM.Unavailable]: {
    className: 'bg-warning',
    color:     'text-warning',
    labelKey:  'pai.vmset.powerState.unavailable',
  },
  [VM_POWER_STATES_ENUM.Succeeded]: {
    className: 'bg-warning',
    color:     'text-warning',
    labelKey:  'pai.vmset.powerState.succeeded',
  },
};

export const PAI_IMAGE_STATES_ENUM = {
  Normal:   'normal',
  Abnormal: 'abnormal',
  Pending:  'pending',
};

export const PAI_IMAGE_STATES = {
  [PAI_IMAGE_STATES_ENUM.Normal]: {
    className: 'bg-success',
    color:     'text-success',
    labelKey:  'pai.vmset.image.state.normal',
  },
  [PAI_IMAGE_STATES_ENUM.Abnormal]: {
    className: 'bg-error',
    color:     'text-error',
    labelKey:  'pai.vmset.image.state.abnormal',
  },
  [PAI_IMAGE_STATES_ENUM.Pending]: {
    className: 'bg-warning',
    color:     'text-warning',
    labelKey:  'pai.vmset.image.state.pending',
  },
};
