import StorageClass from '@shell/models/storage.k8s.io.storageclass';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { ALIAS } from '~/pkg/pai/config/labels-annotations';
// These are storage class drivers w/ custom components
// all but longhorn are in-tree plugins
export const PROVISIONER_OPTIONS = [
  {
    labelKey: 'storageClass.no-provisioner.title',
    value:    'kubernetes.io/no-provisioner',
  },
  {
    labelKey:  'storageClass.longhorn.title',
    value:     'driver.longhorn.io',
    supported: true
  },
];
export default class extends PaiLocationMixin(StorageClass) {
  get alias() {
    return (this.metadata.annotations && this.metadata.annotations[ALIAS]) ? this.metadata.annotations[ALIAS] : '';
  }
}
