<template>
  <canvas
    id="s-canvas"
    :width="contentwidth"
    :height="contentheight"
  />
</template>
<script>
export default {
  name:  'Verification',
  props: {
    identifycode: {
      type:    String,
      default: '1234'
    },
    fontsizemin: {
      type:    Number,
      default: 25
    },
    fontsizemax: {
      type:    Number,
      default: 30
    },
    backgroundcolormin: {
      type:    Number,
      default: 255
    },
    backgroundcolormax: {
      type:    Number,
      default: 255
    },
    colormin: {
      type:    Number,
      default: 0
    },
    colormax: {
      type:    Number,
      default: 500
    },
    linecolormin: {
      type:    Number,
      default: 100
    },
    linecolormax: {
      type:    Number,
      default: 255
    },
    dotcolormin: {
      type:    Number,
      default: 0
    },
    dotcolormax: {
      type:    Number,
      default: 255
    },
    contentwidth: {
      type:    Number,
      default: 90
    },
    contentheight: {
      type:    Number,
      default: 38
    }
  },
  methods: {
    // 生成一个随机数
    randomnum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 生成一个随机的颜色
    randomcolor(min, max) {
      const r = this.randomnum(min, max);
      const g = this.randomnum(min, max);
      const b = this.randomnum(min, max);

      return `rgb(${ r },${ g },${ b })`;
    },
    drawpic() {
      const canvas = document.getElementById('s-canvas');
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, this.contentwidth, this.contentheight);
      ctx.textBaseline = 'bottom';
      // 绘制背景
      ctx.globalAlpha = 0.5;
      ctx.fillStyle = '#325970';
      ctx.fillRect(0, 0, this.contentwidth, this.contentheight);
      // 绘制文字
      for (let i = 0; i < this.identifycode.length; i++) {
        this.drawtext(ctx, this.identifycode[i], i);
      }
      // this.drawline(ctx);
      this.drawdot(ctx);
    },
    drawtext(ctx, txt, i) {
      ctx.fillStyle = this.randomcolor(this.colormin, this.colormax);
      ctx.font = `${ this.randomnum(this.fontsizemin, this.fontsizemax) }px simhei`;
      const x = (i + 1) * (this.contentwidth / (this.identifycode.length + 1));
      const y = this.randomnum(this.fontsizemax, this.contentheight - 5);
      const deg = this.randomnum(-45, 45);

      // 修改坐标原点和旋转角度
      ctx.translate(x, y);
      ctx.rotate(deg * Math.pi / 180);
      ctx.fillText(txt, -7, 0);
      // 恢复坐标原点和旋转角度
      ctx.rotate(-deg * Math.pi / 180);
      ctx.translate(-x, -y);
    },
    // drawline(ctx) {
    //   // 绘制干扰线
    //   for (let i = 0; i < 5; i++) {
    //     ctx.strokeStyle = this.randomcolor(this.linecolormin, this.linecolormax);
    //     ctx.beginPath();
    //     ctx.moveTo(this.randomnum(0, this.contentwidth), this.randomnum(0, this.contentheight));
    //     ctx.lineTo(this.randomnum(0, this.contentwidth), this.randomnum(0, this.contentheight));
    //     ctx.stroke();
    //   }
    // },
    drawdot(ctx) {
      // 绘制干扰点
      for (let i = 0; i < 80; i++) {
        ctx.fillStyle = this.randomcolor(0, 255);
        ctx.beginPath();
        ctx.arc(this.randomnum(0, this.contentwidth), this.randomnum(0, this.contentheight), 1, 0, 2 * Math.pi);
        ctx.fill();
      }
    }
  },
  watch: {
    identifycode() {
      this.drawpic();
    }
  },
  mounted() {
    this.drawpic();
  }
};
</script>
<style scoped>
.s-canvas {
  height: 38px;
}
.s-canvas canvas{
  margin-top: 1px;
  margin-left: 8px;
}
</style>
