<script>
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
export default {
  name: 'ConsoleModals',
  data() {
    return { podName: '', vmPods: [] };
  },
  fetch() {
    this.vmPods = this.vm.runningInstances;
    if (this.vmPods.length) {
      this.podName = this.vmPods[0];
    }
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'consoleModal';
    },
    vm() {
      return this.$store.getters['management/byId'](PAI_RESOURCES.VMSET, this.$store.state['pai-common'].currentItem.id);
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/clear');
    },
    onActionConsole() {
      this.vm.onOpenShell(this.podName);
    },
  }
};
</script>
<template>
  <el-dialog
    :title="t('pai.labels.tip')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :modal-append-to-body="false"
  >
    <span>{{ t('pai.edit.SelectPlaceholder') }}：</span>
    <el-select
      v-model="podName"
      size="small"
      filterable
    >
      <el-option
        v-for="item in vmPods"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('generic.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="onActionConsole"
      >{{ t('generic.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.el-dialog__body {
  text-align: center;
}
</style>
