// --------------------------------------
// customize
// --------------------------------------
export const PAI_WORKLOAD = 'pai.workload';
export const PAI_POD = 'pai.pod';
export const PAI_VMSET_POD = 'pai.vmset.pod';
export const PAI_CHARTS = 'pai.charts';
export const PAI_NODE = 'pai.node';
export const PAI_EVENT = 'pai.event';
export const PAI_CLUSTER = 'pai.cluster';
export const PAI_NAMESPACE = 'pai.namespace';
export const PAI_PLATFORM_OVERVIEW = 'pai.platform.overview';
export const PAI_PLATFORM_SETTING = 'pai.platform.setting';
export const PAI_RBAC = 'pai.rbac';
export const PAI_SERVICE_ACCOUNT = 'pai.serviceaccount';
export const PAI_SERVICE_GROUP = 'pai.service.group';

export const PAI_PLATFORM_USER = 'pai.platform.user';

export const PAI_RESOURCES = {
  PAI_INGRESS:           'cluster.liveit100.com.paiingress',
  VMSET:                 'virt.liveit100.com.vmset',
  VM_IMAGE:              'virt.liveit100.com.vmimage',
  NAD:                   'k8s.cni.cncf.io.networkattachmentdefinition',
  BACKUP:                'velero.io.backup',
  DELETE_BACKUP_REQUEST: 'velero.io.deletebackuprequest',
  DOWNLOAD_REQUEST:      'velero.io.downloadrequest',
  RESTORE:               'velero.io.restore',
  SNAPSHOT:              'longhorn.io.snapshot',
  OVERVIEW:              'overview',
};

export const PAI_ANNOTATIONS = { NAMESPACES: 'com.tdology.cloud.nss' };

export const PAI_WORKLOAD_TYPES = {
  DEPLOYMENT:   'pai.apps.deployment',
  CRON_JOB:     'pai.batch.cronjob',
  DAEMON_SET:   'pai.apps.daemonset',
  JOB:          'pai.batch.job',
  STATEFUL_SET: 'pai.apps.statefulset',
  REPLICA_SET:  'pai.apps.replicaset',
};

export const PAI_PLATFORM_SETTING_TYPES = {
  GATEWAY: 'cluster.liveit100.com.gateway',
  LICENSE: 'cluster.liveit100.com.license',
  MACHINE: 'cluster.liveit100.com.machine',
};

export const PAI_CATALOG = {
  CLUSTER_REPO: 'pai.catalog.cattle.io.clusterrepo',
  OPERATION:    'pai.catalog.cattle.io.operation',
  APP:          'pai.catalog.cattle.io.app',
  REPO:         'pai.catalog.cattle.io.repo',
};

export const PAI_SERVICE = {
  SERVICE:        'pai.service',
  INGRESS:        'pai.networking.k8s.io.ingress',
  HPA:            'pai.autoscaling.horizontalpodautoscaler',
  NETWORK_POLICY: 'pai.networking.k8s.io.networkpolicy',
};

export const PAI_STORAGE = {
  STORAGE_CLASS: 'pai.storage.k8s.io.storageclass',
  PV:            'pai.persistentvolume',
  PVC:           'pai.persistentvolumeclaim',
  SECRET:        'pai.secret',
  CONFIG_MAP:    'pai.configmap',
};
export const INSTANCE_STATUS = {
  Running:     'Running',
  Failed:      'Failed',
  Pending:     'Pending',
  Created:     'Created',
  Stopped:     'Stopped',
  Terminating: 'Terminating',
  Succeeded:   'Succeeded',
  Unknown:     'Unknown',
  Error:       'Error',
};
export const PAI_CAPI = {
  RANCHER_CLUSTER:      'pai.provisioning.cattle.io.cluster',
};
export const PAI_RBAC_TYPES = {
  ROLE:                 'pai.rbac.authorization.k8s.io.role',
  CLUSTER_ROLE:         'pai.rbac.authorization.k8s.io.clusterrole',
  ROLE_BINDING:         'pai.rbac.authorization.k8s.io.rolebinding',
  CLUSTER_ROLE_BINDING: 'pai.rbac.authorization.k8s.io.clusterrolebinding',
};
