<script>
import BackLink from '@shell/components/BackLink';
import BackRoute from '../mixins/back-link';
import LocaleSelector from '../components/LocaleSelector';
import { KEYMAP, mapPref, ROWS_PER_PAGE } from '@shell/store/prefs';
import ButtonGroup from '@shell/components/ButtonGroup.vue';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';

export default {
  layout:     'pai/home',
  components: {
    LabeledSelect, BackLink, ButtonGroup, LocaleSelector
  },
  mixins:   [BackRoute],
  computed: {
    keymap:  mapPref(KEYMAP),
    perPage: mapPref(ROWS_PER_PAGE),
    keymapOptions() {
      return this.$store.getters['prefs/options'](KEYMAP).map((value) => {
        return {
          labelKey: `prefs.keymap.${ value }`,
          value
        };
      });
    },
    perPageOptions() {
      const t = this.$store.getters['i18n/t'];

      return this.$store.getters['prefs/options'](ROWS_PER_PAGE).map(count => ({
        label: t('prefs.perPage.value', { count }),
        value: count
      }));
    },
  }
};
</script>

<template>
  <div class="indented-panel">
    <BackLink :link="backLink" />
    <h1
      v-t="'prefs.title'"
      class="mb-20"
    />
    <!-- Language -->
    <div class="mt-10 mb-10">
      <h4 v-t="'prefs.language'" />
      <div class="row">
        <div class="col span-4">
          <LocaleSelector />
        </div>
      </div>
    </div>
    <!-- YAML editor key mapping -->
    <div class="col mt-10 mb-10">
      <hr>
      <h4 v-t="'prefs.keymap.label'" />
      <ButtonGroup
        v-model="keymap"
        :options="keymapOptions"
      />
    </div>
    <div class="col mt-10 mb-10">
      <hr>
      <h4 v-t="'prefs.perPage.label'" />
      <LabeledSelect
        v-model.number="perPage"
        :options="perPageOptions"
        option-key="value"
        option-label="label"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.indented-panel {
  height: 100%;
  width: 90%;
  margin-left: 5%;
}
</style>
