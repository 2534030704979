
import User from '@shell/models/management.cattle.io.user';
import { getDetailLocation } from '~/pkg/pai/utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';

export default class extends User {
  get detailLocation() {
    return getDetailLocation(this, PRODUCT_NAME);
  }
}
