<script>
export default {
  name:  'Network',
  props: {
    type: {
      type:     String,
      required: true,
    },
  },
  data() {
    const config = this.$attrs.row.spec.config;
    let result = '';
    let networkType = '';

    if (config) {
      const parseConfig = JSON.parse(config);

      switch (this.type) {
      case 'networkType':
        networkType = parseConfig.type;
        result = this.t(`pai.network.type.${ networkType }`);
        break;
      case 'assignmentType':
        if (parseConfig.ipam) {
          networkType = parseConfig.ipam.type;
          result = this.t(`pai.network.type.${ networkType }`);
        }
        break;
      case 'hostNetwork':
        result = parseConfig.uplink || parseConfig.master || parseConfig.device;
        break;
      }
    }

    return { result };
  },
};
</script>

<template>
  <span>
    {{ result }}
  </span>
</template>
