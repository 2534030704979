<script>
import { PRODUCT_NAME } from '../config/pai';

export default {
  props: {
    value: {
      type:     null,
      required: true
    },

    type: {
      type:     String,
      required: true,
    },
    cluster: {
      type:    String,
      default: 'local',
    },
    namespace: {
      type:    String,
      default: '',
    },

    product: {
      type:    String,
      default: PRODUCT_NAME,
    }
  },

  computed: {
    url() {
      const name = `${ this.product }-c-cluster-resource${ this.$attrs.row.metadata.namespace ? '-namespace' : '' }-id`;

      const params = {
        resource:  this.type,
        namespace: this.$attrs.row.metadata.namespace,
        id:        this.$attrs.row.metadata.name,
        product:   this.product || PRODUCT_NAME,
        cluster:   this.cluster
      };

      return { name, params };
    }
  }
};
</script>

<template>
  <span v-if="value">
    <nuxt-link :to="url">
      {{ value }}
    </nuxt-link>
  </span>
</template>
