<script>
import ResourceFetch from '@/shell/mixins/resource-fetch';
import { dasherize, ucFirst } from '@/shell/utils/string';
import grouping from '../components/SortableTable/grouping';
import paging from '../components/SortableTable/paging';
import filtering from '../components/SortableTable/filtering';
import ButtonGroup from '@/shell/components/ButtonGroup';
import { mapGetters } from 'vuex';
import { clone, get } from '@/shell/utils/object';
import { removeObject } from '@/shell/utils/array';
import { ASYNC_BUTTON_STATES } from '@/shell/components/AsyncButton';
import { NAMESPACE } from '@/shell/config/types';
import VmCard from '../components/VmCard.vue';
import config from '../plugins/pai-resource-class';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import ConsoleModal from '@/pkg/pai/components/ConsoleModal';
import DeleteVmModal from '@/pkg/pai/components/DeleteVmModal';
import CloneVmModal from '@/pkg/pai/components/CloneVmModal';
import Progress from '@/pkg/pai/components/Progress';
import { convertCpuToCore, convertUnitToG } from '../utils/units';
import { COMMAND_HASH_MPA } from '@/pkg/pai/config/settings';
import { ALIAS } from '../config/labels-annotations';
import { compare } from '@shell/utils/sort';
import { NAMESPACE_FILTERS } from '@/shell/store/prefs';
import Loading from '@shell/components/Loading.vue';
import dayjs from 'dayjs';

const DEFAULT_GROUP = 'none';

export const FORMATTERS = {};

export default {
  layout:     'pai/default',
  components: {
    Loading,
    ConsoleModal,
    ButtonGroup,
    VmCard,
    DeleteVmModal,
    CloneVmModal,
    Progress,
  },
  mixins: [grouping, paging, ResourceFetch, filtering],

  data() {
    const resource = this.$route.params.resource;
    const getters = this.$store.getters;
    const inStore = getters['currentStore'](resource);
    const schema = getters[`management/schemaFor`](resource);
    const options = getters[`type-map/optionsFor`](schema);
    const listGroups = options?.listGroups || [];
    const listGroupMapped = listGroups.reduce((acc, grp) => {
      acc[grp.value] = grp;

      return acc;
    }, {});
    const params = { ...this.$route.params };
    const createLocation = {
      name: `${ this.$route.name }-create`,
      params,
    };

    return {
      ALIAS,
      filterValue:                      '',
      selectedKeys:                     [],
      listSelectKeys:                   [],
      schema,
      createLocation,
      config,
      resource,
      listGroups,
      listGroupMapped,
      currentPhase:                     ASYNC_BUTTON_STATES.WAITING,
      expanded:                         {},
      actionOfInterest:                 null,
      activeNames:                      [], // 当前展开的collapseItem的name,用于展开/关闭collapse
      isExpandAll:                      true, // 是否展开全部的collapse
      inStore,
      hasFetch:                         false,
      watch:                            false,
      // query param for simple filtering
      useQueryParamsForSimpleFiltering: true,
      componentTestid:                  'sortable-table',
      tableActions:                     true,
      subExpandColumn:                  false,
      rowActions:                       true,
      keyField:                         '_key',
      hasAdvancedFiltering:             false,
      installedVms:                     [],
      group:                            DEFAULT_GROUP,
      type:                             'card',
      usageRateList:                    {},
      backupVisible:                    true
    };
  },
  async fetch() {
    await this.$fetchType(this.resource);
    const installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });

    for await (const vm of installedVms) {
      if (vm.status && vm.status.instances) {
        const instances = vm.status.instances;
        const vmArr = [];
        let netArr = [];
        const ipList = [];

        for (const key in instances) {
          vmArr.push(instances[key]);
        }
        vmArr.forEach((vm) => {
          if (vm?.net !== undefined) {
            netArr = netArr.concat(vm?.net);
          }
        });
        if (netArr.length !== 0) {
          netArr?.forEach((item) => {
            if (item.ips && item.ips.length) {
              ipList.push(item.ips[0]);
            }
          });
        }
        vm.ipList = ipList;

        const vmPods = [];

        const keys = Object.keys(instances);

        keys.forEach((key) => {
          if (instances[key].status === 'Running') {
            vmPods.push(key);
          }
        });

        let vms = '';

        if (vmPods.length > 1) {
          vms = vmPods.join('|');
        } else if (vmPods.length === 1) {
          vms = vmPods[0];
        }
        this.usageRateList[vm.id] = {
          cpuUsedRate:  0,
          ramUsedRate:  0,
          diskUsedRate: 0,
        };
        if (vms) {
          this.$store.dispatch('pai-grafana/getCpu', {
            cluster:   this.currentCluster,
            namespace: vm.metadata.namespace,
            podName:   vms,
          })
            .then((value) => {
              this.usageRateList[vm.id].cpuUsedRate = value;
            });
          this.$store.dispatch('pai-grafana/getRamDashboard', {
            cluster:   this.currentCluster,
            namespace: vm.metadata.namespace,
            podName:   vms,
          })
            .then((value) => {
              this.usageRateList[vm.id].ramUsedRate = value;
            });
          this.$store.dispatch('pai-grafana/getDisk', {
            cluster:   this.currentCluster,
            namespace: vm.metadata.namespace,
            podName:   vms,
          })
            .then((value) => {
              this.usageRateList[vm.id].diskUsedRate = value;
            });
        }
      }
    }
    this.installedVms = installedVms.sort((a, b) => {
      return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
    }).sort((a, b) => {
      return compare(b.starTimeStamp, a.starTimeStamp);
    });
    const backupData = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP);

    if (!backupData) {
      this.backupVisible = false;
    }
  },
  watch: {
    groupBy(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    namespaces(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    page(neu, old) {
      this.watcherUpdateLiveAndDelayed(neu, old);
    },
    // Ensure we update live and delayed columns on first load
    initalLoad: {
      handler(neu) {
        if (neu) {
          this._didinit = true;
          this.$nextTick(() => this.updateLiveAndDelayed());
        }
      },
      immediate: true,
    },

    // this is the flag that indicates that manual refresh data has been loaded
    // and we should update the deferred cols
    manualRefreshLoadingFinished: {
      handler(neu, old) {
        // this is merely to update the manual refresh button status
        this.currentPhase = !neu ? ASYNC_BUTTON_STATES.WAITING : ASYNC_BUTTON_STATES.ACTION;
        if (neu && neu !== old) {
          this.$nextTick(() => this.updateLiveAndDelayed());
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.activeNames = this.getAllActiveNames();
      },
      immediate: true,
    },
    displayRows: {
      handler(nue, old) {
        if (nue.length !== old.length) {
          this.activeNames = this.getAllActiveNames();
        }
      },
      deep: true,
    },
    activeNames: {
      handler() {
        const allActiveNames = this.getAllActiveNames();

        this.isExpandAll = this.activeNames.length === allActiveNames.length;
      },
      deep: true,
    },
    namespaceFilter(neu) {
      if (neu && !this.hasFetch) {
        this.$fetchType(this.resource);
      }
    },
  },
  computed: {
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
    groupOptions() {
      const standard = [
        {

          icon:  'icon-list-flat',
          value: 'none',
        },
        {

          icon:  'icon-folder',
          value: 'namespace',
        },
      ];

      return standard.concat(this.listGroups);
    },

    typeOptions() {
      const standard = [
        {
          label: this.t('pai.overview.list'),
          value: 'list',
        },
        {
          label: this.t('pai.overview.card'),
          value: 'card',
        },
      ];

      return standard.concat(this.listGroups);
    },

    groupBy() {
      if (this.$store.getters['type-map/groupByFor'](this.schema)) {
        return this.$store.getters['type-map/groupByFor'](this.schema);
      }

      return 'groupByLabel';
    },

    ...mapGetters(['currentProduct']),
    isNamespaced() {
      if (this.namespaced !== null) {
        return this.namespaced;
      }

      return !!get(this.schema, 'attributes.namespaced');
    },

    showNamespaceColumn() {
      const groupNamespaces = this.group === 'namespace';

      return !this.showGrouping || !groupNamespaces;
    },

    _headers() {
      let headers;
      const showNamespace = this.showNamespaceColumn;

      if (this.headers) {
        headers = this.headers.slice();
      } else {
        headers = this.$store.getters['type-map/headersFor'](this.schema);
      }

      // If only one namespace is selected, hide the namespace column
      if (!showNamespace) {
        const idx = headers.findIndex(header => header.name === NAMESPACE.name);

        if (idx >= 0) {
          headers.splice(idx, 1);
        }
      }

      // If we are grouping by a custom group, it may specify that we hide a specific column
      const custom = this.listGroupMapped[this.group];

      if (custom?.hideColumn) {
        const idx = headers.findIndex(header => header.name === custom.hideColumn);

        if (idx >= 0) {
          headers.splice(idx, 1);
        }
      }

      return headers;
    },

    filteredRows() {
      const isAll = this.$store.getters['isAllNamespaces'];

      // If the resources isn't namespaced or we want ALL of them, there's nothing to do.
      if (
        !this.isNamespaced ||
        (isAll && !this.currentProduct?.hideSystemResources) ||
        this.ignoreFilter
      ) {
        return this.rows || [];
      }

      const includedNamespaces = this.$store.getters['namespaces']();

      // Shouldn't happen, but does for resources like management.cattle.io.preference
      if (!this.rows) {
        return [];
      }

      const haveAllNamespace = this.$store.getters['haveAllNamespace'];

      return this.rows.filter((row) => {
        if (this.currentProduct?.hideSystemResources && this.isNamespaced) {
          return (
            !!includedNamespaces[row.metadata.namespace] &&
            !row.isSystemResource
          );
        } else if (!this.isNamespaced) {
          return true;
        } else if (haveAllNamespace) {
          // `rows` only contains resource from a single namespace
          return true;
        } else {
          return !!includedNamespaces[row.metadata.namespace];
        }
      });
    },

    showGrouping() {
      if (this.groupable === null) {
        const namespaceGroupable =
          this.$store.getters['isMultipleNamespaces'] && this.isNamespaced;
        const customGroupable = this.listGroups.length > 0;

        return namespaceGroupable || customGroupable;
      }

      return this.groupable || false;
    },
    // ...mapGetters({ isTooManyItemsToAutoUpdate: 'resource-fetch/isTooManyItemsToAutoUpdate' }),
    ...mapGetters({ isManualRefreshLoading: 'resource-fetch/manualRefreshIsLoading' }),
    namespaces() {
      return this.$store.getters['activeNamespaceCache'];
    },

    initalLoad() {
      return !!(!this.loading && !this._didinit && this.rows?.length);
    },

    manualRefreshLoadingFinished() {
      return !!(
        !this.loading &&
        this._didinit &&
        this.rows?.length &&
        !this.isManualRefreshLoading
      );
    },

    fullColspan() {
      let span = 0;

      for (let i = 0; i < this.columns.length; i++) {
        if (!this.columns[i].hide) {
          span++;
        }
      }

      if (this.tableActions) {
        span++;
      }

      if (this.subExpandColumn) {
        span++;
      }

      if (this.rowActions) {
        span++;
      }

      return span;
    },

    columns() {
      // Filter out any columns that are too heavy to show for large page sizes
      const out = this._headers.slice()
        .filter(c => !c.maxPageSize || (c.maxPageSize && c.maxPageSize >= this.perPage));

      if (this.groupBy) {
        const entry = out.find(x => x.name === this.groupBy);

        if (entry) {
          removeObject(out, entry);
        }
      }

      // If all columns have a width, try to remove it from a column that can be variable (name)
      const missingWidth = out.find(x => !x.width);

      if (!missingWidth) {
        const variable = out.find(x => x.canBeVariable);

        if (variable) {
          const neu = clone(variable);

          delete neu.width;

          out.splice(out.indexOf(variable), 1, neu);
        }
      }

      // handle cols visibility and filtering if there is advanced filtering
      if (this.hasAdvancedFiltering) {
        const cols = this.handleColsVisibilyAndFiltering(out);

        return cols;
      }

      return out;
    },

    // For data-title properties on <td>s
    dt() {
      const out = {
        check:   `Select: `,
        actions: `Actions: `,
      };

      this.columns.forEach((col) => {
        out[col.name] = `${ col.label || col.name }:`;
      });

      return out;
    },

    classObject() {
      return {
        'top-divider':   this.topDivider,
        'body-dividers': this.bodyDividers,
        'overflow-y':    this.overflowY,
        'overflow-x':    this.overflowX,
      };
    },

    // Do we have any live columns?
    hasLiveColumns() {
      const liveColumns = this.columns.find(
        c => c.formatter?.startsWith('Live') || c.liveUpdates,
      );

      return !!liveColumns;
    },

    hasDelayedColumns() {
      const delaeydColumns = this.columns.find(c => c.delayLoading);

      return !!delaeydColumns;
    },

    columnFormmatterIDs() {
      const columnsIds = {};

      this.columns.forEach((c) => {
        if (c.formatter) {
          columnsIds[c.formatter] = dasherize(c.formatter);
        }
      });

      return columnsIds;
    },

    // Generate row and column data for easier rendering in the template
    // ensures we only call methods like `valueFor` once
    displayRows() {
      const rows = [];
      const columnFormmatterIDs = this.columnFormmatterIDs;

      this.groupedRows.forEach((grp) => {
        const group = {
          grp,
          key:  grp.key,
          ref:  grp.ref,
          rows: [],
        };

        rows.push(group);
        grp.rows.forEach((row) => {
          const rowData = {
            row,
            key:                        this.get(row, this.keyField),
            showSubRow:                 this.showSubRow(row, this.keyField),
            canRunBulkActionOfInterest: this.canRunBulkActionOfInterest(row),
            columns:                    [],
          };

          group.rows.push(rowData);
          this.columns.forEach((c) => {
            const value = c.delayLoading ? undefined : this.valueFor(row, c, c.isLabel);
            let component;
            let formatted = value;
            let needRef = false;

            if (Array.isArray(value)) {
              formatted = value.join(', ');
            }

            if (c.formatter) {
              if (FORMATTERS[c.formatter]) {
                component = FORMATTERS[c.formatter];
                needRef = true;
              } else {
                // Check if we have a formatter from a plugin
                const pluginFormatter = this.$plugin?.getDynamic('formatters', c.formatter);

                if (pluginFormatter) {
                  component = pluginFormatter;
                  needRef = true;
                }
              }
            }

            rowData.columns.push({
              col:       c,
              value,
              formatted,
              component,
              needRef,
              delayed:   c.delayLoading,
              live:      c.formatter?.startsWith('Live') || c.liveUpdates,
              label:     this.labelFor(c),
              dasherize: columnFormmatterIDs[c.formatter] || '',
            });
          });
        });
      });
      // 对以namespace分组的数据进一步封装为以项目分组
      if (this.isGroupByProject) {
        const allNamespaces = this.$store.getters[`cluster/all`](NAMESPACE);
        const newRows = [];

        rows.forEach((row) => {
          const namespaceId = row.key.split(' ')[1];
          const namespace = allNamespaces.find(
            namespace => namespace.metadata.name === namespaceId,
          );

          if (!this.filters.length || this.filterProjects.includes(namespace?.projectId) || this.filterNamespaces.includes(namespaceId)) {
            const productIds = [];

            newRows.forEach((row) => {
              if (row.projectId) {
                productIds.push(row.projectId);
              }
            });
            row.rows.forEach((ipRow) => {
              const instances = (ipRow.row.status && ipRow.row.status.instances) ? ipRow.row.status.instances : [];
              const vmArr = [];
              let netArr = [];
              const ipList = [];

              for (const key in instances) {
                vmArr.push(instances[key]);
              }
              vmArr.forEach((vm) => {
                if (vm?.net !== undefined) {
                  netArr = netArr.concat(vm?.net);
                }
              });
              if (netArr.length !== 0) {
                netArr?.forEach((item) => {
                  if (item.ips && item.ips.length) {
                    ipList.push(item.ips[0]);
                  }
                });
              }
              ipRow.row.ipList = ipList;
            });
            if (namespace) {
              if ( namespace.projectId) {
                if (productIds.includes(namespace.projectId)) {
                  newRows[productIds.indexOf(namespace.projectId)].rows.push(row);
                } else if (namespace.groupByLabel) {
                  newRows.push({
                    key:       namespace.groupByLabel,
                    projectId: namespace.projectId,
                    rows:      [row],
                  });
                }
              } else if (productIds.includes('unGrouped')) {
                newRows[productIds.indexOf('unGrouped')].rows.push(row);
              } else if (namespace.groupByLabel) {
                newRows.push({
                  key:       namespace.groupByLabel,
                  projectId: 'unGrouped',
                  rows:      [row],
                });
              }
            }
          }
        });

        return newRows;
      }

      return rows;
    },

    isGroupByProject() {
      return this.groupBy === 'groupByLabel';
    },

    consoleModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'consoleModal';
    },

    deleteVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },

    cloneVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },

    filters() {
      // 用户选择的项目/命名空间
      if (this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster]) {
        if (this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster].join(',').includes('all://')) {
          return [];
        } else {
          return this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster];
        }
      } else {
        return [];
      }
    },
    filterProjects() {
      return this.filters.filter(v => v.includes('project://')).map(v => v.split('//')[1]);
    },
    filterNamespaces() {
      return this.filters.filter(v => v.includes('ns://')).map(v => v.split('//')[1]);
    },
  },
  methods: {
    dayjs,
    compare,
    convertCpuToCore,
    convertUnitToG,
    get,
    dasherize,
    changeCollection() {
      this.installedVms.sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      }).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
    },
    labelFor(col) {
      if (col.labelKey) {
        return this.t(col.labelKey, undefined, true);
      } else if (col.label) {
        return col.label;
      }

      return ucFirst(col.name);
    },
    handleActionButtonClick(i, event) {
      // Each row in the table gets its own ref with
      // a number based on its index. If you are using
      // an ActionMenu that doen't have a dependency on Vuex,
      // these refs are useful because you can reuse the
      // same ActionMenu component on a page with many different
      // target elements in a list,
      // so you can still avoid the performance problems that
      // could result if the ActionMenu was in every row. The menu
      // will open on whichever target element is clicked.
      this.$emit('clickedActionButton', {
        event,
        targetElement: this.$refs[`actionButton${ i }`][0],
      });
    },
    showSubRow(row, keyField) {
      const hasInjectedSubRows =
        this.subRows &&
        (!this.subExpandable || this.expanded[get(row, keyField)]);
      const hasStateDescription = row.stateDescription;

      return hasInjectedSubRows || hasStateDescription;
    },
    canRunBulkActionOfInterest(resource) {
      if (!this.actionOfInterest) {
        return false;
      }

      const matchingResourceAction = resource.availableActions.find(
        a => a.action === this.actionOfInterest.action,
      );

      return matchingResourceAction?.enabled;
    },
    valueFor(row, col, isLabel) {
      if (typeof col.value === 'function') {
        return col.value(row);
      }

      if (isLabel) {
        if (row.metadata?.labels && row.metadata?.labels[col.label]) {
          return row.metadata?.labels[col.label];
        }

        return '';
      }

      // Use to debug table columns using expensive value getters
      const expr = col.value || col.name;
      const out = get(row, expr);

      if (out === null || out === undefined) {
        return '';
      }

      return out;
    },
    onActionAllCollapse() {
      if (this.isExpandAll) {
        this.activeNames = [];
      } else {
        this.activeNames = this.getAllActiveNames();
      }
    },
    getAllActiveNames() {
      const activeNames = [];

      if (this.isGroupByProject) {
        this.displayRows.forEach((pro, proIndex) => {
          activeNames.push(`${ proIndex }`);
          pro.rows.forEach((ns, nsIndex) => {
            activeNames.push(`${ proIndex }-${ nsIndex }`);
          });
        });
      }

      return activeNames;
    },
    watcherUpdateLiveAndDelayed(neu, old) {
      if (neu !== old) {
        this.$nextTick(() => this.updateLiveAndDelayed());
      }
    },
    updateLiveAndDelayed() {
      if (this.hasLiveColumns) {
        this.updateLiveColumns();
      }

      if (this.hasDelayedColumns) {
        this.updateDelayedColumns();
      }
    },

    updateDelayedColumns() {
      clearTimeout(this._delayedColumnsTimer);

      if (!this.$refs.column || this.pagedRows.length === 0) {
        return;
      }

      const delayedColumns = this.$refs.column.filter(c => c.startDelayedLoading && !c.__delayedLoading);
      // We add 100 pixels here - so we will render the delayed columns for a few extra rows below what is visible
      // This way if you scroll slowly, you won't see the columns being loaded
      const clientHeight = (window.innerHeight || document.documentElement.clientHeight) + 100;

      let scheduled = 0;

      for (let i = 0; i < delayedColumns.length; i++) {
        const dc = delayedColumns[i];
        const y = dc.$el.getBoundingClientRect().y;

        if (y >= 0 && y <= clientHeight) {
          dc.startDelayedLoading(true);
          dc.__delayedLoading = true;

          scheduled++;

          // Only update 4 at a time
          if (scheduled === 4) {
            this._delayedColumnsTimer = setTimeout(this.updateDelayedColumns, 100);

            return;
          }
        }
      }
    },
    vmDetail(id) {
      const params = this.$route.params;

      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-id`,
        params: {
          ...params,
          id,
          product:  PAI,
          resource: PAI_RESOURCES.VMSET,
          cluster:  this.currentCluster,
        },
      });
    },
    onActionPower(type) {
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      })
        .then(async() => {
          for (let i = 0; i < this.installedVms.length; i++) {
            const value = this.installedVms[i];

            value.spec.power = type;
            await value.save();
          }
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success'),
          });
        })
        .catch(() => {
        });
    },
    computedDiskSize(value) {
      let diskData = 0;

      if (value.spec.volumeClaimTemplates?.length > 0) {
        value.spec.volumeClaimTemplates.forEach((v) => {
          if (v.spec?.resources?.requests?.storage) {
            diskData += convertUnitToG(v.spec.resources.requests.storage);
          }
        });
      }

      return `${ diskData }G`;
    },
    handleCommand(command) {
      const {
        key,
        value,
      } = command;

      if (key === 'power') {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        })
          .then(async() => {
            value.spec.power = (!value.spec.power || value.spec.power === 'Off') ? 'On' : 'Off';
            await value.save();
            await this.$message({
              type:    'success',
              message: this.t('pai.labels.success'),
            });
          })
          .catch(() => {
          });
      } else if (key === 'control') {
        value.onOpenShell();
      } else if (key === 'restart') {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        })
          .then(async() => {
            value.spec.power = 'Off';
            await value.save();
            setTimeout(async() => {
              value.spec.power = 'On';
              await value.save();
            }, 1000 * 10);
            await this.$message({
              type:    'success',
              message: this.t('pai.labels.success'),
            });
          })
          .catch(() => {
          });
      } else if (key === 'delete') {
        this.$store.dispatch('pai-common/updateState', {
          currentModal: 'deleteVmModal',
          currentItem:  value,
        });
      } else if (key === 'copy') {
        value.goToCloneVM();
      } else if (key === 'image') {
        value.goToMakeImage();
      } else {
        this.$router.push({
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            product:   PAI,
            cluster:   this.currentCluster,
            resource:  PAI_RESOURCES.VMSET,
            namespace: value.metadata.namespace,
            id:        value.metadata.name,
          },
          hash: COMMAND_HASH_MPA[key],
        });
      }
    },
    batchAction(command) {
      let keys = this.type === 'card' ? this.selectedKeys : this.listSelectKeys;

      if (keys.length === 0) {
        this.$message.warning(this.t('pai.vmset.tips.selectVm'));
      } else if (command === 'delete') {
        this.$confirm(this.t('pai.vmset.tips.deleteConfirm'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        }).then(async() => {
          const vms = this.installedVms.filter(v => keys.includes(v.id));

          await this.$store.dispatch('pai-common/updateState', {
            currentModal: 'deleteVmModal',
            currentItem:  vms,
          });
          keys = [];
        }).catch(() => {}) ;
      } else {
        try {
          this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
            confirmButtonText: this.t('pai.labels.confirm'),
            cancelButtonText:  this.t('pai.labels.cancel'),
            type:              'warning',
          }).then(async() => {
            for (const key of keys) {
              const vm = this.installedVms.find(v => v.id === key);

              if (vm) {
                switch (command) {
                case 'Off':
                  vm.safeOff();
                  break;
                case 'On':
                  vm.powerOn();
                  break;
                case 'restart':
                  vm.powerRestart();
                  break;
                case 'break':
                  vm.powerOff();
                  break;
                }
              }
            }
            await this.$message({
              type:    'success',
              message: this.t('pai.labels.success'),
            });
            keys = [];
          }).catch(() => {});
        } catch (e) {
          this.$message({
            type:    'warning',
            message: e.message ? e.message : e,
          });
        }
      }
    },
    onChecked(e) {
      if (this.selectedKeys.includes(e)) {
        this.selectedKeys.splice(this.selectedKeys.indexOf(e), 1);
      } else {
        this.selectedKeys.push(e);
      }
    },
    getRowKeys(row) {
      return row.id;
    },
    selectionChange(e, value) {
      this.listSelectKeys = e.map((item) => {
        return item.id;
      });
    }
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <div v-else>
    <div class="head">
      <div class="content">
        <ButtonGroup
          v-model="type"
          :options="typeOptions"
        />
        <div class="verticalLine" />
        <button
          class="btn role-primary"
          @click="$router.push(createLocation)"
        >
          <i
            class="el-icon-plus"
            style="margin-top: 0"
          />{{ t('pai.detail.vmset.createVm') }}
        </button>
        <el-dropdown @command="batchAction">
          <button class="btn role-primary">
            <i class="el-icon-s-operation" />{{ t('pai.detail.vmset.batchOperation') }}<i class="el-icon-caret-bottom" />
          </button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="On">
              <i class="el-icon-video-play" />{{ t('pai.detail.vmset.batchPowerOn') }}
            </el-dropdown-item>
            <el-dropdown-item command="Off">
              <i class="el-icon-switch-button" />{{ t('pai.detail.vmset.batchPowerOff') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="false">
              <i class="el-icon-cloudy" />{{ t('pai.detail.vmset.vmBackUp') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="false">
              <i class="el-icon-document-copy" />{{ t('pai.detail.vmset.vmCopy') }}
            </el-dropdown-item>
            <el-dropdown-item command="restart">
              <i class="el-icon-refresh-right" />{{ t('pai.detail.vmset.batchPowerRestart') }}
            </el-dropdown-item>
            <el-dropdown-item v-if="false">
              <i class="el-icon-suitcase-1" />{{ t('pai.detail.vmset.importToolkit') }}
            </el-dropdown-item>
            <el-dropdown-item command="break">
              <i class="el-icon-switch-button" />{{ t('pai.detail.vmset.batchBreak') }}
            </el-dropdown-item>
            <el-dropdown-item command="delete">
              <i class="el-icon-delete" />{{ t('pai.detail.vmset.batchDelete') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <button v-if="false">
          <i class="el-icon-bottom" />{{ t('pai.detail.vmset.exportVm') }}
        </button>
        <button
          class="btn role-primary"
          @click="onActionPower('On')"
        >
          <i class="el-icon-video-play" />{{ t('pai.detail.vmset.allPowerOn') }}
        </button>
        <button
          class="btn role-primary"
          @click="onActionPower('Off')"
        >
          <i class="el-icon-switch-button" />{{ t('pai.detail.vmset.allPowerOff') }}
        </button>
      </div>
      <div class="content">
        <div class="demo-input-suffix">
          <el-input
            v-model="filterValue"
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
          />
        </div>
        <ButtonGroup
          v-model="group"
          :options="groupOptions"
        />
        <button
          v-show="group === 'namespace'"
          class="btn role-primary"
          @click="onActionAllCollapse"
        >
          <i class="el-icon-finished" />{{ isExpandAll ? t('pai.list.collapseAll') : t('pai.list.expandAll') }}
        </button>
      </div>
    </div>
    <el-row :gutter="20">
      <hr style="margin: 20px 0;">
      <el-col :span="24">
        <template>
          <!--分组-->
          <div v-show="group === 'namespace'">
            <!-- 卡片 -->
            <div v-show="type==='card'">
              <el-collapse
                v-for="(projectRow, projectIndex) in displayRows"
                :key="projectRow.key"
                v-model="activeNames"
              >
                <el-collapse-item
                  :name="projectIndex + ''"
                  class="product"
                >
                  <template slot="title">
                    <i class="el-icon-document" />
                    <div v-html="projectRow.key" />
                  </template>
                  <el-collapse
                    v-for="(groupedRows, nsIndex) in projectRow.rows"
                    :key="groupedRows.key"
                    v-model="activeNames"
                  >
                    <el-collapse-item
                      class="namespace"
                      :name="projectIndex + '-' + nsIndex"
                    >
                      <template slot="title">
                        <i class="el-icon-files" />
                        <div v-html="groupedRows.ref" />
                      </template>
                      <!-- 云主机container -->
                      <div class="apps">
                        <div class="content">
                          <div style="display:flex;flex-wrap: wrap">
                            <div
                              v-for="vm in groupedRows.rows.sort((a, b) => {
                                return compare(dayjs(b.row.creationTimestamp).unix(), dayjs(a.row.creationTimestamp).unix());
                              }).sort((a, b) => {
                                return compare(b.row.starTimeStamp, a.row.starTimeStamp);
                              }).filter(v=>v.row.id.includes(filterValue) || v.row.alias.includes(filterValue))"
                              :key="vm.row.metadata.uid"
                            >
                              <VmCard :value="vm.row">
                                <template slot="checkbox">
                                  <el-checkbox
                                    size="mini"
                                    :value="selectedKeys.includes(vm.row.id)"
                                    @change="onChecked(vm.row.id)"
                                  />
                                </template>
                              </VmCard>
                            </div>
                          </div>
                        </div>
                      </div>
                      <table
                        class="table"
                        :class="classObject"
                      />
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
            <!-- 列表 -->
            <div v-show="type==='list'">
              <el-collapse
                v-for="(projectRow, projectIndex) in displayRows"
                :key="projectRow.key"
                v-model="activeNames"
              >
                <el-collapse-item
                  :name="projectIndex + ''"
                  class="product"
                >
                  <template slot="title">
                    <i class="el-icon-document" />
                    <div v-html="projectRow.key" />
                  </template>
                  <el-collapse
                    v-for="(groupedRows, nsIndex) in projectRow.rows"
                    :key="groupedRows.key"
                    v-model="activeNames"
                  >
                    <el-collapse-item
                      class="namespace"
                      :name="projectIndex + '-' + nsIndex"
                    >
                      <template slot="title">
                        <i class="el-icon-files" />
                        <div v-html="groupedRows.ref" />
                      </template>
                      <!--云主机-->
                      <el-table
                        ref="multipleTable"
                        :data="groupedRows.rows.map(v=>v.row).filter(v=>v.id.includes(filterValue) || v.alias.includes(filterValue))"
                        style="width: 100%"
                        :row-key="getRowKeys"
                        @selection-change="selectionChange"
                      >
                        <el-table-column
                          type="selection"
                          :reserve-selection="true"
                          width="55"
                        />
                        <el-table-column
                          :label="t('pai.detail.vmset.status')"
                        >
                          <template slot-scope="scope">
                            {{ scope.row.spec.power === 'On'? t('pai.vmset.powerState.on'): t('pai.vmset.powerState.off') }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.tab.diskManagement.name')"
                          width="130"
                        >
                          <template slot-scope="scope">
                            <a @click="vmDetail(scope.row.id)">
                              {{ scope.row.metadata.name }}
                            </a>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.edit.alias')"
                          width="100"
                        >
                          <template slot-scope="scope">
                            <a @click="vmDetail(scope.row.id)">
                              {{
                                scope.row.metadata.annotations && scope.row.metadata.annotations[ALIAS] ? scope.row.metadata.annotations[ALIAS] : '-'
                              }}
                            </a>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.tab.overview.ipAddress') "
                          width="115"
                        >
                          <template slot-scope="scope">
                            <el-tooltip
                              effect="light"
                            >
                              <div slot="content">
                                <div
                                  v-for="(item, index) in scope.row.ipList"
                                  :key="index"
                                  class="content"
                                >
                                  <p style="font-size: 14px">
                                    {{ item }}
                                  </p>
                                </div>
                              </div>
                              <template>
                                <div
                                  class="content"
                                >
                                  {{ scope.row.ipList ? scope.row.ipList[0] : ' ' }}
                                  <i class="icon el-icon-s-unfold" />
                                </div>
                              </template>
                            </el-tooltip>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.operationSystem')"
                        >
                          <template slot-scope="scope">
                            {{ scope.row.spec.os }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="CPU"
                          width="50"
                        >
                          <template slot-scope="scope">
                            {{ convertCpuToCore(get(scope,'row.spec.template.spec.containers') && get(scope,'row.spec.template.spec.containers').length ? get(get(scope,'row.spec.template.spec.containers')[0],'resources.requests.cpu') : '') }}
                          </template>
                        </el-table-column>
                        <el-table-column :label="`${t('pai.detail.vmset.tab.overview.ramLabel')}(G)`">
                          <template slot-scope="scope">
                            {{ `${ convertUnitToG(scope.row.spec.template.spec.containers[0].resources.limits.memory ? scope.row.spec.template.spec.containers[0].resources.limits.memory : 0) }G` }}
                          </template>
                        </el-table-column>
                        <el-table-column :label="t('pai.detail.vmset.caliche')">
                          <template slot-scope="scope">
                            {{ computedDiskSize(scope.row) }}
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.tab.overview.cpuUsage')"
                          width="160px"
                        >
                          <template slot-scope="scope">
                            <div class="progress">
                              <Progress
                                :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].cpuUsedRate:0"
                                :bg-color="'rgb(235, 238, 245)'"
                              />
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.tab.overview.ramUsage')"
                          width="160px"
                        >
                          <template slot-scope="scope">
                            <div class="progress">
                              <Progress
                                :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].ramUsedRate:0"
                                :bg-color="'rgb(235, 238, 245)'"
                              />
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.tab.overview.diskPercentage')"
                          width="160px"
                        >
                          <template slot-scope="scope">
                            <div class="progress">
                              <Progress
                                :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].diskUsedRate:0"
                                :bg-color="'rgb(235, 238, 245)'"
                              />
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          :label="t('pai.detail.vmset.operate')"
                        >
                          <template slot-scope="scope">
                            <el-dropdown @command="handleCommand">
                              <i class="el-icon-more" />
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{key:'power',value:scope.row}">
                                  <i
                                    class="el-icon-switch-button"
                                  />
                                  {{ scope.row.spec.power === 'On' ? t('pai.detail.vmset.firing') : t('pai.detail.vmset.shutdown') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'control',value:scope.row}">
                                  <i class="el-icon-monitor" />{{ t('pai.detail.vmset.control') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'copy',value:scope.row}">
                                  <i class="el-icon-document-copy" />{{ t('pai.detail.vmset.copy') }}
                                </el-dropdown-item>
                                <el-dropdown-item
                                  v-if="backupVisible"
                                  :command="{key:'backup',value:scope.row}"
                                >
                                  <i class="el-icon-rank" />{{ t('pai.detail.vmset.backUp') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'image',value:scope.row}">
                                  <i class="el-icon-cloudy" />{{ t('pai.detail.vmset.makeMirror') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'log',value:scope.row}">
                                  <i class="el-icon-office-building" />{{ t('pai.detail.vmset.viewLog') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'monitor',value:scope.row}">
                                  <i class="el-icon-stopwatch" />{{ t('pai.detail.vmset.viewMonitor') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'restart',value:scope.row}">
                                  <i class="el-icon-refresh-right" />{{ t('pai.detail.vmset.restart') }}
                                </el-dropdown-item>
                                <el-dropdown-item :command="{key:'delete',value:scope.row}">
                                  <i class="el-icon-delete" />{{ t('pai.detail.vmset.delete') }}
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-collapse-item>
                  </el-collapse>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <!--不分组-->
          <div v-show="group === 'none'">
            <div
              v-show="type==='card'"
              style="margin-bottom: 10px"
            >
              <div class="list">
                <div
                  v-for="vm in installedVms.filter(v=>!filters.length || filterNamespaces.includes(v.metadata.namespace) || filterProjects.includes(v.projectId)).filter(v=>v.id.includes(filterValue) || v.alias.includes(filterValue))"
                  :key="vm.metadata.uid"
                >
                  <VmCard
                    :value="vm"
                    @changeCollection="changeCollection"
                  >
                    <template slot="checkbox">
                      <el-checkbox
                        size="mini"
                        :value="selectedKeys.includes(vm.id)"
                        @change="onChecked(vm.id)"
                      />
                    </template>
                  </VmCard>
                </div>
              </div>
            </div>
            <div v-show="type==='list'">
              <el-table
                ref="multipleTable"
                :data="installedVms.filter(v=>!filters.length || filterNamespaces.includes(v.metadata.namespace) || filterProjects.includes(v.projectId)).filter(v=>v.id.includes(filterValue) || v.alias.includes(filterValue))"
                style="width: 100%"
                :row-key="getRowKeys"
                @selection-change="selectionChange"
              >
                <el-table-column
                  type="selection"
                  :reserve-selection="true"
                  width="50"
                />
                <el-table-column
                  :label="t('pai.detail.vmset.status')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.spec.power === 'On'? t('pai.vmset.powerState.on'): t('pai.vmset.powerState.off') }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.tab.diskManagement.name')"
                  width="130"
                >
                  <template slot-scope="scope">
                    <a @click="vmDetail(scope.row.id)">
                      {{ scope.row.metadata.name }}
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.edit.alias')"
                  width="100"
                >
                  <template slot-scope="scope">
                    <a @click="vmDetail(scope.row.id)">
                      {{
                        scope.row.metadata.annotations && scope.row.metadata.annotations[ALIAS] ? scope.row.metadata.annotations[ALIAS] : '-'
                      }}
                    </a>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.vmset.project')"
                  width="100"
                >
                  <template slot-scope="scope">
                    {{ scope.row.projectDisplayName !== null? scope.row.projectDisplayName: t('resourceTable.groupLabel.notInAProject') }}
                  </template>
                </el-table-column>
                <el-table-column :label="t('pai.vmset.nameSpace')">
                  <template slot-scope="scope">
                    {{ scope.row.id.split('/')[0] }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.tab.overview.ipAddress')"
                  width="115"
                >
                  <template slot-scope="scope">
                    <el-tooltip
                      effect="light"
                    >
                      <div slot="content">
                        <div
                          v-for="(item, index) in scope.row.ipList"
                          :key="index"
                          class="content"
                        >
                          <p style="font-size: 14px">
                            {{ item }}
                          </p>
                        </div>
                      </div>
                      <template>
                        <div
                          class="content"
                        >
                          {{ scope.row.ipList ? scope.row.ipList[0] : ' ' }}
                          <i class="icon el-icon-s-unfold" />
                        </div>
                      </template>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.operationSystem')"
                >
                  <template slot-scope="scope">
                    {{ scope.row.spec.os }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="CPU"
                  width="50"
                >
                  <template slot-scope="scope">
                    {{ convertCpuToCore(get(scope,'row.spec.template.spec.containers') && get(scope,'row.spec.template.spec.containers').length ? get(get(scope,'row.spec.template.spec.containers')[0],'resources.requests.cpu') : '') }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="`${t('pai.detail.vmset.tab.overview.ramLabel')}(G)`"
                >
                  <template slot-scope="scope">
                    {{ `${ convertUnitToG(scope.row.spec.template.spec.containers[0].resources.limits.memory ? scope.row.spec.template.spec.containers[0].resources.limits.memory : 0) }G` }}
                  </template>
                </el-table-column>
                <el-table-column :label="t('pai.detail.vmset.caliche')">
                  <template slot-scope="scope">
                    {{ computedDiskSize(scope.row) }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.tab.overview.cpuUsage')"
                  width="160px"
                >
                  <template slot-scope="scope">
                    <div class="progress">
                      <Progress
                        :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].cpuUsedRate:0"
                        :bg-color="'rgb(235, 238, 245)'"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.tab.overview.ramUsage')"
                  width="160px"
                >
                  <template slot-scope="scope">
                    <div class="progress">
                      <Progress
                        :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].ramUsedRate:0"
                        :bg-color="'rgb(235, 238, 245)'"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="t('pai.detail.vmset.tab.overview.diskPercentage')"
                  width="160px"
                >
                  <template slot-scope="scope">
                    <div class="progress">
                      <Progress
                        :value="usageRateList[scope.row.id]?usageRateList[scope.row.id].diskUsedRate:0"
                        :bg-color="'rgb(235, 238, 245)'"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :label="t('pai.detail.vmset.operate')">
                  <template slot-scope="scope">
                    <el-dropdown @command="handleCommand">
                      <i class="el-icon-more" />
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="{key:'power',value:scope.row}">
                          <i
                            class="el-icon-switch-button"
                          />{{
                            scope.row.spec.power === 'On' ? t('pai.detail.vmset.firing') : t('pai.detail.vmset.shutdown')
                          }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'control',value:scope.row}">
                          <i class="el-icon-monitor" />{{ t('pai.detail.vmset.control') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'copy',value:scope.row}">
                          <i class="el-icon-document-copy" />{{ t('pai.detail.vmset.copy') }}
                        </el-dropdown-item>
                        <el-dropdown-item
                          v-if="backupVisible"
                          :command="{key:'backup',value:scope.row}"
                        >
                          <i class="el-icon-rank" />{{ t('pai.detail.vmset.backUp') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'image',value:scope.row}">
                          <i class="el-icon-cloudy" />{{ t('pai.detail.vmset.makeMirror') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'log',value:scope.row}">
                          <i class="el-icon-office-building" />{{ t('pai.detail.vmset.viewLog') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'monitor',value:scope.row}">
                          <i class="el-icon-stopwatch" />{{ t('pai.detail.vmset.viewMonitor') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'restart',value:scope.row}">
                          <i class="el-icon-refresh-right" />{{ t('pai.detail.vmset.restart') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{key:'delete',value:scope.row}">
                          <i class="el-icon-delete" />{{ t('pai.detail.vmset.delete') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </template>
      </el-col>
    </el-row>
    <ConsoleModal v-if="consoleModalVisible" />
    <DeleteVmModal v-if="deleteVmModalVisible" />
    <CloneVmModal v-if="cloneVmModalVisible" />
  </div>
</template>

<style lang="scss">
.progress {
  .el-progress-bar__outer{
    width: 70px;
  }
  .el-progress__text {
    margin-left: 0px;
  }
}
</style>

<style lang="scss" scoped>
.list {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-gap: 5px;
}
::v-deep .el-collapse {
  border: unset;

  i {
    color: var(--primary);
    margin-right: 10px;
  }

  .el-collapse-item__header {
    height: 40px;
    background: #fafafa;
    color: var(--primary);
    border: unset;
  }

  .product .el-collapse-item__header {
    margin-bottom: 10px;
    padding-left: 10px;
  }

  .namespace .el-collapse-item__header {
    margin: 0;
    padding-left: 20px;
  }

  .el-collapse-item__arrow {
    order: -100;
    margin: 0 10px 0 0;
  }

  .el-collapse-item__content {
    padding: 0;
  }

  .el-collapse-item__wrap {
    border: unset;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    display: flex;

    div, button {
      margin: 0 5px;
    }
  }

  .verticalLine {
    width: 2px;
    height: 35px;
    margin: 0;
    background-color: #2F6D9A;
  }
}

a {
  cursor: pointer;
}

.apps {
  padding: 10px 25px 10px 0;

  .content {
    margin-bottom: 10px;
  }
}

.progress {
  display: flex;
  align-items: center;

  progress {
    width: 70px;
  }
}
</style>
