import Ingress from '@shell/models/networking.k8s.io.ingress';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { SERVICE } from '@shell/config/types';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import { PAI_SERVICE } from '~/pkg/pai/config/types';

export default class extends PaiLocationMixin(Ingress) {
  targetTo(workloads, serviceName) {
    if (!serviceName) {
      return null;
    }

    const isTargetsWorkload = serviceName.startsWith('ingress-');
    const id = `${ this.namespace }/${ serviceName }`;

    if ( isTargetsWorkload ) {
      const workload = workloads.find(w => w.id === (id));

      return workload?.detailLocation || '';
    } else {
      const product = this.$rootGetters['productId'];

      if (product === PRODUCT_NAME) {
        return {
          name:   `${ PRODUCT_NAME }-c-cluster-resource-namespace-id`,
          params: {
            product:   PRODUCT_NAME,
            cluster:   this.$rootGetters['clusterId'],
            resource:  PAI_SERVICE.SERVICE,
            id:        serviceName,
            namespace: this.namespace,
          },
        };
      }

      return {
        name:   'c-cluster-product-resource-namespace-id',
        params: {
          resource:  SERVICE,
          id:        serviceName,
          namespace: this.namespace,
        }
      };
    }
  }
}
