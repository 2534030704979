<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import CruResource from '../components/vmset/CruResource';
import { _CREATE, _EDIT, _VIEW } from '@shell/config/query-params';
import Tabbed from '../components/Tabbed/index.vue';
import Tab from '../components/Tabbed/Tab.vue';
import FormValidation from '@shell/mixins/form-validation';
import Labels from '../components/form/Labels';
import SectionTitle from '../components/form/SectionTitle';
import { NETWORK_ATTACHMENT, PV, PVC, STORAGE_CLASS } from '@shell/config/types';
import LabeledRadioGroup from '@/pkg/pai/components/form/LabeledRadioGroup';
import { LabeledInput } from '@components/Form/LabeledInput';
import { exceptionToErrorsArray } from '@shell/utils/error';
import ContainerResourceLimit from '@pkg/pai/components/ContainerResourceLimit';
import UnitInput from '@shell/components/form/UnitInput';
import { removeObject } from '@/shell/utils/array';
import { cleanUp, clone, get, set } from '@/shell/utils/object';
import { NODE, SCHEMA } from '@/shell/config/types';
import { PAI_ANNOTATIONS, PAI_RESOURCES } from '@/pkg/pai/config/types';
import { DESCRIPTION } from '@/shell/config/labels-annotations';
import { convertUnitToG } from '@/pkg/pai/utils/units';
import { ALIAS, IMAGE, PVC_LABELS } from '../config/labels-annotations';
import { ADDRESS_ASSIGNMENT_TYPE, IMAGE as IMAGE_PARAM } from '../config/settings';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { mapGetters } from 'vuex';
import { createYaml } from '@shell/utils/create-yaml';
import { HOSTNAME } from '@shell/config/labels-annotations';
import { addOffset } from '@/pkg/pai/utils/ip';
import HealthCheck from '../components/form/HealthCheck';
import AliasNsDescription from '~/pkg/pai/components/form/AliasNsDescription.vue';

export default {
  components: {
    AliasNsDescription,
    LabeledRadioGroup,
    CruResource,
    Tabbed,
    Tab,
    Labels,
    SectionTitle,
    LabeledInput,
    ContainerResourceLimit,
    UnitInput,
    HealthCheck
  },

  mixins: [CreateEditView, FormValidation],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
    mode: {
      type:    String,
      default: _EDIT,
    },
    realMode: {
      type:    String,
      default: _EDIT,
    },
  },
  created() {
    if (this.registerAfterHook) {
      this.registerAfterHook(this.done);
    }
  },
  async fetch() {
    this.storageClasses = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
    this.images = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE });
    this.updateImageOptions();
    if (this.$route.query.image) {
      // 从云主机应用市场选择的镜像进行创建
      const imageName = this.$route.query.image;

      this.getInitImage(imageName);
    } else if (this.osDisk.sc) {
      // 编辑
      this.getInitImage(this.osDisk.sc);
    }
    // 筛选非系统镜像
    this.storageClassOptions = this.storageClasses.filter(v => !(v.annotations && v.annotations[IMAGE.NAME]))
    // 筛选Block设备
      .filter(v => !(v.provisioner === IMAGE_PARAM.DRIVER[1] && (!v.parameters || v.parameters.engine !== 'zfs' || !v.parameters.parent)))
      .map((s) => {
        return {
          label: s.alias ? `${ s.alias }(${ s.name })` : s.name,
          value: s.name,
        };
      }).sort();
    this.persistentVolumes = await this.$store.dispatch('cluster/findAll', { type: PV });
    this.persistentVolumeOptions = this.persistentVolumes
      .map((s) => {
        const status = s.status.phase === 'Available' ? '' : ` (${ s.status.phase })`;

        return {
          label: `${ s.name }${ status }`,
          value: s.name,
        };
      })
      .sort((l, r) => l.label.localeCompare(r.label));
    this.persistentVolumeClaims = await this.$store.dispatch('cluster/findAll', { type: PVC });

    const vmPvcs = this.persistentVolumeClaims.filter(v => (v.spec.volumeMode === 'Block' && (!v.metadata.labels || !v.metadata.labels[PVC_LABELS.MOUNT_VM] || (v.metadata.labels && v.metadata.labels[PVC_LABELS.MOUNT_VM] && v.metadata.labels[PVC_LABELS.MOUNT_VM] === '') || v.spec.accessModes[0] === 'ReadOnlyMany')));

    this.persistentVolumeClaimOptions = vmPvcs.filter(v => !v?.metadata?.labels || !v.metadata.labels[PVC_LABELS.OS])
      .map((v) => {
        return {
          label:     v.metadata.name,
          value:     v.metadata.name,
          namespace: v.metadata.namespace,
        };
      });

    this.osPvcOptions = vmPvcs.filter(v => v?.metadata?.labels && v.metadata.labels[PVC_LABELS.OS])
      .map((v) => {
        return {
          label:     v.metadata.name,
          value:     v.metadata.name,
          os:        v.metadata.labels[PVC_LABELS.OS],
          namespace: v.metadata.namespace,
        };
      });
    const nodes = await this.$store.dispatch('cluster/findAll', { type: NODE });

    this.nodeOptions = [{
      label: this.t('pai.vmset.nodeScheduling.auto'),
      value: '',
    }, ...nodes?.map((node) => {
      return {
        value:    node.metadata.name,
        label:    node.metadata.name + ((!!node.stateObj.error || !!node.spec.unschedulable) ? `（${ this.t('pai.vmset.nodeScheduling.unschedulable') }）` : `（${ this.t('pai.vmset.nodeScheduling.schedulable') })`),
        disabled: !!node.stateObj.error || !!node.spec.unschedulable,
      };
    })];
    this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
    const networkData = [this.defaultNetwork];

    if (this.value.spec.nics) {
      this.value.spec.nics.filter(v => v !== 'default').forEach((v) => {
        const network = this.networks.find(network => network.namespace === this.value.namespace && network.metadata.name === v);

        if (network) {
          const config = JSON.parse(network.spec.config);

          const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
          let ip = '-';
          let srcIP = '-';

          if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
            if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
              ip = config?.ipam?.ranges[0][0].subnet;
            }
          } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
            if (config?.ipam?.addresses?.length && config?.ipam?.addresses[0]) {
              ip = addOffset(config?.ipam?.addresses[0].address, this.ipOffset ? this.ipOffset : 0);
              srcIP = config?.ipam?.addresses[0].address;
            }
          }
          networkData.push({
            name:        network.metadata.name,
            alias:       network.alias,
            networkType: config.type,
            hostNetwork: config.uplink,
            assignmentType,
            ip,
            srcIP,
          });
        }
      });
    }

    this.networkData = networkData;

    this.updateNetworkOptions();
  },
  data() {
    // init
    const GPU_KEY = 'nvidia.com/gpu';

    if (!this.value.spec) {
      this.value.spec = {
        template: {
          spec: {
            containers: [{
              name:            'os',
              image:           'pause',
              imagePullPolicy: 'IfNotPresent',
              resources:       {
                limits: {
                  cpu:    '1',
                  memory: '2Gi',
                },
                requests: {
                  cpu:    '1',
                  memory: '2Gi',
                },
              },
              ports: [{
                containerPort: 9100,
                name:          'metrics',
                protocol:      'TCP',
              }],
              securityContext: {
                runAsNonRoot:             false,
                readOnlyRootFilesystem:   false,
                privileged:               false,
                allowPrivilegeEscalation: false,
              }
            }]
          }
        },
      };
    }
    if (!this.value.spec.power) {
      this.value.spec.power = 'On';
    }
    if (!this.value.spec.replicas) {
      this.value.spec.replicas = 1;
    }
    if (!this.value.metadata.name) {
      this.value.metadata.name = `vm${ parseInt(new Date().getTime() / 1000) }`;
    }
    if (!this.value.metadata.annotations[ALIAS]) {
      this.value.setAnnotation(ALIAS, '');
    }
    if (!this.value.spec.nodeSelector) {
      this.$set(this.value.spec, 'nodeSelector', { 'kubernetes.io/hostname': '' });
    }
    const allVolumes = [];

    if (!this.value.spec.volumeClaimTemplates) {
      this.value.spec.volumeClaimTemplates = [];
    }
    if (!this.value.spec.volumes) {
      this.value.spec.volumes = [];
    }
    const osDisk = {
      name:   'vmosdisk',
      size:   '',
      sc:     '',
      pvc:    '',
      osType: 'new',
      arch:   '',
      desc:   '',
      agent:  '',
      os:     '',
      driver: '',
    };
    let osSize = '';
    let osSc = '';
    // 获取云主机的template磁盘，用于禁止修改的判断
    const templatePvcNames = [];
    const toolkits = [];
    const originToolkits = [];

    if (this.value.spec.volumeClaimTemplates.length > 0) {
      this.value.spec.volumeClaimTemplates.forEach((v) => {
        if (v.metadata && v.metadata.name === 'vmosdisk') {
          osSc = v.spec.storageClassName;
        }
        templatePvcNames.push(v.metadata.name);
        if ((v.metadata && v.metadata.name === 'vmoscdrom' && this.value.labels && this.value.labels[IMAGE.ISO]) || (v.metadata && v.metadata.name === 'vmosdisk' && !osDisk.sc)) {
          osDisk.name = v.metadata.name;
          osDisk.osType = 'new';
          osDisk.size = `${ convertUnitToG(v.spec.resources.requests.storage) }Gi`;
          osDisk.sc = v.spec.storageClassName;
          osSize = v.spec.resources.requests.storage;
        } else if (v.metadata && v.metadata.labels && v.metadata.labels[IMAGE.TOOL]) {
          toolkits.push(v.metadata.name);
          originToolkits.push(v.metadata.name);
        } else {
          allVolumes.push({
            name:    v.metadata && v.metadata.name ? v.metadata.name : '',
            pvcType: 'new',
            size:    `${ convertUnitToG(v.spec.resources.requests.storage) }Gi`,
            sc:      v.spec.storageClassName,
          });
        }
      });
    }
    if (this.value.spec.volumes?.length > 0) {
      this.value.spec.volumes.forEach((v) => {
        if (v.name && v.name === 'vmosdisk') {
          osDisk.name = v.name;
          osDisk.osType = 'exists';
          osDisk.pvc = v.persistentVolumeClaim.claimName;
        } else {
          allVolumes.push({
            name:    v.name ? v.name : '',
            pvcType: 'exists',
          });
        }
      });
    }
    const defaultNetwork = {
      alias:     this.t('pai.vmset.defaultNetwork'),
      isDefault: true,
    };

    if (!this.value.spec.nodeSelector || !this.value.spec.nodeSelector['kubernetes.io/hostname']) {
      this.$set(this.value.spec.nodeSelector, 'kubernetes.io/hostname', '');
    }

    return {
      ipOffset:                     this.value.spec.ipOffset,
      removePvcNames:               [],
      templatePvcNames,
      osSize,
      osSc,
      originToolkits:               [],
      IMAGE,
      PVC_LABELS,
      volumeNameIdx:                1,
      currentVolume:                allVolumes.length > 0 ? allVolumes[0] : {},
      allVolumes,
      nodeOptions:                  [],
      storageClasses:               [],
      images:                       [],
      storageClassOptions:          [],
      imageOptions:                 [],
      persistentVolumeOptions:      [],
      osPvcOptions:                 [],
      persistentVolumes:            [],
      persistentVolumeClaims:       [],
      persistentVolumeClaimOptions: [],
      networks:                     [],
      networkOptions:               [],
      pvcType:                      'new',
      pvcTypeOptions:               [
        {
          label: this.t('pai.vmset.storage.pvc.new'),
          value: 'new',
        },
        {
          label: this.t('pai.vmset.storage.pvc.exists'),
          value: 'exists',
        },
      ],
      osTypeOptions: [
        {
          label: this.t('pai.vmset.storage.newOs'),
          value: 'new',
        },
        {
          label: this.t('pai.vmset.storage.existingOs'),
          value: 'exists',
        },
      ],
      networkData:       [],
      container:         this.value.spec.template.spec.containers[0],
      GPU_KEY,
      systemTypeOptions: [
        {
          value: '',
          label: this.t('pai.detail.vmset.all'),
        },
        {
          value: 'linux',
          label: 'Linux',
        }, {
          value: 'windows',
          label: 'Windows',
        }],
      osDisk,
      nodeSelector:  this.value.spec.nodeSelector[HOSTNAME],
      defaultNetwork,
      disabled:      this.mode === _VIEW ? 'true' : false,
      replicas:      this.value.spec.replicas,
      needRestart:   false,
      lastVolume:    null,
      aliasArr:      [],
      livenessProbe: this.value.spec.template.spec.containers[0].livenessProbe,
      isIso:         !!this.value.labels[IMAGE.ISO],
      isoOptions:    [],
      toolkits,
    };
  },
  computed: {
    ...mapGetters(['currentCluster']),
    isVolumeEmpty() {
      return this.allVolumes && this.allVolumes.length === 0;
    },
    namespace() {
      return this.value?.metadata?.namespace || '';
    },
    flatResources: {
      get() {
        const {
          limits = {},
          requests = {},
        } = this.container.resources || {};
        const {
          cpu:            limitsCpu,
          memory:         limitsMemory,
          [this.GPU_KEY]: limitsGpu,
        } = limits;
        const {
          cpu:    requestsCpu,
          memory: requestsMemory,
        } = requests;

        return {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          limitsGpu,
        };
      },
      set(neu) {
        const {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          limitsGpu,
        } = neu;

        const out = {
          requests: {
            cpu:    requestsCpu,
            memory: requestsMemory,
          },
          limits: {
            cpu:            limitsCpu,
            memory:         limitsMemory,
            [this.GPU_KEY]: limitsGpu,
          },
        };

        this.$set(this.container, 'resources', cleanUp(out));
      },
    },
    currentClusterId() {
      return this.currentCluster?.id || 'local';
    },

    isCreate() {
      return this.realMode === _CREATE;
    },

    isVolumeEditable() {
      return this.templatePvcNames.includes(this.currentVolume.name);
    },

    isExistsVolumeEditable() {
      return !this.replicas || this.replicas === 1;
    },
  },

  methods: {
    forceFetch() {
      return this.$store.dispatch('cluster/find', {
        type: PAI_RESOURCES.VMSET,
        id:   this.value.id,
        opt:  { force: true },
      });
    },
    done() {
      if (this.realMode === _CREATE) {
        this.forceFetch();
        const location = {
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            id:        this.value.metadata.name,
            product:   PAI,
            resource:  PAI_RESOURCES.VMSET,
            cluster:   this.currentClusterId,
            namespace: this.value.metadata.namespace,
          },
          hash: '#eventLog',
        };

        this.$router.push(location);

        return;
      }
      if (this.realMode === _EDIT && this.needRestart) {
        this.forceFetch()
          .then(() => {
            const resource = this.$store.getters['cluster/byId'](PAI_RESOURCES.VMSET, this.value.id);

            const callback = () => {
              const location = {
                name:   `${ PAI }-c-cluster-resource-namespace-id`,
                params: {
                  id:        resource.metadata.name,
                  product:   PAI,
                  resource:  PAI_RESOURCES.VMSET,
                  cluster:   this.currentClusterId,
                  namespace: resource.metadata.namespace,
                },
                hash: '#eventLog',
              };

              this.$router.push(location);
            };
            const titleKey = this.value.powerState !== 'Off' ? 'dialog.generic.mustRestart.title' : 'dialog.generic.mustPowerOn.title';
            const bodyKey = this.value.powerState !== 'Off' ? 'dialog.generic.mustRestart.body' : 'dialog.generic.mustPowerOn.body';

            resource.needPowerRestart(resource, callback, titleKey, bodyKey);
          });
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    addVolume() {
      this.setRestartState();
      this.allVolumes.push({
        name:    this.pvcType === 'new' ? this.generateVolumeName() : '',
        pvcType: this.pvcType,
        sc:      '',
        size:    ''
      });
      this.currentVolume = this.allVolumes[this.allVolumes.length - 1];
    },
    generateVolumeName() {
      this.volumeNameIdx = 1;
      let name = '';
      let hasName = true;

      while (hasName) {
        name = `volume-${ this.volumeNameIdx }`;
        // 因为不允许在云主机里对磁盘大小进行编辑，所以避免新增的磁盘与原有磁盘名称重复
        hasName = this.allVolumes.find(v => v.name === name) || this.templatePvcNames.includes(name);
        this.volumeNameIdx++;
      }

      return name;
    },
    removeAllVolumes() {
      this.setRestartState();
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      })
        .then(() => {
          this.removePvcNames = this.allVolumes.map(v => v.name);

          this.allVolumes = this.allVolumes.filter((item) => {
            if (this.pvcType === 'new') {
              return item.pvcType !== 'new';
            } else {
              return item.pvcType === 'new';
            }
          });
          this.currentVolume = {};
        })
        .catch(() => {
        });
    },
    removeVolume(volume) {
      if (volume.sc || volume.size) {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        })
          .then(() => {
            this.setRestartState();
            this.removePvcNames.push(volume.name);
            this.allVolumes = removeObject(this.allVolumes, volume);
            this.value.spec.volumeClaimTemplates = this.value.spec.volumeClaimTemplates.filter(vct => vct.metadata.name !== volume.name);
          });
      } else {
        this.setRestartState();
        this.removePvcNames.push(volume.name);
        this.allVolumes = removeObject(this.allVolumes, volume);
        this.value.spec.volumeClaimTemplates = this.value.spec.volumeClaimTemplates.filter(vct => vct.metadata.name !== volume.name);
      }
    },
    getAliasArr() {
      const newArr = [];

      this.networkData?.forEach((item) => {
        newArr.push(item.alias);
      });
      this.aliasArr = [...new Set(newArr)];
    },
    addNetwork() {
      this.setRestartState();
      this.networkData.push({});
      this.getAliasArr();
    },
    removeAllNetworks() {
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(() => {
        this.setRestartState();
        this.networkData.splice(0, this.networkData.length);
        this.networkData.push(this.defaultNetwork);
        this.getAliasArr();
      }).catch(() => {});
    },
    onNetworkChange(e, index) {
      this.setRestartState();
      const config = JSON.parse(e.spec.config);
      const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
      let ip = '-';

      if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
        if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
          ip = config?.ipam?.ranges[0][0].subnet;
        }
      } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
        if (config?.ipam?.addresses?.length) {
          ip = config?.ipam?.addresses[0].address;
        }
      }
      this.$set(this.networkData, index, {
        name:        e.metadata.name,
        alias:       e.alias,
        networkType: config.type,
        hostNetwork: config.uplink,
        assignmentType,
        ip,
        srcIP:       ip,
      });
      this.networkData = this.networkData.map((item) => {
        let ip = '';

        if (item?.assignmentType === 'static' && item?.ip) {
          ip = addOffset(item?.srcIP, this.ipOffset ? this.ipOffset : 0);
        } else {
          ip = item?.ip;
        }

        return {
          ...item,
          ip
        };
      });
      this.getAliasArr();
    },
    removeNetwork(row, index) {
      if (row.alias) {
        this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        }).then(() => {
          this.setRestartState();
          this.networkData.splice(index, 1);
          this.getAliasArr();
        });
      } else {
        this.setRestartState();
        this.networkData.splice(index, 1);
        this.getAliasArr();
      }
    },
    updateNetworkOptions() {
      this.networkOptions = this.networks.filter(v => v.metadata.namespace === this.value.metadata.namespace).map((v) => {
        return {
          label: v.alias,
          value: v,
        };
      });
    },
    updateImageOptions() {
      // 包含vmimages的存储类为系统镜像(需要根据命名空间判断权限)
      this.imageOptions = this.storageClasses.filter(v => v.annotations && v.annotations[IMAGE.NAME] && !v.labels[IMAGE.ISO])
        .filter(v => !v.metadata.annotations?.['com.tdology.cloud.nss'] || v.metadata.annotations?.['com.tdology.cloud.nss'].includes(this.namespace))
        .map((s) => {
          const size = (s.metadata.annotations && s.metadata.annotations[IMAGE.SIZE]) ? `(${ convertUnitToG(s.metadata.annotations[IMAGE.SIZE]) }Gi)` : '';

          return {
            label: s.annotations[IMAGE.ALIAS] ? s.annotations[IMAGE.ALIAS] + size : s.annotations[IMAGE.NAME] + size,
            value: s.metadata.name,
            sc:    s,
          };
        }).sort();
      this.isoOptions = this.storageClasses.filter(v => v.annotations && v.annotations[IMAGE.NAME] && !!v.labels[IMAGE.ISO])
        .filter(v => !v.metadata.annotations?.['com.tdology.cloud.nss'] || v.metadata.annotations?.['com.tdology.cloud.nss'].includes(this.namespace))
        .map((v) => {
          const size = (v.metadata.annotations && v.metadata.annotations[IMAGE.SIZE]) ? `(${ convertUnitToG(v.metadata.annotations[IMAGE.SIZE]) }Gi)` : '';

          return {
            sc:    v,
            value: v.name,
            label: v.annotations[IMAGE.ALIAS] ? v.annotations[IMAGE.ALIAS] + size : v.annotations[IMAGE.NAME] + size,
          };
        });
      if ((!this.value.labels[IMAGE.ISO] && !this.imageOptions.map(v => v.value).includes(this.osDisk.sc)) || (this.value.labels[IMAGE.ISO] && !this.isoOptions.map(v => v.value).includes(this.osDisk.sc))) {
        this.osDisk.sc = '';
      }
    },
    async saveOverride(buttonCb) {
      const installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });

      try {
        // 字段验证
        const numRegex = /^[1-9]\d*$/;

        if (!this.value.metadata.annotations[ALIAS]) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.nameTipNull'),
          });
          buttonCb(false);

          return;
        }

        if (this.value.metadata.annotations[ALIAS] && this.realMode === _CREATE) {
          let passStatus = true;

          installedVms.forEach((item) => {
            if (item.id.split('/')[0] === this.value.metadata.namespace && item.metadata.name === this.value.metadata.annotations[ALIAS]) {
              passStatus = false;
            }
          });
          if (!passStatus) {
            this.$message({
              type:    'warning',
              message: this.t('pai.vmset.tips.namespaceRepeat'),
            });
            buttonCb(false);

            return;
          }
        }

        if (this.osDisk.osType === 'new') {
          if (this.isCreate && !this.osDisk.sc) {
            this.$message({
              type:    'warning',
              message: `${ this.t('validation.required', { key: this.t('pai.vmset.system.image') }, true) }`,
            });
            buttonCb(false);

            return;
          }
          if (!this.osDisk.size) {
            this.$message({
              type:    'warning',
              message: `${ this.t('validation.required', { key: this.t('pai.vmset.system.size') }, true) }`,
            });
            buttonCb(false);

            return;
          }
          // 系统盘大小必须大于镜像大小
          if (this.osDisk.imageSize && !this.isIso) {
            if (convertUnitToG(this.osDisk.size) < convertUnitToG(this.osDisk.imageSize)) {
              this.$message({
                type:    'warning',
                message: this.t('pai.vmset.image.sizeOver'),
              });
              buttonCb(false);

              return;
            }
          }
        } else if (this.osDisk.osType === 'exists' && !this.osDisk.pvc) {
          this.$message({
            type:    'warning',
            message: `${ this.t('validation.required', { key: this.t('pai.vmset.storage.os') }, true) }`,
          });
          buttonCb(false);

          return;
        }

        let flag = true;

        if (this.allVolumes.length > 0) {
          for (let i = 0; i < this.allVolumes.length; i++) {
            const volume = this.allVolumes[i];

            if (!volume.name) {
              this.$message({
                type:    'warning',
                message: `${ this.t('validation.required', { key: this.t('persistentVolumeClaim.volumeClaim.label') }, true) }`,
              });
              buttonCb(false);
              flag = false;
            } else if (volume.pvcType === 'new') {
              if (!volume.sc) {
                this.$message({
                  type:    'warning',
                  message: `${ this.t('validation.required', { key: this.t('pai.menu.storage.storage.k8s.io.storageclass') }, true) }`,
                });
                flag = false;
              } else if (!volume.size) {
                this.$message({
                  type:    'warning',
                  message: `${ this.t('validation.required', { key: this.t('pai.detail.vmset.tab.diskManagement.storageSize') }, true) }`,
                });
                flag = false;
              } else if (!parseInt(volume.size) || !(parseInt(volume.size) > 0)) {
                this.$message({
                  type:    'warning',
                  message: `${ this.t('pai.vmset.tips.storage') }`,
                });
                flag = false;
              }
            }
          }
        }

        if (!this.container.resources.limits.cpu || !numRegex.test(parseInt(this.container.resources.limits.cpu))) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.CPULimitsTip'),
          });
          buttonCb(false);

          return;
        }
        if (!this.container.resources.limits.memory || !numRegex.test(parseInt(this.container.resources.limits.memory))) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.ramLimitsTip'),
          });
          buttonCb(false);

          return;
        }
        if (!this.container.resources.requests.cpu || !numRegex.test(parseInt(this.container.resources.requests.cpu))) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.CPURequestsTip'),
          });
          buttonCb(false);

          return;
        }
        if (!this.container.resources.requests.memory || !numRegex.test(parseInt(this.container.resources.requests.memory))) {
          this.$message({
            type:    'warning',
            message: this.t('pai.vmset.tips.ramRequestsTip'),
          });
          buttonCb(false);

          return;
        }

        if (!flag) {
          buttonCb(false);

          return;
        }
        // 资源配额-预留不能大于限制
        if (this.container.resources.requests.cpu) {
          if (!this.container.resources.limits.cpu || parseInt(this.container.resources.limits.cpu) < parseInt(this.container.resources.requests.cpu)) {
            this.$message({
              type:    'warning',
              message: `CPU${ this.t('pai.vmset.tips.resource') }`,
            });
            buttonCb(false);

            return;
          }
        }
        if (this.container.resources.requests.memory) {
          if (!this.container.resources.limits.memory || parseInt(this.container.resources.limits.memory) < parseInt(this.container.resources.requests.memory)) {
            this.$message({
              type:    'warning',
              message: this.t('pai.overview.memory') + this.t('pai.vmset.tips.resource'),
            });
            buttonCb(false);

            return;
          }
        }
        let netWorkFlag = true;

        if (this.networkData.length > 0) {
          this.networkData.forEach((item) => {
            if (!item?.alias) {
              netWorkFlag = false;
            }
          });
        }
        if (!netWorkFlag) {
          this.$message({
            type:    'warning',
            message: `${ this.t('pai.detail.vmset.tab.networkManagement.networkManagement') }${ this.t('pai.verify.required') }`,
          });
          buttonCb(false);

          return;
        }

        this.setData(this.value);
        // 编辑云主机时卸载卷需要手动清空pvc的label
        if (!this.isCreate) {
          const pvcs = this.persistentVolumeClaims.filter(v => v.labels && v.labels[PVC_LABELS.MOUNT_VM] === this.value.metadata.name);

          for (const v of this.removePvcNames) {
            const pvc = pvcs.find(pvc => pvc.metadata.name.includes(v));

            if (pvc) {
              pvc.setLabel(PVC_LABELS.MOUNT_VM, '');
              pvc.setLabel(PVC_LABELS.MOUNT_POD, '');
              try {
                await pvc.save();
              } catch (e) {
                this.errors = exceptionToErrorsArray(e);
              }
            }
          }
        }
        if (this.realMode === _CREATE) {
          await this.save(buttonCb);
        } else {
          try {
            // ISO镜像发生变化时
            if (this.value.labels[IMAGE.ISO]) {
              const iso = this.value.spec.volumeClaimTemplates.find(v => v.metadata.labels && v.metadata.labels[IMAGE.ISO] && v.metadata.name === 'vmoscdrom');

              if (!iso || (iso && iso.spec.storageClassName !== this.osSc)) {
                const pvc = this.persistentVolumeClaims.find(v => v.name.includes('vmoscdrom') && v.labels && v.labels[IMAGE.ISO] && v.labels[PVC_LABELS.MOUNT_VM] === this.value.name);

                if (pvc) {
                  await pvc.remove();
                }
              }
            }
            // 系统工具包发生变化时
            const tools = this.value.spec.volumeClaimTemplates.filter(v => v.metadata.labels && v.metadata.labels[IMAGE.TOOL]);
            const uninstallTools = this.originToolkits.filter(v => !tools.includes(v));
            const pvcs = this.persistentVolumeClaims.filter(v => uninstallTools.includes(v.spec.storageClassName) && v.labels && v.labels[IMAGE.ISO] && v.labels[PVC_LABELS.MOUNT_VM] === this.value.name);

            for (let i = 0;i < pvcs.length;i++) {
              await pvcs[i].remove();
            }
            await this.value.save();
          } catch (e) {
            if (e?._status === 409) {
              this.errors.push(this.t('dialog.generic.saveNeedRestart.body'));

              const resource = await this.value.forceFetch();

              const callback = () => {
                const location = {
                  name:   `${ PAI }-c-cluster-resource-namespace-id`,
                  params: {
                    id:        resource.metadata.name,
                    product:   PAI,
                    resource:  PAI_RESOURCES.VMSET,
                    cluster:   this.currentClusterId,
                    namespace: resource.metadata.namespace,
                  },
                  hash: '#eventLog',
                };

                this.$router.push(location);
              };

              buttonCb(false);
              resource.needPowerRestart(resource, callback, 'dialog.generic.saveNeedRestart.title', 'dialog.generic.saveNeedRestart.body');

              return;
            }
          }

          buttonCb(true);
          this.done();
        }
      } catch (e) {
        this.errors = exceptionToErrorsArray(e);
        buttonCb(false);
      }
    },
    onSelectedSc(e) {
      this.setRestartState();
      this.osDisk.sc = e;
      const sc = this.storageClasses.find(v => v.metadata.name === e);

      if (sc) {
        const annotations = get(sc, `metadata.annotations`);

        if (annotations) {
          this.osDisk.os = annotations[IMAGE.OS];
          this.osDisk.agent = annotations[IMAGE.AGENT];
          this.osDisk.arch = annotations[IMAGE.ARCH];
          this.osDisk.desc = annotations[DESCRIPTION];
          this.osDisk.imageSize = annotations[IMAGE.SIZE];
          if (this.isIso && !this.isCreate) {
            this.osDisk.size = this.osSize;
          } else {
            this.osDisk.size = `${ Math.ceil(convertUnitToG(annotations[IMAGE.SIZE])) }Gi`;
          }
          const osName = annotations[IMAGE.NAME];
          const os = this.images.find(v => v.name === osName);

          if (os && os.labels && os.labels[IMAGE.LOGO]) {
            this.value.setAnnotation(IMAGE.LOGO, os.labels[IMAGE.LOGO]);
          }
          this.osDisk.driver = sc.provisioner === IMAGE_PARAM.DRIVER[0] ? 'longhorn' : 'local-path';
        }
      }
    },
    getInitImage(imageName) {
      // 目前通过vmimage的namespace-name找到对应的sc，后期通过label找到
      const sc = this.storageClasses.find(v => v.metadata.name === imageName);

      if (sc) {
        if (sc.metadata && sc.metadata.annotations && sc.metadata.annotations[PAI_ANNOTATIONS.NAMESPACES]) {
          const nss = sc.metadata.annotations[PAI_ANNOTATIONS.NAMESPACES].split(',');

          if (nss.length > 0 && (!nss.includes(sc.metadata.namespace))) {
            this.$set(this.value.metadata, 'namespace', nss[0]);
          }
        }
        this.osDisk.sc = sc.metadata.name;
        const annotations = get(sc, `metadata.annotations`);

        if (annotations) {
          this.osDisk.os = annotations[IMAGE.OS];
          this.osDisk.arch = annotations[IMAGE.ARCH];
          this.osDisk.agent = annotations[IMAGE.AGENT];
          this.osDisk.desc = annotations[DESCRIPTION];
          this.osDisk.imageSize = annotations[IMAGE.SIZE];
          if (this.isIso && !this.isCreate) {
            this.osDisk.size = this.osSize;
          } else {
            this.osDisk.size = `${ Math.ceil(convertUnitToG(annotations[IMAGE.SIZE])) }Gi`;
          }
          const osName = annotations[IMAGE.NAME];
          const os = this.images.find(v => v.name === osName);

          if (os && os.labels && os.labels[IMAGE.LOGO]) {
            this.value.setAnnotation(IMAGE.LOGO, os.labels[IMAGE.LOGO]);
          }
          this.osDisk.driver = sc.provisioner === IMAGE_PARAM.DRIVER[0] ? 'longhorn' : 'local-path';
        }
      }
    },
    limitChange() {
      this.setRestartState();
    },
    labelsChange(e) {
      this.value.spec.nodeSelector['kubernetes.io/hostname'] = e;
      this.setRestartState();
    },
    generateYaml() {
      const resource = this.value;

      const schemas = this.$store.getters[`cluster/all`](SCHEMA);
      const clonedResource = clone(resource);

      delete clonedResource.isSpoofed;
      this.setData(clonedResource);

      return createYaml(schemas, PAI_RESOURCES.VMSET, clonedResource);
    },

    setData(resource) {
      resource.spec.agent = this.osDisk.agent;
      let volumeClaimTemplateMap = resource.spec.volumeClaimTemplates.map(v => v.metadata.name);
      const volumeMap = resource.spec.volumes.map(v => v.name);

      // 绑定系统盘
      // iso
      if (resource.labels[IMAGE.ISO]) {
        const iso = {
          metadata: {
            name:   'vmoscdrom',
            labels: { [IMAGE.ISO]: 'true' }
          },
          spec: {
            resources:        { requests: { storage: `${ convertUnitToG(this.osDisk.size) }Gi` } },
            storageClassName: this.osDisk.sc,
          }
        };

        if (volumeClaimTemplateMap.includes('vmoscdrom')) {
          const osDiskIndex = volumeClaimTemplateMap.indexOf('vmoscdrom');

          if (this.osDisk.sc) {
            resource.spec.volumeClaimTemplates[osDiskIndex] = iso;
          } else {
            resource.spec.volumeClaimTemplates = resource.spec.volumeClaimTemplates.filter(v => v.metadata.name !== 'vmoscdrom');
          }
        } else {
          resource.spec.volumeClaimTemplates.push(iso);
        }
        volumeClaimTemplateMap = resource.spec.volumeClaimTemplates.map(v => v.metadata.name);
        if (!volumeClaimTemplateMap.includes('vmosdisk')) {
          resource.spec.volumeClaimTemplates.push({
            metadata: { name: this.osDisk.name },
            spec:     {
              resources:        { requests: { storage: `${ convertUnitToG(this.osDisk.size) }Gi` } },
              storageClassName: this.osDisk.driver,
            },
          });
        } else if (this.isCreate) {
          const osDiskIndex = volumeClaimTemplateMap.indexOf('vmosdisk');

          resource.spec.volumeClaimTemplates[osDiskIndex].spec.storageClassName = this.osDisk.driver;
        }
        // 普通镜像
      } else if (this.osDisk.osType === 'new') {
        if (volumeClaimTemplateMap.includes('vmosdisk')) {
          const osDiskIndex = volumeClaimTemplateMap.indexOf('vmosdisk');

          resource.spec.volumeClaimTemplates[osDiskIndex].spec.resources.requests.storage = `${ convertUnitToG(this.osDisk.size) }Gi`;
          resource.spec.volumeClaimTemplates[osDiskIndex].spec.storageClassName = this.osDisk.sc;
        } else {
          resource.spec.volumeClaimTemplates.push({
            metadata: { name: this.osDisk.name },
            spec:     {
              resources:        { requests: { storage: `${ convertUnitToG(this.osDisk.size) }Gi` } },
              storageClassName: this.osDisk.sc,
            },
          });
        }
      } else if (this.osDisk.osType === 'exists') {
        if (volumeMap.includes('vmosdisk')) {
          const osDiskIndex = volumeMap.indexOf('vmosdisk');

          resource.spec.volumes[osDiskIndex].name = this.osDisk.name;
          resource.spec.volumes[osDiskIndex].persistentVolumeClaim.claimName = this.osDisk.pvc;
        } else {
          resource.spec.volumes.push({
            name:                  this.osDisk.name,
            persistentVolumeClaim: { claimName: this.osDisk.pvc },
          });
        }
      }
      // 绑定数据盘
      this.allVolumes.forEach((volume) => {
        if (volume.pvcType === 'new') {
          if (volumeClaimTemplateMap.includes(volume.name)) {
            const index = volumeClaimTemplateMap.indexOf(volume.name);

            resource.spec.volumeClaimTemplates[index].spec.resources.requests.storage = `${ convertUnitToG(volume.size) }Gi`;
            resource.spec.volumeClaimTemplates[index].spec.storageClassName = volume.sc;
          } else {
            resource.spec.volumeClaimTemplates.push({
              metadata: { name: volume.name },
              spec:     {
                resources:        { requests: { storage: `${ convertUnitToG(volume.size) }Gi` } },
                storageClassName: volume.sc,
                volumeMode:       'Block',
              },
            });
          }
        } else if (volume.pvcType === 'exists') {
          if (volumeMap.includes(volume.name)) {
            const index = volumeMap.indexOf(volume.name);

            resource.spec.volumes[index].name = volume.name;
            resource.spec.volumes[index].persistentVolumeClaim.claimName = volume.name;
          } else {
            resource.spec.volumes.push({
              name:                  volume.name,
              persistentVolumeClaim: { claimName: volume.name },
            });
          }
        }
      });
      // 清空原有工具包
      resource.spec.volumeClaimTemplates = resource.spec.volumeClaimTemplates.filter(v => !(v.metadata.labels && v.metadata.labels[IMAGE.ISO]) || (v.metadata.labels && v.metadata.labels[IMAGE.ISO] && (v.metadata.name === 'vmoscdrom' || v.metadata.name === 'vmosdisk')));
      // 绑定工具包
      for (let i = 0;i < this.toolkits.length;i++) {
        const name = this.toolkits[i];
        const toolkit = this.isoOptions.find(v => v.value === name);
        const param = {
          metadata: { name, labels: { [IMAGE.ISO]: 'true', [IMAGE.TOOL]: 'true' } },
          spec:     {
            resources:        { requests: { storage: `${ convertUnitToG(toolkit ? toolkit.sc.annotations[IMAGE.SIZE] : 0) }Gi` } },
            storageClassName: name,
          }
        };

        volumeClaimTemplateMap = resource.spec.volumeClaimTemplates.map(v => v.metadata.name);
        if (volumeClaimTemplateMap.includes(name)) {
          const osDiskIndex = volumeClaimTemplateMap.indexOf(name);

          resource.spec.volumeClaimTemplates[osDiskIndex] = param;
        } else {
          resource.spec.volumeClaimTemplates.push(param);
        }
      }
      // 处理网卡
      const nics = this.networkData.filter(v => !v.isDefault).map(v => v.name);

      resource.spec.nics = [...nics];
      // 处理nodeSelector
      if (!this.nodeSelector) {
        this.$delete(resource.spec.nodeSelector, HOSTNAME);
      }
    },
    setRestartState() {
      if (!this.isCreate) {
        this.needRestart = true;
      }
    },
    onIsoChange(e) {
      if (e) {
        this.value.setLabel(IMAGE.ISO, 'true');
      } else {
        this.$delete(this.value.metadata.labels, IMAGE.ISO);
      }
      this.osDisk.sc = '';
    },
  },
  watch: {
    namespace() {
      this.updateNetworkOptions();
      this.updateImageOptions();
    },
    ipOffset(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setRestartState();

        this.networkData = this.networkData.map((item) => {
          let ip = '';

          if (item?.assignmentType === 'static' && item?.ip) {
            ip = addOffset(item?.srcIP, newValue);
          } else {
            ip = item?.ip;
          }

          return {
            ...item,
            ip
          };
        });
      }
    },
    'osDisk.size': {
      deep: true,
      handler() {
        this.setRestartState();
      },
    },
    currentVolume: {
      handler(newValue, oldValue) {
        this.lastVolume = oldValue;
      },
    },
    'currentVolume.size': {
      deep: true,
      handler(newValue, oldValue) {
        if (this.lastVolume === null && newValue !== oldValue) {
          this.setRestartState();
        }
        if (this.lastVolume !== null && this.lastVolume.size !== oldValue && newValue !== oldValue) {
          this.setRestartState();
        }
      },
    },
    replicas(nue) {
      // 多个实例时不能设置已有磁盘
      if (nue > 1) {
        this.osDisk.osType = 'new';
        this.pvcType = 'new';
      }
    },
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    :generate-yaml="generateYaml"
    @error="e=>errors = e"
    @finish="saveOverride"
    @cancel="cancel"
  >
    <div class="row">
      <AliasNsDescription
        :value="value"
        :mode="mode"
        class="col span-10"
        @isNamespaceNew="isNamespaceNew = $event"
      />
      <LabeledInput
        v-model.number="replicas"
        :mode="mode"
        :label="t('pai.vmset.replicas')"
        class="col span-2 mb-20"
        style="width: 15%;"
        type="number"
        required
        :min="1"
        @input="value.spec.replicas = Number($event)"
      />
    </div>
    <Tabbed
      ref="containersTabbed"
      class="deployment-tabs"
      :show-tabs-add-remove="true"
      :flat="true"
    >
      <!--   基本信息   -->
      <Tab
        :label="t('pai.vmset.info')"
        name="vm"
        :weight="99"
        :required-tab="true"
      >
        <div class="tab-main">
          <SectionTitle
            :value="t('pai.vmset.system.type')"
            class="sectionTitle"
          />
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            :colon="false"
          >
            <el-descriptions-item>
              <el-select
                v-model="osDisk.os"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                class="col span-6"
                :disabled="!isCreate"
                style="width: 60%"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="(item,i) in systemTypeOptions"
                  :key="item.value+i"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item />
          </el-descriptions>
          <SectionTitle
            :value="t('pai.vmset.storage.os')"
            class="sectionTitle"
          />
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            :colon="false"
          >
            <el-descriptions-item>
              <el-checkbox
                v-model="isIso"
                :disabled="!isCreate"
                @change="onIsoChange"
              >
                {{ t('pai.vmset.image.iso') }}
              </el-checkbox>
            </el-descriptions-item>
            <el-descriptions-item v-if="isIso">
              <template>
                <span style="color: red;margin-right: 10px;">*</span>
              </template>
              <el-select
                v-model="osDisk.sc"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                class="col span-6"
                style="width: 56%"
                clearable
                @change="onSelectedSc"
              >
                <el-option
                  v-for="(item,i) in isoOptions.filter(v=>!!v.sc.annotations[IMAGE.OS])"
                  :key="item.value+i"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item v-else />
            <el-descriptions-item>
              <LabeledRadioGroup
                v-model="osDisk.osType"
                class="row"
                name="osType"
                :options="osTypeOptions"
                :mode="isCreate ? 'edit' : 'view'"
                :row="true"
                :disabled="!isExistsVolumeEditable || isIso"
              />
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            :colon="false"
          >
            <template v-if="osDisk.osType === 'new'">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span> {{ t('pai.vmset.system.image') }}
                </template>
                <el-select
                  :disabled="!isCreate || isIso"
                  :value="isIso ? isCreate ? osDisk.driver : osSc : osDisk.sc"
                  :placeholder="t('pai.edit.SelectPlaceholder')"
                  class="col span-6"
                  style="width: 60%"
                  @change="onSelectedSc"
                >
                  <el-option
                    v-for="(item) in imageOptions.filter(v=>v.sc.metadata && v.sc.metadata.annotations && v.sc.metadata.annotations[IMAGE.OS] && v.sc.metadata.annotations[IMAGE.OS].includes(osDisk.os))"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span> {{ t('pai.vmset.system.size') }}
                </template>
                <UnitInput
                  v-model="osDisk.size"
                  label=""
                  :mode="isCreate ? 'edit' : 'view'"
                  :input-exponent="3"
                  :increment="1024"
                  :output-modifier="true"
                  style="width: 60%"
                  :required="true"
                />
              </el-descriptions-item>
              <el-descriptions-item v-if="osDisk.sc">
                <div class="selectedInfo-box">
                  <div><span>{{ t('pai.vmset.image.selectedInfo') }}:</span></div>
                  <div><span>{{ t('pai.vmset.image.framework') }}:</span>&nbsp;&nbsp;{{ osDisk.arch || '-' }}</div>
                  <div><span>{{ t('pai.vmset.image.description') }}:</span>&nbsp;&nbsp;{{ osDisk.desc || '-' }}</div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.detail.vmset.system') + t('pai.vmset.image.toolkit') }}
                </template>
                <el-select
                  v-model="toolkits"
                  :placeholder="t('pai.edit.SelectPlaceholder')"
                  class="col span-6"
                  style="width: 60%"
                  multiple
                >
                  <el-option
                    v-for="(item,i) in isoOptions.filter(v=>!v.sc.annotations[IMAGE.OS])"
                    :key="item.value+i"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item v-if="!osDisk.sc" />
            </template>
            <template v-if="osDisk.osType === 'exists'">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span> {{ t('pai.vmset.storage.selectOs') }}
                  <el-tooltip
                    :content="t('pai.vmset.tips.block.disk')"
                    effect="light"
                  >
                    <i class="el-icon-question" />
                  </el-tooltip>
                </template>
                <el-select
                  v-model="osDisk.pvc"
                  :placeholder="t('pai.edit.SelectPlaceholder')"
                  class="col span-6"
                  :disabled="!isCreate"
                >
                  <el-option
                    v-for="(item,i) in osPvcOptions.filter(v=> v.namespace === value.metadata.namespace && v.os.includes(osDisk.os))"
                    :key="item.value+i"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item />
            </template>
          </el-descriptions>
          <SectionTitle
            :value="t('pai.vmset.limit.label')"
            class="sectionTitle"
          />
          <ContainerResourceLimit
            v-model="flatResources"
            :mode="mode"
            :show-tip="false"
            @input="limitChange"
          />
        </div>
      </Tab>
      <!-- 存储      -->
      <Tab
        :label="t('nav.group.Storage')"
        name="storage"
        :weight="98"
        class="tab"
        :required-tab="false"
      >
        <SectionTitle
          :value="t('pai.vmset.system.storage')"
          class="row sectionTitle"
        />

        <div class="tab">
          <LabeledRadioGroup
            v-model="pvcType"
            class="row"
            name="pvcType"
            :options="pvcTypeOptions"
            :row="true"
            :disabled="!isExistsVolumeEditable"
          />
          <el-tooltip
            :content="t('pai.vmset.tips.replicas')"
            effect="light"
          >
            <i class="el-icon-question replicasIcon" />
          </el-tooltip>
          <div class="storageBox">
            <div class="row mt-15">
              <button
                type="button"
                class="btn role-primary"
                style="margin-right: 10px"
                @click="addVolume"
              >
                <i class="el-icon-plus" />
                {{ t('generic.add') }}
              </button>
              <button
                type="button"
                class="btn bg-error"
                :disabled="isVolumeEmpty"
                @click="removeAllVolumes"
              >
                <i class="el-icon-delete" />
                {{ t('pai.labels.removeAll') }}
              </button>
            </div>

            <div v-if="pvcType==='new'">
              <div
                v-for="(volume,i) in allVolumes"
                :key="volume.name+i"
                class="padding-tb"
              >
                <el-descriptions
                  v-if="volume.pvcType === 'new'"
                  title=""
                  direction="vertical"
                  :column="2"
                >
                  <el-descriptions-item :label="t('pai.menu.storage.storage.k8s.io.storageclass')">
                    <el-select
                      v-model="volume.sc"
                      :placeholder="t('pai.edit.SelectPlaceholder')"
                      class="col span-6"
                      :disabled="isVolumeEditable"
                      filterable
                      style="width: 60%"
                    >
                      <el-option
                        v-for="(item,i) in storageClassOptions"
                        :key="item.value+i"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <el-tooltip
                      :content="t('pai.vmset.tips.block.sc')"
                      effect="light"
                    >
                      <i class="el-icon-question" />
                    </el-tooltip>
                  </el-descriptions-item>
                  <el-descriptions-item :label="t('pai.vmset.storage.pv.size')">
                    <UnitInput
                      v-model="volume.size"
                      label=""
                      :mode="isVolumeEditable ? 'view' : 'edit'"
                      :input-exponent="3"
                      :increment="1024"
                      :output-modifier="true"
                      :min="1"
                      style="width: 60%"
                    />
                  </el-descriptions-item>
                </el-descriptions>
                <el-button
                  v-if="volume.pvcType === 'new'"
                  type="text"
                  class="removeBtn newBtn"
                  @click="removeVolume(volume)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </div>

            <div v-else-if="pvcType==='exists'">
              <div
                v-for="(volume,i) in allVolumes"
                :key="volume.name+i"
                class="padding-tb"
              >
                <el-descriptions
                  v-if="volume.pvcType==='exists'"
                  title=""
                  direction="vertical"
                  :column="1"
                >
                  <el-descriptions-item :label="t('persistentVolumeClaim.volumeClaim.label')">
                    <el-select
                      v-model="currentVolume.name"
                      :placeholder="t('pai.edit.SelectPlaceholder')"
                      class="col span-6"
                      filterable
                      style="width: 36%"
                    >
                      <el-option
                        v-for="(item,i) in persistentVolumeClaimOptions.filter(v=> v.namespace === value.metadata.namespace)"
                        :key="item.value+i"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </el-descriptions-item>
                </el-descriptions>
                <el-button
                  v-if="volume.pvcType==='exists'"
                  type="text"
                  class="removeBtn existsBtn"
                  @click="removeVolume(volume)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <!--   网络   -->
      <Tab
        :label="t('nav.group.Networking')"
        name="network"
        :weight="97"
        class="tab"
        :required-tab="false"
      >
        <SectionTitle
          :value="t('pai.vmset.system.network')"
          class="row sectionTitle"
        />
        <div class="storageBox">
          <div class="row mt-10 mb-10">
            <button
              type="button"
              class="btn role-primary"
              style="margin-right: 10px"
              @click="addNetwork"
            >
              <i class="el-icon-plus" />
              {{ t('generic.add') }}
            </button>
            <button
              type="button"
              class="btn bg-error"
              @click="removeAllNetworks"
            >
              <i class="el-icon-delete" />
              {{ t('pai.labels.removeAll') }}
            </button>
          </div>
          <el-table
            :data="networkData"
            style="width: 100%"
          >
            <el-table-column
              :label="t('nav.group.Networking')"
              width="190"
            >
              <template slot-scope="scope">
                <el-select
                  v-model="networkData[scope.$index].alias"
                  :placeholder="t('pai.edit.SelectPlaceholder')"
                  :disabled="scope.row.isDefault"
                  filterable
                  @change="onNetworkChange($event,scope.$index)"
                >
                  <el-option
                    v-for="(item,i) in networkOptions"
                    :key="item.value+i"
                    :label="item.label"
                    :value="item.value"
                    :disabled="aliasArr.includes(item.label)"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              :label="t('pai.list.network.hostNetwork')"
              prop="hostNetwork"
              width="200"
            >
              <template slot-scope="scope">
                <div class="tableCellBox">
                  {{ scope.row.isDefault || !scope.row.hostNetwork ? '-' : scope.row.hostNetwork }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="t('pai.detail.vmset.tab.networkManagement.type')"
              prop="networkType"
              width="200"
            >
              <template slot-scope="scope">
                <div class="tableCellBox">
                  {{ scope.row.isDefault || !scope.row.networkType ? '-' : (scope.row.networkType === 'bridge' ? `${t('pai.vmset.network.bridge')}` : scope.row.networkType) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="t('pai.list.network.assignmentType')"
              prop="assignmentType"
              width="200"
            >
              <template slot-scope="scope">
                <div
                  class="tableCellBox"
                >
                  {{ scope.row.isDefault || !scope.row.assignmentType ? '-' : scope.row.assignmentType }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="ip"
              width="400"
            >
              <!-- eslint-disable vue/no-unused-vars -->
              <template
                slot="header"
                slot-scope="scope"
              >
                <!--eslint-enable-->
                <div style="display: flex">
                  <span style="margin: 24px 6px 0px 0px;">
                    <el-tooltip
                      :content="t('pai.vmset.network.ipOffsetTip')"
                      effect="light"
                    >
                      <i class="el-icon-question" />
                    </el-tooltip>
                    {{ t('pai.detail.vmset.tab.overview.ipAddress') }}
                  </span>
                  <span>
                    <LabeledInput
                      v-model.number="ipOffset"
                      :mode="mode"
                      label=""
                      class="col span-1 mb-20"
                      style="width: 110px;position: relative;top: 20px;"
                      type="number"
                      required
                      :min="0"
                      :disabled="value.spec.power !== 'Off' && !isCreate"
                      :placeholder="t('pai.vmset.network.editOffset')"
                      @input="value.spec.ipOffset = Number($event)"
                    />
                    <span
                      v-if="value.spec.power === 'On' && !isCreate"
                      style="color: #f64747;position: relative;top: -28px;left: 124px;"
                    >{{ t('pai.vmset.tips.ipOffsetTips') }}</span>
                  </span>
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  class="tableCellBox"
                >
                  {{ scope.row.ip || '-' }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="">
              <template slot-scope="scope">
                <el-button
                  v-show="!scope.row.isDefault"
                  type="primary"
                  size="mini"
                  class="primaryBtn"
                  @click="removeNetwork(scope.row, scope.$index)"
                >
                  {{ t('generic.remove') }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </Tab>
      <!--   高级选项   -->
      <Tab
        :label="t('pai.vmset.advancedOptions')"
        name="vms"
        :weight="96"
        :required-tab="false"
      >
        <Tabbed :side-tabs="true">
          <Tab
            label-key="generic.labelsAndAnnotations"
            name="general"
            :weight="100"
            :tooltip="t('pai.vmset.tips.label')"
          >
            <Labels
              v-model="value"
              :mode="value.powerState !== 'Off' && !isCreate ? 'view' : mode"
            />
          </Tab>
          <Tab
            label-key="pai.vmset.nodeScheduling.label"
            name="nodeScheduling"
            :weight="99"
          >
            <SectionTitle
              :value="t('pai.vmset.nodeScheduling.label')"
              class="sectionTitle"
            />
            <div class="row mt-15">
              <el-select
                v-model="nodeSelector"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                class="col span-6"
                filterable
                @change="labelsChange"
              >
                <el-option
                  v-for="(item,i) in nodeOptions"
                  :key="item.value+i"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </div>
          </Tab>
          <Tab
            label-key="pai.vmset.nodeScheduling.health"
            name="healthCheck"
            :weight="99"
          >
            <HealthCheck
              :value="container"
              :mode="mode"
              :readiness-probe-hidden="true"
              :startup-probe-hidden="true"
              @input="Object.assign(container, $event)"
            />
          </Tab>
        </Tabbed>
      </Tab>
    </Tabbed>
  </CruResource>
</template>
<style lang="scss" scoped>
.tab {
  padding: 20px;
}

.el-tag {
  margin: 0 5px;
  cursor: pointer;
}

.active {
  border-color: var(--primary);
}
.tab-main {
  padding: 20px;
}

.el-input-group {
  display: -webkit-box;
}

.el-input-pend {
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 10px;
  width: 1px;
  white-space: nowrap;
  border-left: unset;
}
.selectedInfo-box {
  width: 60%;
  padding: 15px;
  margin-top: -30px;
  border: 1px dashed rgba(187, 187, 187, 1);
  background-color: rgba(250, 250, 250, 1);
}
.replicasIcon {
  position: relative;
  top: -20px;
  left: 286px;
}
.storageBox {
  background-color: #FAFAFA;
  border: 1px solid #DCDEE7;
  padding: 20px 10px;
  border-radius: 3px;
}
.padding-tb {
  display: flex;
  padding: 10px 0px 0px 0px;
}
.removeBtn{
  color: #246FA5;
}
.newBtn{
  position: relative;
  right: 16%;
  top: 11px;
}
.existsBtn {
  position: relative;
  right: 60%;
  top: 11px;
}
.primaryBtn {
  background-color: #246FA5;
  border: 1px solid #246FA5;
}
.tableCellBox {
  width: 180px;
  height: 34px;
  padding: 0px 10px;
  line-height: 34px;
  background-color: #EFEFEF;
  border: none;
  border-radius: 3px;
}

::v-deep .el-descriptions__title {
  font-size: 14px;
  font-weight: unset;
}

::v-deep .el-input-number.is-controls-right .el-input-number__decrease, .el-input-number.is-controls-right .el-input-number__increase {
  height: auto;
  line-height: 22px !important;
}

::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 10px;
}
::v-deep {
  .labeled-input INPUT.no-label,
  .labeled-input INPUT:hover.no-label,
  .labeled-input INPUT:focus.no-label {
    padding: 6px 0px 6px 0px;
  }
  .labeled-input.compact-input {
    min-height: 0;
  }
}
::v-deep .el-descriptions__body {
  background-color: transparent;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #EFEFEF;
  border: none;
  color: #C0C4CC;
  cursor: not-allowed;
}
</style>
