export function addOffset(ipWithSubnet, offset) {
  const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\/(?:[0-9]|[1-2][0-9]|3[0-2])$/;

  if (!regex.test(ipWithSubnet)) {
    return 'ip格式错误';
  }
  if (offset >= maxIp(ipWithSubnet)) {
    return `偏移量必须小于${ maxIp(ipWithSubnet) }`;
  }

  // 将IP地址和子网掩码分离
  const [ip, subnet] = ipWithSubnet.split('/');

  // 将IP地址转换为32位二进制数
  let ipBinary = ip.split('.')
    .map(num => parseInt(num));

  ipBinary = (ipBinary[0] << 24) + (ipBinary[1] << 16) + (ipBinary[2] << 8) + ipBinary[3];

  // 增加偏移量
  ipBinary += offset;

  // 将32位二进制数转换回IP地址
  let newIp = [];

  newIp.push((ipBinary >>> 24) & 0xFF);
  newIp.push((ipBinary >>> 16) & 0xFF);
  newIp.push((ipBinary >>> 8) & 0xFF);
  newIp.push(ipBinary & 0xFF);
  newIp = newIp.join('.');

  return `${ newIp }/${ subnet }`;
}

export function maxIp(ipWithSubnet) {
  const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}\/(?:[0-9]|[1-2][0-9]|3[0-2])$/;

  if (!regex.test(ipWithSubnet)) {
    return 256;
  }
  // 将IP地址和子网掩码分离
  const subnet = ipWithSubnet.split('/')[1];

  // 计算子网掩码的位数
  const subnetBits = parseInt(subnet);

  // 计算IP地址的范围
  return Math.pow(2, 32 - subnetBits);
}
