import { MANAGEMENT } from '@shell/config/types';

export function projectDisplayName(displayName, getters, errors, validatorArgs, path, data) {
  if (!displayName) {
    return errors;
  }

  const projects = getters['management/all'](MANAGEMENT.PROJECT);

  if (!projects || projects.length === 0) {
    return errors;
  }
  const t = getters['i18n/t'];

  if (!data.id) {
    const projectNames = projects.map(project => project.nameDisplay);

    if (projectNames && projectNames.includes(displayName)) {
      errors.push(t('validation.duplicatedProjectName'));
    }

    return errors;
  } else {
    const projectMap = {};

    projects.forEach((project) => {
      projectMap[project.id] = project.nameDisplay;
    });
    if (projectMap[data.id] !== displayName && Object.values(projectMap)
      .includes(displayName)) {
      errors.push(t('validation.duplicatedProjectName'));
    }

    return errors;
  }
}
