<script>
import ResourceTable from '../components/ResourceTable';
import { WORKLOAD_TYPES, SCHEMA, NODE, POD } from '@shell/config/types';
import ResourceFetch from '../mixins/resource-fetch';
import { convertResource } from '@/pkg/pai/utils';
import { getClusterFromRoute, getProductFromRoute } from '@shell/middleware/authenticated';

const schema = {
  id:         'pai.workload',
  type:       SCHEMA,
  attributes: {
    kind:       'Workload',
    namespaced: true,
  },
  metadata:   { name: 'workload' },
};

const $loadingResources = ($route, $store) => {
  const allowedResources = [];

  Object.values(WORKLOAD_TYPES)
    .forEach((type) => {
      // You may not have RBAC to see some of the types
      if ($store.getters['cluster/schemaFor'](type)) {
        allowedResources.push(type);
      }
    });

  const allTypes = $route.params.resource === schema.id;

  return {
    loadResources:     allTypes ? allowedResources : [$route.params.resource],
    loadIndeterminate: allTypes,
  };
};

export default {
  name:       'ListWorkload',
  components: { ResourceTable },
  mixins:     [ResourceFetch],
  layout:     'pai/default',
  props:      {
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false,
    },
  },

  async fetch() {
    const actualResource = convertResource(this.$route.params.resource);

    if (this.allTypes && this.loadResources.length) {
      this.$initializeFetchData(this.loadResources[0], this.loadResources);
      this.$initializeFetchData(actualResource);
    } else {
      this.$initializeFetchData(actualResource);
    }
    try {
      const schema = this.$store.getters[`cluster/schemaFor`](NODE);

      if (schema) {
        this.$fetchType(NODE);
      }
    } catch {
    }
    this.loadHeathResources();
    // resource is workload
    if (this.allTypes) {
      this.resources = await Promise.all(this.loadResources.map((allowed) => {
        return this.$fetchType(allowed, this.loadResources);
      }));
      // resource是workload下的菜单
    } else {
      const type = convertResource(this.$route.params.resource);

      if (this.$store.getters['cluster/schemaFor'](type)) {
        const resource = await this.$fetchType(type);

        this.resources = [resource];
      }
    }
  },

  data() {
    // Ensure these are set on load (to determine if the NS filter is required) rather than too late on `fetch`
    const {
      loadResources,
      loadIndeterminate,
    } = $loadingResources(this.$route, this.$store);

    return {
      resources: [],
      loadResources,
      loadIndeterminate,
    };
  },

  computed: {
    allTypes() {
      return this.$route.params.resource === schema.id;
    },

    schema() {
      const type = this.$route.params.resource;

      if (type !== schema.id) {
        return this.$store.getters['cluster/schemaFor'](convertResource(type));
      }

      return schema;
    },

    filteredRows() {
      const out = [];

      for (const typeRows of this.resources) {
        if (!typeRows) {
          continue;
        }

        for (const row of typeRows) {
          if (!this.allTypes || !row.ownedByWorkload) {
            out.push(row);
          }
        }
      }

      return out;
    },
  },

  // All of the resources that we will load that we need for the loading indicator
  $loadingResources($route, $store) {
    return $loadingResources($route, $store);
  },

  methods: {
    getCustomDetailLink(row) {
      const { resource } = this.$route.params;
      const product = getProductFromRoute(this.$route);
      const cluster = getClusterFromRoute(this.$route);

      if (row.metadata.namespace) {
        return {
          name:   `${ product }-c-cluster-resource-namespace-id`,
          params: {
            product,
            cluster,
            resource,
            namespace: row.metadata.namespace,
            id:        row.id.split('/')[1]
          }
        };
      } else {
        return {
          name:   `${ product }-c-cluster-resource-id`,
          params: {
            product,
            cluster,
            resource,
            id: row.id
          }
        };
      }
    },
    loadHeathResources() {
      // Fetch these in the background to populate workload health
      if (this.allTypes) {
        this.$fetchType(POD);
        this.$fetchType(WORKLOAD_TYPES.JOB);
      } else {
        const type = convertResource(this.$route.params.resource);

        if (type === WORKLOAD_TYPES.JOB) {
          // Ignore job (we're fetching this anyway, plus they contain their own state)
          return;
        }

        if (type === WORKLOAD_TYPES.CRON_JOB) {
          this.$fetchType(WORKLOAD_TYPES.JOB);
        } else {
          this.$fetchType(POD);
        }
      }
    },
  },

  typeDisplay() {
    const { params: { resource: type } } = this.$route;
    let paramSchema = schema;

    if (type !== schema.id) {
      paramSchema = this.$store.getters['cluster/schemaFor'](type);
    }

    return this.$store.getters['type-map/labelFor'](paramSchema, 99);
  },
};
</script>

<template>
  <ResourceTable
    :loading="$fetchState.pending"
    :schema="schema"
    :rows="filteredRows"
    :overflow-y="true"
    :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
    :force-update-live-and-delayed="forceUpdateLiveAndDelayed"
    :get-custom-detail-link="getCustomDetailLink"
  />
</template>
