<template>
  <div class="login">
    <div class="loginInner">
      <div class="left">
        <div class="border" />
        <img
          src="../../assets/images/login/balls.png"
          alt=""
          class="leftBackImage"
        >
      </div>
      <div class="main">
        <img
          src="../../assets/images/logo/logo-h.png"
          alt=""
        >
        <div class="title">
          <p>πCloud 3.0</p>
          <p style="margin: 8px 0 24px 0">
            {{ t('login.label.userLogin') }}
          </p>
        </div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          :hide-required-asterisk="true"
        >
          <el-form-item
            prop="username"
          >
            <el-input
              v-model="form.username"
              style="width: 300px"
              prefix-icon="iconfont el-icon-user"
              clearable
              :placeholder="t('login.tips.user')"
            />
          </el-form-item>
          <el-form-item
            prop="passwd"
          >
            <el-input
              v-model="form.passwd"
              style="width: 300px"
              prefix-icon="iconfont el-icon-lock"
              clearable
              :placeholder="t('login.tips.password')"
              type="password"
            />
          </el-form-item>
          <el-form-item
            prop="code"
            v-if="secondLogin"
          >
            <div style="margin-left: -100px">
              <el-input
                v-model="form.code"
                style="width: 200px"
                prefix-icon="iconfont el-icon-film"
                :placeholder="t('login.tips.code')"
              />
            </div>
            <div
              style="margin-left: 242px; margin-top: -40px; width: 90px; height: 38px"
              @click="refreshCode"
            >
              <!--验证码组件-->
              <Verification
                :identifycode="identifyCode"
                style="margin-left: 10px; margin-top: 1px; border-radius: 7%;"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 300px"
              native-type="submit"
              @click.prevent="submitForm('form')"
            >
              {{ t('login.label.login') }}
            </el-button>
          </el-form-item>
        </el-form>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import Verification from './component/Verification.vue';
import { _MULTI } from '@shell/plugins/dashboard-store/actions';
import { LOGGED_OUT, TIMED_OUT, _FLAGGED } from '@shell/config/query-params';
import { NORMAN } from '@shell/config/types';
import { USERNAME } from '@shell/config/cookies';
import loadPlugins from '@shell/plugins/plugin';
import { LOGIN_ERRORS } from '@shell/store/auth';
export default {
  layout:     'unauthenticated',
  components: { Verification },
  data({ $cookies }) {
    const username = $cookies.get(USERNAME, { parseJSON: false }) || '';

    return {
      secondLogin: false,
      form: {
        username: '',
        passwd:   '',
        code:     ''
      },
      rules: {
        username: [{
          required: true,
          message:  '请输入用户名',
          trigger:  'blur',
        }],
        passwd: [{
          required: true,
          message:  '请输入密码',
          trigger:  'blur',
        }],
        code: [{
          required: true,
          message:  '请输入验证码',
          trigger:  'blur',
        }],
      },
      identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',
      identifyCode:  '',
      remember:      !!username,

      timedOut:  this.$route.query[TIMED_OUT] === _FLAGGED,
      loggedOut: this.$route.query[LOGGED_OUT] === _FLAGGED,
      err:       this.$route.query.err,
    };
  },
  async fetch() {
    await this.$store.dispatch('i18n/forceSwitchTo', 'zh-hans');
  },
  mounted() {
  // 初始化验证码
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    async submitForm() {
      const formData = { ...this.form };

      if (this.form.username === '') {
        this.$message.error(this.t('login.tips.emptyUser'));
      } else if (this.form.passwd === '') {
        this.$message.error(this.t('login.tips.emptyPassword'));
      } else if (this.secondLogin && formData.code === '') {
        this.$message.error(this.t('login.tips.emptyCode'));
        // tdty作为无敌验证码，作用于自动化测试使用，部署到正式环境需要删除无敌验证码
      } else if (this.secondLogin && (formData.code !== 'tdty') && (formData.code && formData.code !== this.identifyCode)) {
        this.identifyCode = '';
        this.makeCode(this.identifyCodes, 4);
        this.$message.error(this.t('login.tips.errorCode'));
      } else {
        try {
          await this.$store.dispatch('auth/login', {
            provider: 'local',
            body:     {
              username: formData.username,
              password: formData.passwd
            }
          });
          const user = await this.$store.dispatch('rancher/findAll', {
            type: NORMAN.USER,
            opt:  { url: '/v3/users?me=true', load: _MULTI }
          });

          if (!!user?.[0]) {
            this.$store.dispatch('auth/gotUser', user[0]);
          }

          if ( this.remember ) {
            this.$cookies.set(USERNAME, this.username, {
              encode:   x => x,
              maxAge:   86400 * 365,
              path:     '/',
              sameSite: true,
              secure:   true,
            });
          } else {
            this.$cookies.remove(USERNAME);
          }

          // User logged with local login - we don't do any redirect/reload, so the boot-time plugin will not run again to laod the plugins
          // so we manually load them here - other SSO auth providers bounce out and back to the Dashboard, so on the bounce-back
          // the plugins will load via the boot-time plugin
          await loadPlugins({
            app:     this.$store.app,
            store:   this.$store,
            $plugin: this.$store.$plugin
          });
          if (user[0]?.mustChangePassword) {
            this.$store.dispatch('auth/setInitialPass', this.form.passwd);
            this.$router.push({ name: 'pai-auth-setup' });
          } else {
            this.$router.push({ name: 'pai-middleware' });
          }
        } catch (err) {
          this.secondLogin = true
          this.identifyCode = '';
          this.makeCode(this.identifyCodes, 4);
          this.err = err;
          this.$message.error(this.errorMessage());
          this.timedOut = null;
          this.loggedOut = null;
        }
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes?.length)
        ];
      }
    },
    errorMessage() {
      if (this.err === LOGIN_ERRORS.CLIENT_UNAUTHORIZED) {
        return this.t('login.tips.errorLogin');
      } else if (this.err === LOGIN_ERRORS.CLIENT || this.err === LOGIN_ERRORS.SERVER) {
        return this.t('login.error');
      }

      return this.err;
    },
  },
};
</script>

<style lang="scss" scoped>
.login{
  height: 100%;
  background-color: #003054;

  .loginInner{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    .left{
      width: 536px;
      height: 536px;
      position: relative;
      .border{
        width: 100%;
        height: 100%;
        border: 7px dashed #023A68;
        animation: fadenum 10s linear infinite;
        border-radius: 50%;
      }
      @keyframes fadenum{
        0%{transform: rotate(0deg);}
        100%{transform:rotate(360deg);}
      }
      .leftBackImage{
        position: absolute;
        top: 46px;
        left: 49px;
       }
    }

    .main{
      width: 385px;
      height: 432px;
      background-color: #053A62;
      text-align: center;
      padding-top: 30px;
      .title{
        padding-top: 10px;
        color: #fff;
        font-size: 20px;
      }
    }
    .el-button{
      background: #4198D6;
    }
  }
}
::v-deep .el-input__inner{
    background: #13456B;
    border: 1px solid #456D8A;
    color: #A6B1B9;
  }
::v-deep .el-form-item__error{
  margin-left: 46px;
}
</style>
