import { NAME, NODE, OBJECT } from '@shell/config/table-headers';
import { PAI_POD, PAI_NODE, PAI_ANNOTATIONS } from './types';
import { ALIAS } from '@/pkg/pai/config/labels-annotations';

export const PAI_POD_NAME_COL = {
  ...NAME,
  formatter:     'NamespaceLinkName',
  formatterOpts: {
    type:    PAI_POD,
    product: 'pai',
  },
};

export const PAI_ALIAS_NAME_COL = {
  name:          'alias',
  labelKey:      'tableHeaders.name',
  value:         `$['metadata']['annotations']['${ ALIAS }']`,
  getValue:      row => row.metadata?.annotations?.[ALIAS],
  sort:          ['aliasSort'],
  formatter:     'LinkDetail',
  canBeVariable: true,
};

export const PAI_ALIAS_COL = {
  name:          'alias',
  labelKey:      'tableHeaders.alias',
  value:         `$['metadata']['annotations']['${ ALIAS }']`,
  getValue:      row => row.metadata?.annotations?.[ALIAS],
  sort:          ['aliasSort'],
  formatter:     'LinkDetail',
  canBeVariable: true,
};
export const PAI_NODE_COL = {
  ...NODE,
  formatter:     'NodeLinkName',
  formatterOpts: { type: PAI_NODE },
};
export const PAI_OBJECT_COL = {
  ...OBJECT,
  formatter: 'PaiInvolvedObjectLink',
};

export const PAI_PROJECT_COL = {
  name:        'project',
  labelKey:    'pai.vmset.project',
  value:       'projectDisplayName',
  sort:        ['projectDisplayName'],
  dashIfEmpty: true,
};
export const PODS = {
  name:      'pods',
  labelKey:  'pai.detail.vmset.vm&container',
  sort:      'podConsumed',
  search:    false,
  value:     row => row.podConsumedUsage,
  formatter: 'PercentageBar',
  width:     120,
};
export const PAI_AUTH_COL = {
  name:      'auth',
  labelKey:  'pai.list.sc.auth',
  value:     `$['metadata']['annotations']['${ PAI_ANNOTATIONS.NAMESPACES }']`,
  sort:      `$['metadata']['annotations']['${ PAI_ANNOTATIONS.NAMESPACES }']`,
  formatter: 'AuthNs',
};
