var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overview"},[_c('div',{staticClass:"cluster-chart"},[_c('ClusterChart',{attrs:{"rows":_vm.clusters}})],1),_vm._v(" "),_c('div',{staticClass:"cluster-tabs"},[_c('el-tabs',{attrs:{"value":"cluster"}},[_c('el-tab-pane',{attrs:{"label":((_vm.t('pai.overview.cluster')) + " (" + (_vm.kubeClusters.length) + ")"),"name":"cluster"}},[_c('ClusterList',{attrs:{"key-field":"id","rows":_vm.kubeClusters,"headers":_vm.clusterHeaders,"loading":!_vm.kubeClusters},scopedSlots:_vm._u([{key:"col:name",fn:function(ref){
var row = ref.row;
return [_c('td',[_c('div',{staticClass:"list-cluster-name"},[(row.isReady&& row.state==='active')?_c('span',[_c('n-link',{attrs:{"to":{ name: 'pai-c-cluster-overview', params: { cluster: row.id, product: _vm.PAI }}}},[_vm._v("\n                    "+_vm._s(row.nameDisplay)+"\n                  ")])],1):_c('span',[_vm._v(_vm._s(row.nameDisplay))]),_vm._v(" "),(row.unavailableMachines)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(row.unavailableMachines),expression:"row.unavailableMachines"}],staticClass:"conditions-alert-icon icon-alert icon"}):_vm._e()])])]}},{key:"col:cpu",fn:function(ref){
var row = ref.row;
return [(_vm.cpuAllocatable(row))?_c('td',[_vm._v("\n              "+_vm._s(((_vm.cpuAllocatable(row)) + " " + (_vm.t('landing.clusters.cores', {count:_vm.cpuAllocatable(row) }))))+"\n            ")]):_c('td',[_vm._v("\n              -\n            ")])]}},{key:"col:memory",fn:function(ref){
var row = ref.row;
return [( _vm.memoryAllocatable(row) && !_vm.memoryAllocatable(row).match(/^0 [a-zA-z]/))?_c('td',[_vm._v("\n              "+_vm._s(_vm.memoryAllocatable(row))+"\n            ")]):_c('td',[_vm._v("\n              -\n            ")])]}},{key:"col:node",fn:function(ref){
var row = ref.row;
return [_c('td',[_vm._v(_vm._s(_vm.nodeCount(row)))])]}}])})],1)],1)],1),_vm._v(" "),_c('PromptRemove')],1)}
var staticRenderFns = []

export { render, staticRenderFns }