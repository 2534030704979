import PV from '@shell/models/persistentvolume';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
export const VOLUME_PLUGINS = [
  {
    labelKey:  'persistentVolume.hostPath.label',
    value:     'hostPath',
    supported: true
  },
  {
    labelKey:  'persistentVolume.local.label',
    value:     'local',
    supported: true
  },
  {
    labelKey:  'persistentVolume.longhorn.label',
    value:     'longhorn',
    supported: true
  },
  {
    labelKey:  'persistentVolume.nfs.label',
    value:     'nfs',
    supported: true
  },
];
export default class extends PaiLocationMixin(PV) {

}
