<script>
import * as echarts from 'echarts';
import { MANAGEMENT } from '@/shell/config/types';
import day from 'dayjs';
import { PAI_RESOURCES } from '../../../../../config/types';
import metricPoller from '@shell/mixins/metric-poller';
import { PVC } from '@shell/config/types';
import { PRODUCT_NAME, PRODUCT_NAME as PAI } from '../../../../../config/pai';

export default {
  layout: 'pai/default',
  async fetch() {
    // 集群
    const kubeClusters = await this.$store.dispatch('management/findAll', { type: MANAGEMENT.CLUSTER });
    const activeCluster = [];

    kubeClusters.forEach((item) => {
      if (item.state === 'active') {
        activeCluster.push(item);
      }
    });
    this.resourdeData[0].all = kubeClusters.length;
    this.resourdeData[0].active = activeCluster.length;

    activeCluster.map((item, index) => {
      if (item.id === 'local') {
        activeCluster.unshift(activeCluster.splice(index, 1)[0]);
      }
    });
    this.kubeClusters = kubeClusters;
    this.activeCluster = activeCluster;
    this.clustersVal = activeCluster[0].id;
    this.nodeCluster = activeCluster[0].id;
    this.netRateCluster = activeCluster[0].id;
    this.diskCluster = activeCluster[0].id;

    // 节点数据
    const nodeData = await this.$store.dispatch('management/findAll', { type: MANAGEMENT.NODE });
    const activeNode = [];

    nodeData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeNode.push(item);
      }
    });

    this.$set(this.resourdeData[1], 'all', nodeData.length);
    this.$set(this.resourdeData[1], 'active', activeNode.length);

    //  网络资源数据
    try {
      const internetData = await this.$store.dispatch('management/findAll', { type: PAI_RESOURCES.NAD });
      const activeInternet = [];

      internetData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeInternet.push(item);
        }
      });
      this.$set(this.resourdeData[2], 'all', internetData.length);
      this.$set(this.resourdeData[2], 'active', activeInternet.length);
    } catch (e) {
      console.log(e);
    }

    //  存储资源数据
    try {
      const diskData = await this.$store.dispatch('cluster/findAll', { type: PVC });
      const activeDisk = [];

      diskData.forEach((item) => {
        if (item.metadata.state.name === 'bound') {
          activeDisk.push(item);
        }
      });

      this.$set(this.resourdeData[3], 'all', diskData.length);
      this.$set(this.resourdeData[3], 'active', activeDisk.length);
    } catch (e) {
      console.log(e);
    }

    let cloudData = 0;
    const activeCloud = [];
    let podData = 0;
    let activePod = 0;
    let statefulsetData = 0;
    let activeStatefulset = 0;
    let deploymentData = 0;
    let activeDeployment = 0;
    let jobData = 0;
    let activeJob = 0;
    let cronjobData = 0;
    let activeCronjob = 0;
    let daemonsetData = 0;
    let activeDaemonset = 0;

    for (const item of activeCluster) {
      const countsReq = await this.$store.dispatch('management/request', { url: `/k8s/clusters/${ item.id }/v1/counts` });
      const countsResult = countsReq.data[0].counts;

      // 云主机
      try {
        const cloudReq = await this.$store.dispatch('management/request', { url: `/k8s/clusters/${ item.id }/v1/virt.liveit100.com.vmset` }).catch((err) => {
          new Error(err);
        });

        if (cloudReq !== undefined) {
          cloudData += cloudReq.count !== undefined ? cloudReq.count : 0;
          cloudReq?.data?.forEach((item) => {
            if (item.spec.power === 'On') {
              activeCloud.push(item) ;
            }
          });
        }
      } catch (e) {
        console.log(e);
      }

      // 容器组
      podData += countsResult['pod'] !== undefined ? countsResult['pod'].summary?.count : 0;
      if (countsResult['pod']?.summary?.states !== undefined) {
        const statesData = countsResult['pod'].summary?.states;

        for (const key in statesData) {
          activePod = podData - statesData[key];
        }
      } else {
        activePod = podData;
      }

      // 有状态负载
      statefulsetData += countsResult['apps.statefulset'].summary?.count !== undefined ? countsResult['apps.statefulset'].summary?.count : 0;
      if (countsResult['apps.statefulset'].summary?.states !== undefined) {
        const statesData = countsResult['apps.statefulset'].summary?.states;

        for (const key in statesData) {
          activeStatefulset = statefulsetData - statesData[key];
        }
      } else {
        activeStatefulset = statefulsetData;
      }

      // 无状态负载
      deploymentData += countsResult['apps.deployment'].summary?.count !== undefined ? countsResult['apps.deployment'].summary?.count : 0;
      if (countsResult['apps.deployment'].summary?.states !== undefined) {
        const statesData = countsResult['apps.deployment'].summary?.states;

        for (const key in statesData) {
          activeDeployment = deploymentData - statesData[key];
        }
      } else {
        activeDeployment = deploymentData;
      }

      // 普通任务
      jobData += countsResult['batch.job'].summary?.count !== undefined ? countsResult['batch.job'].summary?.count : 0;
      if (countsResult['batch.job'].summary?.states !== undefined) {
        const statesData = countsResult['batch.job'].summary?.states;

        for (const key in statesData) {
          activeJob = jobData - statesData[key];
        }
      } else {
        activeJob = jobData;
      }

      // 定时任务
      cronjobData += countsResult['batch.cronjob'].summary?.count !== undefined ? countsResult['batch.cronjob'].summary?.count : 0;
      if (countsResult['batch.cronjob'].summary?.states !== undefined) {
        const statesData = countsResult['batch.cronjob'].summary?.states;

        for (const key in statesData) {
          activeCronjob = cronjobData - statesData[key];
        }
      } else {
        activeCronjob = cronjobData;
      }

      //  守护进程集
      daemonsetData += countsResult['apps.daemonset'].summary?.count !== undefined ? countsResult['apps.daemonset'].summary?.count : 0;
      if (countsResult['apps.daemonset'].summary?.states !== undefined) {
        const statesData = countsResult['apps.daemonset'].summary?.states;

        for (const key in statesData) {
          activeDaemonset = daemonsetData - statesData[key];
        }
      } else {
        activeDaemonset = daemonsetData;
      }
    }
    this.$set(this.taskData[0], 'all', cloudData);
    this.$set(this.taskData[0], 'active', activeCloud.length);
    this.$set(this.taskData[1], 'all', podData);
    this.$set(this.taskData[1], 'active', activePod);
    this.$set(this.taskData[2], 'all', statefulsetData);
    this.$set(this.taskData[2], 'active', activeStatefulset);
    this.$set(this.taskData[3], 'all', deploymentData);
    this.$set(this.taskData[3], 'active', activeDeployment);
    this.$set(this.taskData[4], 'all', jobData);
    this.$set(this.taskData[4], 'active', activeJob);
    this.$set(this.taskData[5], 'all', cronjobData);
    this.$set(this.taskData[5], 'active', activeCronjob);
    this.$set(this.taskData[6], 'all', daemonsetData);
    this.$set(this.taskData[6], 'active', activeDaemonset);
    this.loadMetrics();
  },
  computed: {
    netLine() {
      return this.$store.state['pai-grafana'].netLineForm;
    },
    diskIOPSData() {
      return this.$store.state['pai-grafana'].diskIOPSDataForm;
    },
  },
  mixins: [metricPoller],

  watch: {
    netLine() {
      this.getNetLineRate();
    },
    diskIOPSData() {
      this.getDiskIOPSRate();
    },
  },

  data() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return {
      timeFormat:   'HH:MM',
      startTimeDay: Math.round((new Date().setDate(new Date().getDate() - 15)) / 1000).toString(), // 15天前
      startTime:    Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(), // 1小时前的10位时间戳
      endTime:      new Date(String(day().format(dateFormat))).getTime() / 1000, // 系统时间10位时间戳
      clusterValue: '01',
      nodeValue:    '01',
      useGrid:      {
        top:          '30px',
        left:         '2.5%',
        right:        '2%',
        bottom:       '3%',
        containLabel: true
      },
      activeCluster: [],
      colors:        ['#0076F7', '#F29B69', '#D95040', '#F2BD42', '#76C1C9', '#E99D42', '#DC6153'],
      resourdeData:  [
        {
          title:  this.t('pai.overview.cluster'),
          active: 0,
          all:    0,
          path:   require('../images/cluster.png'),
          code:   'cluster',
        }, {
          title:  this.t('pai.overview.node'),
          active: 0,
          all:    0,
          path:   require( '../images/node.png'),
          code:   'node',
        }, {
          title:  this.t('pai.menu.k8s.cni.cncf.io.networkattachmentdefinition'),
          active: 0,
          all:    0,
          path:   require('../images/internet.png'),
          code:   'netWork',
        }, {
          title:  this.t('pai.menu.k8s.cni.cncf.io.storageattachmentdefinition'),
          active: 0,
          all:    0,
          path:   require('../images/storage.png'),
          code:   'disk',
        },
      ],
      taskData: [
        {
          title:  this.t('pai.menu.vmset'),
          all:    0,
          active: 0,
          path:   require('../images/cluster.png'),
          status: `${ this.t('pai.detail.vmset.On') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'cloud'
        }, {
          title:  this.t('pai.menu.pod'),
          all:    0,
          active: 0,
          path:   require( '../images/node.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'container'
        }, {
          title:  this.t('pai.menu.apps.statefulLoad'),
          all:    0,
          active: 0,
          path:   require('../images/internet.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'state'
        }, {
          title:  this.t('pai.menu.apps.statelessLoad'),
          all:    0,
          active: 0,
          path:   require('../images/storage.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'stateNo'
        }, {
          title:  this.t('pai.menu.batch.job'),
          all:    0,
          active: 0,
          path:   require('../images/cluster.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'task'
        }, {
          title:  this.t('pai.menu.batch.cronjob'),
          all:    0,
          active: 0,
          path:   require( '../images/node.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'taskD'
        }, {
          title:  this.t('pai.menu.apps.daemonset'),
          all:    0,
          active: 0,
          path:   require('../images/internet.png'),
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'course'
        },
      ],
      options: [
        {
          name:  this.t(`pai.detail.vmset.cpuUtilization`),
          value: '01'
        },
        {
          name:  this.t('pai.detail.vmset.ramUtilization'),
          value: '02'
        },
        {
          name:  this.t('pai.detail.vmset.tab.overview.diskPercentage'),
          value: '03'
        },
      ],
      timeData:       [],
      timeDataNode:   [],
      clustersVal:    '',
      nodeCluster:    '',
      netRateCluster: '',
      diskCluster:    '',
      kubeClusters:   [],
      fiveMinutes:    300, // 5分钟间隔
      clusterMonitor: false,
      nodeMonitor:    false,
      netMonitor:     false,
      iopsMonitor:    false
    };
  },
  created() {
    this.getNetRateWork();
    this.getDiskWork();
  },

  methods: {
    async loadMetrics() {
      if (this.clusterValue === '01') {
        await this.getCPUUtil();
      } else if (this.clusterValue === '02') {
        await this.getMemoryUtil();
      } else if (this.clusterValue === '03') {
        await this.getDiskUtil();
      }
      if (this.nodeValue === '01') {
        await this.getCPUNode();
      } else if (this.nodeValue === '02') {
        await this.getMemoryNode();
      } else if (this.nodeValue === '03') {
        await this.getDiskNode();
      }
      await this.getNetRateWork();
      await this.getDiskWork();
    },
    getCLusterName(id) {
      let clusterName = '';

      this.activeCluster.filter((item) => {
        if (item.id === id) {
          clusterName = item.nameDisplay;
        }
      });

      return clusterName;
    },
    // 集群利用率切换
    clusterValChange(val) {
      this.clustersVal = val;
      this.$nextTick(() => {
        if (this.clusterValue === '01') {
          this.getCPUUtil();
        } else if (this.clusterValue === '02') {
          this.getMemoryUtil();
        } else if (this.clusterValue === '03') {
          this.getDiskUtil();
        }
      });
    },
    //  集群select切换
    clusterChange(val) {
      this.clusterValue = val;
      this.$nextTick(() => {
        if (val === '01') {
          this.getCPUUtil();
        } else if (val === '02') {
          this.getMemoryUtil();
        } else if (val === '03') {
          this.getDiskUtil();
        }
      });
    },

    // 节点集群切换
    nodeClusterChange(val) {
      this.nodeCluster = val;
      this.$nextTick(() => {
        if (this.nodeValue === '01') {
          this.getCPUNode();
        } else if (this.nodeValue === '02') {
          this.getMemoryNode();
        } else if (this.nodeValue === '03') {
          this.getDiskNode();
        }
      });
    },
    // 节点
    nodeChange(val) {
      this.nodeValue = val;
      this.$nextTick(() => {
        if (val === '01') {
          this.getCPUNode();
        } else if (val === '02') {
          this.getMemoryNode();
        } else if (val === '03') {
          this.getDiskNode();
        }
      });
    },

    // 网络吞吐量集群切换
    netRateClusterChange(val) {
      this.netRateCluster = val;
      this.$nextTick(() => {
        this.getNetRateWork();
      });
    },
    // 磁盘集群切换
    diskClusterChange(val) {
      this.diskCluster = val;
      this.$nextTick(() => {
        this.getDiskWork();
      });
    },
    // 集群使用率
    getClusterUseChart(data) {
      if (!this.$refs.clusterUseChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.clusterUseChart);
      const seriesArr = [];
      const colors = [];
      const Xdata = this.timeData;
      let option = {};

      myChart.clear();

      if (data?.length !== 0) {
        data.forEach((item, index) => {
          colors[index] = this.colors[index];
          seriesArr.push({
            name: item.name,
            type: 'line',
            data: item.num,
          });
        });

        option = {
          animation: false,
          color:     colors,
          grid:      this.useGrid,
          tooltip:   {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `
               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        Xdata
          },
          yAxis: {
            type:    'value',
            name:    '单位：%',
            nameGap: '10',
            max:     100,
          },
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // 节点使用率
    getNodeUseChart(data) {
      if (!this.$refs.nodeUseChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.nodeUseChart);
      const seriesArr = [];
      const colors = [];
      const Xdata = this.timeDataNode;
      let option = {};

      myChart.clear();

      if ( data?.length !== 0) {
        data.forEach((item, index) => {
          colors[index] = this.colors[index];
          seriesArr.push({
            name: item.name,
            type: 'line',
            data: item.num,
          });
        });

        option = {
          animation: false,
          color:     colors,
          grid:      this.useGrid,
          tooltip:   {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `

               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        Xdata
          },
          yAxis: {
            type:    'value',
            name:    '单位：%',
            nameGap: '10',
            max:     100,
          },
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option, true);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // CPU利用率数据
    async getCPUUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];

      const params = { clusterName: this.getCLusterName(this.clustersVal), url: `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate({__name__=~"node_cpu_seconds_total",mode="idle"}[240s])))*100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }` };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404 ) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    // 内存利用率数据
    async getMemoryUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const params = { clusterName: this.getCLusterName(this.clustersVal), url: `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - sum(node_memory_MemAvailable_bytes OR windows_os_physical_memory_free_bytes) / sum(node_memory_MemTotal_bytes OR windows_cs_physical_memory_bytes))*100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }` };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404 ) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    // 磁盘利用率数据
    async getDiskUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const params = { clusterName: this.getCLusterName(this.clustersVal), url: `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes) by (device, instance)))) / ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes{device=~"data/.*|longhorn/.*"}) by (device, instance) or vector(0)))) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }` };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404 ) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    //  节点CPU使用率
    async getCPUNode() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      let ipsResult = {};
      let ips = {};
      const newArr = [];

      const paramNode = {
        showGrowl: false, clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const arrNode = await this.$store.dispatch('pai-common/getUse', paramNode).catch((err) => {
        if (err.response.status === 404 ) {
          this.nodeMonitor = true;
          this.getNodeUseChart(newArr);
        } else {
          this.nodeMonitor = false;
          this.getNodeUseChart(newArr);
        }
      });

      ipsResult = arrNode?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }
      const param = { clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate(node_cpu_seconds_total{mode="idle",job="node-exporter"}[5m])) by (instance)) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }` };
      const result = await this.$store.dispatch('pai-common/getUse', param);

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: ips[item.metric.instance.split(':')[0]],
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeDataNode = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getNodeUseChart(newArr);
    },

    //  节点内存使用率
    async getMemoryNode() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      let ipsResult = {};
      let ips = {};

      const paramNode = {
        showGrowl: false, clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arrNode = await this.$store.dispatch('pai-common/getUse', paramNode).catch((err) => {
        if (err.response.status === 404 ) {
          this.nodeMonitor = true;
          this.getNodeUseChart(newArr);
        } else {
          this.nodeMonitor = false;
          this.getNodeUseChart(newArr);
        }
      });

      ipsResult = arrNode?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }
      const param = {
        showGrowl: false, clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(sum(node_memory_MemTotal_bytes{job="node-exporter"})  by (instance) - sum(node_memory_MemAvailable_bytes)  by (instance)) / sum(node_memory_MemTotal_bytes)  by (instance) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', param);

      const datas = result?.data?.data?.result;

      datas?.map((item ) => {
        newArr.push(
          {
            name: ips[item.metric.instance.split(':')[0]],
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeDataNode = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getNodeUseChart(newArr);
    },

    //  节点磁盘使用率
    async getDiskNode() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      let ipsResult = {};
      let ips = {};
      let usedList = [];
      let list = [];

      // 获取节点
      const param1 = {
        showGrowl: false, clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arr1 = await this.$store.dispatch('pai-common/getUse', param1).catch((err) => {
        if (err.response.status === 404 ) {
          this.nodeMonitor = true;
          this.getNodeUseChart(list);
        } else {
          this.nodeMonitor = false;
          this.getNodeUseChart(list);
        }
      });

      ipsResult = arr1?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }

      const param2 = {
        showGrowl: false, clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes{job="node-exporter"}) by (device, instance)) by (instance) - sum(avg(node_filesystem_avail_bytes{fstype="zfs",device=~"data/.*|longhorn/.*"}) by (device, instance) or up * 0) by (instance))/1024/1024/1024)&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arr2 = await this.$store.dispatch('pai-common/getUse', param2);
      const totalData = arr2?.data;

      const param3 = { clusterName: this.getCLusterName(this.nodeCluster), url: `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes{job="node-exporter"}) by (device, instance)) by (instance) - sum(avg(node_filesystem_avail_bytes) by (device, instance)) by (instance))/1024/1024/1024)&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }` };

      const arr3 = await this.$store.dispatch('pai-common/getUse', param3);
      const usedData = arr3?.data;

      if (usedData?.data?.result?.length !== 0) {
        usedList = usedData?.data?.result?.map((v) => {
          const name = ips[v.metric.instance.split(':')[0]];

          return ({
            name,
            nodeIP: v.metric.instance.split(':')[0],
            value:  v.values
          });
        });
      }

      if (totalData?.data?.result?.length !== 0) {
        const storageNode = totalData?.data?.result?.filter((v, i) => v?.metric?.instance)?.map((v, i) => {
          const nodeName = ips[v.metric.instance.split(':')[0]];
          const used = usedList.filter(v => v.name === nodeName);

          const timeArr = used[0]?.value.map((time, index) => {
            const now = new Date(time[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
          const percent = used[0]?.value.map((use, index) => {
            return parseFloat((use[1] / v.values[index][1] * 100).toFixed(2));
          });

          return {
            name: nodeName,
            time: timeArr,
            num:  percent,
          };
        });

        list = storageNode?.map((v, i) => {
          return { ...v };
        });
      }
      this.getNodeUseChart(list);
    },

    // 模块对应路由跳转
    handleRoute(val) {
      if (val === 'cluster') {
        this.$router.push({ name: 'pai-overview' });
      }
    },
    // 网络吞吐量/磁盘请求次数
    getNetRateWork() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳

      this.$store.dispatch('pai-grafana/getNetLinePlatForm', {
        cluster: this.netRateCluster,
        step:    15,
        startTime,
        endTime
      });
    },
    getDiskWork() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳

      this.$store.dispatch('pai-grafana/getDiskIOPSPlatForm', {
        cluster: this.diskCluster,
        step:    15,
        startTime,
        endTime
      });
    },
    getNetLineRate() {
      if (!this.$refs.netRate) {
        return;
      }
      const myChart = echarts.init(this.$refs.netRate);
      let option = {};

      this.netMonitor = false;

      myChart.clear();

      if (this.netLine.length !== 0 && this.netLine !== 404) {
        option = {
          animation: false,
          grid:      this.useGrid,
          legend:    {
            type:              'scroll',
            pageButtonItemGap: 10,
            data:              [],
            itemWidth:         15,
            top:               10,
            left:              'center'
          },
          tooltip: {
            trigger:  'axis',
            position: 'left',
            formatter(params) {
              return `${ params[0].axisValueLabel }<br>${
                params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }M</span>` + `<br>${
                params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }M</span>`;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        this.netLine[0]?.nodeValuesDate,
          },
          yAxis: {
            type:    'value',
            name:    '单位：M',
            nameGap: '10'
          },
          series: [
            {
              type:      'line',
              name:      this.netLine[0]?.node,
              smooth:    true,
              symbol:    this.netLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.netLine[0]?.nodeValuesData,
              itemStyle: { color: '#4097D5' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  '#4097D5'
                  },
                  {
                    offset: 1,
                    color:  '#DBE4EF'
                  }
                ])
              },
            },
            {
              type:      'line',
              name:      this.netLine[1]?.node,
              smooth:    true,
              symbol:    this.netLine[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.netLine[1]?.nodeValuesData,
              itemStyle: { color: '#C75250' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  '#C75250'
                  },
                  {
                    offset: 1,
                    color:  '#F7ECEC'
                  }
                ])
              },
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
        if (this.netLine === 404 ) {
          this.netMonitor = true;
        }
      }

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getDiskIOPSRate() {
      if (!this.$refs.diskIOPS) {
        return;
      }
      const myChart = echarts.init(this.$refs.diskIOPS);
      let option = {};

      this.iopsMonitor = false;

      myChart.clear();

      if (this.diskIOPSData.length !== 0 && this.diskIOPSData !== 404 ) {
        option = {
          animation: false,
          grid:      this.useGrid,
          legend:    {
            type:              'scroll',
            pageButtonItemGap: 10,
            data:              [],
            itemWidth:         15,
            top:               10,
            left:              'center'
          },
          tooltip: {
            trigger:  'axis',
            position: 'left',
            formatter(params) {
              return `${ params[0].axisValueLabel }<br>${
                params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }(次/s）</span>` + `<br>${
                params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }(次/s）</span>`;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        this.diskIOPSData[0]?.nodeValuesDate,
          },
          yAxis: {
            type:    'value',
            name:    '单位：(次/s)',
            nameGap: '10'
          },
          series: [
            {
              type:      'line',
              name:      this.diskIOPSData[0]?.node,
              smooth:    true,
              symbol:    this.diskIOPSData[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.diskIOPSData[0]?.nodeValuesData,
              itemStyle: { color: 'rgba(189,183,107,0.7)' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  'rgba(189,183,107,0.7)'
                  },
                  {
                    offset: 1,
                    color:  'rgba(189,183,107,0.1)'
                  }
                ])
              },
            },
            {
              type:      'line',
              name:      this.diskIOPSData[1]?.node,
              smooth:    true,
              symbol:    this.diskIOPSData[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.diskIOPSData[1]?.nodeValuesData,
              itemStyle: { color: 'rgba(32,178,170,0.7)' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  'rgba(32,178,170,0.7)'
                  },
                  {
                    offset: 1,
                    color:  'rgba(32,178,170,0.1)'
                  }
                ])
              },
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
        if (this.diskIOPSData === 404 ) {
          this.iopsMonitor = true;
        }
      }

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    monitorLink(cluster) {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-chart`,
        params: {
          cluster,
          product: PRODUCT_NAME,
        },
        query: {
          'repo-type': 'cluster', repo: 'rancher-charts', chart: 'rancher-monitoring'
        }
      });
    }
  }
};
</script>
<template>
  <div class="platform-overview">
    <div class="platform-overview-main">
      <div class="main-resource flex">
        <div
          v-for="(item,index) in resourdeData"
          :key="index"
          class="main-resourceCard flex"
          :style="{ cursor:item.code == 'cluster' ? 'pointer':'default' }"
          @click="handleRoute(item.code)"
        >
          <div class="main-resourceCard-left">
            <div class="img-bg">
              <img
                :src="item.path"
                :alt="item.title"
              >
            </div>
          </div>

          <div class="main-resourceCard-right">
            <div class="box-title">
              {{ item.title }}
            </div>
            <div class="flex">
              <div class="box-num">
                <span class="box-numBig">{{ item.active }}</span>/<span class="box-numMini">{{ item.all }}</span>
              </div>
              <div class="box-text">
                {{ t('pai.detail.vmset.normal') + '/' + t('pai.detail.vmset.all') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-task flex">
        <div
          v-for="(item,index) in taskData"
          :key="index"
          class="main-taskCard"
        >
          <div class="main-taskCard-top">
            <span class="img-bgMini">
              <img
                src="../../overview/images/module-icon.png"
                :alt="item.title"
              >
            </span>
            <span
              class="box-title"
              style="margin-left: 6px"
            >
              {{ item.title }}
            </span>
          </div>

          <div class="main-resourceCard-bottom">
            <div class="flex">
              <div class="box-num">
                <span class="box-numBig">{{ item.active }}</span>/<span class="box-numMini">{{ item.all }}</span>
              </div>
              <div class="box-text">
                {{ item.status }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-useChart flex">
        <div class="main-useChart-left">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.clusterUsage') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <div>
              <el-select
                v-model="clustersVal"
                class="mySelect"
                placeholder="选择集群"
                filterable
                @change="clusterValChange"
              >
                <el-option
                  v-for="(item, index) in kubeClusters"
                  :key="index"
                  :label="item.nameDisplay"
                  :value="item.id"
                  :disabled="item.metadata.state.name !== 'active'"
                />
              </el-select>
              <el-select
                v-model="clusterValue"
                placeholder="请选择"
                filterable
                @change="clusterChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div
            ref="clusterUseChart"
            style="width: 100%;height: 200px"
          />
          <div
            v-if="clusterMonitor === true"
            class="chartMonitor"
            @click="monitorLink(clustersVal)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
        <div class="main-useChart-right">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.nodeUsage') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <div class="select-box">
              <el-select
                v-model="nodeCluster"
                class="mySelect"
                placeholder="选择集群"
                filterable
                @change="nodeClusterChange"
              >
                <el-option
                  v-for="(item, index) in kubeClusters"
                  :key="index"
                  :label="item.nameDisplay"
                  :value="item.id"
                  :disabled="item.metadata.state.name !== 'active'"
                />
              </el-select>
              <el-select
                v-model="nodeValue"
                placeholder="请选择"
                filterable
                @change="nodeChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div
            ref="nodeUseChart"
            style="width: 100%;height: 200px"
          />
          <div
            v-if="nodeMonitor === true"
            class="chartMonitor"
            @click="monitorLink(nodeCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
      </div>
      <div
        class="main-useChart flex"
        style="margin-top: 14px"
      >
        <div class="main-useChart-left">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.tab.monitor.netRate') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <el-select
              v-model="netRateCluster"
              class="mySelect"
              placeholder="选择集群"
              filterable
              @change="netRateClusterChange"
            >
              <el-option
                v-for="(item, index) in kubeClusters"
                :key="index"
                :label="item.nameDisplay"
                :value="item.id"
                :disabled="item.metadata.state.name !== 'active'"
              />
            </el-select>
          </div>
          <div
            ref="netRate"
            style="width: 100%;height: 200px"
          />
          <div
            v-if="netMonitor"
            class="chartMonitor"
            @click="monitorLink(netRateCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
        <div class="main-useChart-right">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.tab.overview.diskRequest') + 'IOPS' +'（'+ t( 'pai.detail.vmset.tab.monitor.anHour' )+'）' }}</div>
            <el-select
              v-model="diskCluster"
              class="mySelect"
              placeholder="选择集群"
              filterable
              @change="diskClusterChange"
            >
              <el-option
                v-for="(item, index) in kubeClusters"
                :key="index"
                :label="item.nameDisplay"
                :value="item.id"
                :disabled="item.metadata.state.name !== 'active'"
              />
            </el-select>
          </div>
          <div
            ref="diskIOPS"
            style="width: 100%;height: 200px"
          />
          <div
            v-if="iopsMonitor"
            class="chartMonitor"
            @click="monitorLink(diskCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.platform-overview {
  .el-input__inner {
    height: 26px;
    line-height: 26px;
  }
  .el-input__icon {
    line-height: 26px;
  }
}
</style>

<style lang="scss" scoped>
.platform-overview{
  .platform-overview-title{
    margin: 10px 0px;
  }
  .platform-overview-main{
    padding: 1px 10px 10px 10px;
    background-color: #F5F4F6;
    .main-resource{
      margin: 14px 0px;
      .main-resourceCard{
        width: 25%;
        background-color: #FFFFFF;
        padding: 20px;
        margin-right: 16px;
        cursor: pointer;
        .main-resourceCard-left{
          width: 30%;
          .img-bg{
            width: 63px;
            height: 60px;
            line-height: 84px;
            text-align: center;
            border-radius: 50%;
            background-color: #F0F2F5;
          }
          img{
            width: 35px;
            height: 35px;
          }
        }
        .main-resourceCard-right {
          width: 65%;
        }
      }
      .main-resourceCard:last-child{
        margin-right: 0px;
      }
    }
    .main-task {
      background-color: #FFFFFF;
      padding: 14px 20px;
      margin: 14px 0px;
      .main-taskCard{
        width: 14%;
        padding: 10px 15px;
        border-right: 1px solid #BBBBBB;
        .main-taskCard-top {
          .img-bgMini{
            display: inline-block;
            width: 26px;
            height: 26px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background-color: #F0F2F5;
          }
          img{
            width: 16px;
            height: 16px;
          }
        }
        .main-resourceCard-bottom{
          margin-top: 10px;
        }
      }
      .main-taskCard:last-child{
        border-right: none;
      }
    }
    .main-useChart{
      .main-useChart-left, .main-useChart-right{
        width: 50%;
        background-color: #FFFFFF;
        padding: 12px 16px;
      }
    }
    .main-nodeChart {
      background-color: #FFFFFF;
      margin: 14px 0px 0px 0px;
      padding: 12px 16px;
    }
  }
  .box-title {
    margin-bottom: 16px;
    font-weight: 600;
    margin-top: 6px;
  }
  .box-num {
    .box-numBig{
      font-size: 18px;
      color: #4AC58F;
      font-weight: 600;
    }
  }
  .box-text {
    margin-top: 4px;
  }
  .text-grey {
    color: #8D8E90;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .chartMonitor {
    font-size: 16px;
    position: relative;
    top: -44%;
    left: 55%;
    a{
      cursor: pointer;
    }
  }
}
</style>
