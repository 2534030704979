<script>
import { Card } from '@components/Card';
import { Banner } from '@components/Banner';
export default {
  name:       'PowerOffDialog',
  components: { Card, Banner },

  props: {
    resource: {
      type:     Object,
      required: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    confirm() {
      this.resource.powerOff();
      this.close();
    },
  },
};
</script>

<template>
  <Card
    class="prompt-rotate"
    :show-highlight-border="false"
  >
    <h4
      slot="title"
      class="text-default-text"
      v-html="t('dialog.powerOffDialog.title')"
    />
    <template #body>
      <Banner
        color="warning"
        :label="t('dialog.powerOffDialog.body')"
        class="mt-20"
      />
    </template>
    <div
      slot="actions"
      class="buttons"
    >
      <button
        class="btn role-secondary mr-10"
        @click="close"
      >
        {{ t('generic.cancel') }}
      </button>

      <button
        class="btn role-primary"
        @click="confirm"
      >
        {{ t('generic.confirm') }}
      </button>
    </div>
  </Card>
</template>
<style lang="scss" scoped>
.prompt-rotate {
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
