<script>

export default {
  props: {
    row: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    this.backup = await this.row.getBackup();
  },
  data() {
    return { backup: {}, loading: true };
  },
  computed: {
    project() {
      const backup = this.backup;

      if (backup) {
        return backup.projectDisplayName;
      }

      return '-';
    },
  },
  methods: {
    startDelayedLoading() {
      this.loading = false;
    }
  }
};
</script>

<template>
  <i
    v-if="loading"
    class="icon icon-spinner delayed-loader"
  />
  <span v-else>{{ project }}</span>
</template>
