
import CRTB from '@shell/models/management.cattle.io.clusterroletemplatebinding';
import { getDetailLocation } from '~/pkg/pai/utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';

export default class extends CRTB {
  get detailLocation() {
    return getDetailLocation(this);
  }
  get listLocation() {
    return {
      name: `${ PRODUCT_NAME }-c-cluster-members`,
      hash: `#cluster-membership`,
    };
  }
}
