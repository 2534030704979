import { getDetailLocation } from '~/pkg/pai/utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/deploy';
import FleetClusterGroup from '@shell/models/fleet.cattle.io.clustergroup';
import { FLEET } from '@shell/config/types';
import { convert, matching } from '@shell/utils/selector';

export default class extends FleetClusterGroup {
  get detailLocation() {
    return getDetailLocation(this, PRODUCT_NAME);
  }

  get targetClusters() {
    const workspace = this.$getters['byId'](FLEET.WORKSPACE, this.metadata.namespace);

    if (!workspace) {
      return [];
    }
    const expressions = convert(this.spec?.selector?.matchLabels || {}, this.spec?.selector?.matchExpressions || []);

    if ( !expressions.length ) {
      return workspace.clusters;
    }

    const match = matching(workspace.clusters, expressions);

    return match;
  }
}
