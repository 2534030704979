<script>
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';
import { PRODUCT_NAME, PRODUCT_NAME as PAI } from '../config/pai';
import { PAI_POD } from '../config/types';
import { POD } from '@/shell/config/types';

export default {
  name:  'ContainerQuickForm',
  props: { onClose: { type: Function, default: null } },
  async fetch() {
    this.namespaceOptions = this.$store.getters[`cluster/all`](NAMESPACE).map((item) => {
      return item.metadata.name;
    });
    this.form.namespace = this.namespaceOptions.find((item) => {
      return item === 'default';
    }) || this.namespaceOptions[0];
    const url = '/api/v1/namespaces/picloud-system/services/http:regui:80/proxy/list';
    const schemas = this.$store.getters['cluster/schemaFor'](POD);

    const opt = {
      method:  'get',
      headers: {
        'content-type': 'application/yaml',
        accept:         'application/json',
      },
      data: {},
      url,
    };
    const data = await schemas.$ctx.dispatch('request', { opt });

    const arr = [];

    for (const key in data) {
      for (const key1 in data[key]) {
        data[key][key1].forEach((item) => {
          arr.push(`${ key }/${ key1 }:${ item }`);
        });
      }
    }
    this.allImages = arr;
  },
  data() {
    return {
      namespaceOptions: [],
      form:             {
        name: '', namespace: '', containerImages: ''
      },
      allImages: [],
      radio:     '1'
    };
  },
  computed: {
    createVisible() {
      return this.$store.state['pai-common'].currentModal === 'ContainerQuickForm';
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      if (this.onClose) {
        this.onClose();
      }
    },
    createLocation() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.currentCluster,
          product:  PAI,
          resource: PAI_POD,
        },
      });
    },
    handleSwitchImage() {
      this.form.containerImages = '';
    },
    async quickCreationPod() {
      // 快速创建容器
      if (!this.form.name || !this.form.namespace || !this.form.containerImages) {
        this.$message.error(this.t('pai.edit.tips.required'));

        return;
      }
      if (/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g.test(this.form.name)) {
        this.$message.error(this.t('pai.edit.tips.prohibitChinese'));

        return;
      }
      const pod = await this.$store.dispatch(`cluster/create`, {
        type: POD,
        spec: {
          template: {
            metadata: {
              labels:    { app: this.form.name },
              namespace: this.form.namespace,
              name:      this.form.name
            },
            spec: { containers: [{ name: 'container-0', image: this.form.containerImages }] }
          }
        }
      });

      await pod.save();
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-resource-namespace-id`,
        params: {
          product:   PAI,
          cluster:   this.currentCluster,
          resource:  PAI_POD,
          namespace: this.form.namespace,
          id:        this.form.name,
        },
      });
    }
  }
};
</script>

<template>
  <div class="containerForm">
    <el-dialog
      :title="t('pai.apps.container.quickContainerCreation')"
      :visible.sync="createVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-descriptions
        :column="1"
        :colon="false"
      >
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.nameSpace') }}
          </template>
          <el-select
            v-model="form.namespace"
            filterable
            style="width: 378px;"
          >
            <el-option
              v-for="(item) in namespaceOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.container')+t('pai.edit.machine.name') }}
          </template>
          <el-input
            v-model="form.name"
            style="width: 378px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.apps.container.containerMirroring') }}
          </template>
          <div style="margin: 7px 0 0 16px;">
            <el-radio-group
              v-model="radio"
              @change="handleSwitchImage"
            >
              <el-radio
                v-model="radio"
                label="1"
              >
                {{ t('pai.apps.container.selectMirror') }}
              </el-radio>
              <el-radio
                v-model="radio"
                label="2"
              >
                {{ t('pai.apps.container.customInput') }}
              </el-radio>
            </el-radio-group>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <el-select
            v-if="radio==='1'"
            v-model="form.containerImages"
            :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.detail.vmset.operationSystemImage')"
            style="width: 378px;"
            filterable
          >
            <el-option
              v-for="(item) in allImages"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-input
            v-if="radio==='2'"
            v-model="form.containerImages"
            :placeholder="t('pai.edit.placeholder')+t('pai.detail.vmset.operationSystemImage')"
            style="width: 378px;"
          />
        </el-descriptions-item>
      </el-descriptions>
      <a
        style="display: block; margin: 8px 24px 0 0; color: #246FA5; float: right; cursor: pointer"
        @click="createLocation"
      >
        {{ t('pai.apps.vm.enterAdvancedCreation') }}
      </a>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="quickCreationPod"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.containerForm{
  ::v-deep .el-form-item__label {
    margin-top: 0px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0px;
  }
  ::v-deep .el-descriptions-item__label{
    width: 92px;
  }
  span{
    text-align: left;
    line-height: 36px;
  }
}
</style>
