
<script>

import { PRODUCT_NAME } from '../config/pai';
import { PAI_RESOURCES } from '../config/types';

export default {
  props: {
    row: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    this.vmsets = await this.row.getVmsets();
  },
  data() {
    return { vmsets: [], loading: true };
  },
  computed: {
    vmsetLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.$route.params.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      };
    },
  },
  methods: {
    startDelayedLoading() {
      this.loading = false;
    },
  }
};
</script>

<template>
  <i
    v-if="loading"
    class="icon icon-spinner delayed-loader"
  />
  <span v-else>
    <nuxt-link
      v-if="vmsets.length"
      :to="vmsetLink"
    >
      {{ vmsets.length }}
    </nuxt-link>

    <span v-else>0</span> </span>
</template>
