<template>
  <div class="cluster-single-module bgCard cluster-flex">
    <div
      v-for="(item,index) in moduleData"
      :key="index"
      class="module-main"
      @click="handleRoute(item.code)"
    >
      <div class="module-main-top">
        <span class="img-bg">
          <img
            src="../images/module-icon.png"
            alt="图片"
          >
        </span>
        <span
          class="cluster-title"
          style="margin-left: 6px"
        >
          {{ item.title }}
        </span>
      </div>

      <div class="module-main-bottom">
        <div class="cluster-flex">
          <div class="box-num">
            <span class="green-color">{{ item.active }}</span>/<span>{{ item.all }}</span>
          </div>
          <div class="box-text">
            {{ item.status }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PAI_POD, PAI_RESOURCES, PAI_WORKLOAD_TYPES } from '../../../../../config/types';
import { PRODUCT_NAME } from '../../../../../config/pai';

export default {
  name:  'ClusterSingleModule',
  props: {
    cloudData: {
      type:    Array,
      default: () => {
        return [];
      }
    },
    podData: {
      type:    Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      moduleData: [
        {
          title:  this.t('pai.menu.vmset'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.On') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'cloud'
        }, {
          title:  this.t('pai.menu.pod'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'container'
        }, {
          title:  this.t('pai.menu.apps.statefulLoad'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'state'
        }, {
          title:  this.t('pai.menu.apps.statelessLoad'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'stateNo'
        }, {
          title:  this.t('pai.menu.batch.job'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'task'
        }, {
          title:  this.t('pai.menu.batch.cronjob'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'taskD'
        }, {
          title:  this.t('pai.menu.apps.daemonset'),
          all:    0,
          active: 0,
          status: `${ this.t('pai.detail.vmset.normal') }/${ this.t('pai.detail.vmset.all') }`,
          code:   'course'
        },
      ]
    };
  },

  watch: {
    cloudData(val, oldVal) {
      // //  云主机
      const activeCloud = [];

      val.forEach((item) => {
        if (item.spec.power === 'On') {
          activeCloud.push(item) ;
        }
      });

      this.$set(this.moduleData[0], 'all', val.length);
      this.$set(this.moduleData[0], 'active', activeCloud.length);
    },
    podData(val, oldVal) {
      //  容器组
      const activePod = [];

      val.forEach((item) => {
        if (item.metadata.state.name === 'Running' || item.metadata.state.name === 'running' || item.metadata.state.name === 'Completed' || item.metadata.state.name === 'completed') {
          activePod.push(item) ;
        }
      });
      this.$set(this.moduleData[1], 'all', val.length);
      this.$set(this.moduleData[1], 'active', activePod.length);
    }
  },
  async fetch() {
    //  有状态负载
    const statefulsetData = await this.$store.dispatch('cluster/findAll', { type: 'apps.statefulset' });
    const activeStatefulset = [];

    statefulsetData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeStatefulset.push(item) ;
      }
    });
    this.$set(this.moduleData[2], 'all', statefulsetData.length);
    this.$set(this.moduleData[2], 'active', activeStatefulset.length);

    //  无状态负载
    const deploymentData = await this.$store.dispatch('cluster/findAll', { type: 'apps.deployment' });
    const activeDeployment = [];

    deploymentData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeDeployment.push(item) ;
      }
    });
    this.$set(this.moduleData[3], 'all', deploymentData.length);
    this.$set(this.moduleData[3], 'active', activeDeployment.length);

    //  普通任务
    const jobData = await this.$store.dispatch('cluster/findAll', { type: 'batch.job' });
    const activeJob = [];

    jobData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeJob.push(item) ;
      }
    });
    this.$set(this.moduleData[4], 'all', jobData.length);
    this.$set(this.moduleData[4], 'active', activeJob.length);

    //  定时任务
    const cronjobData = await this.$store.dispatch('cluster/findAll', { type: 'batch.cronjob' });
    const activeCronjob = [];

    cronjobData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeCronjob.push(item) ;
      }
    });
    this.$set(this.moduleData[5], 'all', cronjobData.length);
    this.$set(this.moduleData[5], 'active', activeCronjob.length);

    //  守护进程
    const daemonsetData = await this.$store.dispatch('cluster/findAll', { type: 'apps.daemonset' });
    const activeDaemonset = [];

    daemonsetData.forEach((item) => {
      if (item.metadata.state.name === 'active') {
        activeDaemonset.push(item) ;
      }
    });
    this.$set(this.moduleData[6], 'all', daemonsetData.length);
    this.$set(this.moduleData[6], 'active', activeDaemonset.length);
  },
  methods: {
    handleRoute(val) {
      let resource = '';

      switch (val) {
      case 'cloud':
        resource = PAI_RESOURCES.VMSET;
        break;
      case 'container':
        resource = PAI_POD;
        break;
      case 'state':
        resource = PAI_WORKLOAD_TYPES.STATEFUL_SET;
        break;
      case
        'stateNo':
        resource = PAI_WORKLOAD_TYPES.DEPLOYMENT;
        break;
      case 'task':
        resource = PAI_WORKLOAD_TYPES.JOB;
        break;
      case 'taskD':
        resource = PAI_WORKLOAD_TYPES.CRON_JOB;
        break;
      case 'course':
        resource = PAI_WORKLOAD_TYPES.DAEMON_SET;
      }

      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product: PRODUCT_NAME,
          resource,
        },
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cluster-single-module{
  .module-main{
    width: 14%;
    cursor: pointer;
    padding: 10px 15px;
    border-right: 1px solid #BBBBBB;
    .module-main-top {
      .img-bg{
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #F0F2F5;
      }
      img{
        width: 16px;
        height: 16px;
      }
    }
    .module-main-bottom{
      margin-top: 16px;
      .box-text{
        padding-top: 4px;
      }
      .green-color {
        font-size: 18px;
        font-weight: 600;
        color: #4AC58F;
      }
    }
  }
  .module-main:last-child{
    border-right: 0px;
  }
}
</style>
