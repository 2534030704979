import ProvCluster from '@shell/models/provisioning.cattle.io.cluster';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import StarMixin from '~/pkg/pai/mixins/star';
import { ALIAS } from '~/pkg/pai/config/labels-annotations';

export default class extends StarMixin(ProvCluster) {
  get detailLocation() {
    const product = this.$rootGetters['productId'];

    const routeName = this.currentRouter().app._route.name;

    if (routeName === 'manage-cluster-resource-namespace-id' || routeName === 'manage-cluster-resource-create' || product === PRODUCT_NAME) {
      return {
        name:   `pai-c-cluster-overview`,
        params: {
          cluster: super.mgmtClusterId,
          product: PRODUCT_NAME,
        },
      };
    }

    return super.detailLocation;
  }

  explore() {
    let location;
    const product = this.$rootGetters['productId'];

    const routeName = this.currentRouter().app._route.name;

    if (routeName === 'manage-cluster-resource-namespace-id' || product === PRODUCT_NAME) {
      location = {
        name:   `pai-c-cluster-overview`,
        params: {
          cluster: super.mgmtClusterId,
          product: PRODUCT_NAME,
        },
      };
    } else {
      location = {
        name:   'c-cluster',
        params: { cluster: super.mgmtClusterId }
      };
    }
    this.currentRouter().push(location);
  }

  get doneOverride() {
    return this.listLocation;
  }

  get listLocation() {
    const product = this.$rootGetters['productId'];

    const routeName = this.currentRouter().app._route.name;

    if (routeName === 'manage-cluster-resource-namespace-id' || routeName === 'manage-cluster-resource-create' || product === PRODUCT_NAME) {
      return {
        name:   `${ PRODUCT_NAME }-overview`,
        params: {
          product: PRODUCT_NAME,
          cluster: 'local',
        },
      };
    } else {
      return super.listLocation;
    }
  }

  get displayName() {
    const name = this.name;
    let alias = '';

    if (this.annotations && this.annotations[ALIAS]) {
      alias = this.annotations[ALIAS];

      return `${ alias }/${ name }`;
    }

    return name;
  }
}
