<script>
import { mapGetters } from 'vuex';
import { Card } from '@components/Card';
import { Banner } from '@components/Banner';
import Loading from '@shell/components/Loading';
import YamlEditor from '@shell/components/YamlEditor';
import FileSelector from '@shell/components/form/FileSelector';
import AsyncButton from '@shell/components/AsyncButton';
import LabeledSelect from '@shell/components/form/LabeledSelect';
import SortableTable from '@shell/components/SortableTable';
import { sortBy } from '@shell/utils/sort';
import { exceptionToErrorsArray } from '@shell/utils/error';
import { NAMESPACE } from '@shell/config/types';
import {
  AGE, NAME as NAME_COL, NAMESPACE as NAMESPACE_COL, STATE, TYPE
} from '@shell/config/table-headers';
import { EDITOR_MODES } from '@/shell/components/YamlEditor';

export default {
  components: {
    AsyncButton,
    Banner,
    Card,
    Loading,
    YamlEditor,
    FileSelector,
    LabeledSelect,
    SortableTable
  },

  props: {
    defaultNamespace: {
      type:    String,
      default: 'default'
    },
  },

  async fetch() {
    this.allNamespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE, opt: { url: 'namespaces' } });
  },

  data() {
    return {
      currentYaml:   { value: '', type: '' },
      yamls:         [],
      allNamespaces: null,
      errors:        null,
      rows:          [],
      done:          false,
    };
  },

  computed: {
    ...mapGetters(['currentCluster']),

    namespaceOptions() {
      const out = this.allNamespaces.map((obj) => {
        return {
          label: obj.name,
          value: obj.name,
        };
      });

      return sortBy(out, 'label');
    },

    headers() {
      return [
        STATE,
        TYPE,
        NAME_COL,
        NAMESPACE_COL,
        AGE
      ];
    },

    editorMode() {
      return this.currentYaml.type === 'success' ? EDITOR_MODES.VIEW_CODE : EDITOR_MODES.EDIT_CODE;
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    onFileSelected(files) {
      files.forEach((file, i) => {
        let name = file.name;
        let j = 1;

        while (this.yamls.map(v => v.name).includes(name)) {
          name = `${ files[i].name }(${ j })`;
          j++;
        }
        this.yamls.push({ value: file.value, name });
      });
      this.onSelectedYaml(this.yamls[this.yamls.length - 1]);
    },

    async importYaml(btnCb) {
      let successFileNum = 0;

      try {
        this.errors = [];
        if (!this.yamls.length) {
          const res = await this.currentCluster.doAction('apply', {
            yaml:             this.currentYaml.value,
            defaultNamespace: this.defaultNamespace,
          });

          this.rows.push(...res);
        } else {
          for (let i = 0; i < this.yamls.length; i++) {
            if (this.yamls[i].type !== 'success') {
              const res = await this.currentCluster.doAction('apply', {
                yaml:             this.yamls[i].value,
                defaultNamespace: this.defaultNamespace,
              });

              this.rows.push(...res);
              this.yamls[i].type = 'success';
              successFileNum++;
            }
          }
        }
        if (!this.yamls.length || successFileNum === this.yamls.length) {
          btnCb(true);
          this.done = true;
        }
      } catch (err) {
        this.errors = exceptionToErrorsArray(err);
        this.done = false;
        btnCb(false);
      }
    },

    rowClick(e) {
      if ( e.target.tagName === 'A' ) {
        this.close();
      }
    },

    onCloseYaml(name) {
      const index = this.yamls.findIndex(v => v.name === name);

      this.yamls.splice(index, 1);
      if (this.yamls.length > 0) {
        this.onSelectedYaml(this.yamls[0]);
      } else {
        this.onSelectedYaml({ value: '' });
      }
    },

    onSelectedYaml(tag) {
      this.currentYaml = tag;
      const component = this.$refs.yamleditor;

      if (component) {
        this.errors = null;
        component.updateValue(tag.value);
      }
    },

    async eventDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const fileData = Array.from(e.dataTransfer.files || []);

      function getFileContents(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (ev) => {
            const value = ev.target.result;
            const name = file.name;
            const fileContents = { value, name };

            resolve(fileContents);
          };

          reader.onerror = (err) => {
            reject(err);
          };
          reader.readAsText(file);
        });
      }

      try {
        const asyncFileContents = fileData.map(getFileContents);
        const fileContents = await Promise.all(asyncFileContents);

        this.onFileSelected(fileContents);
      } catch (error) {
        if (this.showGrowlError) {
          this.$store.dispatch('growl/fromError', { title: 'Error reading file', error }, { root: true });
        }
      }
    },
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <Card
    v-else
    :show-highlight-border="false"
  >
    <template #title>
      <div style="display: block; width: 100%;">
        <template v-if="done">
          <h4>{{ t('import.success', {count: rows.length}) }}</h4>
        </template>
        <template v-else>
          <h4 v-t="'import.title'" />
          <div class="row">
            <div class="col span-6">
              <FileSelector
                class="btn role-secondary pull-left"
                :label="t('generic.readFromFile')"
                :multiple="true"
                :include-file-name="true"
                @selected="onFileSelected"
              />
            </div>
            <div class="col span-6">
              <LabeledSelect
                v-model="defaultNamespace"
                class="pull-right"
                :options="namespaceOptions"
                label-key="import.defaultNamespace.label"
                mode="edit"
              />
            </div>
          </div>
          <div class="tags">
            <el-tag
              v-for="(tag) in yamls"
              :key="tag.name"
              class="tag"
              closable
              :hit="currentYaml.name === tag.name"
              :type="tag.type"
              @click="onSelectedYaml(tag)"
              @close="onCloseYaml(tag.name)"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </template>
      </div>
    </template>
    <template #body>
      <template v-if="done">
        <div class="results">
          <SortableTable
            :rows="rows"
            :headers="headers"
            mode="view"
            key-field="_key"
            :search="false"
            :paging="true"
            :row-actions="false"
            :table-actions="false"
            @rowClick="rowClick"
          />
        </div>
      </template>

      <div
        v-else
        @drop="eventDrop"
      >
        <YamlEditor
          ref="yamleditor"
          v-model="currentYaml.value"
          :editor-mode="editorMode"
          class="yaml-editor"
        />
      </div>
      <Banner
        v-for="(err, i) in errors"
        :key="i"
        color="error"
        :label="err"
      />
    </template>
    <template #actions>
      <div
        v-if="done"
        class="text-center"
        style="width: 100%"
      >
        <button
          type="button"
          class="btn role-primary"
          @click="close"
        >
          {{ t('generic.close') }}
        </button>
      </div>
      <div
        v-else
        class="text-center"
        style="width: 100%"
      >
        <button
          type="button"
          class="btn role-secondary mr-10"
          @click="close"
        >
          {{ t('generic.cancel') }}
        </button>
        <AsyncButton
          v-if="!done"
          mode="import"
          :disabled="!currentYaml.value.length"
          @click="importYaml"
        />
      </div>
    </template>
  </Card>
</template>

<style lang='scss' scoped>
  $min: 50vh;
  $max: 50vh;

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;

    .el-tag {
      cursor: pointer;
      margin: 0 5px 5px 0;
      color: var(--primary);

      .el-tag__close {
        color: var(--primary);
      }
    }

    .el-tag.is-hit {
      border-color: var(--primary);
    }
  }

  .yaml-editor {
    flex: 1;
    min-height: $min;
    max-height: $max;

    ::v-deep .code-mirror {
      .CodeMirror {
        position: initial;
      }

      .CodeMirror,
      .CodeMirror-scroll,
      .CodeMirror-gutters {
        min-height: $min;
        max-height: $max;
      }
    }
  }
</style>
