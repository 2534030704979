<script>
import LazyImage from '@shell/components/LazyImage';
import { get } from '@shell/utils/object';
import capitalize from 'lodash/capitalize';
import windowsIcon from '../assets/vmImage/windows.png';
import linuxIcon from '../assets/vmImage/linux.png';
import { PRODUCT_NAME as PAI } from '../config/pai';
import { DESCRIPTION } from '@shell/config/labels-annotations';
import { ALIAS, IMAGE } from '../config/labels-annotations';
import { STORAGE_CLASS } from '@shell/config/types';
import { PAI_IMAGE_STATES, PAI_IMAGE_STATES_ENUM } from '../plugins/pai-resource-class';
import { omitString } from '../utils/string';
import { PAI_RESOURCES } from '../config/types';
import { _EDIT, MODE } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import { compare } from '@shell/utils/sort';
import { IMAGE as IMAGES } from '../config/settings';
import VmQuickForm from './VmQuickForm';

export default {
  components: { LazyImage, VmQuickForm },

  props: {
    rows: {
      type:     Array,
      required: true,
    },
    keyField: {
      type:    String,
      default: 'key',
    },
    iconField: {
      type:    String,
      default: 'icon',
    },
    nameField: {
      type:    String,
      default: 'name',
    },
    descriptionField: {
      type:    String,
      default: 'description',
    },
    sideLabelField: {
      type:    String,
      default: 'sideLabel',
    },
    disabledField: {
      type:    String,
      default: 'disabled',
    },
    asLink: {
      type:    Boolean,
      default: false,
    },
    linkField: {
      type:    String,
      default: 'link'
    },
    targetField: {
      type:    String,
      default: 'target',
    },
    rel: {
      type:    String,
      default: 'noopener noreferrer nofollow'
    },
    noDataKey: {
      type:    String,
      default: 'sortableTable.noRows',
    },
    colorFor: {
      type:    Function,
      default: (r, idx) => `color${ (idx % 8) + 1 }`,
    },
    /**
     * Inherited global identifier prefix for tests
     * Define a term based on the parent component to avoid conflicts on multiple components
     */
    componentTestid: {
      type:    String,
      default: 'select-icon-grid'
    },
    type: {
      type:    String,
      default: 'chart',
    }
  },
  async fetch() {
    this.scs = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
  },
  data() {
    return {
      windowsIcon,
      linuxIcon,
      PAI,
      DESCRIPTION,
      ALIAS,
      currentItem: '',
      scs:         [],
      IMAGES,
      vmForm:      false,
      imageRecord: {}
    };
  },
  computed: { ...mapGetters(['currentCluster']) },
  methods:  {
    omitString,
    get,
    compare,
    isDisabled(idx) {
      return get(this.rows[idx], this.disabledField) === true;
    },

    select(row, idx) {
      if ( this.isDisabled(idx) ) {
        return;
      }

      this.$emit('clicked', row, idx);
    },
    capitalize,
    onActionEdit(row) {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-namespace-id`,
        params: {
          product:   PAI,
          cluster:   this.currentCluster.id,
          resource:  PAI_RESOURCES.VM_IMAGE,
          namespace: row.metadata.namespace,
          id:        row.metadata.name,
        },
        query: { [MODE]: _EDIT },
      });
    },
    onActionInstall(row) {
      this.imageRecord = row;
      this.vmForm = true;
    },
    hanleCloseVM() {
      this.vmForm = false;
    },
    onActionDelete(row) {
      this.$confirm(this.t('pai.labels.isOk'), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(async() => {
        try {
          await row.remove();
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success'),
          });
        } catch (e) {
          this.$message({
            type:    'warning',
            message: e.message,
          });
        }
      }).catch(() => {
      });
    },
    stateClassName(row) {
      const sc = this.scs.find(v => v.name === `${ row.namespace }-${ row.name }`);

      if (sc && row.status && row.status.message === 'Ready') {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Normal].className;
      }
      if (this.isError(row)) {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Abnormal].className;
      } else {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Pending].className;
      }
    },
    stateLabel(row) {
      const sc = this.scs.find(v => v.name === `${ row.namespace }-${ row.name }`);

      if (sc && row.status && row.status.message === 'Ready') {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Normal].labelKey;
      }
      if (this.isError(row)) {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Abnormal].labelKey;
      } else {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Pending].labelKey;
      }
    },
    isError(row) {
      const sc = this.scs.find(v => v.name === `${ row.namespace }-${ row.name }`);

      return (!sc && row?.status?.message) || (sc && row.status?.message && row.status.message !== 'Ready');
    },
    async onActionStar(r) {
      try {
        await r.setStar();
      } catch (e) {}
    },
    icon(item) {
      if (item.labels && item.labels[IMAGE.LOGO]) {
        const logoName = item.labels[IMAGE.LOGO];

        try {
          const logo = require(`../assets/images/imageLogo/${ logoName }`);

          if (logo) {
            return logo;
          }
        } catch (e) {}
      }

      return item.spec?.os?.includes('windows') ? windowsIcon : linuxIcon;
    },
  }
};
</script>

<template>
  <div
    v-if="rows.length"
    class="grid"
  >
    <template v-if="type === 'chart'">
      <div
        :is="asLink ? 'a' : 'div'"
        v-for="(r, idx) in rows.sort((a, b) => {
          return compare(b.starTimeStamp, a.starTimeStamp);
        })"
        :key="get(r, keyField)"
        :href="asLink ? get(r, linkField) : null"
        :target="get(r, targetField)"
        :rel="rel"
        class="item"
        :data-testid="componentTestid + '-' + idx"
        :class="{
          'has-description': !!get(r, descriptionField),
          'has-side-label': !!get(r, sideLabelField), [colorFor(r, idx)]: true, disabled: get(r, disabledField) === true
        }"
      >
        <div class="actions">
          <i
            :class="r.starClass"
            @click="onActionStar(r)"
          />
          <slot name="checkbox" />
        </div>
        <div
          class="side-label"
          :class="{'indicator': true }"
        />
        <div v-if="r.deploysOnWindows">
          <label class="deploys-os-label">
            {{ t('catalog.charts.deploysOnWindows') }}
          </label>
        </div>
        <div v-if="r.windowsIncompatible">
          <label class="os-incompatible-label">
            {{ t('catalog.charts.windowsIncompatible') }}
          </label>
        </div>
        <div
          v-if="get(r, sideLabelField)"
          class="side-label"
          :class="{'indicator': false }"
        >
          <label>{{ get(r, sideLabelField) }}</label>
        </div>
        <div class="logo">
          <i
            v-if="r.iconClass"
            :class="r.iconClass"
          />
          <LazyImage
            v-else
            :src="get(r, iconField)"
          />
        </div>
        <el-link
          type="primary"
          class="name"
          @click="select(r, idx)"
        >
          {{ get(r, nameField) }}
        </el-link>
        <div
          v-if="get(r, descriptionField)"
          class="description"
        >
          {{ get(r, descriptionField) }}
        </div>
      </div>
    </template>
    <template v-else-if="type === 'vm'">
      <div
        :is="asLink ? 'a' : 'div'"
        v-for="(row) in rows.sort((a, b) => {
          return compare(b.starTimeStamp, a.starTimeStamp);
        })"
        :key="row.id"
        class="item vm"
      >
        <div class="card">
          <div class="actions">
            <i
              :class="row.starClass"
              @click="onActionStar(row)"
            />
            <slot name="checkbox" />
          </div>
          <div class="top">
            <div class="left">
              <img
                :src="icon(row)"
                alt=""
              >
              <div class="title">
                <el-tooltip
                  :content="(row.spec && row.spec.alias) ? row.spec.alias+'/'+row.metadata.name : row.metadata.name"
                  effect="light"
                >
                  <nuxt-link
                    :to="{
                      name: `${ PAI }-c-cluster-apps-charts-app`,
                      params: {
                        cluster: $route.params.cluster,
                        product: PAI,
                      },
                      query:{
                        image: row.id,
                      }
                    }"
                  >
                    {{ (row.spec && row.spec.alias) ? omitString(row.spec.alias,15) : omitString(row.metadata.name,15) }}
                  </nuxt-link>
                </el-tooltip>
              </div>
            </div>
            <div>
              <div
                v-if="isError(row)"
                v-tooltip="row.status && row.status.message ? row.status.message : ''"
                style="cursor: pointer"
              >
                <div
                  class="state"
                  :class="stateClassName(row)"
                />
                {{ t(stateLabel(row)) }}
              </div>
              <div v-else>
                <div
                  class="state"
                  :class="stateClassName(row)"
                />
                {{ t(stateLabel(row)) }}
              </div>
            </div>
          </div>
          <div class="line" />
          <div
            class="bottom"
            @mouseover="currentItem=row.id"
            @mouseleave="currentItem=''"
          >
            <div
              v-if="row.id === currentItem"
              class="action"
            >
              <div @click="onActionEdit(row)">
                <i class="el-icon-edit" />{{ t('catalog.tools.action.edit') }}
              </div>
              <div
                v-if="!!row.spec.os"
                @click="onActionInstall(row)"
              >
                <i class="el-icon-download" />{{ t('pai.apps.vm.quickInstallation') }}
              </div>
              <div
                v-if="!!row.spec.os"
                :class="{disabled:row.spec.driver !== IMAGES.DRIVER[0]}"
                @click="row.export()"
              >
                <i class="el-icon-upload2" /> {{ t('pai.vmset.image.export') }}
              </div>
              <div @click="onActionDelete(row)">
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </div>
            </div>
            <div
              v-else
              class="desc"
            >
              {{ (row.spec && row.spec.desc) ? row.spec.desc : t('pai.apps.vm.nodesc') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <vm-quick-form
      v-if="vmForm"
      :on-close="hanleCloseVM"
      :vm-id="imageRecord.id"
      :image="imageRecord"
    />
  </div>
  <div
    v-else
    class="m-50 text-center"
  >
    <h1 v-t="noDataKey" />
  </div>
</template>

<style lang="scss" scoped>
  $height: 135px;
  $side: 15px;
  $margin: 10px;
  $logo: 60px;
  $hover-border-width: 1px;

  .grid {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -1*$margin;

    @media only screen and (min-width: map-get($breakpoints, '--viewport-4')) {
      .item {
        width: 100%;
      }
    }
    @media only screen and (min-width: map-get($breakpoints, '--viewport-7')) {
      .item {
        width: calc(50% - 2 * #{$margin});
      }
    }
    @media only screen and (min-width: map-get($breakpoints, '--viewport-9')) {
      .item {
        width: calc(33.33333% - 2 * #{$margin});
      }
    }
    @media only screen and (min-width: map-get($breakpoints, '--viewport-12')) {
      .item {
        width: calc(25% - 2 * #{$margin});
      }
    }

    $color: var(--body-text) !important;

    .item {
      height: $height;
      margin: $margin;
      padding: $margin;
      position: relative;
      //border-radius: calc( 1.5 * var(--border-radius));
      border: 1px solid var(--border);
      text-decoration: none !important;
      color: $color;
      background: rgba(239, 239, 239, 1);

      &:hover:not(.disabled) {
        box-shadow: 0 0 30px var(--shadow);
        transition: box-shadow 0.1s ease-in-out;
        text-decoration: none !important;
      }

      .side-label {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 2px 5px;

        &.indicator {
          top: 0;
          right: 0;
          left: 0;
        }

      }

      .side-label label, label.deploys-os-label, label.os-incompatible-label{
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          display: block;
          white-space: no-wrap;
          text-overflow: ellipsis;
          // Override default form label properties
          color: var(--card-badge-text);
          margin: 0;
      }

      .deploys-os-label, .os-incompatible-label {
        position: absolute;
        bottom: 10px;
        padding: 2px 5px;
        right: 10px;
      }

      label.os-incompatible-label {
        color: var(--warning);
        background-color: var(--warning-banner-bg)
      }

      .logo {
        text-align: center;
        position: absolute;
        left: $side+$margin;
        top: math.div(($height - $logo), 2);
        width: $logo;
        height: $logo;
        border-radius: calc(2 * var(--border-radius));
        overflow: hidden;
        background-color: white;

        img {
          width: $logo - 4px;
          height: $logo - 4px;
          object-fit: contain;
          position: relative;
          top: 2px;
        }

        i {
          background-position: center;
          background-repeat: no-repeat;
          display: flex;
          height: $logo - 4px;
          margin: 2px;
          width: $logo - 4px;
        }
      }

      &.rancher {
        .side-label, .deploys-os-label {
          background-color: var(--app-rancher-accent);
          label {
            color: var(--app-rancher-accent-text);
          }
        }
        &:hover:not(.disabled) {
          border-color: var(--app-rancher-accent);
        }
      }

      &.partner {
        .side-label, .deploys-os-label {
          background-color: var(--app-partner-accent);
          label {
            color: var(--app-partner-accent-text);
          }
        }
        &:hover:not(.disabled) {
          border-color: var(--app-partner-accent);
        }
      }

      // @TODO figure out how to templatize these
      &.color1 {
        .side-label, .deploys-os-label { background-color: var(--app-color1-accent); label { color: var(--app-color1-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color1-accent); }
      }
      &.color2 {
        .side-label, .deploys-os-label { background-color: var(--app-color2-accent); label { color: var(--app-color2-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color2-accent); }
      }
      &.color3 {
        .side-label, .deploys-os-label { background-color: var(--app-color3-accent); label { color: var(--app-color3-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color3-accent); }
      }
      &.color4 {
        .side-label, .deploys-os-label { background-color: var(--app-color4-accent); label { color: var(--app-color4-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color4-accent); }
      }
      &.color5 {
        .side-label, .deploys-os-label { background-color: var(--app-color5-accent); label { color: var(--app-color5-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color5-accent); }
      }
      &.color6 {
        .side-label, .deploys-os-label { background-color: var(--app-color6-accent); label { color: var(--app-color6-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color6-accent); }
      }
      &.color7 {
        .side-label, .deploys-os-label { background-color: var(--app-color7-accent); label { color: var(--app-color7-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color7-accent); }
      }
      &.color8 {
        .side-label, .deploys-os-label { background-color: var(--app-color8-accent); label { color: var(--app-color8-accent-text); } }
        &:hover:not(.disabled) { border-color: var(--app-color8-accent); }
      }

      &:hover:not(.disabled) {
        background-position: right center;
        border-left-width: $hover-border-width;
        //padding-left: $margin+(-$hover-border-width);
        .logo {
          left: 1px+$side+$margin+(-$hover-border-width);
        }
        .name {
          margin-left: $side+$logo+$margin+1px+(-$hover-border-width);

        }
      }

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: $height - (2 * $margin);
        margin: 0 0 0 $side+$logo+$margin;
      }

      &.has-description {
        .name {
          margin-top: $margin;
          line-height: initial;
        }

        &.has-side-label {
          .name {
            margin-top: $margin + 5px;
          }
        }
      }

      .description {
        margin-top: $margin;
        margin-left: $side+$logo+$margin;
        margin-right: $margin;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text-muted) !important;
      }
    }

    .vm {
      &:hover:not(.disabled) {
        box-shadow: 0 0 30px var(--shadow);
        transition: box-shadow 0.1s ease-in-out;
        cursor: unset;
        text-decoration: none !important;
        border: 1px solid var(--primary);
      }
    }

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .card{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(239, 239, 239, 1);
    .top{
      height: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 20px 10px 0;
      .left{
        display: flex;
        align-items: center;
        img {
          width: 50px;
        }
      }
      .title{
        margin: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .line{
      border-bottom: 2px solid var(--primary);
      height: 5%;
    }
    .bottom{
      text-align: left;
      height: 55%;
      display: flex;
      align-items: center;
      padding: 0 5px;
      div{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
      }
      .action {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .disabled {
          cursor: default;
          pointer-events:none;
        }

        div {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          cursor: pointer;
          &:hover{
            color: var(--primary);
          }
        }
      }
      .desc{
        font-size: 12px;
      }
    }
  }
  .state {
    display: inline-block;
    margin-right: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .actions {
    position: absolute;
    right: 10px;
    top: 5px;
    display: flex;
    align-items: center;

    i {
      cursor: pointer;
      color: #FCCA00;
      margin-right: 1px;
    }
  }
</style>
