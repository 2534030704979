import SteveModel from '@shell/plugins/steve/steve-class';
import { PRODUCT_NAME as PLATFORM } from '../config/platform';
export default class PaiPlatform extends SteveModel {
  get detailLocation() {
    const schema = this.$getters['schemaFor'](this.type);

    const id = this.id?.replace(/.*\//, '');
    const product = this.$rootGetters['productId'];

    if (product === PLATFORM) {
      return {
        name:   `platform-c-cluster-resource${ schema?.attributes?.namespaced ? '-namespace' : '' }-id`,
        params: {
          product:   this.$rootGetters['productId'],
          cluster:   this.$rootGetters['clusterId'],
          resource:  this.type,
          namespace: this.metadata?.namespace,
          id,
        }
      };
    }

    return this._detailLocation;
  }
}
