<template>
  <div class="cluster-single-table bgCard">
    <div class="table-main">
      <el-table
        v-if="refreshTable"
        v-loading="tableLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData.filter((item)=>!inputValue || item.children.map(child => child.id).join(',').includes(inputValue))"
        row-key="id"
        min-height="300"
        :tree-props="{children: 'children',}"
        :header-cell-style="{background:'#EFEFEF', color: '#000000'}"
        :default-expand-all="expandeAll"
      >
        <el-table-column
          prop="projectName"
          :label="t('pai.overview.project')"
          min-width="180px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="projectLink(scope.row.id)">
              <span v-if="scope.row.namespaceLength !== 0">{{ scope.row.projectName }}</span>
              <span v-else>&emsp;&emsp;{{ scope.row.projectName }}</span>
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="namespace"
          :label="t('pai.vmset.nameSpace')"
          min-width="120px"
        >
          <template slot-scope="scope">
            <nuxt-link
              v-if="scope.row.namespace !==0"
              :to="namespaceLink(scope.row.namespace)"
            >
              {{ scope.row.namespace }}
            </nuxt-link>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="usageCpu"
          sortable
          :label="t('pai.detail.vmset.CPUUsed')"
          min-width="160px"
        />
        <el-table-column
          prop="cpu"
          min-width="170px"
          sortable
          :label="t('pai.detail.vmset.cpuUtilization')"
        >
          <template slot-scope="scope">
            <el-progress
              :percentage="scope.row.cpu"
              :color="scope.row.cpu >= 80 ?'#C85352' :'#236EA4'"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="usageMem"
          sortable
          :label="t('pai.detail.vmset.memoryUsed')"
          min-width="160px"
        />
        <el-table-column
          prop="memory"
          min-width="170px"
          sortable
          :label="t('pai.detail.vmset.ramUtilization')"
        >
          <template slot-scope="scope">
            <el-progress
              :percentage="scope.row.cpu"
              :color="scope.row.cpu >= 80 ?'#C85352' :'#236EA4'"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="diskRead"
          sortable
          min-width="180px"
          :label="t('pai.detail.vmset.diskRead') + '（KiB/Sec）'"
        >
          <template slot-scope="scope">
            {{ scope.row.diskRead || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="diskWrite"
          sortable
          min-width="180px"
          :label="t('pai.detail.vmset.diskWrite') + '（KiB/Sec）'"
        >
          <template slot-scope="scope">
            {{ scope.row.diskWrite || 0 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="container"
          sortable
          :label="t('pai.detail.vmset.containersNum')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="podLink()">
              {{ scope.row.container }}
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="vm"
          sortable
          :label="t('pai.detail.vmset.vmNum')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="vmLink()">
              {{ scope.row.vm }}
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="internet"
          sortable
          :label="t('pai.detail.vmset.overallNetwork')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="nadLink()">
              {{ scope.row.internet || '0' }}
            </nuxt-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { MANAGEMENT } from '@shell/config/types';
import grouping from '../../../../../components/SortableTable/grouping';
import { PAI_NAMESPACE, PAI_POD, PAI_RESOURCES } from '../../../../../config/types';
import { convertCpuToCore, convertUnitToG, numDelivery } from '../../../../../utils/units';
import { PRODUCT_NAME } from '../../../../../config/pai';
import { _EDIT } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import day from 'dayjs';

export default {
  name:   'RunResourcesTable',
  mixins: [grouping],
  props:  {
    isExpandAll: {
      type:     Boolean,
      required: true,
    },
    inputValue: {
      type:     String,
      required: true,
    },
    allNamespaces: { type: Array },
    projects:      { type: Array }
  },
  data() {
    const params = this.$route.params;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return {
      tableLoading: false,
      tableData:    [],
      cpuCount:     0,
      memoryCount:  0,
      dateTime:     new Date(String(day().format(dateFormat))).getTime() / 1000,
      expandeAll:   false,
      refreshTable: true,
      filterData:   [],
      params,
    };
  },
  async fetch() {
    // const inStore = this.$store.getters['currentStore'](NAMESPACE);
    const allNamespaces = this.allNamespaces;
    const projects = this.projects;

    const projectMap = {};

    for (const project of projects) {
      let limitCpu = 0;
      let limitMem = 0;
      const projectName = project?.metadata?.name;

      limitCpu = convertCpuToCore(project?.spec?.resourceQuota?.limit?.limitsCpu ? project?.spec?.resourceQuota?.limit?.limitsCpu : 0);
      limitMem = convertUnitToG(project?.spec?.resourceQuota?.limit?.limitsMemory ? project?.spec?.resourceQuota?.limit?.limitsMemory : 0);

      projectMap[projectName] = {
        id:              projectName,
        projectName:     project.spec.displayName,
        namespaceLength: 0,
        namespace:       0,
        cpu:             0,
        usageCpu:        0,
        limitCpu,
        memory:          0,
        usageMem:        0,
        limitMem,
        disk:            0,
        usageDisk:       0,
        limitDisk:       0,
        container:       0,
        vm:              0,
        internet:        0,
        diskRead:        0,
        diskWrite:       0,
        children:        [],
      };
    }

    projectMap[''] = {
      id:              'noProject',
      projectName:     'noProject',
      namespaceLength: 0,
      namespace:       0,
      cpu:             0,
      usageCpu:        0,
      limitCpu:        0,
      memory:          0,
      usageMem:        0,
      limitMem:        0,
      disk:            0,
      usageDisk:       0,
      limitDisk:       0,
      container:       0,
      vm:              0,
      internet:        0,
      diskRead:        0,
      diskWrite:       0,
      children:        [],
    };

    const namespaceTableData = allNamespaces.map((namespace) => {
      return {
        id:              `${ namespace.projectId }/${ namespace.name }`,
        projectId:       projectMap[namespace.projectId] !== undefined ? namespace.projectId : '',
        namespace:       namespace.name,
        namespaceLength: 0,
        cpu:             0,
        usageCpu:        0,
        limitCpu:        0,
        memory:          0,
        usageMem:        0,
        limitMem:        0,
        disk:            0,
        usageDisk:       0,
        limitDisk:       0,
        container:       0,
        vm:              0,
        internet:        0,
      };
    });
    let installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET }).catch(err => new Error(err)); // 云主机数据

    installedVms = installedVms == 'Error: Error: Unknown schema for type: virt.liveit100.com.vmset' ? [] : installedVms;
    const containerData = await this.$store.dispatch('cluster/findAll', { type: 'pod' }); // 容器组
    let internetData = await this.$store.dispatch('cluster/findAll', { type: 'k8s.cni.cncf.io.networkattachmentdefinition' }).catch(err => new Error(err)); // 网络资源

    internetData = internetData == 'Error: Error: Unknown schema for type: k8s.cni.cncf.io.networkattachmentdefinition' ? [] : internetData;

    const podsUrl = { url: `/k8s/clusters/${ this.params.cluster }/apis/metrics.k8s.io/v1beta1/pods` };
    const podsMetrics = await this.$store.dispatch('pai-common/getUse', podsUrl);
    const podsMetricsData = podsMetrics.data.items;
    // 分母数据
    const resourceQuotasUrl = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/resourcequotas` };
    const resourceQuotas = await this.$store.dispatch('pai-common/getUse', resourceQuotasUrl);
    const resourceQuotasData = resourceQuotas.data.items;

    this.tableLoading = true;
    for (const namespace of namespaceTableData) {
      const containerList = containerData?.filter(vm => vm?.metadata?.namespace === namespace.namespace);
      const vmList = installedVms?.filter(vm => vm?.metadata?.namespace === namespace.namespace);
      const interList = internetData?.filter(i => i?.metadata?.namespace === namespace.namespace);
      const usageCpuDatas = podsMetricsData?.filter(i => i?.metadata?.namespace === namespace.namespace);
      let usageCpu = 0;
      let usageMem = 0;
      let diskRead = 0;
      let diskWrite = 0;
      let limitCpu = 0;
      let limitMem = 0;

      usageCpuDatas.forEach((cpuData) => {
        cpuData.containers?.forEach((container) => {
          usageCpu += convertCpuToCore(container.usage.cpu);
          usageMem += convertUnitToG(container.usage.memory);
        });
      });

      // 命名空间磁盘读
      const requestDiskRead = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum by (namespace) (rate (container_fs_reads_bytes_total{k3s_io_hostname=~"^.*$",namespace="${ namespace.namespace }"}[5m]))` };
      const requestDiskReadResult = await this.$store.dispatch('pai-common/getUse', requestDiskRead);

      diskRead = requestDiskReadResult?.data?.data?.result[0]?.value[1] !== undefined ? numDelivery(requestDiskReadResult?.data?.data?.result[0]?.value[1] / 1024) : 0;

      // 命名空间磁盘写
      const requestDiskWrite = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query?query=sum by (namespace) (rate (container_fs_writes_bytes_total{k3s_io_hostname=~"^.*$",namespace="${ namespace.namespace }"}[5m]))` };
      const requestDiskWriteResult = await this.$store.dispatch('pai-common/getUse', requestDiskWrite);

      diskWrite = requestDiskWriteResult?.data?.data?.result[0]?.value[1] !== undefined ? numDelivery(requestDiskWriteResult?.data?.data?.result[0]?.value[1] / 1024) : 0;

      const limitCpuDatas = resourceQuotasData.filter(i => i?.metadata?.namespace === namespace.namespace);

      const newLimitData = limitCpuDatas.filter((cpuData) => { // 命名空间最大限额
        if (cpuData.metadata.name.split('-')[0] === 'default') {
          return cpuData;
        }
      });

      if (newLimitData.length > 0) {
        newLimitData.forEach((cpuData) => {
          limitCpu = cpuData.status?.hard['limits.cpu'] !== undefined ? convertCpuToCore(cpuData.status?.hard['limits.cpu']) : 0;
          limitMem = cpuData.status?.hard['limits.memory'] !== undefined ? convertUnitToG(cpuData.status?.hard['limits.memory']) : 0;
        });
      } else {
        limitCpuDatas.forEach((cpuData) => {
          limitCpu = cpuData.status?.hard['limits.cpu'] !== undefined ? convertCpuToCore(cpuData.status?.hard['limits.cpu']) : 0;
          limitMem = cpuData.status?.hard['limits.memory'] !== undefined ? convertUnitToG(cpuData.status?.hard['limits.memory']) : 0;
        });
      }

      if (usageMem !== 0 && limitMem !== 0 && usageMem <= limitMem) {
        namespace.memory = usageMem / limitMem * 100;
      }
      if (usageCpu !== 0 && limitCpu !== 0 && usageCpu <= limitCpu) {
        namespace.cpu = usageCpu / limitCpu * 100;
      }
      namespace.vm = vmList.length;
      namespace.internet = interList.length;
      namespace.usageCpu = numDelivery(usageCpu);
      namespace.usageMem = numDelivery(usageMem);
      namespace.memory = numDelivery( namespace.memory);
      namespace.cpu = numDelivery(namespace.cpu);
      namespace.cpuPro = numDelivery(namespace.cpuPro);
      namespace.diskRead = diskRead;
      namespace.diskWrite = diskWrite;
      namespace.container = containerList.length;

      const project = projectMap[namespace.projectId];

      if (project) {
        project.usageMem += usageMem;
        project.usageCpu += usageCpu;
        project.vm += namespace.vm;
        project.internet += namespace.internet;
        project.children.push(namespace);
        project.namespaceLength += 1;
        project.container += namespace.container;
        project.diskRead += namespace.diskRead;
        project.diskWrite += namespace.diskWrite;
        projectMap[namespace.projectId] = project;
      }
    }

    this.tableData = Object.values(projectMap).map((project) => {
      return {
        ...project,
        usageCpu:  numDelivery(project.usageCpu),
        usageMem:  numDelivery(project.usageMem),
        cpu:       project.usageCpu > 0 && project.limitCpu > 0 && project.usageCpu <= project.limitCpu ? numDelivery(project.usageCpu / project.limitCpu * 100) : 0,
        memory:    project.usageMem > 0 && project.limitMem > 0 && project.usageMem <= project.limitMem ? numDelivery(project.usageMem / project.limitMem * 100) : 0,
        diskRead:  numDelivery(project.diskRead),
        diskWrite: numDelivery(project.diskWrite),
        children:  project.children?.sort((a, b) => b.usageCpu - a.usageCpu)
      };
    }).sort((a, b) => b.cpu - a.cpu);
    if (this.tableData?.length !== 0) {
      this.tableLoading = false;
    }
  },
  computed: { ...mapGetters({ t: 'i18n/t' }) },
  watch:    {
    isExpandAll(val, oldVal) {
      this.refreshTable = false;
      this.expandeAll = val;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    },
  },

  methods: {
    namespaceLink(namespace) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource-id`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_NAMESPACE,
          id:       namespace
        },
      };
    },
    projectLink(project) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource-id`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: MANAGEMENT.PROJECT,
          id:       `${ this.params.cluster }/${ project }`
        },
        query: { mode: _EDIT }
      };
    },
    vmLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      };
    },
    podLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_POD,
        },
      };
    },
    nadLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.NAD,
        },
      };
    },
    cloudLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      };
    }
  },
};
</script>
<style lang="scss">
.cluster-single-table {
  .el-progress-bar__outer{
    width: 100px;
  }
  .el-progress-bar__outer{
    width: 80px;
  }
  .el-progress__text {
    margin-left: 0px;
  }
  .el-table__body{
    width: 100%;
    table-layout: fixed !important;
  }
}
</style>
