import Deployment from '@shell/models/apps.deployment';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { POD } from '@shell/config/types';
import { matches } from '@shell/utils/selector';

export default class extends PaiLocationMixin(Deployment) {
  get pods() {
    const relationships = this.metadata?.relationships || [];
    const podRelationship = relationships.filter(relationship => relationship.toType === POD)[0];

    if (podRelationship) {
      const pods = this.$rootGetters['cluster/podsByNamespace'](this.metadata.namespace);

      return pods.filter((obj) => {
        return matches(obj, podRelationship.selector);
      });
    } else {
      return [];
    }
  }
}
