<script>
import Conditions from '@/shell/components/form/Conditions';
import { mapGetters } from 'vuex';
import { convertResource } from '@/pkg/pai/utils';
import { POD } from '@/shell/config/types';
export default {
  components: { Conditions },
  props:      {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    return {
      needConditions: true,
      filterValue:    '',
      podList:        [],
      allConditions:  [],
      newValue:       {}
    };
  },
  async fetch() {
    this.podList = await this.$store.dispatch('cluster/findAll', { type: POD });
    this.podList.forEach((item) => {
      if (item.metadata.name === this.podName) {
        this.newValue = item;
      }
    });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    showConditions() {
      const inStore = this.$store.getters['currentStore'](this.newValue.type);

      if ( this.$store.getters[`${ inStore }/schemaFor`](convertResource(this.newValue.type)) ) {
        return this.needConditions && this.newValue?.type && this.$store.getters[`${ inStore }/pathExistsInSchema`](this.newValue.type, 'status.conditions');
      }

      return false;
    },
    conditionsHaveIssues() {
      if (this.showConditions) {
        return this.newValue.status?.conditions?.some(cond => cond.error);
      }

      return false;
    },
  },
};
</script>

<template>
  <el-card>
    <el-row style="margin-top:10px">
      <el-col :span="24">
        <Conditions :value="newValue" />
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
</style>
