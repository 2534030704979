import MgmtCluster from '@shell/models/management.cattle.io.cluster';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';

export default class extends MgmtCluster {
  get detailLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return {
        name:   `pai-c-cluster-overview`,
        params: {
          cluster: super.mgmtClusterId,
          product: PRODUCT_NAME,
        },
      };
    }

    return super.detailLocation;
  }

  get isImported() {
    // As of Rancher v2.6.7, this returns false for imported K3s clusters,
    // in which this.provisioner is `k3s`.
    return this.provisioner === 'imported';
  }

  get machineProviderDisplay() {
    if ( this.isImported ) {
      return null;
    }

    const provider = (this.machineProvider || '').toLowerCase();

    if ( provider ) {
      return this.$rootGetters['i18n/withFallback'](`cluster.provider."${ provider }"`, null, provider);
    } else {
      return this.$rootGetters['i18n/t']('generic.unknown');
    }
  }
}
