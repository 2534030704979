<script>
import ResourceTable from '@/pkg/pai/components/ResourceTable';
import ContainerQuickForm from '../components/ContainerQuickForm.vue';
export default {
  name:       'ListPod',
  components: { ResourceTable, ContainerQuickForm },
  props:      {
    resource: {
      type:     String,
      required: true,
    },
    schema: {
      type:     Object,
      required: true,
    },
    rows: {
      type:     Array,
      required: true,
    },
    loading: {
      type:     Boolean,
      required: false,
    },
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false
    }
  },

  $loadingResources() {
    return { loadIndeterminate: true };
  },
  computed: {
    containerQuickFormVisible() {
      return this.$store.state['pai-common'].currentModal === 'ContainerQuickForm';
    },
  },
  methods: {
    handelCloseCreate() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
  }
};
</script>

<template>
  <div>
    <ResourceTable
      v-bind="$attrs"
      :rows="rows"
      :group-by="$attrs.groupBy"
      :schema="schema"
      key-field="_key"
      :loading="loading"
      :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
      :headers="$attrs.headers"
      v-on="$listeners"
    />
    <container-quick-form
      v-if="containerQuickFormVisible"
      :on-close="handelCloseCreate"
    />
  </div>
</template>
