<script>
import UnitInput from '@/shell/components/form/UnitInput';
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';
import { PRODUCT_NAME as PAI } from '../config/pai';
import { PAI_RESOURCES } from '../config/types';
import { COMMAND_HASH_MPA } from '../config/settings';

export default {
  name:       'VmQuickForm',
  components: { UnitInput },
  props:      {
    onClose: { type: Function, default: null },
    vmId:    {
      type:     String,
      required: true,
    },
    image: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    if (this.image.spec.source_parameters.global === 'true' || !this.image.spec.share_to) {
      this.namespaceOptions = await this.$store.getters[`cluster/all`](NAMESPACE).map((item) => {
        return item.metadata.name;
      });
    } else {
      this.namespaceOptions = this.image.spec.share_to;
    }
    this.form.namespace = this.namespaceOptions.find((item) => {
      return item === 'default';
    }) || this.namespaceOptions[0];
  },
  data() {
    const time = new Date().valueOf();
    const desc = this.image?.spec?.desc ? this.image.spec.desc : this.t('pai.apps.vm.nodesc');
    const showName = this.image?.spec?.alias ? this.image.spec.alias : this.image?.metadata?.name;

    return {
      dialogVisible: true,
      form:          {
        name:      `vm${ time }`,
        alias:     '',
        namespace: '',
        diskSize:  this.image.spec.size === 'undefinedGi' ? 0 : `${ parseInt(this.image.spec.size) }Gi`,
        diskName:  this.vmId.replace('/', '-'),
        cpu:       '1',
        ram:       '2Gi'
      },
      namespaceOptions: [],
      allImages:        [],
      desc,
      showName
    };
  },
  computed: {
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  watch: {
    'form.diskSize': {
      deep: true,
      handler() {
        if (this.form.diskSize && parseInt(this.form.diskSize) < parseInt(this.image.spec.size)) {
          this.form.diskSize = `${ parseInt(this.image.spec.size) }Gi`;
        }
      }
    },
    'form.cpu': {
      deep: true,
      handler() {
        if (this.form.cpu && parseInt(this.form.cpu) < 1) {
          this.form.cpu = 1;
        }
      }
    },
    'form.ram': {
      deep: true,
      handler() {
        if (this.form.ram && parseInt(this.form.ram) < 2) {
          this.form.ram = `${ 2 }Gi`;
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    createLocation() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.currentCluster,
          product:  PAI,
          resource: PAI_RESOURCES.VMSET,
        },
        query: { image: this.form.diskName }
      });
    },
    async handleCreate() {
      // 快速创建云主机
      if (!this.form.alias || !this.form.namespace || !this.form.diskSize) {
        this.$message.error(this.t('pai.edit.tips.required'));

        return;
      }
      const vm = await this.$store.dispatch(`cluster/create`, {
        type:     PAI_RESOURCES.VMSET,
        metadata: {
          namespace:   this.form.namespace,
          name:        this.form.name,
          annotations: { 'com.tdology/alias': this.form.alias }
        },
        spec: {
          volumeClaimTemplates: [{
            metadata: { name: 'vmosdisk' },
            spec:     { resources: { requests: { storage: this.form.diskSize } }, storageClassName: this.form.diskName }
          }],
          template: {
            spec: {
              containers: [{
                name:            'os',
                image:           'pause',
                imagePullPolicy: 'IfNotPresent',
                resources:       {
                  limits: {
                    cpu:    this.form.cpu,
                    memory: this.form.ram,
                  },
                  requests: {
                    cpu:    this.form.cpu,
                    memory: this.form.ram,
                  },
                },
                ports: [{
                  containerPort: 9100,
                  name:          'metrics',
                  protocol:      'TCP',
                }],
              }]
            }
          },
          power:    'On',
          replicas: 1,
        }
      });

      await vm.save();
      this.dialogVisible = false;
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-namespace-id`,
        params: {
          product:   PAI,
          cluster:   this.currentCluster,
          resource:  PAI_RESOURCES.VMSET,
          namespace: this.form.namespace,
          id:        this.form.name,
        },
        hash: COMMAND_HASH_MPA['log'],
      });
    },
  }
};
</script>

<template>
  <div class="vmForm">
    <el-dialog
      :title="t('pai.apps.vm.quickInstallation')+`：${showName}`"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-descriptions
        :column="1"
        :colon="false"
      >
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.nameSpace') }}
          </template>
          <el-select
            v-model="form.namespace"
            filterable
            style="width: 364px;"
          >
            <el-option
              v-for="(item) in namespaceOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.vm')+t('pai.edit.machine.name') }}
          </template>
          <el-input
            v-model="form.alias"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.system.size') }}
          </template>
          <UnitInput
            v-model="form.diskSize"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :mode="'edit'"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>CPU
          </template>
          <UnitInput
            v-model="form.cpu"
            :mode="'edit'"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            style="width: 364px;"
          />
        </el-descriptions-item><el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span> {{ t('pai.vmset.ram') }}
          </template>
          <UnitInput
            v-model="form.ram"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :mode="'edit'"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
      </el-descriptions>
      <div class="image-des">
        <p>{{ t('pai.vmset.image.selectedInfo') }}：</p>
        <p>{{ t('pai.vmset.image.hardwareArchitecture') }}：{{ image.spec.arch }}</p>
        <p>{{ t('pai.vmset.image.description') }}：{{ desc }}</p>
      </div>
      <a
        style="display: block; margin: 2px 24px 0 0; color: #246FA5; float: right; cursor: pointer"
        @click="createLocation"
      >
        {{ t('pai.apps.vm.enterAdvancedCreation') }}
      </a>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="handleCreate"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.vmForm{
  ::v-deep .el-form-item__label {
    margin-top: 0px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0px;
  }
  ::v-deep .el-descriptions-item__label{
    width: 105px;
  }
  ::v-deep .el-descriptions__body .el-descriptions__table{
    width: unset;
  }
  span{
    text-align: left;
    line-height: 36px;
  }
  .image-des{
    width: 364px;
    height: 91px;
    margin: 8px 0 17px 115px;
    border-radius: 3px;
    text-align: left;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(194, 194, 194);
    p{
      margin-top: 10px;
      margin-left: 10px;
    }
  }
}
</style>
