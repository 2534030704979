<template>
  <div :style="{ fontSize: fontSize }">
    <el-progress
      :percentage="percent"
      :color="color"
      :define-back-color="bgColor"
      :show-text="false"
    />
    <span>{{ percent }}%</span>
  </div>
</template>
<script>
import { numDelivery } from '../utils/units';

export default {
  props: {
    value: {
      type:     [String, Number],
      required: true,
      default:  0,
    },
    bgColor: {
      type:     [String, Number],
      required: true,
      default:  '#fff',
    },
    fontSize: {
      type:    [String, Number],
      default: '14px',
    }
  },
  computed: {
    percent() {
      if (!this.value || (typeof this.value !== 'number' && typeof this.value !== 'string') || this.value === 'NaN') {
        return 0;
      }
      const result = Number(this.value);

      if (result) {
        if (result >= 100) {
          return 100;
        } else {
          return numDelivery(result);
        }
      } else {
        return 0;
      }
    },
    color() {
      if (this.percent >= 80) {
        return '#C85352';
      } else {
        return '#236EA4';
      }
    }
  }
};
</script>
<style scoped lang="scss">
div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  span{
    width: 50%;
  }
}
</style>
