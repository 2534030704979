<script>
export default {
  props: {
    value: {
      type:    String,
      default: '',
    },
  }
};
</script>
<template>
  <div>  <div class="icon" />{{ value }} <slot style="margin-left: 10px" /></div>
</template>
<style scoped >
.icon {
  width: 3px;
  height: 15px;
  background: var(--primary);
  margin-right: 10px;
}
.sectionTitle {
  margin: 10px 0px 10px 0px;
  background-color: #FAFAFA;
  padding: 5px 0px;
}
</style>
