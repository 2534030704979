<script>
import { LOGGED_OUT } from '@shell/config/query-params';
import { FLEET, MANAGEMENT, NORMAN } from '@shell/config/types';
import { BLANK_CLUSTER } from '@/shell/store';
import { PRODUCT_NAME as PAI } from '../../../pkg/pai/config/pai';
import { PRODUCT_NAME as PLATFORM } from '../../../pkg/pai/config/platform';
import { PRODUCT_NAME as DEPLOY } from '../../../pkg/pai/config/deploy';
import PromptChangePassword from '@shell/components/PromptChangePassword';
import NamespaceFilter from '@shell/components/pai/NamespaceFilter';
import { mapGetters } from 'vuex';
import Jump from '../../../pkg/pai/components/nav/Jump.vue';
import BatchImport from '@shell/components/pai/BatchImport.vue';
import { allHash } from '@shell/utils/promise';
import { isMac } from '@shell/utils/platform';
import ClusterSelector from '@shell/components/pai/ClusterSelector';

export default {
  components: {
    ClusterSelector,
    BatchImport,
    Jump,
    PromptChangePassword,
    NamespaceFilter
  },
  data() {
    const searchShortcut = isMac ? '(\u2318-K)' : '(Ctrl+K)';
    const shellShortcut = '(Ctrl+`)';

    return {
      searchShortcut,
      shellShortcut,
      LOGGED_OUT,
      BLANK_CLUSTER,
      PAI,
      PLATFORM,
      DEPLOY,
      kubeConfigCopying: false,
    };
  },
  computed: {
    ...mapGetters(['currentProduct', 'currentCluster']),
    principal() {
      return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
    },
    hasLocalAccess() {
      return this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined;
    },
    hasBundleAccess() {
      return this.$store.getters[`management/schemaFor`]( FLEET.BUNDLE ) !== undefined;
    },
    isHideFilter() {
      return this.currentProduct && (this.currentProduct.name === PLATFORM || this.currentProduct.name === DEPLOY);
    },
    importEnabled() {
      return !!this.currentCluster?.actions?.apply;
    },
    shellEnabled() {
      return !!this.currentCluster?.links?.shell;
    },
    showKubeConfig() {
      return !this.currentProduct?.hideKubeConfig;
    },

    showCopyConfig() {
      return !this.currentProduct?.hideCopyConfig;
    },
  },
  methods: {
    handleSelectMenu(key) {
      if (key === 'preference') {
        this.$router.push({ name: 'pai-prefs' });
      } else if (key === 'password') {
        this.$refs.promptChangePassword.show(true);
      } else if (key === 'logout') {
        this.$router.push({
          name:  'auth-logout',
          query: { [LOGGED_OUT]: true },
        });
      }
    },
    openImport() {
      this.$modal.show('importModal');
    },
    closeImport() {
      this.$modal.hide('importModal');
    },
    openSearch() {
      this.$modal.show('searchModal');
    },

    hideSearch() {
      this.$modal.hide('searchModal');
    },
    copyKubeConfig(event) {
      const button = event.target?.parentElement;

      if (this.kubeConfigCopying) {
        return;
      }

      this.kubeConfigCopying = true;

      if (button) {
        button.classList.add('header-btn-active');
      }

      // Make sure we wait at least 1 second so that the user can see the visual indication that the config has been copied
      allHash({
        copy:     this.currentCluster.copyKubeConfig(),
        minDelay: new Promise(resolve => setTimeout(resolve, 1000))
      }).finally(() => {
        this.kubeConfigCopying = false;

        if (button) {
          button.classList.remove('header-btn-active');
        }
      });
    },
  },
};
</script>

<template>
  <header ref="header">
    <div class="header">
      <div class="left">
        <div class="logo" />
        <div class="link">
          <nuxt-link
            :to="{name:'pai-home'}"
            :class="{focus:$route.name === 'pai-home'}"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAVFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////8wXzyWAAAAHHRSTlMA/z/pAi7UB/njNd4eBMJzGppb1aeG8LAM/ead4RhRRgAAAIRJREFUeJx90DkWwyAMBFCQELv33b7/PYNI7ECRTPmfitGI32mImlpMF0JnClBxPqQ85qiek1HLHD1+Ds2wvkXKdci2bzdwtj2RP0s6fSLAkhD+kdXa1mSXGBe2L4VeiD5UNKXearqJHPfmhvyDI97gcogtU4vorryHIgAuKDwAPY9XeQH9+wRvsHhy5gAAAABJRU5ErkJggg=="
              alt=""
            >
            <div>
              {{ t('headers.home') }}
            </div>
          </nuxt-link>
          <nuxt-link
            :to="{name:'pai-overview'}"
            :class="{focus:(($route.params.product === PAI || $route.name === 'pai-overview')) && $route.name !== 'pai-home'}"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAvVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+Ke75sAAAAP3RSTlMA/6/0jwX94AcC4uZjzTHdYBD5OInQkvLtgx19xtSin9ZADR+qUutqvAonsMC2KstXphP7ljpF72+b2i7DSa1qKehgAAAA4UlEQVR4nF3Q1ZLDMAwFUMkOMzkONVhm2C504f8/a+Om7XSqF43Og+5IMJTWFEWj3QZRuhO7hLixoz/EUkcT35+MVOtucow8z7KcB7F8JwlR2dnmWyE9E6J6XpAXskHePMg/CGLLdHrwBwnXVBC/nJDOnR46idezyFT6BDOyWJJmUKIbAsw+EBMPwKmxghUGtheZXMGTkXuM0hUQsYeu96roQW2VZKBx211pt1xUN5IgNEWfH48bAp7Rh31ZqnEJEH/2bPoJ+rmimBjRtksNpEnZiAPaX/b33b9P247t9xbgH5gGEYMyjAjnAAAAAElFTkSuQmCC"
              alt=""
            >
            <div>
              {{ t('headers.overview') }}
            </div>
          </nuxt-link>
          <nuxt-link
            :to="{name:'platform-c-cluster-platform-overview',params:{product:PLATFORM,cluster:currentCluster?currentCluster.id:BLANK_CLUSTER}}"
            :class="{focus:$route.params.product === PLATFORM }"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAflBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////vroaSAAAAKnRSTlMA/wUYxkXfIfWlcuBoNlfnD4c6JetfrPrkz0h0OI27fMgdK0DxbZKK/CBkNxvgAAAAqUlEQVR4nJWPRxKDMBAEDUrIIgpQJGf+/0EDwsXV7tt21UzNvv7BN5Qa/zkBX5V3oFYOnH7D4BQnAXxfioTd5szWheRSKdmjTBeFzqKdpC7IMJ2RMWimmLkg4EshEwgTWSwcOFXxPhYYi7jnlVOWBbCOxzGuYcCsq8/LxpswnrymzF09Cct8aLVuh7y8RxxTlbAIWaG+U30QZUwmiWRZdD90aUQpes4f+ADGLQp3T0cqhQAAAABJRU5ErkJggg=="
              alt=""
            >
            <div>
              {{ t('headers.platform') }}
            </div>
          </nuxt-link>
          <nuxt-link
            v-if="hasBundleAccess"
            :to="{name:'deploy-c-cluster-deploy',params:{product:DEPLOY,cluster:currentCluster?currentCluster.id:BLANK_CLUSTER}}"
            :class="{focus:$route.params.product === DEPLOY}"
          >
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAApVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+4/eNVAAAAN3RSTlMA/wP67kK8Oev2/VM0Dd3zFQXimtXmbxzHhrVgeNNJi84TqlgZTb9yO7iypqOUJa97LgplgI9Fp4UvAwAAALBJREFUeJyV0EcSgkAUBFCbGYYoIGAm52BO9z+aaAk1stK/fIvuXz35/4Q4FsYkOYw50hcLmk0AYmu8Zi4AqsLNOEyhyPK2Jkg5DMHKRSD6CDk8wWBM82R4HIpdJBoTROJwmnTlXX07HcgK8m1tK4rhHC7L/imRqsam2S8elr5O9AEBVV7d9HVE5n3qC00KulPU43n2wcqdU9Mv2miV9/Rur673TVFa4+kms2w83Q/3BK72CtTc0PuXAAAAAElFTkSuQmCC"
              alt=""
            >
            <div>
              {{ t('headers.cd') }}
            </div>
          </nuxt-link>
        </div>
      </div>
      <div class="right">
        <div
          v-if="!isHideFilter"
          class="actions"
        >
          <ClusterSelector style="margin-right: 10px" />
          <NamespaceFilter
            v-if="currentCluster"
            style="margin-right: 10px"
          />
          <modal
            class="import-modal"
            name="importModal"
            width="75%"
            height="auto"
            styles="max-height: 90vh;"
          >
            <BatchImport
              :cluster="currentCluster"
              @close="closeImport"
            />
          </modal>
          <modal
            class="search-modal"
            name="searchModal"
            width="50%"
            height="auto"
          >
            <Jump @closeSearch="hideSearch()" />
          </modal>
          <el-menu
            v-if="currentProduct && currentProduct.showClusterSwitcher"
            mode="horizontal"
          >
            <el-submenu  index="clusterTools">
              <template slot="title">
                {{ t('nav.clusterTools') }}
              </template>
              <el-menu-item :disabled="!importEnabled">
                <div
                  class="actionItem"
                  @click="openImport()"
                >
                  <i class="icon icon-upload icon-lg" /> {{ t('nav.import') }}
                </div>
              </el-menu-item>
              <el-menu-item :disabled="!shellEnabled">
                <div
                  v-shortkey="{windows: ['ctrl', '`'], mac: ['meta', '`']}"
                  class="actionItem"
                  @shortkey="currentCluster.openShell()"
                  @click="currentCluster.openShell()"
                >
                  <i class="icon icon-terminal icon-lg" />{{ t('nav.shellShortcut', {key: shellShortcut}) }}
                </div>
              </el-menu-item>
              <el-menu-item v-if="showKubeConfig">
                <div
                  class="actionItem"
                  @click="currentCluster.downloadKubeConfig()"
                >
                  <i class="icon icon-file icon-lg" />{{ t('nav.kubeconfig.download') }}
                </div>
              </el-menu-item>
              <el-menu-item v-if="showCopyConfig">
                <div
                  class="actionItem"
                  @click="copyKubeConfig($event)"
                >
                  <i
                    v-if="kubeConfigCopying"
                    class="icon icon-checkmark icon-lg"
                  />
                  <i
                    v-else
                    class="icon icon-copy icon-lg"
                  />
                  {{ t('generic.copy')+' KubeConfig' }}
                </div>
              </el-menu-item>
              <el-menu-item>
                <div
                  v-shortkey="{windows: ['ctrl', 'k'], mac: ['meta', 'k']}"
                  class="actionItem"
                  @shortkey="openSearch()"
                  @click="openSearch()"
                >
                  <i class="icon icon-search icon-lg" />
                  {{ t('nav.resourceSearch.toolTip', {key: searchShortcut}) }}
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <el-menu
          mode="horizontal"
          @select="handleSelectMenu"
        >
          <el-submenu index="user">
            <template slot="title">
              <i
                class="el-icon-user"
                style="marginRight: 5px"
              />
              {{ principal.loginName }}
            </template>
            <el-menu-item index="preference">
              {{ t('nav.userMenu.preferences') }}
            </el-menu-item>
            <el-menu-item index="password">
              {{ t('headers.password') }}
            </el-menu-item>
            <el-menu-item index="logout">
              {{ t('headers.logout') }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <PromptChangePassword ref="promptChangePassword" />
  </header>
</template>

<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background: #0C5489;
  min-width: 1280px;
  .left {
    display: flex;
    height: 100%;

    .logo {
      width: 150px;
      background-image: url('~pkg/pai/assets/images/logo/logo-h.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      margin-left: 10px;
    }

    .link {
      margin-left: 20px;
      font-size: 16px;
      display: flex;

      a {
        text-decoration: none;
        color: #fff;
        margin: 0 5px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;

        img {
          margin-right: 6px;
          width: 18px;
          height: 18px;
        }
      }

      .focus {
        background: #246FA5;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    height: 100%;

    .actions {
      display: flex;
      align-items: center;
    }

    i {
      color: #FFFFFF;
      margin: 0;
    }

    ::v-deep .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
      border: unset;
    }

    .el-menu--horizontal > .el-submenu {
      height: 100%;
      background: #0C5489;
    }

    ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 100%;
      line-height: 45px;
      color: #FFFFFF;
    }

    ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background: var(--primary);
      color: #FFFFFF;
      height: 100%;
    }

    .el-menu.el-menu--horizontal {
      height: 100%;
      border-bottom: unset;
    }

    ::v-deep .el-menu--horizontal .el-menu .el-menu-item {
      text-align: center;
      width: 100%;
      height: 30px;
      margin: auto;
    }
  }
}
.actionItem {
  text-align: left;
  i {
    margin-right: 10px;
  }
}
</style>
<style lang="scss">
.el-menu--popup {
  min-width: 130px;
  color: unset;

  .el-menu-item {
    margin: auto;
    height: 30px !important;
    text-align: center !important;
    color: black !important;
    &:hover, &._hover {
      background-color: var(--primary-hover-bg);
    }
  }
}
</style>
