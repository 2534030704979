import { convertType } from '~/pkg/pai/utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';

const PlatformLocationMixin = Base => class extends Base {
  get detailLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      const schema = this.$getters['schemaFor'](this.type);
      const id = this.id?.replace(/.*\//, '');

      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource${ schema?.attributes?.namespaced ? '-namespace' : '' }-id`,
        params: {
          product:   PRODUCT_NAME,
          cluster:   this.$rootGetters['clusterId'],
          resource:  convertType(this.type),
          namespace: this.metadata?.namespace,
          id,
        },
      };
    }

    return super.detailLocation;
  }

  get doneOverride() {
    return this.listLocation;
  }

  get listLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.$rootGetters['clusterId'],
          resource: convertType(this.type),
        },
      };
    }

    return super.listLocation;
  }
};

export default PlatformLocationMixin;
