<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import { LabeledInput } from '@components/Form/LabeledInput';
import CruResource from '@shell/components/CruResource';
import { _CREATE, _EDIT, _VIEW } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import { PAI_PLATFORM_SETTING_TYPES } from '@/pkg/pai/config/types';

export default {
  components: {
    CruResource,
    LabeledInput,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: _EDIT
    },
    realMode: {
      type:    String,
      default: _EDIT
    }
  },
  async fetch() {
    this.data = await this.$store.dispatch('management/findAll', { type: PAI_PLATFORM_SETTING_TYPES.GATEWAY });
  },
  data() {
    return { data: [], passwd: this.value.spec?.passwd ? Buffer.from(this.value.spec.passwd, 'base64').toString() : '' };
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    _CREATE() {
      return _CREATE;
    },
    _EDIT() {
      return _EDIT;
    },
    _VIEW() {
      return _VIEW;
    }
  },
  methods: {
    onChange(e) {
      this.value.spec.passwd = Buffer.from(e).toString('base64');
      this.passwd = Buffer.from(this.value.spec.passwd, 'base64').toString();
    },
    done() {
      this.$router.go(-1);
    },
    async save(saveCb) {
      try {
        if (this.mode === 'create') {
          for (const item in this.data) {
            if (this.data[item].metadata.name === this.value.metadata.name) {
              this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

              return;
            }
          }
        }
        if (!this.value.metadata.name || !this.value.spec.addr || !this.value.spec.user || !this.passwd) {
          this.$message.error(this.t('pai.edit.tips.required'));
          saveCb(false);

          return;
        } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.annotations['picloud/alias']) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.addr) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.user)) {
          this.$message.error(this.t('pai.edit.tips.specialCharacters'));
          saveCb(false);

          return;
        }
        await this.value.save();
        this.$message.success(this.t('pai.edit.tips.success'));
        saveCb(true);
        this.done();
      } catch (e) {
        saveCb(false);
        this.$message.error(e);
      }
    }
  },
  created() {
    // Add fields missing when creating new
    const obj = {
      addr: '', user: '', passwd: ''
    };

    if (!this.value.spec) {
      this.$set(this.value, 'spec', obj);
    } else {
      for (const key in obj) {
        if (!this.value.spec[key]) {
          this.value.spec[key] = obj[key];
        }
      }
    }
    this.$set(this.value.metadata, 'namespace', 'picloud-system');
  }

};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="save"
    @cancel="done"
  >
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.metadata.name"
        type="text"
        :required="true"
        :label="t('pai.edit.gateway.name')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.gateway.name')"
        :mode="(realMode===_EDIT||realMode===_VIEW) ? _VIEW : _EDIT"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.metadata.annotations['picloud/alias']"
        type="text"
        :label="t('pai.edit.alias')"
        :placeholder="
          t('pai.edit.placeholder') +
            t('pai.edit.alias')
        "
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.addr"
        type="text"
        :required="true"
        :label="t('pai.edit.gateway.address')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.gateway.address')"
        :mode="mode"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        v-model.trim="value.spec.user"
        type="text"
        :required="true"
        :label="t('pai.edit.gateway.user')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.gateway.user')"
        :mode="mode"
        readonly
        onfocus="this.removeAttribute('readonly')"
        @input="$forceUpdate()"
      />
    </div>
    <div class="detail mb-20">
      <LabeledInput
        :value="passwd"
        type="password"
        :required="true"
        :label="t('pai.edit.gateway.password')"
        :placeholder="t('pai.edit.placeholder')+t('pai.edit.gateway.password')"
        :mode="mode"
        readonly
        onfocus="this.removeAttribute('readonly')"
        @input="onChange"
      />
    </div>
  </CruResource>
</template>
