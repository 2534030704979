<script>
import * as echarts from 'echarts';
import day from 'dayjs';
import { mapGetters } from 'vuex';
import { PVC, POD } from '@/shell/config/types';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  name:   'OverView',
  layout: 'pai/default',
  props:  {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    pod: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    activeName: {
      type:    String,
      default: () => {
        return '';
      }
    }
  },
  data() {
    const params = this.$route.params;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const cpuNumber = this.value.spec?.template?.spec?.containers[0]?.resources?.requests?.cpu ? this.value.spec?.template?.spec?.containers[0].resources.requests.cpu : 0;
    const ramNumber = this.value.spec?.template?.spec?.containers[0]?.resources?.requests?.memory ? this.value.spec?.template?.spec?.containers[0].resources.requests.memory : 0;

    return {
      params,
      dateFormat,
      options:         [],
      optionValue:     '',
      cpuVisible:      false,
      ramVisible:      false,
      networkData:     [],
      networkValue:    '',
      ipData:          [],
      ipValue:         '',
      macData:         '',
      cpuData:         Number((cpuNumber)) || Number((cpuNumber)) === 0 ? Number((cpuNumber)) : Number((cpuNumber).match(/\d+/g)[0]) / 1000,
      ramData:         (Number((ramNumber)) || Number((ramNumber)) === 0 ? Number((ramNumber)) : Number((ramNumber).match(/\d+/g)[0]) / 1024).toFixed(2),
      cpuInput:        '',
      ramInput:        '',
      newArr:          [],
      startTime:       new Date(String(day().subtract(1, 'hours').format(dateFormat))).getTime() / 1000,
      endTime:         new Date(String(day().format(dateFormat))).getTime() / 1000,
      nadList:         [],
      nadType:         '',
      hostNetworkName: '',
      diskTable:       [],
      diskOptionValue: '',
      storageClass:    '',
      size:            '',
      pvcNames:        [],
      disks:           [],
      podList:         [],
      pvcs:            [],
      getCpuUsedRate:  [],
      getRamUsedRate:  [],
      getNetLine:      [],
      getDiskIOPS:     []
    };
  },
  async fetch() {
    this.nadList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.NAD });
    this.disks = await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.podList = await this.$store.dispatch('cluster/findAll', { type: POD });
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.diskNet();
  },
  async created() {
    this.getCpuUsedRate = await this.$store.dispatch('pai-grafana/getCpuUsedRate', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
      step:      300,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.getRamUsedRate = await this.$store.dispatch('pai-grafana/getRamUsedRate', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
      step:      300,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.getNetLine = await this.$store.dispatch('pai-grafana/getNetLine', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
      step:      300,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.getDiskIOPS = await this.$store.dispatch('pai-grafana/getDiskIOPS', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
      step:      300,
      startTime: this.startTime,
      endTime:   this.endTime
    });
    this.$store.dispatch('pai-grafana/getCpu', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
    });
    this.$store.dispatch('pai-grafana/getRamDashboard', {
      cluster:   this.params.cluster,
      namespace: this.value.metadata.namespace,
      podName:   this.pod.name,
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.cpuUsedRate();
      this.ramUsedRate();
      this.getNetLineRate();
      this.getDiskIOPSRate();
      this.getCpuDashboard();
      this.getRamDashboard();
    });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    podStatusStyle() {
      return this.pod.status === 'Succeeded' ? '#81B337' : this.pod.status === 'Pending' ? '#4095E5' : this.pod.status === 'Running' ? '#A2EF4D' : this.pod.status === 'Stopped' ? '#BEBEBE' : '#C85352';
    },
    podStatusText() {
      return this.pod.status === 'Succeeded' ? '成功' : this.pod.status === 'Pending' ? '启动中' : this.pod.status === 'Running' ? '运行中' : this.pod.status === 'Stopped' ? '关闭' : '异常';
    },
    bandwidthEnter() {
      const netLine = this.$store.state['pai-grafana'].netLine;
      let allBandwidthEnter = 0;

      netLine[0]?.nodeValuesData?.forEach((item) => { // 入
        allBandwidthEnter += Number(item);
      });

      return allBandwidthEnter === 0 ? '-' : netLine[0]?.nodeValuesData?.length === undefined ? '-' : ((allBandwidthEnter / netLine[0]?.nodeValuesData?.length)).toFixed(2);
    },
    bandwidthOut() {
      const netLine = this.$store.state['pai-grafana'].netLine;
      let allBandwidthOut = 0;

      netLine[1]?.nodeValuesData?.forEach((item) => { // 出
        allBandwidthOut += Number(item);
      });

      return allBandwidthOut === 0 ? '-' : netLine[1]?.nodeValuesData?.length === undefined ? '-' : ((allBandwidthOut / netLine[1]?.nodeValuesData?.length)).toFixed(2);
    },
    burstEnter() {
      const netLine = this.$store.state['pai-grafana'].netLine;

      return netLine[0]?.nodeValuesData === undefined ? 0 : (((netLine[0]?.nodeValuesData[0]) / netLine[0]?.nodeValuesData?.length)).toFixed(2);
    },
    burstOut() {
      const netLine = this.$store.state['pai-grafana'].netLine;

      return netLine[1]?.nodeValuesData === undefined ? 0 : (((netLine[1]?.nodeValuesData[0]) / netLine[1]?.nodeValuesData?.length)).toFixed(2);
    },
    allCpu() {
      return this.$store.state['pai-grafana'].allCpu;
    },
    ramDashboard() {
      return this.$store.state['pai-grafana'].ramDashboard;
    },
    podName() {
      let podName = '';

      this.podList.forEach((item) => {
        if (item.metadata.name === this.pod.name) {
          podName = item.metadata.name;
        }
      });

      return podName;
    }
  },
  watch: {
    getCpuUsedRate() {
      this.cpuUsedRate();
    },
    getRamUsedRate() {
      this.ramUsedRate();
    },
    getNetLine() {
      this.getNetLineRate();
    },
    getDiskIOPS() {
      this.getDiskIOPSRate();
    },
    allCpu() {
      this.getCpuDashboard();
    },
    ramDashboard() {
      this.getRamDashboard();
    },
    'pod.name': {
      handler() {
        this.$store.dispatch('pai-grafana/getCpuUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
          step:      300,
          startTime: this.startTime,
          endTime:   this.endTime
        });
        this.$store.dispatch('pai-grafana/getRamUsedRate', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
          step:      300,
          startTime: this.startTime,
          endTime:   this.endTime
        });
        this.$store.dispatch('pai-grafana/getNetLine', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
          step:      300,
          startTime: this.startTime,
          endTime:   this.endTime
        });
        this.$store.dispatch('pai-grafana/getDiskIOPS', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
          step:      300,
          startTime: this.startTime,
          endTime:   this.endTime
        });
        this.$store.dispatch('pai-grafana/getCpu', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
        });
        this.$store.dispatch('pai-grafana/getRamDashboard', {
          cluster:   this.params.cluster,
          namespace: this.value.metadata.namespace,
          podName:   this.pod.name,
        });
        this.diskNet()
      },
      deep: true
    },
    activeName(val) {
      if (val === 'overview') {
        this.cpuUsedRate();
        this.ramUsedRate();
        this.getNetLineRate();
        this.getDiskIOPSRate();
        this.getCpuDashboard();
        this.getRamDashboard();
      }
    }
  },
  methods: {
    diskNet() {
      const list = [];

      if (this.value?.status?.instances !== undefined && Object.keys(this.value?.status?.instances).length > 0) {
        this.podList.forEach((item) => {
          if (item.metadata.name === this.pod.name) {
          }
        });
      }
      if (this.value?.status?.instances !== undefined && Object.keys(this.value?.status?.instances).length > 0) {
        const info = this.value.status.instances;

        for (const key in info) {
          list.push({ podName: key, ...info[key] });
        }
        list.filter(list => list.podName === this.pod.name).map((net) => {
          net?.net?.forEach((n, i) => {
            this.networkData[i] = {
              interface: n.interface,
              name:      n.name,
              mac:       n.mac,
              ip:        n.ips
            };
          });
        });
        this.nadList.forEach((item, index) => {
          const config = item.spec.config ? JSON.parse(item.spec.config) : '';

          list[0]?.net?.forEach((n, i) => {
            if (item.metadata.name === n.name?.split('/')[1]) {
              this.networkData[i] = {
                ...this.networkData[i],
                type:            config?.type,
                addressType:     config?.ipam?.type,
                hostNetworkName: config?.uplink,
                createTime:      item.metadata?.creationTimestamp
              };
            }
          });
        });
        this.networkValue = this.networkData.length > 0 ? this.networkData[0].interface : '';
        this.ipData = this.networkData.length > 0 ? this.networkData[0].ip : [];
        this.ipValue = this.ipData[0];
        this.macData = this.networkData.length > 0 ? this.networkData[0].mac : '';
        this.nadType = this.networkData.length > 0 ? this.networkData[0].type : '';
        this.hostNetworkName = this.networkData.length > 0 ? this.networkData[0].hostNetworkName : '';
      }
      //  磁盘
      const newDiskTable = [];
      const podDisk = [];

      this.podList.forEach((item) => {
        if (item.metadata.name === this.pod.name) {
          item.spec.volumes.forEach((disk) => {
            if (disk.persistentVolumeClaim) {
              podDisk.push(disk);
            }
          });
        }
      });
      this.pvcs?.map((disk) => {
        podDisk?.forEach((podDisk) => {
          if (disk?.metadata?.name === podDisk.name) {
            newDiskTable.push({
              name:         disk.metadata.name,
              type:         disk.metadata.name.includes('vmosdisk') ? this.t('pai.vmset.storage.os') : this.t('pai.vmset.storage.data'),
              status:       disk.status.phase,
              volume:       disk.spec.volumeName,
              mode:         disk.spec.accessModes[0],
              storageClass: disk.spec.storageClassName,
              volumeType:   disk.spec.volumeMode,
              time:         disk.metadata.creationTimestamp,
              size:         disk.status?.capacity?.storage,
            });
          }
        });
      });
      const newArr = newDiskTable.filter(
        (obj, index) => newDiskTable.findIndex(item => item.name === obj.name) === index);

      this.diskTable = newArr;
      this.diskOptionValue = this.diskTable[0]?.name;
      this.storageClass = this.diskTable[0]?.storageClass;
      this.size = this.diskTable[0]?.size;
    },
    diskChange(value) {
      this.diskOptionValue = value;
      this.diskTable.map((item, index) => {
        if (item.name === value) {
          this.storageClass = item.storageClass;
          this.size = item.size;
        }
      });
    },
    async changeCpu() {
      this.cpuVisible = false;
      this.value.spec.containers.map((item, index) => {
        item.resources.requests.cpu = `${ Number(this.cpuInput) * 1000 }m`;
      });
      await this.value.save();
    },
    async changeRam() {
      this.ramVisible = false;
      this.value.spec.containers.map((item, index) => {
        item.resources.requests.memory = `${ Number(this.ramInput) * 1024 }Mi`;
      });
      await this.value.save();
    },
    ipChange(value) {
      this.ipValue = value;
    },
    changeNetwork(value) {
      this.networkData.map((item) => {
        if (item.interface === value) {
          this.ipData = item.ip;
          this.macData = item.mac;
          this.nadType = item.type;
          this.hostNetworkName = item.hostNetworkName;
        }
      });
      this.ipValue = this.ipData[0];
    },
    cpuUsedRate() {
      const myChart = echarts.init(this.$refs.cpuUsedRef);
      const option = {
        title: {
          text:      this.getCpuUsedRate?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        grid: {
          show:         true, // 是否显示图表背景网格
          left:         '1%', // 图表距离容器左侧多少距离
          right:        '7%', // 图表距离容器右侧侧多少距离
          bottom:       40, // 图表距离容器下面多少距离
          top:          '16%', // 图表距离容器上面多少距离
          containLabel: true, // 防止标签溢出
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${ params[0].marker }&nbsp;${ params[0]?.seriesName }&nbsp;` + `<span style="float: right">${ params[0].value }%</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this.getCpuUsedRate[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } },
        },
        yAxis: {
          type:    'value',
          name:    '单位：%',
          nameGap: '10',
          max:     100,
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.getCpuUsedRate[0]?.pod,
            smooth:    true,
            symbol:    this.getCpuUsedRate[0]?.nodeValuesDate?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getCpuUsedRate?.length === 0 ? [] : this.getCpuUsedRate[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(178,157,225,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(178,157,225,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(178,157,225,0.1)'
                }
              ])
            },
          },
        ],

      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    ramUsedRate() {
      const myChart = echarts.init(this.$refs.ramUsedRef);
      const option = {
        title: {
          text:      this.getRamUsedRate?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        grid: {
          show:         true, // 是否显示图表背景网格
          left:         '1%', // 图表距离容器左侧多少距离
          right:        '7%', // 图表距离容器右侧侧多少距离
          bottom:       20, // 图表距离容器下面多少距离
          // top:          10, // 图表距离容器上面多少距离
          containLabel: true, // 防止标签溢出
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${ params[0].marker }&nbsp;${ params[0]?.seriesName }&nbsp;` + `<span style="float: right">${ params[0].value }%</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this.getRamUsedRate[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:    'value',
          name:    '单位：%',
          nameGap: '10',
          max:     100,
          min:     0,
        },
        series: [
          {
            type:      'line',
            name:      this.getRamUsedRate[0]?.pod,
            smooth:    true,
            symbol:    this.getRamUsedRate[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getRamUsedRate?.length === 0 ? [] : this.getRamUsedRate[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(178,157,225,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(178,157,225,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(178,157,225,0.1)'
                }
              ])
            },
          },
        ],

      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getNetLineRate() {
      const myChart = echarts.init(this.$refs.netRate);
      const option = {
        title: {
          text:      this.getNetLine?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        grid: {
          show:         true, // 是否显示图表背景网格
          left:         '1%', // 图表距离容器左侧多少距离
          right:        '7%', // 图表距离容器右侧侧多少距离
          bottom:       24, // 图表距离容器下面多少距离
          // top:          -10, // 图表距离容器上面多少距离
          containLabel: true, // 防止标签溢出
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }M</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }M</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this.getNetLine?.length === 0 ? [] : this.getNetLine[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:          'value',
          name:          '单位：M',
          nameGap:       '10',
          min:           0,
          nameTextStyle: { padding: [0, 0, 0, 50] }
        },
        series: [
          {
            type:      'line',
            name:      this.getNetLine[0]?.node,
            smooth:    true,
            symbol:    this.getNetLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getNetLine?.length === 0 ? [] : this.getNetLine[0]?.nodeValuesData,
            itemStyle: { color: '#4097D5' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  '#4097D5'
                },
                {
                  offset: 1,
                  color:  '#DBE4EF'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.getNetLine[1]?.node,
            smooth:    true,
            symbol:    this.getNetLine[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getNetLine?.length === 0 ? [] : this.getNetLine[1]?.nodeValuesData,
            itemStyle: { color: '#C75250' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  '#C75250'
                },
                {
                  offset: 1,
                  color:  '#F7ECEC'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getDiskIOPSRate() {
      const myChart = echarts.init(this.$refs.diskIOPS);
      const option = {
        title: {
          text:      this.getDiskIOPS?.length === 0 ? this.t('pai.detail.vmset.noData') : '', // 暂无数据
          x:         'center',
          y:         'center',
          textStyle: {
            fontSize:   14,
            fontWeight: 'normal',
          }
        },
        legend: {
          type:              'scroll',
          pageButtonItemGap: 10,
          data:              [],
          textStyle:         { color: 'black' },
          pageTextStyle:     { color: 'black' },
          itemWidth:         15,
          top:               10,
          left:              'center'
        },
        grid: {
          show:         true, // 是否显示图表背景网格
          left:         '1%', // 图表距离容器左侧多少距离
          right:        '7%', // 图表距离容器右侧侧多少距离
          bottom:       20, // 图表距离容器下面多少距离
          // top:          -10, // 图表距离容器上面多少距离
          containLabel: true, // 防止标签溢出
        },
        textStyle: { color: 'black' },
        tooltip:   {
          trigger:  'axis',
          position: 'left',
          formatter(params) {
            return `${ params[0].axisValueLabel }<br>${
              params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }(次/s）</span>` + `<br>${
              params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }(次/s）</span>`;
          }
        },
        xAxis: {
          type:        'category',
          boundaryGap: false,
          data:        this.getDiskIOPS?.length === 0 ? [] : this.getDiskIOPS[0]?.nodeValuesDate,
          axisLine:    { lineStyle: { color: 'black' } }
        },
        yAxis: {
          type:          'value',
          name:          '单位：次/s',
          nameGap:       '10',
          min:           0,
          nameTextStyle: { padding: [0, 0, 0, 50] }
        },
        series: [
          {
            type:      'line',
            name:      this.getDiskIOPS[0]?.node,
            smooth:    true,
            symbol:    this.getDiskIOPS[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getDiskIOPS?.length === 0 ? [] : this.getDiskIOPS[0]?.nodeValuesData,
            itemStyle: { color: 'rgba(189,183,107,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(189,183,107,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(189,183,107,0.1)'
                }
              ])
            },
          },
          {
            type:      'line',
            name:      this.getDiskIOPS[1]?.node,
            smooth:    true,
            symbol:    this.getDiskIOPS[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
            sampling:  'average',
            data:      this.getDiskIOPS?.length === 0 ? [] : this.getDiskIOPS[1]?.nodeValuesData,
            itemStyle: { color: 'rgba(32,178,170,0.7)' },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color:  'rgba(32,178,170,0.7)'
                },
                {
                  offset: 1,
                  color:  'rgba(32,178,170,0.1)'
                }
              ])
            },
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getCpuDashboard() {
      const myChart = echarts.init(this.$refs.cpuDashboard);
      const option = {
        series: [
          {
            type:     'gauge',
            title:    { show: true },
            axisLine: {
              lineStyle: {
                width: 11,
                color: [
                  [0.3, '#47C38C'],
                  [0.7, '#DDC76A'],
                  [1, '#C95957']
                ]
              }
            },
            pointer:  { itemStyle: { color: '#256FA5' } },
            axisTick: {
              distance:    -10,
              splitNumber: 1,
              length:      8,
              lineStyle:   {
                color: '#ccc',
                width: 2
              }
            },
            splitLine: {
              distance:  -30,
              length:    30,
              lineStyle: {
                color: '#fff',
                width: -1
              }
            },
            axisLabel: {
              color:    '#ccc',
              distance: 20,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              formatter:      `${ this.allCpu }%`,
              color:          '#000',
              fontSize:       10
            },
            data: [
              {
                value: this.allCpu,
                name:  this.t('pai.detail.vmset.cpuUtilization'),
                title: {
                  show:         true,
                  offsetCenter: [-5, '65%'],
                  fontSize:     10
                }
              }
            ]
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getRamDashboard() {
      const myChart = echarts.init(this.$refs.ramDashboard);
      const option = {
        series: [
          {
            type:     'gauge',
            title:    { show: true },
            axisLine: {
              lineStyle: {
                width: 11,
                color: [
                  [0.3, '#47C38C'],
                  [0.7, '#DDC76A'],
                  [1, '#C95957']
                ]
              }
            },
            pointer:  { itemStyle: { color: '#256FA5' } },
            axisTick: {
              distance:    -10,
              splitNumber: 1,
              length:      8,
              lineStyle:   {
                color: '#ccc',
                width: 2
              }
            },
            splitLine: {
              distance:  -30,
              length:    30,
              lineStyle: {
                color: '#fff',
                width: -1
              }
            },
            axisLabel: {
              color:    '#ccc',
              distance: 20,
              fontSize: 10
            },
            detail: {
              valueAnimation: true,
              formatter:      `${ this.ramDashboard }%`,
              color:          '#000',
              fontSize:       10
            },
            data: [
              {
                value: this.ramDashboard,
                name:  this.t('pai.detail.vmset.ramUtilization'),
                title: {
                  show:         true,
                  offsetCenter: [-5, '65%'],
                  fontSize:     10
                }
              }
            ]
          }
        ]
      };

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
  }
};
</script>
<template>
  <el-card>
    <el-row :gutter="20">
      <el-col :span="8">
        <div style="border-left: 3px solid #246FA5">
            &emsp;{{ t('pai.detail.vmset.tab.overview.basicInfo') }}
        </div>
        <el-row
          :gutter="20"
          class="left"
        >
          <el-col
            :span="24"
          >
            <el-card class="basicInfo">
              <el-row
                :gutter="20"
                style="margin-left: 20px;margin-right: -25px;"
              >
                <div class="basicEachTab">
                  <el-col :span="5">
                    {{ t('pai.detail.vmset.tab.overview.node') }}：
                  </el-col><el-col :span="15">
                    {{ (!pod.node || pod.node === '-'? '调度中': pod.node) }}
                  </el-col>
                </div>
                <div class="basicEachTab">
                  <el-col :span="5">
                    {{ t('pai.detail.vmset.tab.overview.relevancePod') }}：
                  </el-col><el-col :span="15">
                    <span v-if="!podName">暂无可链接pod</span>
                    <nuxt-link
                      v-else
                      :to="{name:'pai-c-cluster-resource-namespace-id',params:{product:params.product,resource:'pai.pod',cluster:params.cluster,namespace:value.metadata.namespace,id:pod.name}}"
                    >
                      {{ podName }}
                    </nuxt-link>
                  </el-col>
                </div>

                <div class="basicEachTab">
                  <el-col :span="5">
                    {{ t('pai.detail.vmset.tab.overview.status') }}：
                  </el-col><el-col :span="15">
                    <div style="display: flex">
                      <div :style="{width: '9px', height: '9px', background: podStatusStyle, borderRadius: '50%', marginTop: '10px', marginRight: '3px'}" />
                      <div>
                        {{ podStatusText }}
                        <el-tooltip
                          v-if="podStatusText === '异常'"
                          :content="pod.status"
                          placement="top"
                          effect="light"
                        >
                          <img src="@pkg/pai/assets/images/overview/tooltips.svg">
                        </el-tooltip>
                      </div>
                    </div>
                  </el-col>
                </div>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24">
            <el-card style="margin-bottom: 10px; height: 100px;">
              <el-row>
                <el-col :span="5">
                  <img
                    src="../../../assets/vmImage/overView-disk.png"
                    style="margin-top: 10px"
                  >
                </el-col>
                <el-col :span="19">
                  <el-row>
                    <el-col :span="24">
                      <el-select
                        v-model="diskOptionValue"
                        :placeholder="t('pai.detail.vmset.placeholderSelect')"
                        style="width: 220px"
                        filterable
                        @change="diskChange"
                      >
                        <el-option
                          v-for="(item,index) in diskTable"
                          :key="index"
                          :label="item.name"
                          :value="item.name"
                        />
                      </el-select>
                    </el-col>
                    <el-col :span="24">
                      <span>{{ t('pai.detail.vmset.tab.overview.diskType') }}: {{ storageClass }}</span>
                      <span>{{ t('pai.detail.vmset.tab.overview.diskSize') }}: {{ size }}</span>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="24">
            <el-card>
              <el-row>
                <el-col :span="5">
                  <div style="margin-top: 60px">
                    <el-row>
                      <el-col :span="24">
                        <img src="../../../assets/vmImage/overView-netWork.png">
                      </el-col>
                      <el-col :span="24">
                        <el-select
                          v-model="networkValue"
                          :placeholder="t('pai.detail.vmset.placeholderSelect')"
                          filterable
                          @change="changeNetwork"
                        >
                          <el-option
                            v-for="(item, index) in networkData"
                            :key="index"
                            :label="item.interface"
                            :value="item.interface"
                          />
                        </el-select>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="19">
                  <div class="netClass">
                    <p>{{ t('pai.detail.vmset.tab.overview.networkType') }}: {{ networkValue === 'eth0'? '管理网卡': nadType }}</p>
                    <p>
                      {{ t('pai.detail.vmset.tab.overview.ipAddress') }}:
                      <el-select
                        v-model="ipValue"
                        style="margin-left: 10px"
                        filterable
                        @change="ipChange"
                      >
                        <el-option
                          v-for="(item, index) in ipData"
                          :key="index"
                          :label="item"
                          :value="item"
                        />
                      </el-select>
                    </p>
                    <p>{{ t('pai.detail.vmset.tab.overview.macAddress') }}:  {{ macData }}</p>
                    <p>{{ t('pai.detail.vmset.tab.overview.virtualSwitch') }}: {{ hostNetworkName }}</p>
                    <p>{{ t('pai.detail.vmset.tab.overview.outBandwidth') }}（kbps）: {{ bandwidthEnter }}</p>
                    <p>{{ t('pai.detail.vmset.tab.overview.outCache') }}（kbps）: {{ burstEnter }}</p>
                    <p>{{ t('pai.detail.vmset.tab.overview.enterBandwidth') }}（kbps）: {{ bandwidthOut }}</p>
                    <p>{{ t('pai.detail.vmset.tab.overview.enterCache') }}（kbps）: {{ burstOut }}</p>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <div style="border-left: 3px solid #246FA5">
          &emsp;{{ t('pai.detail.vmset.tab.overview.performanceInformation') }}
        </div>
        <el-card style="margin-top: 10px">
          <el-row
            :gutter="20"
            style="margin-bottom: -16px"
          >
            <el-col :span="24">
              <el-row class="dashboardCpuRam">
                <el-col :span="12">
                  <div
                    ref="cpuDashboard"
                    style="width: 100%; height: 180px"
                  />
                </el-col>
                <el-col :span="12">
                  <div
                    ref="ramDashboard"
                    style="width: 100%; height: 180px"
                  />
                </el-col>
                <el-col :span="24">
                  <div style="width: 105%; background: #FBF9FC; height: 30px; line-height: 30px; margin-left: -20px;">
                    <div style="margin-left: 10px;">
                      {{ t('pai.detail.vmset.cpuUtilization') }}（{{ t('pai.detail.vmset.tab.overview.lastHour') }}）
                    </div>
                  </div>
                  <div
                    ref="cpuUsedRef"
                    style="width: 100%; height: 190px"
                  />
                </el-col>
                <el-col :span="24">
                  <div style="width: 105%; background: #FBF9FC; height: 30px; line-height: 30px; margin-left: -20px; position: absolute;">
                    <div style="margin-left: 10px;">
                      {{ t('pai.detail.vmset.ramUtilization') }}（{{ t('pai.detail.vmset.tab.overview.lastHour') }}）
                    </div>
                  </div>
                  <div
                    ref="ramUsedRef"
                    style="width: 100%; height: 190px"
                  />
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-col :span="24">
            <div style="border-left: 3px solid #246FA5;">
              &emsp;{{ t('pai.detail.vmset.tab.overview.throughput') }}（{{ t('pai.detail.vmset.tab.overview.lastHour') }}）
            </div>
            <el-card style="margin-top: 10px">
              <div
                ref="netRate"
                style="width: 100%; height: 210px"
              />
            </el-card>
          </el-col>
          <el-col :span="24">
            <div style="border-left: 3px solid #246FA5; margin-top: 10px;">
              &emsp;{{ t('pai.detail.vmset.tab.overview.diskRequest') }} IOPS（{{ t('pai.detail.vmset.tab.overview.lastHour') }}）
            </div>
            <el-card style="margin-top: 10px">
              <div
                ref="diskIOPS"
                style="width: 100%; height: 210px"
              />
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-card>
</template>
<style>
.popperClass .el-popover__title{
  background-color: #246FA5;
  color: #fff;
  width: 380px;
  height: 40px;
  line-height: 40px;
  margin-left: -13px;
  margin-top: -13px;
  padding-left: 15px;
  border-radius: 4px;
}
.el-tooltip__popper {
  max-width: 500px !important;
}
</style>
<style lang="scss" scoped>
.left{
  .el-card{
    background-color: #FAFAFA;
  }
  .basicInfo{
    margin: 10px 0;
    padding-left: -20px;
    line-height: 30px;
    .basicEachTab{
      margin-left: -15px;
      display: flex;
      ::v-deep .el-col-5{
        width: 90px;
        text-align: left;
      }
      .el-col-16{
        margin-left: 10px;
      }
    }
  }
}
.dashboardCpuRam{
  ::v-deep .el-col-12{
    margin-top: -25px;
  }
  ::v-deep .el-col-24{
    margin-top: -25px;
  }
}
.netClass{
  p{
    line-height: 30px;
  }
}
::v-deep .el-select .el-input__inner{
  border: none;
  background-color: #FAFAFA;
  margin-left: -10px;
}
</style>
