import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../shell/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_portalvue_198f83b1 from 'nuxt_plugin_portalvue_198f83b1' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_3a33618a from 'nuxt_plugin_cookieuniversalnuxt_3a33618a' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_516dc5e2 from 'nuxt_plugin_axios_516dc5e2' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_plugins_7c067ab2 from 'nuxt_plugin_plugins_7c067ab2' // Source: ../shell/core/plugins.js (mode: 'all')
import nuxt_plugin_pluginsloader_28e5d348 from 'nuxt_plugin_pluginsloader_28e5d348' // Source: ../shell/core/plugins-loader.js (mode: 'all')
import nuxt_plugin_axios_1ad47a4f from 'nuxt_plugin_axios_1ad47a4f' // Source: ../shell/plugins/axios (mode: 'all')
import nuxt_plugin_tooltip_d4a5a8c8 from 'nuxt_plugin_tooltip_d4a5a8c8' // Source: ../shell/plugins/tooltip (mode: 'all')
import nuxt_plugin_vueclipboard2_260c7e4a from 'nuxt_plugin_vueclipboard2_260c7e4a' // Source: ../shell/plugins/vue-clipboard2 (mode: 'all')
import nuxt_plugin_vselect_52cc65ba from 'nuxt_plugin_vselect_52cc65ba' // Source: ../shell/plugins/v-select (mode: 'all')
import nuxt_plugin_directives_2479586a from 'nuxt_plugin_directives_2479586a' // Source: ../shell/plugins/directives (mode: 'all')
import nuxt_plugin_transitions_8bcbb652 from 'nuxt_plugin_transitions_8bcbb652' // Source: ../shell/plugins/transitions (mode: 'all')
import nuxt_plugin_vuejsmodal_74254d97 from 'nuxt_plugin_vuejsmodal_74254d97' // Source: ../shell/plugins/vue-js-modal (mode: 'all')
import nuxt_plugin_jsyaml_f0345f78 from 'nuxt_plugin_jsyaml_f0345f78' // Source: ../shell/plugins/js-yaml (mode: 'client')
import nuxt_plugin_resize_5bb5e9fb from 'nuxt_plugin_resize_5bb5e9fb' // Source: ../shell/plugins/resize (mode: 'client')
import nuxt_plugin_shortkey_46200fea from 'nuxt_plugin_shortkey_46200fea' // Source: ../shell/plugins/shortkey (mode: 'client')
import nuxt_plugin_i18n_b3ed25b6 from 'nuxt_plugin_i18n_b3ed25b6' // Source: ../shell/plugins/i18n (mode: 'all')
import nuxt_plugin_globalformatters_35acadac from 'nuxt_plugin_globalformatters_35acadac' // Source: ../shell/plugins/global-formatters (mode: 'all')
import nuxt_plugin_trimwhitespace_3c4277a1 from 'nuxt_plugin_trimwhitespace_3c4277a1' // Source: ../shell/plugins/trim-whitespace (mode: 'all')
import nuxt_plugin_extendrouter_84651396 from 'nuxt_plugin_extendrouter_84651396' // Source: ../shell/plugins/extend-router (mode: 'all')
import nuxt_plugin_console_125840f0 from 'nuxt_plugin_console_125840f0' // Source: ../shell/plugins/console (mode: 'client')
import nuxt_plugin_intnumber_2e79f6a4 from 'nuxt_plugin_intnumber_2e79f6a4' // Source: ../shell/plugins/int-number (mode: 'client')
import nuxt_plugin_nuxtclientinit_760372e2 from 'nuxt_plugin_nuxtclientinit_760372e2' // Source: ../shell/plugins/nuxt-client-init (mode: 'client')
import nuxt_plugin_replaceall_d238a798 from 'nuxt_plugin_replaceall_d238a798' // Source: ../shell/plugins/replaceall (mode: 'all')
import nuxt_plugin_backbutton_773771f1 from 'nuxt_plugin_backbutton_773771f1' // Source: ../shell/plugins/back-button (mode: 'all')
import nuxt_plugin_plugin_58afbc7a from 'nuxt_plugin_plugin_58afbc7a' // Source: ../shell/plugins/plugin (mode: 'client')
import nuxt_plugin_codemirrorloader_341a5526 from 'nuxt_plugin_codemirrorloader_341a5526' // Source: ../shell/plugins/codemirror-loader (mode: 'client')
import nuxt_plugin_formatters_18848aa0 from 'nuxt_plugin_formatters_18848aa0' // Source: ../shell/plugins/formatters (mode: 'client')
import nuxt_plugin_version_22d30f1e from 'nuxt_plugin_version_22d30f1e' // Source: ../shell/plugins/version (mode: 'client')
import nuxt_plugin_stevecreateworker_39bb9f0e from 'nuxt_plugin_stevecreateworker_39bb9f0e' // Source: ../shell/plugins/steve-create-worker (mode: 'client')
import nuxt_plugin_elementui_02b5b8cc from 'nuxt_plugin_elementui_02b5b8cc' // Source: ../shell/plugins/element-ui (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"dashboard","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Rancher Dashboard"}],"link":[{"hid":"icon","rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_portalvue_198f83b1 === 'function') {
    await nuxt_plugin_portalvue_198f83b1(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_3a33618a === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_3a33618a(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_516dc5e2 === 'function') {
    await nuxt_plugin_axios_516dc5e2(app.context, inject)
  }

  if (typeof nuxt_plugin_plugins_7c067ab2 === 'function') {
    await nuxt_plugin_plugins_7c067ab2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsloader_28e5d348 === 'function') {
    await nuxt_plugin_pluginsloader_28e5d348(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1ad47a4f === 'function') {
    await nuxt_plugin_axios_1ad47a4f(app.context, inject)
  }

  if (typeof nuxt_plugin_tooltip_d4a5a8c8 === 'function') {
    await nuxt_plugin_tooltip_d4a5a8c8(app.context, inject)
  }

  if (typeof nuxt_plugin_vueclipboard2_260c7e4a === 'function') {
    await nuxt_plugin_vueclipboard2_260c7e4a(app.context, inject)
  }

  if (typeof nuxt_plugin_vselect_52cc65ba === 'function') {
    await nuxt_plugin_vselect_52cc65ba(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_2479586a === 'function') {
    await nuxt_plugin_directives_2479586a(app.context, inject)
  }

  if (typeof nuxt_plugin_transitions_8bcbb652 === 'function') {
    await nuxt_plugin_transitions_8bcbb652(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_74254d97 === 'function') {
    await nuxt_plugin_vuejsmodal_74254d97(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_jsyaml_f0345f78 === 'function') {
    await nuxt_plugin_jsyaml_f0345f78(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_resize_5bb5e9fb === 'function') {
    await nuxt_plugin_resize_5bb5e9fb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_shortkey_46200fea === 'function') {
    await nuxt_plugin_shortkey_46200fea(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_b3ed25b6 === 'function') {
    await nuxt_plugin_i18n_b3ed25b6(app.context, inject)
  }

  if (typeof nuxt_plugin_globalformatters_35acadac === 'function') {
    await nuxt_plugin_globalformatters_35acadac(app.context, inject)
  }

  if (typeof nuxt_plugin_trimwhitespace_3c4277a1 === 'function') {
    await nuxt_plugin_trimwhitespace_3c4277a1(app.context, inject)
  }

  if (typeof nuxt_plugin_extendrouter_84651396 === 'function') {
    await nuxt_plugin_extendrouter_84651396(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_console_125840f0 === 'function') {
    await nuxt_plugin_console_125840f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_intnumber_2e79f6a4 === 'function') {
    await nuxt_plugin_intnumber_2e79f6a4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinit_760372e2 === 'function') {
    await nuxt_plugin_nuxtclientinit_760372e2(app.context, inject)
  }

  if (typeof nuxt_plugin_replaceall_d238a798 === 'function') {
    await nuxt_plugin_replaceall_d238a798(app.context, inject)
  }

  if (typeof nuxt_plugin_backbutton_773771f1 === 'function') {
    await nuxt_plugin_backbutton_773771f1(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_plugin_58afbc7a === 'function') {
    await nuxt_plugin_plugin_58afbc7a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_codemirrorloader_341a5526 === 'function') {
    await nuxt_plugin_codemirrorloader_341a5526(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_formatters_18848aa0 === 'function') {
    await nuxt_plugin_formatters_18848aa0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_version_22d30f1e === 'function') {
    await nuxt_plugin_version_22d30f1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_stevecreateworker_39bb9f0e === 'function') {
    await nuxt_plugin_stevecreateworker_39bb9f0e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_elementui_02b5b8cc === 'function') {
    await nuxt_plugin_elementui_02b5b8cc(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
