<script>
import config, { VM_POWER_STATES, VM_POWER_STATES_ENUM } from '../plugins/pai-resource-class';
import { PRODUCT_NAME as PAI } from '../config/pai';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { mapGetters } from 'vuex';
import { convertCpuToCore, convertUnitToG } from '../utils/units';
import { omitString } from '../utils/string';
import Progress from './Progress';
import { LONGHORN, POD } from '@shell/config/types';
import { COMMAND_HASH_MPA } from '../config/settings';
import { ALIAS, IMAGE } from '../config/labels-annotations';
import { _EDIT, MODE } from '@shell/config/query-params';
import windowsIcon from '../assets/vmImage/windows.png';
import linuxIcon from '../assets/vmImage/linux.png';
import { shutdownPod } from '../utils/guest-command';

export default {
  components: { Progress },
  props:      {
    value: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    this.pods = await this.getPods();
    let diskData = 0;

    if (this.value.spec.volumeClaimTemplates?.length > 0) {
      this.value.spec.volumeClaimTemplates.forEach((v) => {
        if (v.spec?.resources?.requests?.storage) {
          diskData += convertUnitToG(v.spec.resources.requests.storage);
        }
      });
    }
    if (this.value.spec.volumes?.length > 0) {
      for (const v of this.value.spec.volumes) {
        const name = v.name;
        const disk = await this.$store.dispatch('cluster/findMatching', {
          type:     LONGHORN.VOLUMES,
          selector: `metadata.name=${ name }`,
        });

        if (disk && v.spec?.resources?.requests?.storage) {
          diskData += convertUnitToG(v.spec.resources.requests.storage);
        }
      }
    }
    this.diskData = `${ diskData }G`;

    if (this.currentPod.name) {
      this.getPodInfo();
    }
    const backupData = this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.BACKUP);

    if (!backupData) {
      this.backupVisible = false;
    }
  },
  data() {
    const cpuData = convertCpuToCore(this.value.spec?.template?.spec?.containers?.[0]?.resources?.limits?.cpu ? this.value.spec?.template?.spec?.containers[0].resources.limits.cpu : 0) + this.t('suffix.cores');
    const ramData = `${ convertUnitToG(this.value.spec?.template?.spec?.containers?.[0]?.resources?.limits?.memory ? this.value.spec?.template?.spec?.containers[0].resources.limits.memory : 0) }G`;
    const alias = (this.value.metadata.annotations && this.value.metadata.annotations[ALIAS]) ? this.value.metadata.annotations[ALIAS] : '';
    let currentPod = {};
    const vmPods = [];

    if (this.value.status && this.value.status.instances) {
      const { instances = {} } = this.value.status;
      const keys = Object.keys(instances);

      keys.forEach((key) => {
        vmPods.push({
          name:   key,
          status: instances[key].status,
        });
      });
    }
    if (vmPods.length > 0) {
      currentPod = vmPods[0];
    }

    const errorClassName = VM_POWER_STATES[VM_POWER_STATES_ENUM.Error];

    return {
      alias,
      config,
      loadImgSrc:    `this.src = "${ require('../assets/images/home/empty.svg') }"`,
      currentVm:     '',
      powerState:    this.value.powerState,
      cpuData,
      ramData,
      diskData:      '',
      cpuUsedRate:   0,
      ramUsedRate:   0,
      diskUsedRate:  0,
      currentPod,
      errorClassName,
      backupVisible: true
    };
  },
  computed: {
    ...mapGetters(['currentCluster']),
    isPowerOn() {
      return this.value.powerState === VM_POWER_STATES_ENUM.On;
    },
    stateLabel() {
      return this.t(VM_POWER_STATES[this.value.powerState].labelKey);
    },
    stateClassName() {
      return VM_POWER_STATES[this.value.powerState].className;
    },
    isMoveOn() {
      return this.currentVm === this.value.metadata.name;
    },
    currentClusterId() {
      return this.currentCluster?.id || 'local';
    },
    vmDetail() {
      const params = this.$route.params;

      return {
        name:   `${ PAI }-c-cluster-resource-namespace-id`,
        params: {
          ...params,
          id:        this.value.name,
          product:   PAI,
          resource:  PAI_RESOURCES.VMSET,
          cluster:   this.currentClusterId,
          namespace: this.value.namespace,
        },
      };
    },
    osImg() {
      if (this.value.annotations && this.value.annotations[IMAGE.LOGO]) {
        const logoName = this.value.annotations[IMAGE.LOGO];

        try {
          const logo = require(`../assets/images/imageLogo/${ logoName }`);

          if (logo) {
            return logo;
          }
        } catch (e) {}
      }

      return this.value.spec?.os?.includes('windows') ? windowsIcon : linuxIcon;
    },
    vmPods() {
      const vmPods = [];

      if (this.value.status && this.value.status.instances) {
        const { instances = {} } = this.value.status;
        const keys = Object.keys(instances);

        keys.forEach((key) => {
          vmPods.push({
            name:   key,
            status: instances[key].status,
          });
        });
      }

      return vmPods;
    },
    isRunning() {
      return this.currentPod.status === VM_POWER_STATES_ENUM.Running;
    },
  },
  methods: {
    omitString,
    async onActionStar() {
      this.value = await this.value.forceFetch();
      this.value.setStar();

      this.$emit('changeCollection');
    },
    onActionPower(state) {
      this.$confirm(`${ this.t('pai.vmset.tips.podsTakeEffect') },${ this.t('pai.labels.isOk') }`, this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',

      })
        .then(async() => {
          try {
            if (state === 'restart') {
              await this.value.powerOff();
              this.powerState = VM_POWER_STATES_ENUM.Off;
              setTimeout(async() => {
                await this.value.powerOn();
                this.powerState = VM_POWER_STATES_ENUM.On;
              }, 1000 * 10);
            } else if (state === 'safeOff') {
              if (this.pods) {
                for (const pod of this.pods) {
                  try {
                    await shutdownPod(this.currentClusterId, pod);
                  } catch (e) {
                  }
                }
              } else {
                await this.value.powerOff();
              }
            } else {
              this.powerState = state;
              await this.value.power(state);
            }
          } catch (e) {
            this.$message({
              type:    'warning',
              message: e.message,
            });
          }
        }).catch(() => {
        });
    },
    handleCommand(command) {
      if (command === 'edit') {
        const location = {
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            id:        this.value.name,
            product:   PAI,
            resource:  PAI_RESOURCES.VMSET,
            cluster:   this.currentClusterId,
            namespace: this.value.namespace,
          },
          query: { mode: _EDIT },
        };

        this.$router.push(location);
      } else if (command === 'Off') {
        this.onActionPower('Off');
      } else if (command === 'clone') {
        this.value.goToCloneVM();
      } else if (command === 'image') {
        this.value.goToMakeImage();
      } else if (command === 'delete') {
        this.$store.dispatch('pai-common/updateState', {
          currentModal: 'deleteVmModal',
          currentItem:  this.value,
        });
      } else {
        // 此处暂时都跳转至虚机详情页，具体的页面跳转和功能后续实现
        this.$router.push({
          name:   `${ PAI }-c-cluster-resource-namespace-id`,
          params: {
            product:   PAI,
            cluster:   this.currentClusterId,
            resource:  PAI_RESOURCES.VMSET,
            namespace: this.value.metadata.namespace,
            id:        this.value.metadata.name,
          },
          hash: COMMAND_HASH_MPA[command],
        });
      }
    },
    onActionConsole() {
      this.value.onOpenShell(this.currentPod.name);
    },
    getPodClass(state) {
      // 卡片展示两种pod状态：Running、Error和其他Running
      if (state === VM_POWER_STATES_ENUM.Running || state === VM_POWER_STATES_ENUM.Error) {
        return VM_POWER_STATES[VM_POWER_STATES_ENUM.Running].className;
      } else {
        return VM_POWER_STATES[VM_POWER_STATES_ENUM.Pending].className;
      }
    },
    handleCommandPod(e) {
      this.currentPod = e;
    },

    getPodInfo() {
      if (this.isPowerOn) {
        this.$store.dispatch('pai-grafana/getCpu', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.cpuUsedRate = value || 0;
          });
        this.$store.dispatch('pai-grafana/getRamDashboard', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.ramUsedRate = value || 0;
          });
        this.$store.dispatch('pai-grafana/getDisk', {
          cluster:   this.currentClusterId,
          namespace: this.value.metadata.namespace,
          podName:   this.currentPod.name,
        })
          .then((value) => {
            this.diskUsedRate = value || 0;
          });
      }
    },

    async getPods() {
      const pods = [];

      if (this.value.metadata.relationships && this.value.metadata.relationships.length > 0) {
        for (const pod of this.value.metadata.relationships) {
          const podObj = await this.$store.dispatch('cluster/find', {
            type: POD,
            id:   pod.toId,
          });

          pods.push(podObj);
        }
      }

      return pods;
    },

  },
  watch: {
    vmPods: {
      handler(nue, old) {
        if (JSON.stringify(nue) !== JSON.stringify(old) && nue.length > 0) {
          this.currentPod = nue[0];
        }
      },
      deep:      true,
      immediate: true,
    },
    currentPod: {
      handler(nue, old) {
        if (JSON.stringify(nue) !== JSON.stringify(old) && nue.name) {
          this.getPodInfo();
        }
      },
      deep:      true,
      immediate: true,
    },
    isPowerOn: {
      handler(nue) {
        if (!nue) {
          this.cpuUsedRate = 0;
          this.ramUsedRate = 0;
          this.diskUsedRate = 0;
        }
      },
      deep:      true,
      immediate: true,
    },
  },
};
</script>
<template>
  <div class="card">
    <div class="top">
      <div class="actions">
        <i
          :class="value.starClass"
          @click="onActionStar"
        />
        <slot name="checkbox" />
      </div>
      <div class="osImg">
        <img
          :src="osImg"
          alt=""
          width="40px"
          height="40px"
          :onerror="loadImgSrc"
        >
      </div>
      <div class="name">
        <el-tooltip
          :content="alias?alias+'/'+value.metadata.name:value.metadata.name"
          effect="light"
        >
          <nuxt-link
            :to="vmDetail"
            title=""
          >
            {{ alias ? omitString(alias, 20) : omitString(value.metadata.name, 20) }}
          </nuxt-link>
        </el-tooltip>
        <div class="status">
          <div>
            <div
              class="state"
              :class="stateClassName"
            />
            {{ stateLabel }}
          </div>
          <div
            v-if="vmPods.length > 0"
            class="pod"
          >
            <div
              class="state"
              :class="getPodClass(currentPod.status)"
            />
            <el-tooltip
              :content="currentPod.name"
              effect="light"
            >
              <span>
                {{ omitString(currentPod.name) }}
              </span>
            </el-tooltip>
            <el-dropdown @command="handleCommandPod">
              <span class="el-dropdown-link">
                <i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in vmPods"
                  :key="item.name"
                  :command="item"
                  :disabled="!isPowerOn"
                >
                  <div>
                    <div
                      class="state"
                      :class="getPodClass(item.status)"
                    />
                    {{ item.name }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            v-else-if="isPowerOn"
            class="pod"
          >
            <div
              class="state"
              :class="errorClassName.className"
            />
            {{ t(`${ errorClassName.labelKey }`) }}
          </div>
        </div>
      </div>
    </div>
    <div class="line" />
    <div
      class="bottom"
      @mouseover="currentVm=value.metadata.name"
      @mouseleave="currentVm=''"
    >
      <el-row v-show="!isMoveOn">
        <el-col :span="8">
          CPU：{{ cpuData }}
        </el-col>
        <el-col
          :span="16"
          class="bar"
        >
          <div>{{ t('pai.detail.vmset.tab.overview.cpuUsage') }}：</div>
          <Progress
            style="width: 80px"
            :value="cpuUsedRate"
            :bg-color="'rgb(255, 255, 255)'"
            :font-size="'11px'"
          />
        </el-col>
        <el-col :span="8">
          {{ t('pai.vmset.ram') }}：{{ ramData }}
        </el-col>
        <el-col
          :span="16"
          class="bar"
        >
          <div>{{ t('pai.detail.vmset.tab.overview.ramUsage') }}：</div>
          <Progress
            :value="ramUsedRate"
            style="width: 80px"
            :bg-color="'rgb(255, 255, 255)'"
            :font-size="'11px'"
          />
        </el-col>
        <el-col :span="8">
          {{ t('pai.vmset.disk') }}：{{ diskData }}
        </el-col>
        <el-col
          :span="16"
          class="bar"
        >
          <div>{{ t('pai.detail.vmset.tab.overview.diskPercentage') }}：</div>
          <Progress
            :value="diskUsedRate"
            style="width: 80px"
            :bg-color="'rgb(255, 255, 255)'"
            :font-size="'11px'"
          />
        </el-col>
      </el-row>
      <div
        v-show="isMoveOn"
        class="action"
      >
        <div v-if="isPowerOn">
          <div
            v-if="isRunning"
            @click="onActionPower('safeOff')"
          >
            <i
              class="el-icon-switch-button"
              style="font-size: 22px; margin-bottom: 2px; color: #D81E06"
            />{{ t('pai.detail.vmset.shutdown') }}
          </div>
          <div
            v-else
            class="disabled"
          >
            <i class="el-icon-switch-button" />{{ t('pai.detail.vmset.shutdown') }}
          </div>
        </div>
        <div
          v-else
          @click="onActionPower('On')"
        >
          <img
            src="../assets/images/home/powerOn.svg"
            alt=""
          >{{ t('pai.detail.vmset.firing') }}
        </div>
        <div @click="onActionPower('restart')">
          <img
            src="../assets/images/home/powerRestart.svg"
            alt=""
          >{{ t('pai.detail.vmset.restart') }}
        </div>
        <div
          v-if="!isRunning"
          class="disabled"
          style="cursor: default"
        >
          <div style="cursor: default">
            <i class="el-icon-s-platform" />
          </div>
          {{ t('pai.detail.vmset.control') }}
        </div>
        <div
          v-else
          class="running"
          @click="onActionConsole"
        >
          <div>
            <i class="el-icon-s-platform" />
          </div>
          {{ t('pai.detail.vmset.control') }}
        </div>
        <el-dropdown @command="handleCommand">
          <div>
            <img
              src="../assets/images/home/more.svg"
              alt=""
            >{{ t('pai.labels.moer') }}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              command="edit"
            >
              <i class="el-icon-edit" />{{ t('pai.detail.vmset.edit') }}
            </el-dropdown-item>
            <el-dropdown-item command="clone">
              <i class="el-icon-document-copy" />{{ t('pai.detail.vmset.copy') }}
            </el-dropdown-item>
            <el-dropdown-item command="snapshot">
              <i class="el-icon-camera" />{{ t('pai.detail.vmset.snapShot') }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="backupVisible"
              command="backup"
            >
              <i class="el-icon-share" />{{ t('pai.detail.vmset.backUp') }}
            </el-dropdown-item>
            <el-dropdown-item command="image">
              <i class="el-icon-cloudy" />{{ t('pai.detail.vmset.makeMirror') }}
            </el-dropdown-item>
            <el-dropdown-item command="log">
              <i class="el-icon-notebook-2" />{{ t('pai.detail.vmset.viewLog') }}
            </el-dropdown-item>
            <el-dropdown-item command="monitor">
              <i class="el-icon-stopwatch" />{{ t('pai.detail.vmset.viewMonitor') }}
            </el-dropdown-item>
            <el-dropdown-item
              v-if="isPowerOn"
              command="Off"
            >
              <i class="el-icon-switch-button" />{{ t('pai.detail.vmset.powerOff') }}
            </el-dropdown-item>
            <el-dropdown-item command="detail">
              <i class="el-icon-warning-outline" />{{ t('pai.detail.vmset.detail') }}
            </el-dropdown-item>
            <el-dropdown-item command="delete">
              <i class="el-icon-delete" />{{ t('pai.detail.vmset.delete') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  box-shadow:
          0 1px 1px rgb(0 0 0 / 0.25),
          0 2px 2px rgb(0 0 0 / 0.20),
          0 4px 4px rgb(0 0 0 / 0.15),
          0 8px 8px rgb(0 0 0 / 0.10),
          0 16px 16px rgb(0 0 0 / 0.05);
  width: 255px;
  height: 130px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(239, 239, 239, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  border: 1px solid rgba(206, 206, 206, 1);
  padding: 0 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  white-space: nowrap;

  .top {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40%;

    .actions {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      i {
        cursor: pointer;
        color: #FCCA00;
        margin-right: 1px;
      }
    }

    .name {
      flex: 0.75;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 12px;
      overflow: hidden;
      width: 70px;

      .status {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pod {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .el-dropdown {
          font-size: 12px;
        }
      }
    }

    .osImg {
      flex: 0.25;
      display: flex;
      justify-content: center;

      img {
        width: 40px;
      }
    }

    .os {
      flex: 0.3;
      overflow: hidden;
    }
  }

  .line {
    border-bottom: 2px solid #246FA5;
    height: 5%;
  }

  .bottom {
    font-size: 11px;
    text-align: left;
    height: 55%;
    display: flex;
    align-items: center;

    .bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action {
      font-size: 12px;
      width: 100%;
      display: flex;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }
    }

    img {
      width: 22px;
      margin-bottom: 5px;
    }
  }
}

.running {
  i {
    color: var(--link);
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.disabled {
  i {
    color: gray !important;
    font-size: 22px;
    margin-bottom: 5px;
  }
}

.state {
  display: inline-block;
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
</style>
