<script>
import ResourceDetail from '../../../../components/ResourceDetail';

export default {
  name:       'ClusterResourceCreate',
  layout:     'pai/home',
  components: { ResourceDetail },
};
</script>

<template>
  <div style="padding:0 20px">
    <ResourceDetail store-override="management" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .overlay{
  background-color: #fff;
  left:0;
}
</style>
