<script>
import { PRODUCT_NAME } from '../config/pai';

export default {
  props: {
    value: {
      type:     null,
      required: true
    },
    row: {
      type:     Object,
      required: true
    },
  },

  computed: {
    url() {
      return {
        name:   `pai-c-cluster-overview`,
        params: {
          cluster: this.row.mgmtClusterId,
          product: PRODUCT_NAME,
        },
      };
    }
  }
};
</script>

<template>
  <span v-if="value">
    <nuxt-link :to="url">
      {{ value }}
    </nuxt-link>
  </span>
</template>
