import { FAVORITE_RESOURCES } from '@shell/store/prefs';
const StarMixin = Base => class extends Base {
  get starClass() {
    const favoriteResources = this.$rootGetters['prefs/get'](FAVORITE_RESOURCES);
    const currentResource = favoriteResources.find(v => v.type === this.type);
    let isStar = false;

    if (currentResource && Array.isArray(currentResource.values)) {
      const ids = currentResource.values.map(v => v.id);

      isStar = ids.includes(this.id);
    }

    return isStar ? 'el-icon-star-on' : 'el-icon-star-off';
  }

  get starTimeStamp() {
    const favoriteResources = this.$rootGetters['prefs/get'](FAVORITE_RESOURCES);
    const currentResource = favoriteResources.find(v => v.type === this.type);

    if (currentResource && Array.isArray(currentResource.values)) {
      const starInfo = currentResource.values.find(v => v.id === this.id);

      if (starInfo) {
        return starInfo.timeStamp;
      }
    }

    return 0;
  }

  setStar() {
    const favoriteResources = this.$rootGetters['prefs/get'](FAVORITE_RESOURCES);
    let currentResource = favoriteResources.find(v => v.type === this.type);
    const timeStamp = new Date().valueOf();

    if (currentResource && Array.isArray(currentResource.values)) {
      const values = currentResource.values;
      const ids = values.map(v => v.id);

      if (ids.includes(this.id)) {
        values.splice(ids.indexOf(this.id), 1);
      } else {
        values.push({ id: this.id, timeStamp });
      }
      currentResource.values = values;
    } else {
      currentResource = { type: this.type, values: [{ id: this.id, timeStamp }] };
    }
    if (favoriteResources.map(v => v.type).includes(this.type)) {
      favoriteResources[favoriteResources.map(v => v.type).indexOf(this.type)] = currentResource;
    } else {
      favoriteResources.push(currentResource);
    }
    this.$dispatch('prefs/set', { key: FAVORITE_RESOURCES, value: favoriteResources }, { root: true });
  }
};

export default StarMixin;
