import RoleTemplate from '@shell/models/management.cattle.io.roletemplate';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';
import { convertType } from '~/pkg/pai/utils';

export default class extends RoleTemplate {
  get detailLocation() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      const id = this.id?.replace(/.*\//, '');

      return {
        name:   `${ PRODUCT_NAME }-c-cluster-auths-roles-resource-id`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.$rootGetters['clusterId'],
          resource: convertType(this.type),
          id,
        },
      };
    }

    return super.detailLocation;
  }

  get listLocation() {
    return {
      name: `${ PRODUCT_NAME }-c-cluster-auths-roles`,
      hash: `#${ this.subtype }`,
    };
  }
}
