<template>
  <div class="cluster-single-table bgCard">
    <div class="table-main">
      <el-table
        v-if="refreshTable"
        v-loading="tableLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData.filter((item)=>!inputValue || item.children.map(child => child.id).join(',').includes(inputValue))"
        row-key="id"
        min-height="300"
        :tree-props="{children: 'children'}"
        :header-cell-style="{background:'#EFEFEF', color: '#000000'}"
        :default-expand-all="expandeAll"
      >
        <el-table-column
          prop="projectName"
          :label="t('pai.overview.project')"
          min-width="180px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="projectLink(scope.row.id)">
              <span v-if="scope.row.namespaceLength !== 0">{{ scope.row.projectName }}</span>
              <span v-else>&emsp;&emsp;{{ scope.row.projectName }}</span>
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="namespace"
          :label="t('pai.vmset.nameSpace')"
          min-width="120px"
        >
          <template slot-scope="scope">
            <nuxt-link
              v-if="scope.row.namespace !==0"
              :to="namespaceLink(scope.row.namespace)"
            >
              {{ scope.row.namespace }}
            </nuxt-link>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="cpu"
          :label="t('pai.edit.license.cpu')+'(核)'"
          min-width="130px"
          sortable
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isMaxCpu !==undefined">{{ scope.row.isMaxCpu === true ? scope.row.cpu : '无限制' }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="cpuPercent"
          min-width="170px"
          sortable
          :label="t('pai.detail.vmset.CPUProportion')"
        >
          <template slot-scope="scope">
            <el-progress
              v-if="scope.row.isMaxCpu === true"
              :percentage="scope.row.cpuPercent"
              :color="scope.row.cpuPercent >= 80 ?'#C85352' :'#236EA4'"
            />
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="memory"
          :label="t('pai.edit.license.memory')+'(G)'"
          min-width="120px"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.isMaxMem !==undefined">{{ scope.row.isMaxMem === true ? scope.row.memory : '无限制' }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="memoryPercent"
          min-width="170px"
          sortable
          :label="t('pai.detail.vmset.memoryProportion')"
        >
          <template slot-scope="scope">
            <el-progress
              v-if="scope.row.isMaxMem === true"
              :percentage="scope.row.memoryPercent"
              :color="scope.row.memoryPercent >= 80 ?'#C85352' :'#236EA4'"
            />
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="storage"
          sortable
          min-width="110px"
          :label="t('pai.detail.vmset.storageRestrictions')"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.isMaxStorage === true ? scope.row.storage : '无限制' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="storagePercent"
          min-width="170px"
          sortable
          :label="t('pai.detail.vmset.storageProportion')"
        >
          <template slot-scope="scope">
            <el-progress
              v-if="scope.row.isMaxStorage === true"
              :percentage="scope.row.storagePercent"
              :color="scope.row.storagePercent >= 80 ?'#C85352' :'#236EA4'"
            />
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="container"
          sortable
          :label="t('pai.detail.vmset.containersNum')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="podLink()">
              {{ scope.row.container }}
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="vm"
          sortable
          :label="t('pai.detail.vmset.vmNum')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="vmLink()">
              {{ scope.row.vm }}
            </nuxt-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="internet"
          sortable
          :label="t('pai.detail.vmset.overallNetwork')"
          min-width="130px"
        >
          <template slot-scope="scope">
            <nuxt-link :to="nadLink()">
              {{ scope.row.internet }}
            </nuxt-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { MANAGEMENT, PVC } from '@shell/config/types';
import grouping from '../../../../../components/SortableTable/grouping';
import { PAI_NAMESPACE, PAI_POD, PAI_RESOURCES } from '../../../../../config/types';
import { convertCpuToCore, convertUnitToG, numDelivery } from '../../../../../utils/units';
import { PRODUCT_NAME } from '../../../../../config/pai';
import { _EDIT } from '@shell/config/query-params';
import { mapGetters } from 'vuex';
import day from 'dayjs';

export default {
  name:   'StaticResourcesTable',
  mixins: [grouping],
  props:  {
    isExpandAll: {
      type:     Boolean,
      required: true,
    },
    inputValue: {
      type:     String,
      required: true,
    },
    allNamespaces: { type: Array },
    projects:      { type: Array }
  },
  data() {
    const params = this.$route.params;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return {
      tableLoading: false,
      tableData:    [],
      cpuCount:     0,
      memoryCount:  0,
      dateTime:     new Date(String(day().format(dateFormat))).getTime() / 1000,
      expandeAll:   false,
      refreshTable: true,
      eventList:    [],
      params,
    };
  },
  async fetch() {
    const allNamespaces = this.allNamespaces;
    const projects = this.projects;

    const projectMap = {};

    for (const project of projects) {
      let cpu = 0;
      let memory = 0;
      let storage = 0;
      let isMaxCpu = false;
      let isMaxMem = false;
      let isMaxStorage = false;

      const projectName = project.metadata.name;

      cpu = convertCpuToCore(project?.spec?.resourceQuota?.limit?.limitsCpu ? project?.spec?.resourceQuota?.limit?.limitsCpu : 0);
      memory = convertUnitToG(project?.spec?.resourceQuota?.limit?.limitsMemory ? project?.spec?.resourceQuota?.limit?.limitsMemory : 0);
      storage = convertUnitToG(project?.spec?.resourceQuota?.limit?.requestsStorage ? project?.spec?.resourceQuota?.limit?.requestsStorage : 0);
      isMaxCpu = project?.spec?.resourceQuota?.limit?.limitsCpu !== undefined;
      isMaxMem = project?.spec?.resourceQuota?.limit?.limitsMemory !== undefined;
      isMaxStorage = project?.spec?.resourceQuota?.limit?.requestsStorage !== undefined;
      projectMap[projectName] = {
        id:               projectName,
        projectName:      project.spec.displayName,
        namespaceLength:  0,
        namespace:        0,
        cpu,
        cpuPercent:       0,
        memory,
        memoryPercent:    0,
        storage,
        storagePercent:   0,
        container:        0,
        vm:               0,
        internet:         0,
        isMaxCpu,
        isMaxMem,
        isMaxStorage,
        cpuNumerator:     0,
        memoryNumerator:  0,
        storageNumerator: 0,
        children:         [],
      };
    }

    projectMap[''] = {
      id:               'noProject',
      projectName:      'noProject',
      namespaceLength:  0,
      namespace:        0,
      cpu:              0,
      cpuPercent:       0,
      memory:           0,
      memoryPercent:    0,
      storage:          0,
      storagePercent:   0,
      container:        0,
      vm:               0,
      internet:         0,
      limitCpu:         0,
      limitMem:         0,
      isMaxCpu:         false,
      isMaxMem:         false,
      isMaxStorage:     false,
      cpuNumerator:     0,
      memoryNumerator:  0,
      storageNumerator: 0,
      children:         [],
    };

    const namespaceTableData = allNamespaces.map((namespace) => {
      return {
        id:              `${ namespace.projectId }/${ namespace.name }`,
        projectId:       projectMap[namespace.projectId] !== undefined ? namespace.projectId : '',
        namespace:       namespace.name,
        namespaceLength: 0,
        cpu:             0,
        cpuPercent:      0,
        memory:          0,
        memoryPercent:   0,
        storage:         0,
        storagePercent:  0,
        container:       0,
        vm:              0,
        internet:        0,
      };
    });

    let installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET }).catch(err => new Error(err)); // 云主机数据

    installedVms = installedVms == 'Error: Error: Unknown schema for type: virt.liveit100.com.vmset' ? [] : installedVms;
    const containerData = await this.$store.dispatch('cluster/findAll', { type: 'pod' }); // 容器组
    let internetData = await this.$store.dispatch('cluster/findAll', { type: 'k8s.cni.cncf.io.networkattachmentdefinition' }).catch(err => new Error(err)); // 网络资源

    internetData = internetData == 'Error: Error: Unknown schema for type: k8s.cni.cncf.io.networkattachmentdefinition' ? [] : internetData; // 网络资源

    // 获取命名空间CPU/内存
    const podsUrl = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/pods` };
    const podsResult = await this.$store.dispatch('pai-common/getUse', podsUrl);
    const podsDatas = podsResult.data.items;

    // 命名空间限额
    const resourceQuotasUrl = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/resourcequotas` };
    const resourceQuotas = await this.$store.dispatch('pai-common/getUse', resourceQuotasUrl);
    const resourceQuotasData = resourceQuotas.data.items;

    // 存储
    const storageData = await this.$store.dispatch('cluster/findAll', { type: PVC });

    this.tableLoading = true;
    for (const namespace of namespaceTableData) {
      let cpuNumerator = 0;
      let memoryNumerator = 0;
      let storageNumerator = 0;

      const containerList = containerData?.filter(vm => vm?.metadata?.namespace === namespace.namespace);
      const vmList = installedVms?.filter(vm => vm?.metadata?.namespace === namespace.namespace);
      const interList = internetData?.filter(i => i?.metadata?.namespace === namespace.namespace);
      const podsData = podsDatas?.filter(i => i?.metadata?.namespace === namespace.namespace);
      const storageList = storageData?.filter(i => i?.metadata?.namespace === namespace.namespace);

      const limitCpuDatas = resourceQuotasData.filter(i => i?.metadata?.namespace === namespace.namespace);
      let limitCpu = 0;
      let limitMem = 0;
      let limitStorage = 0;
      let isMaxCpu = false;
      let isMaxMem = false;
      let isMaxStorage = false;

      podsData?.forEach((cpuData) => { // 分子数据
        cpuData?.spec?.containers?.forEach((container) => {
          if (container?.resources?.limits?.cpu !== undefined) {
            cpuNumerator += convertCpuToCore(container?.resources?.limits?.cpu);
          }
          if (container?.resources?.limits?.memory !== undefined) {
            memoryNumerator += convertUnitToG(container?.resources?.limits?.memory);
          }
        });
      });
      storageList?.forEach((storage) => {
        if (storage?.spec?.resources?.requests?.storage !== undefined) {
          storageNumerator = convertUnitToG(storage?.spec?.resources?.requests?.storage);
        }
      });

      const newLimitData = limitCpuDatas.filter((cpuData) => { // 命名空间最大限额
        if (cpuData.metadata.name.split('-')[0] === 'default') {
          return cpuData;
        }
      });

      if (newLimitData.length > 0) {
        newLimitData.forEach((cpuData) => {
          limitCpu = cpuData.status?.hard['limits.cpu'] !== undefined ? convertCpuToCore(cpuData.status?.hard['limits.cpu']) : 0;
          limitMem = cpuData.status?.hard['limits.memory'] !== undefined ? convertUnitToG(cpuData.status?.hard['limits.memory']) : 0;
          limitStorage = cpuData.status?.hard['requests.storage'] !== undefined && cpuData.status?.hard['requests.storage'] !== '0' ? convertUnitToG(cpuData.status?.hard['requests.storage']) : 0;
          isMaxCpu = cpuData.status?.hard['limits.cpu'] !== undefined;
          isMaxMem = cpuData.status?.hard['limits.memory'] !== undefined;
          isMaxStorage = cpuData.status?.hard['requests.storage'] !== undefined;
        });
      } else {
        limitCpuDatas.forEach((cpuData) => {
          limitCpu = cpuData.status?.hard['limits.cpu'] !== undefined ? convertCpuToCore(cpuData.status?.hard['limits.cpu']) : 0;
          limitMem = cpuData.status?.hard['limits.memory'] !== undefined ? convertUnitToG(cpuData.status?.hard['limits.memory']) : 0;
          limitStorage = cpuData.status?.hard['requests.storage'] !== undefined && cpuData.status?.hard['requests.storage'] !== '0' ? convertUnitToG(cpuData.status?.hard['requests.storage']) : 0;
          isMaxCpu = cpuData.status?.hard['limits.cpu'] !== undefined;
          isMaxMem = cpuData.status?.hard['limits.memory'] !== undefined;
          isMaxStorage = cpuData.status?.hard['requests.storage'] !== undefined;
        });
      }
      if (cpuNumerator !== 0 && limitCpu !== 0 && cpuNumerator <= limitCpu) {
        namespace.cpuPercent = numDelivery(cpuNumerator / limitCpu * 100);
      }
      if (memoryNumerator !== 0 && limitMem !== 0 && memoryNumerator <= limitMem) {
        namespace.memoryPercent = numDelivery(memoryNumerator / limitMem * 100);
      }
      if (storageNumerator !== 0 && limitStorage !== 0 && storageNumerator <= limitStorage) {
        namespace.storagePercent = numDelivery(storageNumerator / limitStorage * 100);
      }
      namespace.cpu = limitCpu;
      namespace.memory = limitMem;
      namespace.storage = limitStorage;
      namespace.container = containerList.length;
      namespace.vm = vmList.length;
      namespace.internet = interList.length;
      namespace.isMaxCpu = isMaxCpu;
      namespace.isMaxMem = isMaxCpu;
      namespace.isMaxStorage = isMaxStorage;
      namespace.limitCpu += limitCpu;
      namespace.limitMem += limitMem;
      const project = projectMap[namespace.projectId];

      if (project) {
        project.cpuNumerator += cpuNumerator;
        project.memoryNumerator += memoryNumerator;
        project.storageNumerator += storageNumerator;
        project.container += namespace.container;
        project.vm += namespace.vm;
        project.internet += namespace.internet;
        project.namespaceLength += 1;
        project.children.push(namespace);
        projectMap[namespace.projectId] = project;
      }
    }

    this.tableData = Object.values(projectMap).map((project) => {
      return {
        ...project,
        cpuPercent:     project.cpu !== 0 && project.cpuNumerator !== 0 && project.cpuNumerator <= project.cpu ? numDelivery(project.cpuNumerator / project.cpu * 100) : 0,
        memoryPercent:  project.memory !== 0 && project.memoryNumerator !== 0 && project.memoryNumerator <= project.memory ? numDelivery(project.memoryNumerator / project.memory * 100) : 0,
        storagePercent: project.storage !== 0 && project.storageNumerator !== 0 && project.storageNumerator <= project.storage ? numDelivery(project.storageNumerator / project.storage * 100) : 0,
        children:       project.children?.sort((a, b) => b.cpu - a.cpu)
      };
    }).sort((a, b) => b.cpu - a.cpu);
    if (this.tableData?.length !== 0) {
      this.tableLoading = false;
    }
  },
  computed: { ...mapGetters({ t: 'i18n/t' }) },
  watch:    {
    isExpandAll(val, oldVal) {
      this.refreshTable = false;
      this.expandeAll = val;
      this.$nextTick(() => {
        this.refreshTable = true;
      });
    }
  },

  methods: {
    namespaceLink(namespace) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource-id`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_NAMESPACE,
          id:       namespace
        },
      };
    },
    projectLink(project) {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource-id`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: MANAGEMENT.PROJECT,
          id:       `${ this.params.cluster }/${ project }`
        },
        query: { mode: _EDIT }
      };
    },
    vmLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      };
    },
    podLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_POD,
        },
      };
    },
    nadLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.NAD,
        },
      };
    },
    cloudLink() {
      return {
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          cluster:  this.params.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      };
    }
  },
};
</script>
<style lang="scss">
.cluster-single-table {
  .el-progress-bar__outer{
    width: 100px;
  }
  .el-progress-bar__outer{
    width: 80px;
  }
  .el-progress__text {
    margin-left: 0px;
  }
  .el-table__body{
    width: 100%;
    table-layout: fixed !important;
  }
}
</style>
