<script>
import ResourceDetail from '../../../../../../components/ResourceDetail';

export default {
  name:       'RolesResourceCreate',
  layout:     'pai/default',
  components: { ResourceDetail },
};
</script>

<template>
  <ResourceDetail />
</template>
