<template>
  <div class="cluster-single-chart bgCard">
    <div class="chart-top cluster-flex">
      <div class="cluster-title">
        {{ t('pai.detail.vmset.runningTrend') }}
      </div>
      <div class="chart-time">
        <span
          v-for="(item) in timeArr"
          :key="item.value"
          :class="{'active': timeIndex === item.value}"
          @click="handleChange(item.value)"
        >{{ item.label }}</span>
      </div>
    </div>
    <div class="chart-bottom">
      <div
        ref="runChart"
        style="width:100%; height: 230px"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import day from 'dayjs';
import metricPoller from '@shell/mixins/metric-poller';
import { getLastHour, getLastDayHour, supplementZero } from '../../../../../utils/units';

export default {
  name: 'ClusterSingleChart',
  data() {
    const params = this.$route.params;
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';

    return {
      params,
      timeIndex: 1,
      startTime: new Date(String(day().subtract(1, 'hours').format(dateFormat))).getTime() / 1000,
      endTime:   new Date(String(day().format(dateFormat))).getTime() / 1000,
      step:      300,
      chartTime: [],
      cpuData:   [],
      ramData:   [],
      diskData:  [],
      timeArr:   [
        {
          label:    this.t('pai.detail.vmset.tab.monitor.anHour'),
          value:    1,
          isActive: true,
        }, {
          label:    this.t('pai.detail.vmset.tab.monitor.twentyFourHours'),
          value:    24,
          isActive: false,
        }
      ]
    };
  },
  mixins: [metricPoller],

  fetch() {
    this.loadMetrics();
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  watch: {
    cpuData() {
      this.initChart();
    },
    ramData() {
      this.initChart();
    },
    diskData() {
      this.initChart();
    },
  },
  methods: {
    loadMetrics() {
      if (this.timeIndex === 1) {
        this.step = 300;
        this.startTime = new Date(String(day().subtract(1, 'hours').format(this.dateFormat))).getTime() / 1000;// 1小时
        this.endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000;
        this.chartTime = getLastHour(new Date(), 12);
      } else if (this.timeIndex === 24) {
        this.step = 3600;
        this.startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000;// 24小时
        this.endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000;
        this.chartTime = getLastDayHour(new Date(), 24);
      }
      this.getData();
    },
    handleChange(val) {
      this.timeIndex = val;
      if (val === 1) {
        this.step = 300;
        this.startTime = new Date(String(day().subtract(1, 'hours').format(this.dateFormat))).getTime() / 1000;// 1小时
        this.endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000;
        this.chartTime = getLastHour(new Date(), 12);
      } else if (val === 24) {
        this.step = 3600;
        this.startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000;// 24小时
        this.endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000;
        this.chartTime = getLastDayHour(new Date(), 24);
      }
      this.getData();
    },
    initChart() {
      if (!this.$refs.runChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.runChart);

      myChart.clear();
      let option = {};

      if (this.cpuData?.length !== 0 && this.ramData?.length !== 0 && this.diskData?.length !== 0) {
        option = {
          animation: false,
          color:     ['#617BCA', '#93CC77', '#FAC95D'],
          legend:    {
            left: '40%',
            data: ['CPU', '内存', '磁盘']
          },
          grid: {
            left:         '30px',
            right:        '30px',
            bottom:       '30px',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `
               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        this.chartTime,
          },
          yAxis: {
            type:    'value',
            name:    '单位：%',
            nameGap: '20',
            max:     100,
          },
          series: [
            {
              name:      'CPU',
              type:      'line',
              smooth:    true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color:  'rgba(208, 223, 234, 1)'
                  }, {
                    offset: 0.8,
                    color:  'rgba(251, 253, 253, 1)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur:  10
                }
              },
              data: supplementZero(this.chartTime, this.cpuData),
            },
            {
              name:      this.t('pai.vmset.ram'),
              type:      'line',
              smooth:    true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color:  'rgba(147, 204, 119, 1)'
                  }, {
                    offset: 0.8,
                    color:  'rgba(251, 253, 253, 1)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur:  10
                }
              },
              data: supplementZero(this.chartTime, this.ramData),
            },
            {
              name:      this.t('pai.vmset.disk'),
              type:      'line',
              smooth:    true,
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color:  'rgba(250, 203, 98, 1)'
                  }, {
                    offset: 0.8,
                    color:  'rgba(251, 253, 253, 1)'
                  }], false),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur:  10
                }
              },
              data: supplementZero(this.chartTime, this.diskData),
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      '暂无数据',
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getChartData(datas) {
      let values = [];

      datas.data?.data?.result?.map((result) => {
        values = result?.values?.map((i) => {
          const now = new Date(i[0] * 1000);

          if (this.timeIndex == 1) {
            i[0] = `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          } else {
            i[0] = `${ now.getMonth() + 1 }/${ now.getDate() } ${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          }
          i[1] = (parseFloat(i[1])).toFixed(2);

          return i;
        });
      });

      return values;
    },
    async getData() {
      const paramsCpu = { url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate({__name__=~"node_cpu_seconds_total",mode="idle"}[240s])))*100&start=${ this.startTime }&end=${ this.endTime }&step=${ this.step }` };
      const resultCpu = await this.$store.dispatch('pai-common/getUse', paramsCpu);

      if (resultCpu && resultCpu?.status === 200) {
        this.cpuData = this.getChartData(resultCpu);
      }

      const paramsRam = {
        //  内存
        url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - sum(node_memory_MemAvailable_bytes OR windows_os_physical_memory_free_bytes) / sum(node_memory_MemTotal_bytes OR windows_cs_physical_memory_bytes))*100&start=${ this.startTime }&end=${ this.endTime }&step=${ this.step }`,
      };
      const resultRam = await this.$store.dispatch('pai-common/getUse', paramsRam);

      if (resultRam && resultRam?.status === 200) {
        this.ramData = this.getChartData(resultRam);
      }

      const paramsDisk = {
        //  磁盘
        url: `/k8s/clusters/${ this.params.cluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes) by (device, instance)))) / ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes{device=~"data/.*|longhorn/.*"}) by (device, instance) or vector(0)))) * 100&start=${ this.startTime }&end=${ this.endTime }&step=${ this.step }`
      };
      const resultDisk = await this.$store.dispatch('pai-common/getUse', paramsDisk);

      if (resultDisk && resultDisk?.status === 200) {
        this.diskData = this.getChartData(resultDisk);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cluster-single-chart{
  .chart-top{
    .chart-time{
      margin-right: 70px;
      span{
        margin: 0px 16px;
        cursor: pointer;
      }
      .active{
        color: #165DFF;
      }
    }
  }
  .chart-bottom {
    width: 100%;
  }
}
</style>
