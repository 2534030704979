<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import { RadioGroup } from '@components/Form/Radio';
import UnitInput from '@shell/components/form/UnitInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import Tabbed from '@shell/components/Tabbed/index.vue';
import Tab from '@shell/components/Tabbed/Tab.vue';

export default {
  components: {
    Tab,
    Tabbed,
    LabeledSelect,
    SectionTitle,
    LabeledInput,
    RadioGroup,
    UnitInput
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    return {
      enabledOptions: [
        {
          label: this.t('generic.enabled'),
          value: true
        },
        {
          label: this.t('generic.disabled'),
          value: false
        }
      ]
    };
  },
  computed: {
    imagePolicyOptions() {
      return [{
        label: this.t('generic.imagePullPolicy.always'),
        value: 'Always',
      }, {
        label: this.t('generic.imagePullPolicy.ifNotPresent'),
        value: 'IfNotPresent',
      }, {
        label: this.t('generic.imagePullPolicy.never'),
        value: 'Never'
      }];
    },
    nameNodeEnabled() {
      return !this.value.persistence.nameNode.enabled;
    },
    dataNodeEnabled() {
      return !this.value.persistence.dataNode.enabled;
    },
  },
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <Tabbed :side-tabs="true">
      <!--    通用-->
      <Tab
        label-key="monitoring.tabs.general"
        name="general"
        :weight="99"
        :display-alert-icon="conditionsHaveIssues"
      >
        <template>
          <div class="row mt-10">
            <div class="col span-6">
              <LabeledInput
                v-model.number="value.nodeCount"
                type="number"
                :label="t('pai.edit.chart.nodeCount')"
              />
            </div>
          </div>
        </template>
        <!--    nameNode-->
        <template>
          <div class="row mt-10">
            <SectionTitle value="nameNode" />
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <UnitInput
                v-model="value.resources.nameNode.cpu"
                label="CPU"
                :input-exponent="-1"
                :output-modifier="true"
                :base-unit="t('suffix.cpus')"
              />
            </div>
            <div class="col span-6">
              <UnitInput
                v-model="value.resources.nameNode.mem"
                :label="t('pai.vmset.ram')"
                :input-exponent="2"
                :increment="1024"
                :output-modifier="true"
              />
            </div>
          </div>
        </template>
        <!--    dataNode-->
        <template>
          <div class="row mt-10">
            <SectionTitle
              value="dataNode"
              class="row"
            />
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <UnitInput
                v-model="value.resources.dataNode.cpu"
                label="CPU"
                :input-exponent="-1"
                :output-modifier="true"
                :base-unit="t('suffix.cpus')"
              />
            </div>
            <div class="col span-6">
              <UnitInput
                v-model="value.resources.dataNode.mem"
                :label="t('pai.vmset.ram')"
                :input-exponent="2"
                :increment="1024"
                :output-modifier="true"
              />
            </div>
          </div>
        </template>
      </Tab>
      <!--    高级-->
      <Tab
        label-key="pai.vmset.advancedOptions"
        name="advancedOptions"
        :weight="98"
        :display-alert-icon="conditionsHaveIssues"
      >
        <template>
          <div class="row mt-10">
            <div class="col span-6">
              <LabeledSelect
                v-model="value.image.imagePullPolicy"
                label-key="workload.container.imagePullPolicy"
                :options="imagePolicyOptions"
              />
            </div>
          </div>
        </template>
        <!--    nameNode-->
        <template>
          <div class="row mt-10">
            <SectionTitle value="nameNode" />
          </div>
          <div class="row mt-10">
            <div class="col span-12">
              <RadioGroup
                v-model="value.persistence.nameNode.enabled"
                name="nameNodeEnabled"
                :label="t('pai.edit.chart.enabled')"
                :options="enabledOptions"
                :row="true"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.nameNode.accessMode"
                :label="t('pai.detail.vmset.tab.diskManagement.accessMode')"
                :disabled="nameNodeEnabled"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.nameNode.storageClass"
                :label="t('pai.detail.vmset.tab.diskManagement.storageClass')"
                :disabled="nameNodeEnabled"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <UnitInput
                v-model="value.persistence.nameNode.size"
                :label="t('pai.detail.vmset.tab.diskManagement.storageSize')"
                :input-exponent="3"
                :increment="1024"
                :output-modifier="true"
                :disabled="nameNodeEnabled"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.image.namenode"
                :label="t('pai.vmset.image.url')"
              />
            </div>
          </div>
        </template>
        <!--    dataNode-->
        <template>
          <div class="row mt-10">
            <SectionTitle
              value="dataNode"
              class="row"
            />
          </div>
          <div class="row mt-10">
            <div class="col span-12">
              <RadioGroup
                v-model="value.persistence.dataNode.enabled"
                name="dataNodeEnabled"
                :label="t('pai.edit.chart.enabled')"
                :options="enabledOptions"
                :row="true"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.dataNode.accessMode"
                :label="t('pai.detail.vmset.tab.diskManagement.accessMode')"
                :disabled="dataNodeEnabled"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.dataNode.storageClass"
                :label="t('pai.detail.vmset.tab.diskManagement.storageClass')"
                :disabled="dataNodeEnabled"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <UnitInput
                v-model="value.persistence.dataNode.size"
                :label="t('pai.detail.vmset.tab.diskManagement.storageSize')"
                :input-exponent="3"
                :increment="1024"
                :output-modifier="true"
                :disabled="dataNodeEnabled"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.image.datanode"
                :label="t('pai.vmset.image.url')"
              />
            </div>
          </div>
        </template>
        <!--    hiveMysql-->
        <template>
          <div class="row mt-10">
            <SectionTitle
              :value="t('pai.edit.chart.hiveMysql')"
              class="row"
            />
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.hiveMysql.accessMode"
                :label="t('pai.detail.vmset.tab.diskManagement.accessMode')"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.persistence.hiveMysql.storageClass"
                :label="t('pai.detail.vmset.tab.diskManagement.storageClass')"
              />
            </div>
          </div>
          <div class="row mt-10">
            <div class="col span-6">
              <UnitInput
                v-model="value.persistence.hiveMysql.size"
                :label="t('pai.detail.vmset.tab.diskManagement.storageSize')"
                :input-exponent="3"
                :increment="1024"
                :output-modifier="true"
              />
            </div>
            <div class="col span-6">
              <LabeledInput
                v-model="value.image.hivemysql"
                :label="t('pai.vmset.image.url')"
              />
            </div>
          </div>
        </template>
      </Tab>
    </Tabbed>
  </div>
</template>
