<script>

import PaiPodsUsage from '../../../formatters/PaiPodsUsage.vue';
import { CAPI, CATALOG, MANAGEMENT } from '@shell/config/types';
import { formatTime } from '../../../utils';
import { colorForState, stateDisplay } from '@shell/plugins/dashboard-store/resource-class';
import { get } from '@shell/utils/object';
import { convertUnitToG, numDelivery } from '../../../utils/units';
import { BLANK_CLUSTER } from '@shell/store';
import { PAI_CAPI, PAI_CATALOG } from '../../../config/types';
import day from 'dayjs';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';

export default {
  name:       'ClusterCard',
  components: { PaiPodsUsage },
  props:      {
    cluster: {
      type:     Object,
      required: true,
    },
  },
  watch: {
    cluster: {
      handler(neu) {
        this.getIsMonitor();
      },
      immediate: true
    },
  },
  data() {
    return { current: false, containers: [] };
  },
  methods: {
    get,
    convertUnitToG,
    formatTime,
    async onActionStar(item) {
      try {
        await item.setStar();
        this.$emit('changeCollection');
      } catch (e) {}
    },
    isActive(cluster) {
      return stateDisplay(cluster.metadata?.state?.name) === 'Active';
    },
    stateDisplay(value) {
      return stateDisplay(value.metadata?.state?.name);
    },
    colorForState(value) {
      return colorForState(value.metadata?.state?.name);
    },
    creationTimestamp(value) {
      const cluster = this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, value.name);

      return cluster ? formatTime(cluster.metadata.creationTimestamp) : formatTime(value.metadata.creationTimestamp);
    },
    deleteCluster() {
      this.cluster.promptRemove();
    },
    toView() {
      return this.$router.push({ name: 'pai-c-cluster-overview', params: { cluster: this.cluster.mgmtClusterId, product: 'pai' } });
    },
    toManage() {
      return this.$router.push({
        name:   `manage-cluster-resource-namespace-id`,
        params: {
          id:        this.cluster.name,
          namespace: this.cluster.namespace,
          cluster:   BLANK_CLUSTER,
          resource:  PAI_CAPI.RANCHER_CLUSTER,
        },
      });
    },
    getIsMonitor() {
      const startTime = new Date(String(day().subtract(24, 'hours').format(this.dateFormat))).getTime() / 1000; // 24小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳

      const params = {
        showGrowl: false, clusterName: '', url: `/k8s/clusters/${ this.cluster.mgmtClusterId }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate({__name__=~"node_cpu_seconds_total",mode="idle"}[240s])))*100&start=${ startTime }&end=${ endTime }&step=3600`
      };

      this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404) {
          this.$set(this.cluster, 'isMonitor', false);
        } else {
          this.$set(this.cluster, 'isMonitor', true);
        }
      });
    },
    monitorLink(cluster) {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-chart`,
        params: {
          cluster: cluster.mgmtClusterId,
          product: PRODUCT_NAME,
        },
        query: {
          'repo-type': 'cluster', repo: 'rancher-charts', chart: 'rancher-monitoring'
        }
      });
    }
  },
};
</script>

<template>
  <div
    :key="cluster.id"
    class="card"
  >
    <i
      :class="cluster.starClass"
      @click="onActionStar(cluster)"
    />
    <div class="top">
      <img
        src="@pkg/pai/assets/images/overview/cluster.png"
        alt=""
      >
      <div class="name">
        <nuxt-link
          v-if="isActive(cluster)"
          :to="{name: 'pai-c-cluster-overview', params: { cluster: cluster.mgmtClusterId, product: 'pai' }}"
        >
          {{ cluster.nameDisplay }} {{ cluster.haveCollection }}
        </nuxt-link>
        <div v-else>
          {{ cluster.nameDisplay }}
        </div>
        <div class="stateBox">
          <div class="stateBox-left">
            <div
              class="state"
              :class="`${colorForState(cluster).replace('text-', 'bg-')}`"
            />
            {{ stateDisplay(cluster) }}
          </div>
          <div
            class="stateBox-right"
          >
            <div
              v-if="cluster.isMonitor === false"
              class="monitor"
              :style="{ marginLeft: stateDisplay(cluster) == 'Unavailable'? '30px': '71px' }"
              @click="monitorLink(cluster)"
            >
              <img src="../../../assets/images/overview/monitorBlue.svg">
              <a>
                {{ t('pai.detail.vmset.unMonitorInstall') }}
              </a>
            </div>
            <div
              v-else
              class="monitorNo"
              :style="{ marginLeft: stateDisplay(cluster) == 'Unavailable'? '26px': '58px' }"
            >
              <img src="../../../assets/images/overview/monitorGreen.svg">
              <a>
                {{ t('pai.detail.vmset.monitorInstall') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="line" />
    <div
      class="bottom"
      @mouseover="current=true"
      @mouseleave="current=false"
    >
      <el-row v-show="!current">
        <el-col :span="10">
          CPU：{{
            (get(cluster, 'mgmt.status.capacity.cpu') ? get(cluster, 'mgmt.status.capacity.cpu') : '0') + t('suffix.cores')
          }}
        </el-col>
        <el-col :span="14">
          {{ t('pai.overview.pod') }}：
          <PaiPodsUsage :row="cluster" />
        </el-col>
        <el-col :span="10">
          {{ t('pai.overview.memory') }}：{{ convertUnitToG(get(cluster, 'mgmt.status.capacity.memory')) }}G
        </el-col>
        <el-col :span="14">
          {{ cluster.kubernetesVersion }}
        </el-col>
        <el-col :span="10">
          {{ t('pai.overview.node') }}：{{ cluster.mgmt.status.nodeCount }}
        </el-col>
        <el-col :span="14">
          {{ t('pai.overview.createTime') }}：
          <el-tooltip
            effect="light"
            :content="creationTimestamp(cluster)"
          >
            <span>{{ creationTimestamp(cluster) }}</span>
          </el-tooltip>
        </el-col>
      </el-row>
      <div
        v-show="current"
        class="action"
      >
        <div
          v-if="isActive(cluster)"
          @click="toView"
        >
          <img
            src="../../../assets/images/common/view.svg"
            alt=""
          >{{ t('cluster.viewAction') }}
        </div>
        <div @click="toManage">
          <img
            src="../../../assets/images/common/manage.svg"
            alt=""
          >{{ t('cluster.manageAction') }}
        </div>
        <div @click="deleteCluster">
          <img
            src="../../../assets/images/common/delete.svg"
            alt=""
          >{{ t('cluster.deleteAction') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
  box-shadow:
          0 1px 1px rgb(0 0 0 / 0.25),
          0 2px 2px rgb(0 0 0 / 0.20),
          0 4px 4px rgb(0 0 0 / 0.15),
          0 8px 8px rgb(0 0 0 / 0.10),
          0 16px 16px rgb(0 0 0 / 0.05);
  max-width: 280px;
  height: 135px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(239, 239, 239, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  border: 1px solid rgba(206, 206, 206, 1);
  padding: 10px;
  margin-right: 10px;
  margin-bottom: 10px;

  i {
    float: right;
    cursor: pointer;
    color: #FCCA00;
  }

  .top {
    height: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      margin-right: 15px;
    }

    .name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .active {
        cursor: pointer;
        color: #3d98d3;
      }

      .notActive {
        cursor: none;
        color: #000000;
      }
    }
    .stateBox{
      display: flex;
      .state {
        display: inline-block;
        margin-right: 8px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        font-family: SourceHanSansSC-regular, serif;
      }
      .monitor, .monitorNo{
        img {
          width: 15px;
          position: relative;
          top: 2px;
          margin: 0px;
        }
        a {
          font-size: 12px;
          cursor: pointer;
        }
      }
      .monitorNo {
        a{
          color: #4AC58F;
          cursor: default;
        }
      }
    }
  }

  .line {
    border-bottom: 2px solid var(--primary);
    height: 5%;
  }

  .bottom {
    font-size: 12px;
    text-align: left;
    height: 55%;
    display: flex;
    align-items: center;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.action {
  width: 258px;
  margin-top: 5px;
  display: flex;
  justify-content: space-around;

  .disabled {
    cursor: default;
    color: #bbb;
    pointer-events: none;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
      width: 22px;
      margin-bottom: 5px;
    }
  }
}
</style>
